<!-- ----------------------- -->
<!-- 
<textarea cols="50" rows="25" [value]="config | stringifyJson : 4" (blur)="updateConfig($event)">
</textarea>
<div>
<button type="button" (click)="toggleCheckout()">
  Toggle Checkout Screen
</button>
<button type="button" (click)="initializeCheckout()">
  Re-render with updated config
</button>
<input type="checkbox" [checked]="openInPopup" (change)="openInPopup = !openInPopup; initializeCheckout();" /> Open in popup
</div>
<br />

<div><b>CHECKOUT VISIBILITY :</b> {{isCheckoutVisible}}</div> -->

<paytm-checkout *ngIf="isCheckoutVisible"></paytm-checkout>
<!-- ----------------------- -->

<div class="container-fluid">
    <div class="paymentGateway">
        <div class="mt-3">
            <br>
            <div class="row my-3">
                
                <!-- <div class="col col-lg-7 col-md-6 col-sm-12 col-xs-12">
                    <div class="paymentContainer">
                        <div class="orderSummary">
                            <h6 class="float-left">Conventional Fee Details</h6>
                        </div>
                        <div class="card ">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <td class="width10"><img class="creditcard"
                                                src="../../assets/images/debitcard.png"></td>
                                        <td class="width20"><label><strong>Debit Card</strong></label></td>
                                        <td class="width60"> <label>Upto Rs.2000/- NIL & Above RS.2000/- Rs.1.0%</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card ">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <td class="width10"><img class="creditcard"
                                                src="../../assets/images/creditcard.png"></td>
                                        <td class="width20"><label><strong>Credit Card</strong></label></td>
                                        <td class="width60"> <label>Rs.1.1% (Irrespective of Amount)</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card ">
                            <table class="table ">
                                <tbody>
                                    <tr>
                                        <td class="width10"><img class="creditcard1"
                                                src="../../assets/images/netbanking.jpg"></td>
                                        <td class="width20"><label><strong>Net Banking</strong></label></td>
                                        <td class="width5 text-center"><img class="creditcard1"
                                                src="../../assets/images/hdfc.png"></td>
                                        <td class="width30">
                                            <div>
                                                <label><strong></strong></label>
                                            </div>
                                            <div>
                                                <label>For HDFC Bank</label>
                                            </div>
                                        </td>
                                        <td class="width5 text-center"><img class="creditcard1"
                                                src="../../assets/images/icici.jpg"></td>
                                        <td class="width30">
                                            <div>
                                                <label><strong></strong></label>
                                            </div>
                                            <div>
                                                <label>For ICICI Bank</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="width10"></td>
                                        <td class="width20"></td>
                                        <td class="width5 text-center"><img class="creditcard1"
                                                src="../../assets/images/sbi.png"></td>
                                        <td class="width30">
                                            <div>
                                                <label><strong></strong></label>
                                            </div>
                                            <div>
                                                <label>For SBI Bank</label>
                                            </div>
                                        </td>
                                        <td class="width5 text-center"><img class="creditcard1"
                                                src="../../assets/images/bank.png"></td>
                                        <td class="width30">
                                            <div>
                                                <label><strong></strong></label>
                                            </div>
                                            <div>
                                                <label>For Other Bank</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card ">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <td class="width10"><img class="creditcard" src="../../assets/images/neft.png">
                                        </td>
                                        <td class="width20"><label><strong>NEFT</strong></label></td>
                                        <td class="width60"> <label>Rs.7/- For All Transactions</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card ">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <td class="width10"><img class="creditcard" src="../../assets/images/upi.png">
                                        </td>
                                        <td class="width20"><label><strong>UPI</strong></label></td>
                                        <td class="width60"> <label>Upto Rs.2000/- Rs.3 & Above RS.2000/- Rs.7</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card ">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <td class="width10"><img class="creditcard"
                                                src="../../assets/images/wallet.png"></td>
                                        <td class="width20"><label><strong>Wallets</strong></label></td>
                                        <td class="width60"> <label>Rs.1.80% (Irrespective of Amount)</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>

                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3 clsbox">
                    
                    <h5 class="text-center mt-3 mb-3">PAYMENT GATEWAY</h5>

                    <div class="paymentContainer">
                        <div class="orderSummary">
                            <h6 class="float-left">Payment Details</h6>
                        </div>
                        <div>
                            <label class="labelCls "><strong>Reference No : </strong> {{reqsuisionId}} </label>
                        </div>
                        <br>
                        <div>
                            <label class="labelCls">Amount To Pay</label>
                            <span><strong>{{amountData?.userCharges | currency:'INR'}}</strong></span>
                        </div>
                        <br>
                        <div>
                            <label class="labelCls">Surcharge Amount</label>
                            <span><strong>{{amountData?.statutaryCharges | currency:'INR'}}</strong></span>
                        </div>
                        <br>
                        <div>
                            <label class="labelCls">Convenience Fee <span id="neft"><strong>NEFT</strong></span></label>
                            <span><strong>{{amountData?.serviceCharges | currency:'INR' }}</strong></span>
                        </div>
                        <br>
                        <div class="totalamount">
                            <label class="labelCls " id="sizeFont">Total Amount</label>
                            <span><strong>{{amountData?.totalAmount | currency:'INR'}}</strong></span>
                        </div>
                        <br>
                        <div class="text-center" *ngIf="isDisabled">
                            <label class="labelCls1">
                                <fa-icon class="float-center" [icon]="faLock" size="2x"></fa-icon>&nbsp;&nbsp;&nbsp;
                                Secure Checkout by&nbsp;&nbsp;&nbsp; <img class="oneone"
                                    src="../../assets/images/billDesk.png">
                            </label>
                        </div>
                        <br>
                        <div class="row mb-3" *ngIf="isDisabled">
                        
                            <div class="col col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                <label class="labelCls">Select Payment Method</label>
                            </div>

                            <!-- <div class="col col-lg-7 col-md-7 col-sm-7 col-xs-7" *ngIf="!isLoginPresent && isProd">
                                <strong> 
                                      <input type="radio" name="mode" value="UPI" [checked]="(userInfo.jobTitle === 'Citizen' || userInfo.jobTitle === 'Meeseva')? true : false"
                                        (click)="selectPaymentMode('UPI')"><span class="mx-1 radioCls">Online</span>
                                        <ng-container *ngIf="userInfo.jobTitle !== 'Citizen' && userInfo.jobTitle !== 'Meeseva'">
                                        <input  type="radio" name="mode" value="CASH" [checked]=true (click)="selectPaymentMode('CASH')"><span class="mx-1 radioCls">Cash</span>
                                        </ng-container>
                                </strong>
                            </div> -->

                            <div class="col col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                <strong> 
                                      <input type="radio" name="mode" value="UPI" [checked]="(userInfo.jobTitle === 'Citizen' || userInfo.jobTitle === 'Meeseva')? true : false"
                                        (click)="selectPaymentMode('UPI')"><span class="mx-1 radioCls">BillDesk Online</span>
                                        <br>
                                        <ng-container *ngIf="userInfo.jobTitle !== 'Citizen' && userInfo.jobTitle !== 'Meeseva'">
                                            <input type="radio" name="mode" value="UPI" [checked]="!isCashModeEnable"
                                            (click)="selectPaymentMode('PaytmUPI')"><span class="mx-1 radioCls">Paytm Online</span>
                                            <br>
                                            <ng-container *ngIf="isCashModeEnable">
                                                <input  type="radio" name="mode" value="CASH" [checked]=true (click)="selectPaymentMode('CASH')"><span class="mx-1 radioCls">Cash</span>
                                            </ng-container>
                                        </ng-container>
                                </strong>
                            </div>
                        </div>

                        <div class="text-center ">
                            <button name="payment" class="btn btn-success col-lg-6" (click)="confirmPay()" *ngIf="isDisabled">Continue
                                Payment</button>
                        </div>
                        <!-- <br>
                        <div class="text-center ">
                            <button name="payment" class="btn btn-success col-lg-6"(click)="getPaytmCheckSomeData();">Paytm
                                Payment</button>
                        </div> -->
                        <br>
                        <div class="text-center ">
                            <a class="cancel" href="">Cancel Payment</a>
                        </div>
                       <!--  <br>
                        <div class="card ">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>

                                         <td class="width40"><label>
                                                <fa-icon class="float-center" [icon]="faInfoCircle" size="1x"></fa-icon>
                                                &nbsp;&nbsp; For Payment gateway related queries :
                                            </label></td>
                                        <td class="width60"> <label>ph : <strong> 8008612200 / 8008615500
                                                </strong></label>
                                            <label>Email : <strong>info@anyemi.com </strong></label>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                        <br>
                    </div>

                </div>
               
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
            
            </div>
        </div>

    </div>
</div>

<div [className]="isActive ? 'imgLoader' : 'imgLoaderHide'">
    <img src="../../assets/loader.gif">
</div>