import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AarogyaSriComponent } from './aarogya-sri/aarogya-sri.component';
import { UpdateAarogyaSriComponent } from './update-aarogya-sri/update-aarogya-sri.component';
import{HealthCardStatusComponent}from'./health-card-status/health-card-status.component';
import { AuthGuard } from 'src/shared/auth.guard';
import { HealthCardComponent } from './health-card/health-card.component';
import { PatientBankDetailsComponent } from './patient-bankdetails/patient-bankdetails.component';
import{AarogyaSriCardDispatchStatusComponent} from './aarogya-sri-card-dispatch-status/aarogya-sri-card-dispatch-status.component'
const routes: Routes = [
  // { path: 'aarogya-sri',component: AarogyaSriComponent,data:{isCitizen: true}, canActivate: [AuthGuard]},
   { path: 'update-aarogya-sri',component: UpdateAarogyaSriComponent, canActivate: [AuthGuard]},
   {path:'health-card-status',component:HealthCardStatusComponent, canActivate: [AuthGuard]},
   { path: 'health-card', component : HealthCardComponent },
   { path: 'patient-bankdetails',component: PatientBankDetailsComponent, canActivate: [AuthGuard]},
  // {path:'dispatch-status',component:AarogyaSriCardDispatchStatusComponent,canActivate : [AuthGuard]}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthRoutingModule { }
