<div class="row mainCls daDashboardPage">
    <div class="container-fluid ">
       <div class="breadcrumbContainer"> 
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                 <button  class="greenBtnCls float-right"  (click)="refreshDashboard()">Refresh</button>
                 <div class="float-right mt-2 mr-2"><span class="boldText">Last Updated:</span> {{lastUpdate | date : 'hh:mm a'}} on {{lastUpdate | date : 'dd MMMM yyyy'}}</div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
            <div class="col col-lg-4 col-md-4 col-sm-6 col-xs-12" (click)="showDashBoardPopup(showRequisitionData,'totalServiceRaised');">
                <div class="dashboardBox registered">
                    <div class="serviceDetails">
                       <!--  <div>Service Requests Registered</div> -->
                        <div class="tilesFont">Number of Service Requests <br> Raised</div>
                        <span class="count">{{ totalNumberOfServicesRaised }}</span><span class="ml-1"> Requests </span>
                    </div>
                </div>
            </div>
          
            <div *ngIf="this.userInfo.jobTitle !== 'IT Minister'" class="col col-lg-4 col-md-4 col-sm-6 col-xs-12" (click)="showDashBoardPopup(showRequisitionData,'totalServiceFromSame');">
                <div class="dashboardBox delivered">
                    <div class="serviceDetails">
                        <!-- <div>Services Delivered</div> -->
                        <div class="tilesFont">Number of Service Requests Raised &<br> Resided In The Same Secretariat </div>
                        <span class="count"> {{ totalNumberOfServicesRaisedInSameSecretariat }} </span><span class="ml-1"> Requests </span>
                    </div>
                </div>
            </div>
          
            <div *ngIf="this.userInfo.jobTitle !== 'IT Minister'"  class="col col-lg-4 col-md-4 col-sm-6 col-xs-12" (click)="showDashBoardPopup(showRequisitionData,'totalServiceFromOther');">
                <div class="dashboardBox pendingApproval">
                    <div class="serviceDetails">
                        <!-- <div>Pending For Approval</div> -->
                        <div class="tilesFont"> Number of Service Requests Flown To <br>This Secretariat</div>
                        <span class="count">{{ totalNumberOfServicesFlownToThisSecretariat }}</span><span class="ml-1"> Requests </span>
                    </div>
                </div>
            </div>
            <!-- <div class="col col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboardBox resReceived">
                    <div class="serviceDetails">
                        <div>Response Received</div>
                        <span class="count">138</span><span class="ml-1">This year</span>
                    </div>
                </div>
            </div> -->
        
        </div>
        
        <div class="row mt-3" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva' && this.userInfo.jobTitle !== 'IT Minister'">
              <div class="col">
                  <div class="dashboardBox adjustBackgroinImg totalSubmitted" (click)="getVRODashBoardDetailsList(showReqData,'All','All',dashbardVroCounts?.toT_APPS)">
                      <div class="row">
                       <div class="col col-lg-10 col-md-10 text-center p-0"> 
                          <span class="count">{{ (dashbardVroCounts?.toT_APPS !== '' ) ? (dashbardVroCounts?.toT_APPS):0 }}</span>
                          <div class="tilesFont">Total Service Requests</div>
                      </div>
                  </div>
                  </div>
              </div>
  
              <div class="col" *ngIf="this.userInfo.jobTitle !== 'IT Minister'"> 
                <div class="dashboardBox adjustBackgroinImg openWithinSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Open','Within-SubSLA',dashbardVroCounts?.opeN_WITH)"> 
                      <div class="row">
                       <div class="col col-lg-10 col-md-10 text-center p-0"> 
                          <span class="count">{{ (dashbardVroCounts?.opeN_WITH !== '' ) ? (dashbardVroCounts?.opeN_WITH):0 }}</span> <br>
                          <div class="tilesFont">Open Within SLA</div>
                      </div>
                  </div>
                  </div>
              </div>
              <div class="col">
                  <div class="dashboardBox adjustBackgroinImg openBeyondSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Open','Beyond-SubSLA',dashbardVroCounts?.opeN_BEYOND)"> 
                      <div class="row">
                       <div class="col col-lg-10 col-md-10 text-center p-0"> 
                          <span class="count">{{ (dashbardVroCounts?.opeN_BEYOND !== '' ) ? (dashbardVroCounts?.opeN_BEYOND):0 }}</span> <br>
                          <div class="tilesFont">Open Beyond SLA</div>
                      </div>
                  </div>
                  </div>
              </div>
          
              <div class="col" *ngIf="this.userInfo.jobTitle !== 'IT Minister'">
                  <div class="dashboardBox adjustBackgroinImg closedWithinSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Closed','Within-SubSLA',dashbardVroCounts?.closeD_WITH)"> 
                      <div class="row">
                       <div class="col col-lg-10 col-md-10 text-center p-0"> 
                          <span class="count"> {{ (dashbardVroCounts?.closeD_WITH !== '' ) ? (dashbardVroCounts?.closeD_WITH):0 }}</span> <br>
                                  <div class="tilesFont">Closed Within SLA</div>
                      </div>
                  </div>
                  </div>
              </div>
          
              <div class="col" *ngIf="this.userInfo.jobTitle !== 'IT Minister'">
                  <div class="dashboardBox adjustBackgroinImg closedBeyondSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Closed','Beyond-SubSLA',dashbardVroCounts?.closeD_BEYOND)"> 
                      <div class="row">
                       <div class="col col-lg-10 col-md-10 text-center p-0"> 
                          <span class="count">{{ (dashbardVroCounts?.closeD_BEYOND !== '' ) ? (dashbardVroCounts?.closeD_BEYOND):0 }}</span><br>
                                  <div class="tilesFont">Closed Beyond SLA</div>
                      </div>
                  </div>
                  </div>
              </div>
         </div>
        
        </div>

        <div *ngIf="this.userInfo.jobTitle !== 'IT Minister'" class="row ml-3 mt-1">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0 pr-1 menuText">
                <div class="serviceHeader  mb-1">
                    <h6 class="float-left menuText">Services</h6>
                    <input type="text" class="inputCls" placeholder="Search Service Name" [(ngModel)]="searchService" (keyup)="searchServiceName()">
                </div>
                <div class="serviceContainer">
                    <ul class="mr-1 parentList">
                        <ng-container *ngFor = "let menu of menuConstants;let i = index;">

                        <li class="{{menu.imgCls}}" *ngIf="menu.showParent">
                            <b>{{menu.heading}}</b>
                            <span class="labelCls">{{menu.subHeading}} <span class="serviceCount">{{menuConstants[i].services?.length}}</span></span>
                            <a  class="subMenuLinkCls" (click)="menu.showMenu = !menu.showMenu"><span class="collapseArrow" *ngIf="!menu.showMenu"></span><span class="expandArrow" *ngIf="menu.showMenu"></span></a>
                            <ul *ngIf="menu.showMenu">
                                <li *ngFor = "let service of menuConstants[i].services | searchFilter:searchService; let j = index;"><a href="#" [routerLink]=service.path routerLinkActive='active'>{{j+1}}. <span  class="submenuText"  [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                            
                        </li>
                    </ng-container>
                        <!-- <li class="agriculture">
                            <b>Agriculture and Marketing</b>
                            <span class="labelCls">Agriculture <span class="serviceCount">15</span></span>
                            <a class="collapseArrow"></a>
                        </li>
                        <li class="fisheries">
                            <b>Animal Husbandry, Dairy Development &amp; Fisheries</b>
                            <span class="labelCls">Fisheries <span class="serviceCount">21</span></span>
                            <a class="collapseArrow"></a>
                        </li>
                        <li class="bcWelfare">
                            <b>Backward Classes Welfare</b>
                            <span class="labelCls">BC Welfare <span class="serviceCount">22</span></span>
                            <a class="collapseArrow"></a>
                        </li> -->
                        <!-- <li class="civil">
                            <b>Consumer Affairs, Food and Civil Supplies</b>
                            <span class="labelCls">Civil Supplies <span class="serviceCount">6</span></span>
                            <ng-container *ngFor = "let menu of menuConstants;let i = index;">
                            <a  class="subMenuLinkCls" (click)="menu.showMenu = !menu.showMenu" *ngIf="menu.module === 'Civil Supplies Department'"><span class="collapseArrow" *ngIf="!menu.showMenu"></span><span class="expandArrow" *ngIf="menu.showMenu"></span></a>
                                <ul *ngIf="menu.showMenu && menu.module === 'Civil Supplies Department'">
                                    <li *ngFor = "let service of menuConstants[i].services;let j = index;"><a href="#" [routerLink]=service.path routerLinkActive='active'>{{j+1}}. {{service.name}}</a></li>
                                </ul>
                            </ng-container>
                        </li> -->
                    </ul>
                    <ul class="parentList" >
                        <li class="police1" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                            <b>Municipal Administration and Urban Development</b>
                            <span class="labelCls">MAUD <span class="serviceCount">25</span></span>
                            <a  class="subMenuLinkCls" (click)="showMAUD = !showMAUD"><span class="collapseArrow" *ngIf="!showMAUD"></span><span class="expandArrow" *ngIf="showMAUD"></span></a>
                            <div *ngIf="showMAUD" class="mt-3">
                                <form [formGroup]="MAUDForm">
                                <select class="selectCls" required formControlName="mandal" (change)="onMandalChange()">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let mandal of mandals" [ngValue]="mandal">
                                        {{ mandal.name }}
                                    </option>
                                </select>
                                </form>
                                <ul>
                                    <li *ngFor = "let service of maudServices | searchFilter:searchService;let i=index"><a (click)="onClickUrl(service.entityCode)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                                    <!-- <li *ngFor = "let service of maudServices | searchFilter:searchService;let i=index"><a>{{i+1}}.<span [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li> -->
                                </ul>
                            </div>
                        </li>
                        <!-- <li class="energy">
                            <b>Energy</b>
                            <span class="labelCls">Energy <span class="serviceCount">56</span></span>
                            <a  class="subMenuLinkCls" (click)="showEnergy = !showEnergy"><span class="collapseArrow" *ngIf="!showEnergy"></span><span class="expandArrow" *ngIf="showEnergy"></span></a>
                            <div *ngIf="showEnergy" class="mt-3">
                                <div class="mb-2"><a class="bill-payments" href="https://payments.billdesk.com/MercOnline/CPDCLAPPGController" target="_blank">Electricity Bill Payments - CPDCL</a></div>
                                    <div class="mb-2"><a class="bill-payments" href="https://payments.billdesk.com/MercOnline/SPDCLController" target="_blank">Electricity Bill Payments - SPDCL</a></div>
                                <form [formGroup]="energyForm">
                                <select class="selectCls" required formControlName="district" (change)="onDistrictChange()">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let district of districts" [ngValue]="district">
                                        {{ district.name }}
                                    </option>
                                </select>
                                </form>
                                <ul>
                                    <li *ngFor = "let service of energyServices | searchFilter:searchService;let i=index"><a (click)="onClickEnergyUrl(service.entityCode)">{{i+1}}. <span [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                                </ul>
                            </div>
                        </li> -->
                        <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva' && !isProdTestUser">
                            <a (click)="onClickESeva()" class="redirectCls"><b>Bill Payment Services</b></a>
                        </li>
                    <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                        <a (click)="redirectToOldGSWS()" class="redirectCls"><b>Other Services</b></a>
                    </li>

                    <li *ngIf="this.userInfo.jobTitle !== 'Meeseva'">
                        <a (click)="redirectToOldGSWSNew()" class="redirectCls"><b>Meeseva Services</b></a>
                    </li> 
                    <li class="education" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                        <a class="redirectCls"><b>School Education</b></a>
                        <a  class="subMenuLinkCls" (click)="showSchool = !showSchool"><span class="collapseArrow" *ngIf="!showSchool"></span><span class="expandArrow" *ngIf="showSchool"></span></a>
                        <ul>
                            <li *ngIf="showSchool"><a target="_blank" href='https://cse.ap.gov.in/RTE'><b>RTE School Education</b></a></li>
                        </ul>
                    </li> 
                    <li *ngIf="this.userInfo.jobTitle !== 'Meeseva'">
                        <a class="redirectCls"><b>APSPDCL Complaints Registration</b></a>
                        <a  class="subMenuLinkCls" (click)="showComplaint = !showComplaint"><span class="collapseArrow" *ngIf="!showComplaint"></span><span class="expandArrow" *ngIf="showComplaint"></span></a>
                        <ul>
                            <li *ngIf="showComplaint"><a target="_blank" (click)="openCCC('complaint')"><b>Complaint registration</b></a></li>
                            <li *ngIf="showComplaint"><a target="_blank" (click)="openCCC('complaintStatus')"><b>Complaint status</b></a></li>
                        </ul>
                    </li> 
                   
                    <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                        <b>Transport Department</b>
                        <span class="labelCls">Transport Services <span class="serviceCount">{{transportServices?.length}}</span></span>
                        <a  class="subMenuLinkCls" (click)="showTransport = !showTransport"><span class="collapseArrow" *ngIf="!showTransport"></span><span class="expandArrow" *ngIf="showTransport"></span></a>
                        <div *ngIf="showTransport" class="mt-3">
                            <ul>
                                <li *ngFor = "let service of transportServices | searchFilter:searchService;let i=index"><a (click)="onClickTransportService(service)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                        </div>

                    </li>

<!--                     
                    <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva' && (isProdTestUser || !isProdEnv)">
                        <b>Registration and Stamps</b>
                        <span class="labelCls">Registration and Stamps Services <span class="serviceCount">1</span></span>
                        <a  class="subMenuLinkCls" (click)="showhinduMarriage = !showhinduMarriage"><span class="collapseArrow" *ngIf="!showhinduMarriage"></span><span class="expandArrow" *ngIf="showhinduMarriage"></span></a>
                        <div *ngIf="showhinduMarriage" class="mt-3">
                            <ul>
                                <li><a target="_blank" (click)="onClickHinduMarriageService()">1. Hindu Marriage Registration</a></li>
                            </ul>
                        </div>

                    </li> -->
                    
                    <!-- /////Open School Department Services started-->
                    <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                        <b>APOSS Amaravati</b>
                        <span class="labelCls">APOSS Amaravati Services <span class="serviceCount">{{openSchoolServices?.length}}</span></span>
                        <a  class="subMenuLinkCls" (click)="showOpenSchool = !showOpenSchool"><span class="collapseArrow" *ngIf="!showOpenSchool"></span><span class="expandArrow" *ngIf="showOpenSchool"></span></a>
                        <div *ngIf="showOpenSchool" class="mt-3">
                            <ul>
                                <li *ngFor = "let service of openSchoolServices | searchFilter:searchService;let i=index"><a (click)="onClickOpenSchoolService(service)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                        </div>

                    </li>

                     <!-- /////Fisheries Department Services started-->
                     <!-- <li class="fisheries" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'  && (isProdTestUser || !isProdEnv)">
                        <b>Fisheries Department</b>
                        <span class="labelCls">Fisheries Services <span class="serviceCount">{{fisheriesServices?.length}}</span></span>
                        <a  class="subMenuLinkCls" (click)="showFisheries = !showFisheries"><span class="collapseArrow" *ngIf="!showFisheries"></span><span class="expandArrow" *ngIf="showFisheries"></span></a>
                        <div *ngIf="showFisheries" class="mt-3">
                            <ul>
                                <li *ngFor = "let service of fisheriesServices | searchFilter:searchService;let i=index"><a (click)="onClickFisheriesServices(service)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                        </div>

                    </li> -->
                     <!-- /////PR Department Services started-->
                    <!-- <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva' && (isProdTestUser || !isProdEnv)">
                        <b>SVAMITVA Mutation</b>
                        <span class="labelCls">SVAMITVA Mutation Services <span class="serviceCount">{{SVAMITVAMutationServices?.length}}</span></span>
                        <a  class="subMenuLinkCls" (click)="showMutation = !showMutation"><span class="collapseArrow" *ngIf="!showMutation"></span><span class="expandArrow" *ngIf="showMutation"></span></a>
                        <div *ngIf="showMutation" class="mt-3">
                            <ul>
                                <li *ngFor = "let service of SVAMITVAMutationServices | searchFilter:searchService;let i=index"><a (click)="onClickSVAMITVAMutationServices(service)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                        </div>

                    </li> -->
                   
                   <!-- Sadarem department -->
                   <li class="saderam" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                        <b>SADAREM services</b>
                        <span class="labelCls">Family Welfare <span class="serviceCount">2</span></span>
                        <a  class="subMenuLinkCls" (click)="showSaderam = !showSaderam"><span class="collapseArrow" *ngIf="!showSaderam"></span><span class="expandArrow" *ngIf="showSaderam"></span></a>
                        <div *ngIf="showSaderam" class="mt-3">
                            <ul>
                                <li><a target="_blank" (click)="onClickSadaramService()" class="submenuText" >1.Slot Booking For Sadarem Certificate</a></li>
                                <li><a routerLink="/sadarem-print-certificate" routerLinkActive='active' class="submenuText" >2.Sadarem Print Certificate</a></li>
                            </ul>
                        </div>

                    </li>

                    <li *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'"><a target="_blank" (click)="onClickAarogyaSurakshaService()"><b>Aarogya Suraksha 2.0 Print CaseSheet</b></a></li>
                    
                    <!-- sand services -->
                     <li class="sandservice" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                        <b>Mines and Geology Department</b>
                        <span class="labelCls">GSWS Online Sand booking Services <span class="serviceCount">1</span></span>
                        <a  class="subMenuLinkCls" (click)="showSandBooking = !showSandBooking"><span class="collapseArrow" *ngIf="!showSandBooking"></span><span class="expandArrow" *ngIf="showSandBooking"></span></a>
                        <div *ngIf="showSandBooking" class="mt-3">
                            <ul>
                                <li *ngFor = "let service of sandServices | searchFilter:searchService;let i=index"><a (click)="onClickOnlineSandBooking(service)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                        </div>
                    </li>



                    <!-- FIRE Service Department start -->
                   <li class="fire-department" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva'">
                    <b>ANDHRA PRADESH STATE DISASTER RESPONSE AND FIRE SERVICES DEPARTMENT</b>
                    <span class="labelCls">Fire Service <span class="serviceCount">3</span></span>
                    <a  class="subMenuLinkCls" (click)="showFire = !showFire"><span class="collapseArrow" *ngIf="!showFire"></span><span class="expandArrow" *ngIf="showFire"></span></a>
                    <div *ngIf="showFire" class="mt-3">
                        <ul>
                            <ul>
                                <li *ngFor = "let service of fireServices | searchFilter:searchService;let i=index"><a (click)="onClickFireService(service)">{{i+1}}.<span class="submenuText" [innerHTML] = "service.name | highlightSearch: searchService"></span></a></li>
                            </ul>
                        </ul>
                    </div>

                </li>



                    <!-- FIRE Service Department end -->

                        <!-- <li class="forest">
                            <b>Environment, Forest, Science and Technology</b>
                            <span class="labelCls">Forest (PCCF) <span class="serviceCount">1</span></span>
                            <a class="collapseArrow"></a>
                        </li>
                        <li class="education">
                            <b>Human Resources (School Education)</b>
                            <span class="labelCls">School Education <span class="serviceCount">2</span></span>
                            <a class="collapseArrow"></a>
                        </li>
                        <li class="police">
                            <b>Home</b>
                            <span class="labelCls">Police (DGP) <span class="serviceCount">13</span></span>
                            <a class="collapseArrow"></a>
                        </li>
                        <li class="housing">
                            <b>Housing</b>
                            <span class="labelCls">AP Housing <span class="serviceCount">6</span></span>
                            <a class="collapseArrow"></a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="row ml-3 mt-1" *ngIf="this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva' && this.userInfo.jobTitle !== 'IT Minister'">
            <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 pl-0 pr-1">
                <img src="../../assets/sla-chart.png" class="chartCls">
            </div> -->
            <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9 pl-0">
                <div class="rankings">
                    <h6>Secretariat Ranking</h6>
                    <div class="ranklevels borderRight">
                        <div class="districtlevel"></div>
                        <div class="labelCls lblCls">At District Level</div>
                        <!-- <div class="rank">222 / 1287</div> -->
                        <div class="rank">{{ sacretraitRanking?.diD_RANK }} / {{ sacretraitRanking?.diD_TOT_RNK  }}</div>
                    </div>
                    <div class="ranklevels">
                        <div class="mandallevel"></div>
                        <div class="labelCls lblCls">At Mandal Level</div>
                       <!--  <div class="rank">30 / 33</div> -->
                        <div class="rank">{{ sacretraitRanking?.miD_RANK }} / {{ sacretraitRanking?.miD_TOT_RNK  }}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.userInfo.jobTitle !== 'IT Minister'" class="col-md-3 col-lg-3 col-sm-12 col-xs-12 pl-0">
                <div class="serviceBlock text-center mb-2">
                    <div class="searchFeature my-2"></div>
                    <h6>Search By Feature</h6>
                    <div class="labelCls">Search by Transaction ID</div>
                  
                    <form [formGroup]="ViewTransactions">
                        <div class="row mb-3 mt-3">
                           
                            <div class="col nopadding col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="transactionId" placeholder="Transaction Id" class="inputCls" formControlName="transactionId" style="width: 100%;">
                                <div *ngIf="submitted && fc.transactionId.errors" class="selectError">
                                    <div *ngIf="fc.transactionId.errors?.required">Please Enter transaction id</div>
                                </div>
                            </div>

                            <div class="col nopadding col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <button class="btn btnSearch btn-primary mx-auto d-block" (click)="submit(showTransaction)">Search</button>
                            </div>
            
                        </div>
                    </form>
                   
                   <!--  <button name="Search" class="blueBtnCls mt-2">Search</button> -->
                </div>
            </div>
        </div>
      
        <!-- <div class="row ml-3 mt-1">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0">
                <div class="schemes">
                    <div class="serviceHeader">
                        <h6 class="float-left">DBT Schemes</h6>
                        <input type="text" class="inputCls" placeholder="Search Schemes">
                    </div>
                    <ul class="borderRight">
                        <li>Rythu Bharosa</li>
                        <li>Jagananna Vidya Deevena</li>
                        <li>Ban on Alcohol</li>
                        <li>Amma Vodi</li>
                    </ul>
                    <ul class="borderRight">
                        <li>Arogya Sri Card Registration</li>
                        <li>Asara</li>
                        <li>Pension</li>
                        <li>Pension Social Audit</li>
                    </ul>
                    <ul>
                        <li>gruha Vasati</li>
                        <li>Arogyasri</li>
                        <li>Jalayagnam</li>
                    </ul>
                </div>
            </div>
        </div> -->
        </div>
    </div>



<!-- Transaction Popup -->
    <ng-template #showTransaction let-modal class="modalCls btnHide">
        <div id="printTransStatus">
        <div class="modal-header">
            <div>
                <img src="../../assets/images/logo_official.png" class="d-block mx-auto" width="10%">
                <div class="subHeadingCls text-center"><b>Transaction Status</b></div>

                <div class="text-right">
                    <label class="labelCls note_txt"><b>Date :</b> {{ dateTime | date: 'dd/MM/yyyy'}} {{ dateTime | date:
                        'h:mm:ss a'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>

                </div>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        
        

        <div class="modal-body">

            <div class="mainPageContainer mr-3 noborder">
                <div id="printData">
                    <div class="row">

                        <!-- <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Secretariat Name :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{secretariatName}}</label>
                        </div> -->

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>User ID :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                           <!--  <label class="labelCls">{{userInfo.userPrincipalName}}</label> -->
                           <label class="labelCls">{{ requisitionInfo?.userName }}</label>
                        </div>

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Department Name:</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls"> {{departmentName }} Department </label>
                        </div>

                    </div>

                    <div class="row">


                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Service Name :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !=='RD'">
                            <label class="labelCls" style="word-break: break-word;">{{ serviceName }}</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@1' || this.requisitionInfo.serviceCode ==='Pensions@V1')">
                            <label class="labelCls" style="word-break: break-word;">Pensions O.A.P</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@2' || this.requisitionInfo.serviceCode ==='Pensions@V2' || this.requisitionInfo.serviceCode ==='Pensions-NEW@2' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V2')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Weavers</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@3' || this.requisitionInfo.serviceCode ==='Pensions@V3')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Disabled</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@4' || this.requisitionInfo.serviceCode ==='Pensions@V4')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Widow</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@5' || this.requisitionInfo.serviceCode ==='Pensions@V5')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Toddy Tappers</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@6' || this.requisitionInfo.serviceCode ==='Pensions@V6')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Transgender</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@7' || this.requisitionInfo.serviceCode ==='Pensions@V7')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Fishermen</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@8' || this.requisitionInfo.serviceCode ==='Pensions@V8')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Single Women</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@9' || this.requisitionInfo.serviceCode ==='Pensions@V9' || this.requisitionInfo.serviceCode ==='Pensions-NEW@9' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V9')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Traditional Cobblers</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                            *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@10' || this.requisitionInfo.serviceCode ==='Pensions@V10' || this.requisitionInfo.serviceCode ==='Pensions-NEW@10' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V10')">
                            <label class="labelCls" style="word-break: break-word;">Pensions Dappu Artists</label>
                        </div>


                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Application Number :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{ transactionID}}</label>
                        </div>

                    </div>

                    <div class="row">


                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b> Applicant Name : </b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{ applicantName }} </label>
                        </div>

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b> Applicant Father Name : </b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{ applicantFatherName }}</label>
                        </div>


                    </div>

                    <div class="row">


                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b> District :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{district }}</label>
                        </div>

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b> Mandal:</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{mandal }}</label>
                        </div>


                    </div>

                    <div class="row" *ngIf="this.departmentCode !== 'RD'">


                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b> Amount Paid : </b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{ totalAmount }} /-</label>
                        </div>

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Amount In Words :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls"> {{ amountInwords }} Rupees Only</label>
                        </div>


                    </div>

                    <div class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !== 'RD'">
                            <label class="labelCls lableHead"><b>Payment Mode :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !== 'RD'">
                            <label class="labelCls">{{paymentMode}}</label>
                        </div>

                        

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>SLA :</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls">{{ SLA }}</label>
                        </div>


                    
                    </div>

                    <div class="row">
                       <!--  <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Department Name:</b></label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls"> {{departmentName }} Department </label>
                        </div> -->

                        <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <label class="labelCls lableHead"><b>Transaction Status :</b></label>
                        </div>
                        <div class="col col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="labelCls"> {{ transactionStatusData }} </label>
                        </div> 

                     

                    
                    </div>

                </div>
            </div>

         
            <!-- <br> <div class="row mt-5 nomargin">
              
             <div class="col nopadding">
                <ul class="progressbar row progressbar{{cntDash}}">
    
                    <li class="col nopadding" *ngFor="let item of transactionDataPopup.data;"
                     [ngClass]="[ (item.transaction_status == 'Within SLA' ? 'active':'') ||  (item.transaction_status == 'Beyond SLA' ? 'inactive':'') ||  (item.transaction_status == 'Closed' ? 'closed_request':'') ]">
                        <div class="left-divider green-divider">
                            <h6>{{ item.role }}</h6>
                            <p>{{item.transaction_date}} {{item.transaction_status}}</p>
                            <p>{{ item.status}}</p>
                        </div>
                    </li>
    
                 
                </ul>
    
             </div>
    
            </div> -->
            <!-- ------------ -->

        </div>

    </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="printReceipt()">Print</button>
                
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
        </div>
    </ng-template>
<!-- Transaction Popup end -->

<!-- dasboard count -->
    <ng-template #showRequisitionData let-modal class="modalCls btnHide">

        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Service Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="modal-body"  id="transPoup">

            <div class="table-responsive tableFixHead">

                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Application Number</th>
                            <th class="width32">Service Name</th>
                            <!-- <th>Status</th> -->
                            <th>Transaction Date</th>
                            <th>Amount</th>
                        </tr>
                    </thead>


                    <tbody *ngIf="items.length != 0">
                        <!--  <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize; let i = index;"> -->
                        
                            <tr *ngFor="let item of items; let i = index;">
                       
                           <td class="text-center"> {{ ((page-1) * pageSize) + (i+1) }}</td>
                            <td>{{ item?.requisitionId }}</td>
                            <td>{{ item?.serviceFullName }}</td>
                            <!-- <td> <a class="textDcor"
                                (click)="getTransactionData(showTransaction, item?.transactionId)">View
                                Status</a></td> -->
                            <td>{{ item?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a' }}</td>
                            <td class="text-center">{{ item?.transactionAmount }}</td>
                        </tr>
                    </tbody>

                    <!-- <tbody *ngIf="items.length==0"> -->
                    <tbody *ngIf="totalPageCount==0">
                        <br><tr class="text-center">
                        Records not found...  
                        </tr>
                    </tbody>

                </table>

            </div>
        </div>

        <div class="modal-footer">
          
                
              <!--   <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged($event)">
                </ngb-pagination> -->
                <ngb-pagination class="d-flex justify-content-end" *ngIf="totalPageCount > 0" [collectionSize]="totalPageCount" [(page)]="page" [pageSize]="pageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged($event)">
                </ngb-pagination>
          
            <!-- <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button> -->
        </div>
    </ng-template>

    
 <ng-template #showReqData let-modal class="modalCls">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{dashbardpopupTitle}} Service Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body" id="transPoup">

        <div class="table-responsive tableFixHead">
            <table class="tableCls">
                <thead>
                    <tr>
                        <th>Serial No</th>
                        <th>Application Number</th>
                        <th class="width32">Service Name</th>
                        <th>Transaction Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>

               
                <tbody *ngIf="items.length != 0">
                    <!--  <tr *ngFor="let item of items; let i=index;"> -->
                    
                     <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize; let i = index;">
                         <td class="text-center"> {{ ((page-1) * pageSize) + (i+1) }}</td>
                         <td>{{ item?.requisitionId }}</td>
                         <td>{{ item?.serviceFullName }}</td>
                         <td>{{ item?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a' }}</td>
                         <td class="text-center">{{ item?.transactionAmount }}</td>
                     </tr>
                 </tbody>

                 <tbody *ngIf="items.length==0">
                     <br><tr class="text-center">
                     Records not found...  
                     </tr>
                 </tbody>

            </table>

        </div>
    </div>

    <div class="modal-footer">
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
        
       <!--  <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button> -->
    </div>
</ng-template>



<!-- dasboard count -->



<ng-template #showTransactionStatusNewPopup let-modal class="modalCls">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Transaction Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body" id="transPoup">
        <br>
        <div class="table-responsive tableFixHead">
            <table class="tableCls">
                <thead>
                    <tr>
                        <th>District Name</th>
                        <th>Mandal Name</th>
                        <th class="width32">Secretariat Name</th>
                        <th>Service Name</th>
                        <th>SLA</th>
                        <th>Citizen Name</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td> Krishna</td>
                        <td> Avanigadda</td>
                        <td> Puligadda</td>
                        <td> Revenue service</td>
                        <td> 7 days</td>
                        <td> Test test wete</td>
                    </tr>
                </tbody>

           
            </table>

        </div>
       
        <!-- <br><br><div class="row mt-5 nomargin">
         <div class="col nopadding">
            <ul class="progressbar row progressbar{{cntDash}}">

                <li class="col nopadding" *ngFor="let item of transactionDataPopup.data;"
                 [ngClass]="[ (item.transaction_status == 'Within SLA' ? 'active':'') ||  (item.transaction_status == 'Beyond SLA' ? 'inactive':'') ||  (item.transaction_status == 'Closed' ? 'closed_request':'') ]">
                    <div class="left-divider green-divider">
                        <h6>{{ item.role }}</h6>
                        <p>{{item.transaction_date}} {{item.transaction_status}}</p>
                        <p>{{ item.status}}</p>
                    </div>
                </li>
            </ul>

         </div>
        </div> -->

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
