<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    color: #000;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }
  </style>
  <app-loader></app-loader>


 <!--  <button  class="btn btn-primary  mt-3 btn_login_cls" style="margin-left: 60px;z-index: 9999;" *ngIf="!loginDisplay" (click)="login()">Log In</button> -->

<!-- <div class="container-fluid"> -->
  <!-- <app-home *ngIf="!loginDisplay" (onLogin)="login()"></app-home> -->
  <!-- <button  class="btn btn-primary mt-3" *ngIf="!loginDisplay" (click)="login()">Login</button> -->
  <div class="homePageCon" *ngIf="!loginDisplay">

  <app-home *ngIf="!loginDisplay"></app-home>
  <!-- <app-document-list *ngIf="!loginDisplay && isDocUrl"></app-document-list> -->
  </div>
  <ng-container *ngIf="loginDisplay">
<div class="container-fluid">
    <app-header *ngIf="loginDisplay && showHeader"></app-header>
    <div class="row mainContainer">
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pr-0 menuWidthCls" *ngIf="loginDisplay && menuClass && showHeader"> <!--add pr-0 class-->
        <div class="common-menu">
          <ng-container *ngIf="menuDisplay">
          <ul>

            <li *ngIf="role !== 'Citizen' && role !== 'Meeseva' && role !== 'IT Minister'"><a routerLink="/signature-upload" [queryParams]="{flag: 'SELF'}" routerLinkActive='active'><span class="rightArrowCls"></span>Profile Update</a></li>
            <li *ngIf="userInfo.jobTitle == 'Digital Assistant' || userInfo.jobTitle == 'Ward Education Secretary'"><a routerLink="/pan-details" routerLinkActive='active'><span class="rightArrowCls"></span>PAN Details</a></li>
            <li *ngIf="userInfo.jobTitle == 'Digital Assistant' || userInfo.jobTitle == 'Ward Education Secretary'"><a routerLink="/utilization-certificate" routerLinkActive='active'><span class="rightArrowCls"></span>Utilization Certificate Upload</a></li>
            <li><a routerLink="/home" routerLinkActive='active'><span class="rightArrowCls"></span>Dashboard</a></li>
            <li *ngIf="role !== 'Citizen' && role !== 'Meeseva'"><a (click)="showReports = !showReports"><span class="rightArrowCls" *ngIf="!showReports"></span><span class="downArrowCls" *ngIf="showReports"></span>Reports</a>
              <ul class="child-menu" *ngIf="showReports">
                <li><a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiMGNjMDkyZjAtMWMzOS00MTNmLTllODctNWVjYTY5Zjk5NzYwIiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9&pageName=ReportSection'><span class="rightArrowCls"></span>Dashboard</a></li>
                <li><a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiZGZjNzkwMDctZjhmMy00MTVlLTlkNTUtZWQwOTAyM2Q0YzA0IiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9'><span class="rightArrowCls"></span>Sub SLA Dashboard</a></li>
                <li><a routerLink="/excel-report" routerLinkActive='active'><span class="rightArrowCls"></span>Download Report</a></li>
                <li><a routerLink="/csp-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>AP SEVA Services Dashboard</a></li>
                <li><a routerLink="/csp-payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>AP SEVA Payment Dashboard</a></li>
                <!-- <li><a routerLink="/schedule-report" routerLinkActive='active'><span class="rightArrowCls"></span>Team Schedule Report</a></li> -->
                <!-- <li><a routerLink="/payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>PAYMENT REPORT</a></li>                 -->
                <li><a routerLink="/ots-payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>OTS Payment Report</a></li> 
                <!-- <li><a routerLink="/jagananna-suraksha-report" routerLinkActive='active'><span class="rightArrowCls"></span>Jagananna Suraksha Report</a></li>   -->
                <li><a routerLink="/aarogya-suraksha-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Scheduling Status Reports</a></li>
                <li><a routerLink="/aarogya-suraksha-dashboard" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Camp Status Report</a></li>
                <li><a routerLink="/welfare-scheme-program" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling Of Welfare Schemes Display Boards-Program Details Report</a></li>
                <li><a routerLink="/good-government-report" routerLinkActive='active'><span class="rightArrowCls"></span>ఇది మంచి ప్రభుత్వం క్యాంపెయిన్ రిపోర్ట్ </a></li>
                <!-- <li><a routerLink="/jagananna-suraksha-dashboard" routerLinkActive='active'><span class="rightArrowCls"></span>Jagananna Suraksha Dashboard</a></li>
                <li><a routerLink="/jagananna-suraksha-camp-status-report" routerLinkActive='active'><span class="rightArrowCls"></span>Jagananna Suraksha Camp Status Report</a></li>    -->
                <li><a routerLink="/jagananna-aarogya-suraksha-token-generation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Token Generation Report</a></li>      
                <li><a routerLink="/palleku-podam" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling of Welfare Schemes  Display Boards - Schedule</a></li>
                <li><a routerLink="/welfare-scheme-program" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling Of Welfare Schemes Display Boards-Program Details Report</a></li>  
                <!-- <li><a routerLink="/good-government-report" routerLinkActive='active'><span class="rightArrowCls"></span>ఇది మంచి ప్రభుత్వం క్యాంపెయిన్ రిపోర్ట్ </a></li> -->
                <li><a routerLink="/passbook-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>Passbook Service Report</a></li>                                                                                                                                                      
                <li><a routerLink="/pension-report" routerLinkActive='active'><span class="rightArrowCls"></span>Pension 6 Step Parameter Update Report</a></li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                <li><a routerLink="/monitoring-dashboard-for-pan-collection" routerLinkActive='active'><span class="rightArrowCls"></span>Monitoring Dashboard for PAN Collection Report</a></li>      
                <li><a routerLink="/utilization-certificate-report" routerLinkActive='active'><span class="rightArrowCls"></span>Utilization Certificate Report</a></li> 
                <li><a routerLink="/aadhar-challan-generation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aadhar challan generation Report</a></li> 
                <li><a routerLink="/volunteer-appreciation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Volunteer Appreciation Program Details Report</a></li> 
                <li><a routerLink="/pension-occupational-report" routerLinkActive='active'><span class="rightArrowCls"></span>Pension Occupational Report</a></li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                <li><a routerLink="/mutation-for-corrections" routerLinkActive='active'><span class="rightArrowCls"></span>Mutation For Corrections REPORT</a></li>
                <li><a routerLink="/secreteriate-pan-updated-report" routerLinkActive='active'><span class="rightArrowCls"></span>Secretariat Pan Updated Report</a></li>
                <li><a routerLink="/secreteriate-revVillage-mapping-report" routerLinkActive='active'><span class="rightArrowCls"></span>Secretariat-Revenue Village Mapping Report</a></li>
                <li *ngIf="isCivilSuppliesStatus" ><a routerLink="/civil-supplies/check-status" routerLinkActive='active'><span class="rightArrowCls"></span>Check Civil Supplies Status</a></li>
                <!-- <li><a routerLink="/payment-issue-tracker-report" routerLinkActive='active'><span class="rightArrowCls"></span>Payment Issue Tracker Report</a></li> -->
                </ul>
            </li>
            <li><a routerLink="/view-transactions" routerLinkActive='active'><span class="rightArrowCls"></span>View Transaction</a></li>
           
           <!--  <li><a routerLink="/view-challan" routerLinkActive='active'><span class="rightArrowCls"></span>Challan Payment</a></li> -->
           <li *ngIf="role !== 'Citizen' && role !== 'Meeseva' && role !== 'IT Minister'"><a (click)="showChallan = !showChallan"><span class="rightArrowCls" *ngIf="!showChallan"></span><span class="downArrowCls" *ngIf="showChallan"></span>Challan Payment</a>
            <ul class="child-menu" *ngIf="showChallan">
              <li (click)="redirectChallan('gsws')"><a routerLinkActive='active'><span class="rightArrowCls"></span>GSWS Challan</a></li>
              <li (click)="redirectChallan('ots')"><a routerLinkActive='active'><span class="rightArrowCls"></span>OTS Services Challan</a></li>
              <li><a routerLink="/aadhaar-challan-generation"><span class="rightArrowCls"></span>Aadhaar Services Challan</a></li>
              <!-- <li><a routerLink="/payment-issue-tracker" routerLinkActive='active'><span class="rightArrowCls"></span>Payment Issue Tracker</a></li> -->
            </ul>   
           </li>
           <!-- <li><a routerLink="/challan-mapping" routerLinkActive='active'><span class="rightArrowCls"></span>Check Challan Status</a></li> -->
            <li *ngIf="role !== 'Citizen' && role !== 'Meeseva' && role !== 'IT Minister'"><a href="#" (click)="redirectToRTS()"><span class="rightArrowCls"></span>Request Tracking System</a></li>
            <li *ngIf="role === 'IT Minister'"><a routerLink="/pension-ui/pension-form"><span class="rightArrowCls"></span>New Pension Form</a></li>
          </ul>
          <div *ngIf="role !== 'IT Minister'" class="serviceListCls">List Of Services</div>
          <div *ngIf="role !== 'IT Minister'" class="listContainer">
          <ul class="serviceList">
            <li class="appSubmit"><a><span class="downArrowCls"></span>Application Submission</a></li>
            <li *ngFor = "let menu of menuConstants">
              <a (click)="menu.showSubmenu = !menu.showSubmenu" class="menuServiceCls"><span class="actionCls" *ngIf="menu.showSubmenu"> - </span><span class="actionCls" *ngIf="!menu.showSubmenu"> + </span><span class="nameCls">{{menu.heading}}</span></a>
                <ul *ngIf="menu.showSubmenu">
                  <li *ngFor = "let service of menu.services;let i = index"><a href="#" [routerLink]=service.path routerLinkActive='active'>{{i+1}}. {{service.name}}</a></li>
                </ul>
                </li>
          </ul>
          </div>
          </ng-container>
          <ng-container *ngIf="dbMenuDisplay && isDashboard">
            <ul>
              <!-- <li><a><span class="rightArrowCls"></span>Report An Issue</a></li> -->
              <!--  <li><a><span class="rightArrowCls"></span>Check Application Status</a></li> -->
           
             <li *ngIf="role !== 'Citizen' && role !== 'Meeseva'  && role !== 'IT Minister'"><a routerLink="/signature-upload" [queryParams]="{flag: 'SELF'}" routerLinkActive='active'><span class="rightArrowCls"></span>Profile Update</a></li>
             <li *ngIf="userInfo.jobTitle == 'Digital Assistant' || userInfo.jobTitle == 'Ward Education Secretary'"><a routerLink="/pan-details" routerLinkActive='active'><span class="rightArrowCls"></span>PAN Details</a></li>
             <li *ngIf="userInfo.jobTitle == 'Digital Assistant' || userInfo.jobTitle == 'Ward Education Secretary'"><a routerLink="/utilization-certificate" routerLinkActive='active'><span class="rightArrowCls"></span>Utilization Certificate Upload</a></li>
             <li><a routerLink="/home" routerLinkActive='active'><span class="rightArrowCls"></span>Dashboard</a></li>
              <li><a routerLink="/view-transactions" routerLinkActive='active'><span class="rightArrowCls"></span>View Transactions</a></li>
            
              <!-- <li><a routerLink="/view-challan" routerLinkActive='active'><span class="rightArrowCls"></span>Challan Payment</a></li> -->

              <li *ngIf="role !== 'Citizen' && role !== 'Meeseva' && role !== 'IT Minister'"><a (click)="showChallan = !showChallan"><span class="rightArrowCls" *ngIf="!showChallan"></span><span class="downArrowCls" *ngIf="showChallan"></span>Challan Payment</a>
                <ul class="child-menu" *ngIf="showChallan">
                  <li (click)="redirectChallan('gsws')"><a routerLinkActive='active'><span class="rightArrowCls"></span>GSWS Challan</a></li>
                  <li (click)="redirectChallan('ots')"><a routerLinkActive='active'><span class="rightArrowCls"></span>OTS Services Challan</a></li>
                  <li><a routerLink="/aadhaar-challan-generation"><span class="rightArrowCls"></span>Aadhaar Services Challan</a></li>
                 
                  <!-- <li><a routerLink="/payment-issue-tracker" routerLinkActive='active'><span class="rightArrowCls"></span>Payment Issue Tracker</a></li> -->
                </ul>   
               </li>
               <li *ngIf="role === 'IT Minister'"><a routerLink="/pension-ui/pension-form"><span class="rightArrowCls"></span>New Pension Form</a></li>

               <!-- <li><a routerLink="/challan-mapping" routerLinkActive='active'><span class="rightArrowCls"></span>Check Challan Status</a></li> -->
              <li *ngIf="role !== 'Citizen' && role !== 'Meeseva' && role !== 'IT Minister' "><a href="#" (click)="redirectToRTS()"><span class="rightArrowCls"></span>Request Tracking System</a></li>
              <li *ngIf="role !== 'Citizen' && role !== 'Meeseva'"><a (click)="showReportsInDB = !showReportsInDB"><span class="rightArrowCls" *ngIf="!showReportsInDB"></span><span class="downArrowCls" *ngIf="showReportsInDB"></span>Reports</a>
                <ul class="child-menu" *ngIf="showReportsInDB">
                <li><a routerLink="/csp-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>AP SEVA Services Dashboard</a></li>
                <li><a routerLink="/csp-payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>AP SEVA Payment Dashboard</a></li>
                <!-- <li><a routerLink="/schedule-report" routerLinkActive='active'><span class="rightArrowCls"></span>Team Schedule Report</a></li> -->
                  <li><a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiMGNjMDkyZjAtMWMzOS00MTNmLTllODctNWVjYTY5Zjk5NzYwIiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9&pageName=ReportSection'><span class="rightArrowCls"></span>Analytics Dashboard</a></li>
                  <li><a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiZGZjNzkwMDctZjhmMy00MTVlLTlkNTUtZWQwOTAyM2Q0YzA0IiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9'><span class="rightArrowCls"></span>Sub SLA Dashboard</a></li>
                <!-- <li><a routerLink="/excel-report" routerLinkActive='active'><span class="rightArrowCls"></span>Download Report</a></li> -->
                 <!-- <li><a routerLink="/payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>Payment Report</a></li> -->
                 <li><a routerLink="/ots-payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>OTS Payment Report</a></li>
                 <!-- <li><a routerLink="/jagananna-suraksha-report" routerLinkActive='active'><span class="rightArrowCls"></span>Jagananna Suraksha Report</a></li>    -->
                 <li><a routerLink="/aarogya-suraksha-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Scheduling Status Reports</a></li>
                 <li><a routerLink="/aarogya-suraksha-dashboard" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Camp Status Report</a></li>
                 <!-- <li><a routerLink="/jagananna-suraksha-dashboard" routerLinkActive='active'><span class="rightArrowCls"></span>Jagananna Suraksha Dashboard</a></li>                                                   
                 <li><a routerLink="/jagananna-suraksha-camp-status-report" routerLinkActive='active'><span class="rightArrowCls"></span>Jagananna Suraksha Camp Status Report</a></li>     -->
                 <li><a routerLink="/jagananna-aarogya-suraksha-token-generation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Token Generation Report</a></li>  
                 <li><a routerLink="/palleku-podam" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling of Welfare Schemes  Display Boards - Schedule</a></li>  
                 <li><a routerLink="/welfare-scheme-program" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling Of Welfare Schemes Display Boards-Program Details Report</a></li>    
                 <li><a routerLink="/good-government-report" routerLinkActive='active'><span class="rightArrowCls"></span>ఇది మంచి ప్రభుత్వం క్యాంపెయిన్ రిపోర్ట్ </a></li>
                 <li><a routerLink="/passbook-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>Passbook Service Report</a></li>     
                 <li><a routerLink="/pension-report" routerLinkActive='active'><span class="rightArrowCls"></span>Pension 6 Step Parameter Update Report</a></li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                 <li><a routerLink="/monitoring-dashboard-for-pan-collection" routerLinkActive='active'><span class="rightArrowCls"></span>Monitoring Dashboard for PAN Collection Report</a></li>     
                 <li><a routerLink="/utilization-certificate-report" routerLinkActive='active'><span class="rightArrowCls"></span>Utilization Certificate Report</a></li> 
                 <li><a routerLink="/aadhar-challan-generation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aadhar challan generation Report</a></li> 
                 <li><a routerLink="/volunteer-appreciation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Volunteer Appreciation Program Details Report</a></li> 
                 <li><a routerLink="/pension-occupational-report" routerLinkActive='active'><span class="rightArrowCls"></span>Pension Occupational Report</a></li> 
                 <li><a routerLink="/mutation-for-correction" routerLinkActive='active'><span class="rightArrowCls"></span>Mutation For Corrections</a></li>
                <li><a routerLink="/mutation-for-transactions" routerLinkActive='active'><span class="rightArrowCls"></span>Mutation For Transactions</a></li>
                <li><a routerLink="/secreteriate-pan-updated-report" routerLinkActive='active'><span class="rightArrowCls"></span>Secretariat Pan Updated Report</a></li>
                <li><a routerLink="/secreteriate-revVillage-mapping-report" routerLinkActive='active'><span class="rightArrowCls"></span>Secretariat-Revenue Village Mapping Report</a></li>
                <li><a routerLink="/failedtransactions" routerLinkActive='active'><span class="rightArrowCls"></span>Failed Transactions</a></li>
                <!-- <li><a routerLink="/passbook-related-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>Passbooks Related Service Report</a></li> -->
                <!-- <li><a routerLink="/passbook-related-service-status-report" routerLinkActive='active'><span class="rightArrowCls"></span>Passbook Related Service Status Report</a></li> -->                
                <li *ngIf="isCivilSuppliesStatus" ><a routerLink="/civil-supplies/check-status" routerLinkActive='active'><span class="rightArrowCls"></span>Check Civil Supplies Status</a></li>
                <!-- <li><a routerLink="/payment-issue-tracker-report" routerLinkActive='active'><span class="rightArrowCls"></span>Payment Issue Tracker Report</a></li> -->

                  </ul>
              </li>
              <!-- <li><a><span class="rightArrowCls"></span>Services</a></li>
              <li><a><span class="rightArrowCls"></span>Cluster To HH Mapping</a></li>
              <li><a><span class="rightArrowCls"></span>Edit Employee Details</a></li>
              <li><a><span class="rightArrowCls"></span>Employee Job Corner</a></li>
              <li><a><span class="rightArrowCls"></span>Downloads</a></li> -->
            </ul>
          </ng-container>
        </div>
      </div>
      <div [ngClass]="{'pl-0 col-lg-10 col-md-10 col-sm-12 col-xs-12 rightWidthCls': menuClass, 'col-lg-12 col-md-12 col-sm-12 col-xs-12': !menuClass}">
        <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right my-3">
          <button  class="btn btn-primary mr-3" *ngIf="loginDisplay" (click)="logout()">Logout</button>
        </div> -->
        <div class="welcomeContainer" *ngIf="loginDisplay && showHeader" [ngClass]="{'spacerClass': !menuClass}">
          <div class="welcomeMsg">
            <span class="helloMsg" (mouseover)="showUserDetails = true" (mouseout)="showUserDetails = false">Hello, {{userName}} <span class="blueDownArrowCls"></span></span>
            <span class="tagMsg">{{role}}</span>
            <div class="userDetails" *ngIf="showUserDetails">
              <div class="profileCls">
                <label>User ID - </label><span>{{userInfo.userPrincipalName}}</span>
              </div>
              <div class="contactCls">
                <label>Phone No - </label><span>{{(loggedinUserMobileNo !== null && loggedinUserMobileNo?.trim() !== '') ? loggedinUserMobileNo : 'Not Available'}}</span>
              </div>
              <div class="loginCls">
                <label>Last Login - </label><span>{{ lastLoginDatetime ? lastLoginDatetime : 'Not Available' }}</span>
              </div>
            </div>
          </div>
          <div><img class="signature" *ngIf = "userInfo.jobTitle === 'Tahsildar' || userInfo.jobTitle === 'Deputy Tahsildar' || userInfo.jobTitle === 'Revenue Divisonal Officer'" [src]="sourceFile"></div>
          <span class="helloMsg" style="cursor: pointer; margin-left: 22%;"
          routerLink="/good-government" routerLinkActive='active'
          *ngIf="userInfo.jobTitle == 'Digital Assistant' ||
            userInfo.jobTitle == 'Ward Education Secretary' ||
            userInfo.jobTitle == 'Panchayat Secretary' ||
            userInfo.jobTitle == 'Ward Admin Secretary'">
          ఇది మంచి ప్రభుత్వం
        </span>
          <div class="timeContainer">
            <!-- <div class="float-left mt-1"><a href="#" class="updateProfile" (click)="openUpdateProfile(profileModal)">Update Profile</a></div>
            <div class="mx-3 my-1 float-left lineCls"></div> -->
            <div class="timeLogo float-left"></div>
            <div class="timeContent">
              <span class="time">{{ time | date : 'hh:mm:ss a'}}</span>
              <span class="date">{{ time | date : 'dd MMMM yyyy' }} {{time | date: 'EEE' | uppercase}}</span>
            </div>
            <div class="mx-3 my-1 float-left lineCls"></div>
             <button  class="btnCls" *ngIf="loginDisplay" (click)="openLogoutModal(logoutModal)">Log Out</button>
          </div>
        </div>        
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>
  </ng-container>
  


  <ng-template #logoutModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Confirm Log Out</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>Are you sure you want to log out now?</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls bntResetCls" (click)="modal.dismiss()">No</button>
      <button  type="submit" class="greenBtnCls" name="logout" (click)="logout()">Yes</button>
    </div>
   </ng-template>

   <ng-template #sessionModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Session Timed Out</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>{{sessionMsg}}</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="cancelLogout()">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="logout" (click)="logout()">Log Out</button>
    </div>
   </ng-template>

   <ng-template #profileModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Update Profile</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <form [formGroup]="profileForm">
        <div class="form-group row">
            <label class="col-sm-5 labelCls">First Name : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7">
                <input type="text" name="firstName" formControlName="firstName" class="inputCls" appBlockCopyPaste required maxlength="50"
              [ngClass]="{ 'is-invalid':submitted && f.firstName.errors }">
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required || f.firstName.errors.whiteSpaceValidate">First Name is required</div>
                <div *ngIf="f.firstName.errors?.pattern">Please enter valid First Name</div>
              </div>
          </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 labelCls">Last Name : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7">
                <input type="text" name="lastName" formControlName="lastName" class="inputCls" appBlockCopyPaste required maxlength="50"
              [ngClass]="{ 'is-invalid':submitted && f.lastName.errors }">
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required || f.lastName.errors.whiteSpaceValidate">Last Name is required</div>
                <div *ngIf="f.lastName.errors?.pattern">Please enter valid Last Name</div>
            </div>
          </div>
          </div>  
      <div class="form-group row">
        <label class="col-sm-5 labelCls">Mobile No : <span class="mandatoryCls">*</span></label>
        <div class="col-sm-7">
            <input type="text" name="mobileNo" formControlName="mobileNo" class="inputCls" appBlockCopyPaste
            [ngClass]="{ 'is-invalid':submitted && f.mobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
            <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
              <div *ngIf="f.mobileNo.errors.required">Mobile No is required</div>
              <div *ngIf="f.mobileNo.errors?.minlength">Please enter valid Mobile No</div>
          </div>
        </div>
      </div>
      </form>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="logout" (click)="updateProfile()">Update</button>
    </div>
   </ng-template>