<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Health And Family Welfare Department<span class="activePage">/ Aarogyasri Mithra Patient Account Details
                </span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3">Aarogyasri Mithra Patient Account Details</h6>
        <form [formGroup]="bankDetailForm">
            <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
                <ng-container [ngbNavItem] = "1">
                    <a ngbNavLink>Basic Details</a>
                    <ng-template ngbNavContent>
                        <app-common-form formControlName="commonForm" [department]="'HF'" [isSubmitted]="commonFormSubmitted" (reset)="resetCommonForm()"></app-common-form>
                        <div class="btnHolder">
                        <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="2">
                    <a ngbNavLink>Application Form</a>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">  
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">Enter UHID :<span class="mandatoryCls">*</span></label>
                            </div>
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            
                                <input type="text" name="aarogya_sri_number" placeholder="UHID number" required  numbersOnly
                                    class="inputCls" formControlName="aarogya_sri_number" maxlength="15" minlength="11"
                                    (input)="onUHIDChange()" [ngClass]="{ 'is-invalid':getDetailsFlag && f.aarogya_sri_number.errors }">
            
                                <div *ngIf="getDetailsFlag && f.aarogya_sri_number.errors" class="invalid-feedback ">
                                    <div *ngIf="f.aarogya_sri_number.errors.required">Please enter UHID number</div>
                                    <div *ngIf="f.aarogya_sri_number.errors?.minlength">Please enter valid UHID number</div>
                                </div>
                            </div>
                
                            <div class="btnHolder">
                                <button class="greenBtnCls float-right" (click)="getcaseData()">Get Details</button>
                            </div>

                        </div>    
                       
                        <div *ngIf="caseData.length > 0">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab table-responsive">
                                        <hr class="hrCls mt-4">
                                        <h6 class="mb-3">Case Details </h6>
                                        <table class="tableCls" style="width :1300px">
                                            <thead>
                                                <tr>
                                                    <th>Serial No</th>
                                                    <th>Case Id</th>
                                                    <th>Status</th>
                                                    <th>Ration Card No</th>
                                                    <th>Patient Name</th>
                                                    <th>Hospital Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let case of caseData; let i = index">
                                                    <td class="serial">{{i+1}}</td>
                                                    <td> <a class="caselink" (click)="selectCase(case)">{{case.caseId}}</a> </td>
                                                    <td>{{case.accStatus}}</td>
                                                    <td>{{case.cardNo}}</td>
                                                    <td>{{case.patientName}}</td>
                                                    <td>{{case.hospName}}</td>                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <hr class="hrCls mt-4">
                            <ng-container *ngIf="selectedCase" >
                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="mb-3">
                                            Case Number : {{ selectedCase.caseId }}
                                        </div>                                    
                                    </div>
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="mb-3">
                                            Patient Name : {{ selectedCase.patientName }}
                                        </div>                                    
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="mb-3">
                                            Card Number : {{ selectedCase.cardNo }}
                                        </div>                                    
                                    </div>
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="mb-3"> 
                                            Hospital Name : {{ selectedCase.hospName }}                                       
                                        </div>                                    
                                    </div>
                                </div>                           

                                <hr class="hrCls mt-4"> 

                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">A/C Holder Type:<span class="mandatoryCls">*</span></label>
                                        <input type="radio" (change)="acTypeChange('S')" value="S" formControlName="account_holder_type"><span class="mx-2 radioCls">Self</span>
                                        <input type="radio" (change)="acTypeChange('F')"value="F" formControlName="account_holder_type"><span class="mx-2 radioCls">Family Member</span>
                                        <div *ngIf="submitted && f.account_holder_type.errors" class="selectError">
                                            <div *ngIf="f.account_holder_type.errors">Please enter A/C holder type.</div>
                                        </div>        
                                    </div>
                                </div>
                                
                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">A/C Holder Name:<span class="mandatoryCls">*</span></label>
                                        <input *ngIf="f.account_holder_type.value == 'S'" type="text" name="account_holder_name" class="inputCls" formControlName="account_holder_name" 
                                        [ngClass]="{ 'is-invalid':submitted && f.account_holder_name.errors }" required readonly>
                                        <select *ngIf="f.account_holder_type.value == 'F'" class="selectCls" (change)="acNameChange()" formControlName = "account_holder_name" 
                                        [ngClass]="{ 'is-invalid':submitted && f.account_holder_name.errors }" required appBlockCopyPaste>
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let familyDetail of FamilyDetails" [ngValue]="familyDetail">{{familyDetail.memberName}}
                                        </select> 
                                        <div *ngIf="submitted && f.account_holder_name.errors" class="selectError">
                                            <div *ngIf="f.account_holder_name.errors">Please select A/C holder name.</div>
                                        </div> 
                                        <!-- <div *ngIf="f.account_holder_type.value == 'F' && submitted && f.account_holder_name_family.errors" class="selectError">
                                            <div *ngIf="f.account_holder_name_family.errors">Please select A/C holder name.</div>
                                        </div>         -->
                                    </div>
                            
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">Father/Husband Name:<span class="mandatoryCls">*</span></label>
                                        <input type="text" name="father_name" class="inputCls" formControlName="father_name" 
                                        [ngClass]="{ 'is-invalid':submitted && f.father_name.errors }" required maxlength="200">
                                        <div *ngIf="submitted && f.father_name.errors" class="selectError">
                                            <div *ngIf="f.father_name.errors.required || f.father_name.errors.whiteSpaceValidate">Please enter Father/Husband name</div>
                                            <div *ngIf="f.father_name.errors?.pattern">Please enter valid Father/Husband name</div>
                                        </div>        
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">Account No:<span class="mandatoryCls">*</span></label>
                                        <input type="text" name="account_number" class="inputCls" formControlName="account_number" required
                                        [ngClass]="{ 'is-invalid':submitted && f.account_number.errors }" numbersOnly appBlockCopyPaste maxlength="20">
                                        <div *ngIf="submitted && f.account_number.errors" class="selectError">
                                            <div *ngIf="f.account_number.errors?.required">
                                                Please enter Account No.
                                            </div>
                                            <div *ngIf="f.account_number.errors?.acnumbernotvalid">
                                                Please enter valid Account No.
                                            </div>
                                        </div>        
                                    </div>
                                
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">Re Enter Account No:<span class="mandatoryCls">*</span></label>
                                        <input type="password" name="re_account_number" class="inputCls" formControlName="re_account_number" required
                                        [ngClass]="{ 'is-invalid':submitted && f.re_account_number.errors }" numbersOnly appBlockCopyPaste  maxlength="20">
                                        <div *ngIf="submitted && f.re_account_number.errors" class="selectError">
                                            <div *ngIf="f.re_account_number.errors?.required">
                                                Please enter Account No.
                                            </div>
                                            <div *ngIf="f.re_account_number.errors?.acnumberMismatch">
                                                Account number mismatched.
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">Bank Name:<span class="mandatoryCls">*</span></label>
                                        <select class="selectCls" formControlName = "bank_name" [ngClass]="{ 'is-invalid':submitted && f.bank_name.errors }"
                                        (change)="onBankChange($event.target)" required appBlockCopyPaste>
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let bank of BankDetails" [ngValue]="bank">{{bank.bankName}}
                                        </select> 
                                        <div *ngIf="submitted && f.bank_name.errors" class="selectError">
                                            <div *ngIf="f.bank_name.errors">Please select bank name.</div>
                                        </div>        
                                    </div>
                                
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">Bank Branch:<span class="mandatoryCls">*</span></label>
                                        <!-- <input type="text" name="bank_branch" class="inputCls" formControlName="bank_branch" required> -->
                                        <select class="selectCls" formControlName = "bank_branch" [ngClass]="{ 'is-invalid':submitted && f.bank_branch.errors }" 
                                        (change)="onBankBranchChange($event.target)" required appBlockCopyPaste>
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let branch of BankBranchDetails" [ngValue]="branch">{{branch.branchName}}
                                        </select> 
                                        <div *ngIf="submitted && f.bank_branch.errors" class="selectError">
                                            <div *ngIf="f.bank_branch.errors">Please select branch.</div>
                                        </div>        
                                    </div>
                                </div>

                                <div class="row mb-3">
                            
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">IFSC Code:<span class="mandatoryCls">*</span></label>
                                        <input type="text" name="ifsc_code" class="inputCls" formControlName="ifsc_code" (input)="onIfscCodeChange()"
                                        [ngClass]="{ 'is-invalid':submitted && f.ifsc_code.errors }" required maxlength="11" minlength="11">
                                        <div *ngIf="submitted && f.ifsc_code.errors" class="selectError">
                                            <div *ngIf="f.ifsc_code.errors.required || f.ifsc_code.errors.whiteSpaceValidate">Please enter IFSC code</div>
                                            <div *ngIf="f.ifsc_code.errors?.pattern">Please enter valid IFSC code</div>
                                        </div>        
                                    </div>

                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls">Remarks:<span class="mandatoryCls">*</span></label>
                                        <input type="textarea" name="remarks" class="inputCls" formControlName="remarks" 
                                        [ngClass]="{ 'is-invalid':submitted && f.remarks.errors }" required>
                                        <div *ngIf="submitted && f.remarks.errors" class="selectError">
                                            <div *ngIf="f.remarks.errors.required || f.remarks.errors.whiteSpaceValidate">Please enter remarks.</div>
                                            <div *ngIf="f.remarks.errors?.pattern">Please enter valid remarks.</div>
                                        </div>   
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label class="labelCls">Note: If Bank name and branch is not available in list then please contact AHCT</label>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                        <label class="labelCls" for="applicationForm">Passbook/Cheque book<span class="mandatoryCls">*</span></label>
                                        <div class="mb-3"><b>Note: </b>All Upload Documents are in jpg/jpeg/png Format Only. Maximum Upload Size Per File is 1MB.</div>

                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="passbook" id="passbook" accept=".jpg, .jpeg, .png" required
                                            [ngClass]="{ 'is-invalid':submitted && f.passbook.errors }" (change)="onPassbookFileSelected($event)">
                                            <label class="custom-file-label" #passbookLabel for="passbook"><i class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls" (click)="clearFileUpload('passbook', 'passbookLabel')" *ngIf="f.passbook.value"></div>
                                        </div>
                                        <div *ngIf="submitted && f.passbook.errors" class="selectError">
                                            <div *ngIf="f.passbook.errors.required">Please upload passbook/cheque book</div>
                                            <div *ngIf="f.passbook.errors?.inValidExt && !f.passbook.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                                            <div *ngIf="f.passbook.errors?.inValidSize && !f.passbook.errors.required && !f.passbook.errors.inValidExt">File size exceeding 1MB</div> 
                                        </div>
                                    </div>
                                </div>

                                <hr class="hrCls mt-4">
                                <div class="subHeadingCls mb-3">Declaration</div>
                                <div class="row mb-3">
                                    <div class="ml-4 col-sm-9">
                                        <input class="form-check-input " formControlName="familyForm" required
                                            type="checkbox" value="" id="familyForm"
                                            [ngClass]="{ 'is-invalid':submitted && f.familyForm.errors }">
                                        <label class="labelCls" for="familyForm">The above information is true to the best
                                            of my knowledge. I agree to share my Aadhaar details of self and family with
                                            Government of Andhra Pradesh. I am aware that declaration of wrong information
                                            will entail any disciplinary action against me.:<span
                                                class="mandatoryCls">*</span> </label>
                                        <div *ngIf="submitted && f.familyForm.errors" class="invalid-feedback">
                                            <div *ngIf="f.familyForm.errors.required">Please Check Note</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="btnHolder">
                                    <button class="greenBtnCls float-right" (click)="submitAccountDetails()">Show Payment</button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav"></div>
        </form>
    </div>
</div>