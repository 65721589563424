import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-jagananna-suraksha-report',
  templateUrl: './jagananna-suraksha-report.component.html',
  styleUrls: ['./jagananna-suraksha-report.component.css'],
})
export class JaganannaSurakshaReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  headerCol = [{colId:'districT_NAME', colName: 'District'},
  {colId:'mandaL_NAME', colName: 'Mandal'},
  {colId:'secretariaT_NAME', colName: 'Secretariat'},
  {colId:'requisitionid', colName: 'Requisition Id'},
  {colId:'transactionamount', colName: 'Amount'},
  {colId:'transactiondate', colName: 'Requisition Date'},
  {colId:'paymenT_STATUS', colName: 'Payment Status'},
  {colId:'challanid', colName: 'Challan Id'},
  {colId:'challaN_TYPE', colName: 'Challan Type'},
  {colId:'challaN_DATE', colName: 'Challan Date'},
  ]
  form: FormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  departments: any[] = [];
  services: any[] = [];
  formatTypes: any[]= [];
  items: any[] = [];
  totalItems: any[] = [];
  beyondSLAItems: any[] = [];
  page = 1;
  popupPage = 1;
  pageSize = 10;
  format: any;
  showReports:Boolean=false;
  submitted:Boolean=false;
  noOfCol: any = 12;
  role: any;
  defaultFromDate: any;
  currentDate = new Date();
  popupPageSize = 10;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('BEYONDSLATABLE') beyondSLATable!: ElementRef;
  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private router: Router,private modalService: NgbModal) {
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],

    });
    this.format = 'District Wise';
    this.formatTypes = [{name: 'District Wise', isActive: true},
                        {name:'Mandal Wise', isActive: false},
                        {name:'Secretariat Wise', isActive: false}
  ]
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
    }
    this.getDistricts();
  }

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'District Wise';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
     });
  }

  get f() { return this.form.controls; }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getDistricts')
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
          console.log(this.districts,'districts')
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + '').subscribe({
      next: (responseData: any) => {
        console.log(responseData,'mandals')
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange() {
    this.showReports = false;
    this.villages = [];
   this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'villages')
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange() {
    this.showReports = false;
  }

  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  
  getFormat(): any {
      if (this.format === 'District Wise' ) {
      this.noOfCol = 11;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 11;
      return this.noOfCol;
    } else {
      this.noOfCol = 12;
      return this.noOfCol;
    }
  }
  onRuralUrbanChange() {
    if(this.f['district'].value !== 'All') {
      this.onDistrictChange();
    }
  }

  downloadExcel() {
    let fileName: any = 'Suraksha Report' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Jagananna Suraksha Report');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  downloadExcelForBeyondSLA() {
    let fileName: any =   'Pending Transactions Report'  //this.title + ' ' + this.f.fromDate.value +' to '+ this.f.toDate.value +')';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.beyondSLATable.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let sheetName = 'Pending Transactions Report';
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }



  GetJaganannaSurakshaReportData(from?: any): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
       
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
     
        let postData: any = '?Format='+ this.format +'&DistrictID=' + district + '&MandalId=' + mandal +'&SeceratraiteCode=' + village ;
        this.commonService.getRequest(this.commonConstants.GetJaganannaSurakshaReportData + postData).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.totalFlag !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totalFlag: string; }) => item.totalFlag === 'Y')
                  console.log(this.items,'items')
                  console.log( this.totalItems ,'total items')
            } else {
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
    }
    getPendingTransaction(item: any, modal: any) {
      let format: any;
        format = 'Pending';
      // }
      this.beyondSLAItems = [];
      this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
          this.popupPage = 1;
          
          let district = item.lgD_DIST_CODE === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.lgD_DIST_CODE;
          let mandal = item.lgD_MANDAL_CODE === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.lgD_MANDAL_CODE;
          let village = item.secretariaT_CODE === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode) : item.secretariaT_CODE;
          
          let postData: any = '?Format='+ format +'&DistrictID=' + district +'&MandalId=' + mandal +'&SeceratraiteCode=' + village ;
          
          this.commonService.getRequest(this.commonConstants.GetJaganannaSurakshaReportData + postData).subscribe({
            next: (responseData: any) => { 
              console.log('Transaction Wise',responseData)
              if(typeof(responseData?.result) !== 'string') {
                this.beyondSLAItems = responseData?.result;
              } else {
                this.beyondSLAItems = [];
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
    }
   
    goToHome() {
      this.router.navigate(['/home']);
    }
  }
  