import { Component, OnInit } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import {CommonService,WhitespaceValidator, AadharValidator} from 'src/shared/common.service';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PdfViewerPopupComponent } from '../../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonMsgModalComponent } from '../../../shared/common-msg-modal/common-msg-modal.component';

@Component({
  selector: 'app-health-card',
  templateUrl: './health-card.component.html',
  styleUrls: ['./health-card.component.css']
})
export class HealthCardComponent implements OnInit {
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  sixStepResponseData:any[] = [];
  presentAddress:any =[];
  sixstepResidentId:any[]=[];
  postData: any = {};
  postDataToSave:any={};
  imgSrc:any;
  showImg:Boolean=false;
  showCard:Boolean=false;
  isAadharOTP:Boolean= false;
  isPattadarOtpValidated : boolean = false;
  HOF:any
  villageCode:any;
  commonConstants: any = CommonConstants;
  barcodeValue: any;
  aadharData: any;  
  aadharTransactionID: any;
  generateCard: FormGroup;
  public submitted:Boolean=false;
  stationaryForm: FormGroup;
  submittedStationary:Boolean=false;
  requisitionType:any
  closeResult: string = '';
  userInfo: any = [];
  loggedInUserId : any;
  uhidDownload:any;
  statusInGsws:any;
  certificateName: string="";
  certificateTypeName: string ="";
  isPrint:Boolean=false;
  legacyData:any[]=[];
  houseHoldData:any[]=[];
  volunteerName:any;
  dataFromHouseHold:any=[];
  headOfFamily:any=[];
  encrtyptedAadhar:any;
  referenceID: any;
headofFamilyFromHouseHold:any=[];
showDownloadButton: Boolean = false;
  gridData : {
    memberName: string,
    gender: string,
    teluguName: string,
    dateOfBirth: any,
    familyAadharNo: string,
    relationwithHead: string,
    age: any,
    mobileNumber:any,    
    residentId:any,   
    iS_LIVING_WITHFAMILY:any
  } [] = [];
  role: any;
  constructor(private commonService: CommonService,private formBuilder: FormBuilder,private modalService: NgbModal) { 
   // this.getById('NHC221021001010')
   let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
      this.loggedInUserId = userData.userPrincipalName
    }
    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
      
      this.userInfo = JSON.parse(userData);
    }
   this.generateCard = this.formBuilder.group({
    uhid: ['',[Validators.minLength(11)]],
    //referenceNo: ['',[Validators.pattern(this.commonConstants.alphanumericPattern),Validators.minLength(9)]],
    aadharNo: ['',[AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate,Validators.minLength(12)]],
    otp: [''],
  });
  this.stationaryForm = this.formBuilder.group({
    stationary_number: ["", [Validators.required,Validators.pattern(this.commonConstants.alphanumericPattern)]],
 });
  }

  ngOnInit(): void {
  }
  get f() {
    return this.generateCard.controls;
  }

 /* stationary popup */
get fc() {
  return this.stationaryForm.controls;
}
  getById(type:any,id:any) {   
    
    this.commonService.getRequest(this.commonConstants.getCertificateDetails +'HF&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ id).subscribe({
      next: (responseData: any) => { 
      //   let reqData = responseData?.result?.requisitionData;   
      //   this.showCard=true;     
      //  let sixstepresponse :any[] =[];
       this.uhidDownload= responseData?.result?.requisitionData?.uhid;
       this.fileList =responseData?.result?.fileList;       
     //  this.checkStatus();

       let prefix =id?.substring(0,3);
       if(prefix === 'UHC' || prefix === 'NHC'){
        if(responseData?.result === 'Error in fetching the requisition - Record Not Found'){
          this.commonService.commonErrorPopup("Alert", 'Record Not Found', "md")
         }
       }else if(prefix !== 'UHC' || prefix !== 'NHC'){
        if(responseData?.result === 'Error in fetching the requisition - Record Not Found'){
          this.commonService.commonErrorPopup("Alert", 'This Card does not have Family photo,Please re-upload family photo in Update Aarogyasri Card Service', "md")
         }
       }      
       this.requisitionType=responseData?.result?.requisitionHeader?.requisitionType;
       this.certificateName=responseData?.result?.requisitionHeader?.requisitionType;
     if(this.statusInGsws === 'Approved'){

     //this.downloadCard(type ,id);
         this.straightThruGenerateCertificateData(type,id); 
     }
    //  else if(this.statusInGsws === 'Legacy data record'){
    
    //   this.commonService.commonErrorPopup("Alert", 'This Card does not have Family photo,Please re-upload family photo in Update Aarogyasri Card Service', "md")
    //  }
     else if(this.statusInGsws === 'Pending'){
        this.commonService.commonErrorPopup("Alert", 'Application approval pending with District Coordinator' , "md")
      }else if(this.statusInGsws === 'Rejected'){
        this.commonService.commonErrorPopup("Alert", 'Application rejected by District Coordinator' , "md")
      }else if(this.statusInGsws === 'SentBack'){
        this.commonService.commonErrorPopup("Alert", 'Application rejected by District Coordinator' , "md")
      }else if(this.statusInGsws === 'Transaction Unsuccessful'){
        this.commonService.commonErrorPopup("Alert", 'Payment failed', "md")
      }
      else{
      this.commonService.commonErrorPopup("Alert", 'Record not found', "md")
     }

     },
      
  });
    

  }
 
// residentid(){
//   var sendPostData = {
//     CITIZEN_ID: btoa('505582290249')
//   };
//   this.commonService
//   .postRequestForWebLand(
//     this.commonConstants.getSixstepvalidationDetails,
//     sendPostData,
//     'citizenInfo'
//   )
//   .subscribe({
//     next: (responseData: any) => {
//       this.sixstepResidentId = responseData.result;
//       console.log(responseData, 'residentid');
//       if(this.sixStepResponseData.length > 0 && this.sixstepResidentId.length > 0){
//         for (let i = 0; i < this.sixStepResponseData.length; i++) {
//           for(let j = 0; j < this.sixstepResidentId.length; j++)    
//           if(this.sixStepResponseData[i].familyAadharNo === this.sixstepResidentId[j].CITIZEN_ID){
//           this.sixStepResponseData[i].residentId = this.sixstepResidentId[j].RESIDENT_ID;  
//         }        
//       }
//         }
//     },
//     error: (error) => {
//       console.log('client side', error);
//     },
//   });
// }
  // viewFile(blobRef: any, fileName:any) {
  //   console.log('file Extension', this.checkFileExtension(fileName));
  //   this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
  //     next: (responseData: any) => { 
  //       let fileInfo =  responseData.result.fileContents;
  //       if (this.checkFileExtension(fileName) !== 'pdf') {
         
  //         this.imgSrc= 'data:image/png;base64,' +fileInfo;
  //         this.showImg = true;
  //       }
        
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
    
  // }
  // checkFileExtension(name: any) {
  //   let fileNameArr = name.split('.');
  //   return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  // }
  downloadDigitalCard(cardtype:any){
    if(this.role != 'Citizen') {
      this.f['otp'].clearValidators();
      this.f['otp'].updateValueAndValidity();
    } 
    if(this.role == 'Citizen') {
      this.f['otp'].setValidators(Validators.required);
      this.f['otp'].updateValueAndValidity();
    }
    if(this.f['uhid'].value || (this.f['aadharNo'].value && this.showDownloadButton && this.role == 'Citizen') || (this.f['aadharNo'].value && (this.showDownloadButton == false) && this.role != 'Citizen')){
      if(this.generateCard.valid){
        this.submitted=false;
        this.checkStatus(cardtype); 
      }
      else if(this.f['uhid'].value) {
        this.checkStatus(cardtype)
      }
  else{
    this.submitted=true;
  }
  }
  else if(this.role == 'Citizen' && this.f['aadharNo'].value && (this.showDownloadButton == false)) {
    this.commonService.commonErrorPopup("Alert", "Please verify your aadhar authentication", "md");
  }
    else{
      this.commonService.commonErrorPopup("Alert", "Please enter UHID or Application Number or Registered Aadhaar No", "md")
     // alert('Please enter UHID or Application Number or Registered Aadhaar No');
        }


  }
  generateReferenceNo(type:any){
    let idtype = "";
    let  id ="";
    if(this.f['uhid'].value){
       id =this.f['uhid'].value;
       idtype = "uhid";
    }else{
      id= this.f['aadharNo'].value;
      idtype = "aadhar";
    }
    let postData = {
      Id: id,
      idtype: idtype
    }
    this.commonService.postRequest(this.commonConstants.GetReferenceIdFromAadharOrUHID, postData, true).subscribe({
      next: (responseData: any) => { 
        let reqData = responseData?.result;   
      //this.downloadCard(type,reqData);
     if(reqData.dataFlag == 'E'){
      this.commonService.commonErrorPopup("Alert",'No Records Found' , "md")
     }
    //  else if(reqData.dataFlag == 'L'){
    //   this.commonService.commonErrorPopup("Alert", 'This Card does not have Family photo, Please re-upload family photo in Update Aarogyasri Card Service', "md")
    //  }
     else if(reqData.dataFlag == 'R'){
      this.getById(type,reqData?.requisitionId)
     }
      
        //this.members.push({'secretariatName':this.secretariate})
       
      }
    })  
  }
  downloadCard(type:any,id:any){
    let isSigned: any;  
    isSigned = '&IsSigned=false';   
   // let id = this.f['referenceNo'].value; 
    this.isPrint = (type==='print' || type === true)? true:false
    
  this.commonService.getRequest(this.commonConstants.getSignedCertificateData + id + '&DepartmentCode=' + 'HF' +isSigned+ '&IsPrint='+ this.isPrint).subscribe({
    next: (responseData: any) => {
      let fileInfo =  responseData.result.fileContents;
      let result=responseData?.result;
      if(fileInfo !== null && result.isSuccess === true){

      const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
      modalRef.componentInstance.sourceFile = fileInfo;
      modalRef.componentInstance.fileName = this.uhidDownload;
      modalRef.componentInstance.showPrintDownload = true;

      }else if(this.statusInGsws === 'Pending'){
        this.commonService.commonErrorPopup("Alert", 'Application approval pending with District Coordinator' , "md")
      }else if(this.statusInGsws === 'Rejected'){
        this.commonService.commonErrorPopup("Alert", 'Application rejected by District Coordinator' , "md")
      }else if(this.statusInGsws === 'SentBack'){
        this.commonService.commonErrorPopup("Alert", 'Application rejected by District Coordinator' , "md")
      }
      // else if(this.statusInGsws === 'Legacy data record'){
     
      //    this.commonService.commonErrorPopup("Alert", 'This Card does not have Family photo,Please re-upload family photo in Update Aarogyasri Card Service', "md")
      // }
      else if(this.statusInGsws === 'Transaction Unsuccessful'){
        this.commonService.commonErrorPopup("Alert", 'Payment failed', "md")
      }
        else{
          this.straightThruGenerateCertificateData(this.isPrint,id); 
      //  this.commonService.commonErrorPopup("Alert", result.error, "md")
       // alert(result.error)
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
  }
//   downloadCertificateFromGSWS(id:any){       
//     this.straightThruGenerateCertificateData(id); 
    
  
// }
// regenerateCatBCertificateWithOutSign(id:any){
   
//   let IsSigned = false;

//   this.commonService.getRequest(this.commonConstants.getSignedCertificateData + id + '&DepartmentCode=' + 'HF' +'&IsSigned='+IsSigned +'&IsPrint='+ this.isPrint).subscribe({
//     next: (responseData: any) => {
//       console.log('responseData.result.fileContents',responseData)
//       if(responseData.result.isSuccess == true){
       
//         let fileInfo =  responseData.result.fileContents;
//           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
//           modalRef.componentInstance.sourceFile = fileInfo;         
//             modalRef.componentInstance.fileName = this.uhidDownload;         
//           modalRef.componentInstance.showPrintDownload = true;      
//         }else{
         
//             this.straightThruGenerateCertificateData("CatB",id); 
//       }
//     },
//     error: (error) => {
//       console.log('client side', error);
//     }
//   });
// }
straightThruGenerateCertificateData(isPrint:any,id:any){
  this.commonConstants.certificateList.forEach((e: any) => {
    if(this.certificateName == e.certName)    {
      this.certificateTypeName = e.certID
    }
  }); 
        var sendPostData={
        RequisitionId: id,
        Department: 'HF',
        CertificateType : this.certificateTypeName
      }
      this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
        next: (responseData:any) => { 
          //this.straightThruGenerateCertificate(certitificateType);
          if(responseData.result.isSuccess == true){
            this.straightThruGenerateCertificate(isPrint,id);
         }else{
         
           const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
           modalRef.componentInstance.title = 'Alert';
           modalRef.componentInstance.message = 'Certificate data not available..!!'; 
         }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
  }


straightThruGenerateCertificate(isPrint:any,id:any) {
  this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + id+ '&Department=' + 'HF').subscribe({
    next: (responseData: any) => {

     this.downloadCard(isPrint,id)
      // if(certitificateType === "CatB"){
      //   this.downloadCard(this.isPrint,id)
      //  // this.regenerateCatBCertificateWithOutSign(id);
      // }else{
      //   this.downloadCertificateFromGSWS(id);
      // }
    },
    error: (error) => {
     
      console.log('client side', error);
    }
  });
}

  printCard(content:any){
 
    this.stationaryForm.reset();  
  
    this.modalService
           .open(content, {
             size: 'lg',
             ariaLabelledBy: 'modal-basic-title',
             windowClass: 'app-modal-window',
           })
           .result.then(
             (result) => {
               this.closeResult = `Closed with: ${result}`;
             },
             (reason) => {
               this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
             }
           );
 }
  // submitStaionaryData():any{
  
  //   this.submittedStationary = true;
  //   if (!this.stationaryForm.valid) {
  //     return false;
  //   } else {
  //     this.storeStationaryData(this.fc['stationary_number'].value);
  //   }
  
  // }
  // storeStationaryData(id:any) {
  //   let closePopupBtn: HTMLElement = document.getElementById(
  //     'closePopup'
  //   ) as HTMLElement;
    
  //     let inputData = "?UserId="+this.userInfo.userPrincipalName+"&StationaryNumber="+id+"&TransactionId="+ 'T'+this.f.referenceNo.value;
  //       this.commonService.getRequest(this.commonConstants.getPrintedCertificate + inputData).subscribe({
  //         next: (responseData: any) => { 
  //           if(responseData.result.insertFlag === "Y"){
  //               closePopupBtn.click();
  //               this.saveStationryNumber(id);
  //              // this.PrintPerforma(content);
  //           }else if(responseData.result.insertFlag === "N"){
  //             if(responseData?.result?.stationaryExistCount >0){
  //               this.alertPopupForNoRecords(responseData?.result?.stationaryExistList.join(',')+ ' stationary number already exists');
  //                           } 
  //           }else if(responseData.result.errorMessage){
  //             this.alertPopupForNoRecords(responseData.result.errorMessage);
  //           }
  //         },
  //         error: (error) => {
  //           console.log('client side', error);
  //         }
  //     });
    
  // }
  // saveStationryNumber(stationaryNumber:any) {
  //   //New Aarogyasri card
  //   //Update Aarogyasri card
  //   //AarogyaSriNewCard
  //   //UpdateAarogyaSriCard
  //   let postData = {
  //     userId : this.userInfo.userPrincipalName,
  //     serviceName : "New Aarogyasri card",
  //     serviceCode : "AarogyaSriNewCard",
  //     requisitionId : this.generateCard.controls.referenceNo.value,
  //     department : "HF",
  //     transactionId : 'T' +this.generateCard.controls.referenceNo.value,
  //     noOfCopy : 1,
  //     StationaryNumber : stationaryNumber
  //   }
   
  //   this.commonService.postRequest(this.commonConstants.savePrintedCertificate , postData).subscribe({
  //     next: (responseData: any) => { 
  //         this.downloadDigitalCard('print');
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }
  sendOTP():any{
    // this.submitted = true;
    this.submitted = true;
    this.isAadharOTP = true;
     if(!this.f.aadharNo.valid)
         return false;
     var sendPostData={
       uid: this.f.aadharNo.value,
       authentication_type: "OTPGENERATE"
     }
     this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
       next: (responseData:any) => { 
         if(responseData?.reason == 'OTP Sent Successfully'){
           this.aadharTransactionID = responseData?.result
         } else {
           this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
         }
       },
       error: (error: any) => {
         console.log('client side', error);
       }
     });
   }
   validateOTP():any {
    if(!this.generateCard.valid) {
      this.submitted=true;
      return false;    
    } 
   this.aadharData = [];
    var sendPostData={
      PIDXML: this.aadharTransactionID,
      otp : this.f.otp.value,
      uid: this.f.aadharNo.value, //'514492421388',//
      authentication_type: "OTPVALIDATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        // if(this.f['uhid'].value.length != 11) {

        // }
        if(responseData?.reason == 'OTP Verified Successfully'){

          this.showDownloadButton = true;
          this.commonService.commonErrorPopup('Alert', 'Authentication Success.', 'md');
          this.aadharData = responseData?.result;
              let data: any;
              data = {}
            this.isPattadarOtpValidated = true;
        } else if(responseData?.reason == 'OTP validation failed'){
          this.showDownloadButton = false
         this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        } else{
          this.showDownloadButton = false
          this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  alertPopupForNoRecords(printedDate:any) {
  
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = printedDate ;
    modalRef.result.then((result: any) => {});
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  checkStatus(cardtype:any){
    let id:any;
    let type:any;
  // if(this.f['referenceNo'].value){
  //   id = this.f['referenceNo'].value; 
  //   type='ReferenceId';
  //  }
    if(this.f['aadharNo'].value){
    id = this.f['aadharNo'].value; 
    type='UID';
   }else if(this.f['uhid'].value){
    id = this.f['uhid'].value; 
    type='UHID';
   }
   let requestData = {
    Id: id,
    IdType: type
   }

   this.commonService.postRequest(this.commonConstants.getHealthCardStatus, requestData, true).subscribe({
    next: (responseData: any) => {
      let reqData = responseData?.result;         
      this.legacyData=[];
      if(reqData?.length>0 && typeof(responseData?.result) !== 'string'){
        this.referenceID = reqData[0]?.referenceId;
        this.legacyData=responseData.result;
        let postData= {
          Id: reqData[0]?.referenceId,
          IdType: 'ReferenceId'
        };
        this.commonService.postRequest(this.commonConstants.GetCurentApplicationStatusByID, postData, true ).subscribe({
         next: (statusresponseData: any) => { 
         let status=  statusresponseData?.result;
          if(statusresponseData.result.isSuccess === true){
             this.statusInGsws = statusresponseData?.result?.status; 
           
           if(this.statusInGsws === 'Approved'){
          //  if(this.f['referenceNo'].value != ''){
          //    this.getById(cardtype,this.f['referenceNo'].value)
          //  }else
            if(this.f['uhid'].value != '' || this.f['aadharNo'].value != ''){
               if(this.f['aadharNo'].value != '' && (this.f['aadharNo'].value.length === 12 )){
              // this.generateReferenceNo(cardtype);
              this.getById(cardtype,this.referenceID);
             }else if(this.f['aadharNo'].value != '' && (this.f['aadharNo'].value.length !== 12 )){
               this.commonService.commonErrorPopup("Alert", 'Enter Valid Registered Aadhar Number', "md")
             }  else if(this.f['uhid'].value != '' && this.f['aadharNo'].value != '' && (this.f['aadharNo'].value.length === 12 ) && (this.f['uhid'].value.length === 11 || this.f['uhid'].value.length === 13)){
             //  this.generateReferenceNo(cardtype);
             this.getById(cardtype,this.referenceID);
             }
               else if(this.f['uhid'].value != '' && (this.f['uhid'].value.length === 11 || this.f['uhid'].value.length === 13)){
              // this.generateReferenceNo(cardtype);
              this.getById(cardtype,this.referenceID);
             }else{
               this.commonService.commonErrorPopup("Alert", 'Enter Valid UHID', "md")
             }
          }
         
          
         }else if(this.statusInGsws === 'Pending'){
           this.commonService.commonErrorPopup("Alert", 'Application approval pending with District Coordinator' , "md")
         }else if(this.statusInGsws === 'Rejected'){
           this.commonService.commonErrorPopup("Alert", 'Application rejected by District Coordinator' , "md")
         }else if(this.statusInGsws === 'SentBack'){
           this.commonService.commonErrorPopup("Alert", 'Application rejected by District Coordinator' , "md")
         }else if(this.statusInGsws === 'Legacy data record'){
     
           this.legacyDataChecking();
     
           //this.commonService.commonErrorPopup("Alert", 'This Card does not have Family photo,Please re-upload family photo in Update Aarogyasri Card Service', "md")
         }else if(this.statusInGsws === 'Transaction Unsuccessful'){
           this.commonService.commonErrorPopup("Alert", 'Payment failed', "md")
         }else{
           this.commonService.commonErrorPopup("Alert", 'Record not found', "md")
         }
       } 
     else{
           this.commonService.commonErrorPopup("Alert", 'Record not found', "md")
         }
         }})
      } else if (reqData.length === 0) {
        let postData= {
          Id: id,
          IdType: type
        };
        this.commonService.postRequest(this.commonConstants.GetCurentApplicationStatusByID, postData, true).subscribe({
          next: (statusresponseData: any) => { 
            let status ='';
            this.statusInGsws='';
            if(statusresponseData?.result?.isSuccess === true){
              status=  statusresponseData?.result?.status;
              if(status == 'Legacy data record'){                   
                this.statusInGsws = '';
              }else if(status =='Pending'){
                this.statusInGsws = 'Pending at DC' ;
              }else if(status =='No records found for the given Id'){
                this.statusInGsws = '' ; 
              }else if(status =='Transaction Unsuccessful'){
                this.statusInGsws = 'Payment failed' ; 
              }else if(status =='Approved'){
                this.statusInGsws = 'Approved' ; 
              }else if(status == 'Rejected'){
                this.statusInGsws = statusresponseData?.result?.status +((statusresponseData?.result?.reason !== null  && statusresponseData?.result?.reason !== '') ? ( '-'+ statusresponseData?.result?.reason) : '');
              } 
            }else{
              this.statusInGsws = '';
            }
          
            // else{
            //   this.statusInGsws = statusresponseData?.result;
            // }
            //this.statusInGsws = statusresponseData?.result; 

            if(reqData.length === 0 && status == 'Transaction Unsuccessful' ){
              this.commonService.commonErrorPopup("Alert", "Transaction Failed", "md") 
            }else{
              this.commonService.commonErrorPopup("Alert", "Records not found", "md")
            }
          }})
      } else{
        this.commonService.commonErrorPopup("Alert", "Records not found", "md")
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
   })

  }

  legacyDataChecking(){
    let id:any;
      let type:any;
    // if(this.f['referenceNo'].value){
    //   id = this.f['referenceNo'].value; 
    //   type='ReferenceId';
    //  }else
       if(this.f['aadharNo'].value){
      id = this.commonService.RSAEncrypt(this.f['aadharNo'].value); 
      type='UID';
     }else if(this.f['uhid'].value){
      id = this.f['uhid'].value; 
      type='UHID';
     }

    if (this.legacyData.length > 0 && typeof(this.legacyData) !== 'string') {
      let isNullAadhar:any;
      isNullAadhar=this.legacyData.filter(item => (this.commonService.RSADecrypt(item.aadharId) === null) || (this.commonService.RSADecrypt(item.aadharId) === '') || (this.commonService.RSADecrypt(item.aadharId) === undefined));
      if (isNullAadhar.length == 0) {
        if(this.legacyData[0].uhid) {
          this.houseHoldDataFromSixStepAPI(0)
        } else {
          this.commonService.commonErrorPopup("Alert", 'This Family does not have UHID', "md")
        }
      } else {
        this.commonService.commonErrorPopup("Alert", 'Family member details not matching with house hold data, Please apply for Update Aarogyasri Card', "md")
      }
    } else {
      this.commonService.commonErrorPopup("Alert", 'Error', "md")
    }
  }
  houseHoldDataFromSixStepAPI(i:any){    
    let id=this.legacyData[i].aadharId;
    var sendPostData={
      CITIZEN_ID: this.commonService.RSAEncrypt(this.commonService.RSADecrypt(id), true),
    }
    this.commonService.postRequestForWebLand(this.commonConstants.newSixStepDetails, sendPostData , 'citizenInfo').subscribe({
      next: (sixstepData:any) => { 
        this.sixStepResponseData = sixstepData.result;
        this.houseHoldData=[];
    if(this.sixStepResponseData?.length>0){      
  this.houseHoldData=sixstepData.result;
 let isInHousehold:Boolean=false;


  if(this.legacyData.length === this.houseHoldData.length){
    this.houseHoldData.forEach((fo:any) => {
      if(this.legacyData.findIndex((eo) => this.commonService.RSADecrypt(eo.aadharId) === fo.CITIZEN_ID) > -1){
        
          isInHousehold= true;          
        }else{
          isInHousehold= false; 
          return
        }
         
      })

     
  }else{
    isInHousehold= false;
  }
  
 
    
     


      if(isInHousehold){
        let isValid: any;
        let teluguName:any;
        let data:any=[];
        let isTeluguName:any=[];  
          for (let i = 0; i < this.houseHoldData.length; i++){     
            teluguName =[];    
            teluguName =   this.houseHoldData[i].CITIZEN_NAME.match(this.commonConstants.namePattern)      
            data.push(teluguName);
          }
          isTeluguName = data.filter((item:any) => (item === null));
          isValid=this.houseHoldData.filter(item => (item.CITIZEN_NAME === "")|| (item.CITIZEN_NAME === null)||(item.CITIZEN_NAME === undefined) ||(item.CITIZEN_NAME === 'NA')|| (item.RESIDENT_ID=== "") || (!item.DOB_DT));
         
          this.headOfFamily= this.legacyData.filter((item:any) => (item.relation=== 'SELF'));
              if(this.headOfFamily.length == 1){
                if(isValid.length === 0 &&  isTeluguName.length === 0){
                  let postData: any = {};
                  postData.aadhaarNumber = this.commonService.RSAEncrypt(this.headOfFamily[0].aadharId, true);   
                  this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
                    next: (houseHoldResponseData: any) => { 
                      if(houseHoldResponseData?.result && houseHoldResponseData?.result.length !==0){
                        houseHoldResponseData.result.forEach((item: any) => {
                          item.aadhaarNumber = item.aadhaarNumber;
                        });
                    this.volunteerName=houseHoldResponseData?.result[0].volunteername;
                    this.dataFromHouseHold = houseHoldResponseData?.result;
                    this.getEncryptedAadharValues(this.dataFromHouseHold[0].aadhaarNumber)
                    
                      }
                    },
                  });

                 
                }else{
                  this.commonService.commonErrorPopup("Alert",'Family members does not have proper data or have blank member name(citizen Name, date of birth  or resident Id missed)' , "md");
                }
              }else{
                this.commonService.commonErrorPopup("Alert", 'Head of the family members details are not proper, Please apply for Update Aarogyasri Card', "md")
              }
          
         
     
      }else{
        this.commonService.commonErrorPopup("Alert", 'Family member details not matching with house hold data, Please apply for Update Aarogyasri Card', "md")
      }
      
  }  
else if(this.sixStepResponseData.length ==0){
  i+=1;
this.houseHoldDataFromSixStepAPI(i)
}else{
  this.commonService.commonErrorPopup("Alert", "Applicant details not there in House Hold details", "md"); 
}
},
});
  }
  savingLegacyDataToDownloadCard(){
    this.gridData=[];
    this.houseHoldData.forEach((element: { CITIZEN_ID:any ; CITIZEN_NAME: any; DOB_DT: any; GENDER:any; 
      MOBILE_NUMBER: any; RESIDENT_ID: any; residentId:any; IS_LIVING_WITHFAMILY:any}) => {
      
       
      let gridDate =  element.DOB_DT != '' ? this.commonService.formatDate(element.DOB_DT) : null;
      return this.gridData.push(
        {
          memberName: element.CITIZEN_NAME,
          gender: element.GENDER,
          teluguName: '',
          dateOfBirth: gridDate ,
          familyAadharNo: this.commonService.RSAEncrypt(element.CITIZEN_ID),
          relationwithHead: this.getRelationFromLegacydata(element.CITIZEN_ID),
          age: element.DOB_DT ? this.ageCalculator(element.DOB_DT): '' ,
          mobileNumber:element.MOBILE_NUMBER,         
          residentId:element.RESIDENT_ID,
          iS_LIVING_WITHFAMILY:element.IS_LIVING_WITHFAMILY

        }
      ) 
    });

    let doorNoFromHousehold: any ; 
    if((this.dataFromHouseHold[0].doorNo?.trim())[0] === '-') {
      doorNoFromHousehold = (this.dataFromHouseHold[0].doorNo?.trim()).substr(1, (this.dataFromHouseHold[0].doorNo?.trim()).length);
    } else {
      doorNoFromHousehold = this.dataFromHouseHold[0].doorNo?.trim();
    }
    this.postData={
      requisitionHeader: {
        requisitionType: 'LegacyAarogyaSriCard',
        department: 'HF',
        applicantInfo: {
          id: '',
          aadhaarNumber: this.encrtyptedAadhar,
          personNames: [
            {
              languageCode: 'en-in',
              firstName:this.dataFromHouseHold[0].firstName,
              middleName: '',
              lastName: '',
              fatherHusbandName:'',
            },
          ],
          gender: this.dataFromHouseHold[0].gender,
          birthDate: this.dataFromHouseHold[0].birthDate ?this.commonService.formatDate(this.dataFromHouseHold[0].birthDate) :'',
          caste: this.dataFromHouseHold[0].caste,
          religion:'',
          qualification: '',
          maritalStatus: '',
          addresses: [
            {
              addressType: 'present',
              doorNo: doorNoFromHousehold,
              streetName: '',
              villageWard: this.dataFromHouseHold[0].seccode,
              mandalMunicipality:this.dataFromHouseHold[0].mandalcode,
              district: this.dataFromHouseHold[0].districtcode,
              pinCode: this.dataFromHouseHold[0].pinCode,
              postalOfficeCode:'',
              postalOfficeName:'',
              postalVillageCode: '',
              postalVillageName: ''
            }
          ],
          rationCardNo: '',
          deliveryType: '',
          emailAddress: '',
          mobileNumber: this.dataFromHouseHold[0].mobileNumber ? this.dataFromHouseHold[0].mobileNumber : null,
        },
        remarks: '',
      },
      requisitionData:{
        uhid:this.legacyData[0].uhid,
        familyDetails: this.gridData,
          familyAddress: {
            addressType: 'present',
              doorNo: doorNoFromHousehold,
              streetName: '',
              villageWard: this.dataFromHouseHold[0].seccode,
              villageWardName:this.dataFromHouseHold[0].villageWard,
              mandalMunicipality:this.dataFromHouseHold[0].mandalcode,
              mandalMunicipalityName:this.dataFromHouseHold[0].mandalMunicipality,
              district: this.dataFromHouseHold[0].districtcode,
              districtName:this.dataFromHouseHold[0].district,
              pinCode: this.dataFromHouseHold[0].pinCode,
              postalOfficeCode:'',
              postalOfficeName:'',
              postalVillageCode: '',
              postalVillageName: '',
              locality:''
          },
      }
    }
    
    this.commonService.postRequest(this.commonConstants.aarogyasriLegacyHealthCard, this.postData).subscribe({
      next: (responseData:any) => { 
        // this.legacyDataReqId=responseData.result.requisionId
        if(responseData.result.id){
          this.certificateName=responseData?.result?.requisitionHeader?.requisitionType;
          this.uhidDownload=this.legacyData[0].uhid;

          
            this.postDataToSave = {
              Userid: this.userInfo.userPrincipalName,
              RequisitionId: responseData.result.id,
              DepartmentCode: 'HF',
              RequisitionType: 'LegacyAarogyaSriCard',
            };
        
            this.commonService
              .postRequest(this.commonConstants.saveRequisitionDetails, this.postDataToSave)
              .subscribe({
                next: (rsdata: any) => {
                  if(rsdata.result == "Success"){
                    this.straightThruGenerateCertificateData(false,responseData.result.id)
                  }
                }
              })
          
        }
         
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  
  ageCalculator(date: any) {
    let showAge=0;
    const convertAge = new Date(this.commonService.formatDate(date));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    showAge= Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    return showAge ?  showAge :'';
  }
  getRelationFromLegacydata(Id:any){
    let relation:any='';
    let data = this.legacyData.filter((item:any) => (this.commonService.RSADecrypt(item.aadharId) === Id)); 
    return data[0]?.relation ? data[0]?.relation : '';
  }
  getEncryptedAadharValues(aadharValue:any){
    this.encrtyptedAadhar = '';
    this.commonService
      .getRequest(this.commonConstants.cryptoEncryptValue + encodeURIComponent(this.commonService.RSAEncrypt(aadharValue)))
      .subscribe({
        next: (responseData: any) => {
          this.encrtyptedAadhar =  responseData.result.encryptedValue;
         
          if(this.encrtyptedAadhar?.length !==0 && this.encrtyptedAadhar !== null){
          
           this.savingLegacyDataToDownloadCard();

            }else{             
              this.commonService.commonErrorPopup("Alert", "Aadhar Encryption Failed , Try again!!", "md");        
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

}




 