import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { faLock, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonConstants } from './../../constants/common-constants.component';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';

import { CheckoutService } from '../../../angular-checkout-js/src/lib/services/checkout.service';
//import {CONFIG } from '../../mocks/merchant-config.js';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css'],
})
export class PaymentGatewayComponent implements OnInit {
  faLock = faLock;
  faInfoCircle = faInfoCircle;
  currentDate: any;
  serviceData: any;
  paymentMode: FormGroup;
  postData: any = {};
  payment_mode = 'CASH';
  paymentRedirectionURL = '';
  isContinuePayment:Boolean=false;
  commonConstants: any = CommonConstants;
  reqsuisionId: any;
  transactionID: any;
  requisitionType: any;
  departmentID: any = 'RV';
  amountData: any;
  userInfo: any = [];
  basicInfo: any = [];
  serviceList: any = [];
  fullName: String = '';
  serviceName: String = '';
  requestType: String = '';
  merchantID: String = '';
  merchantIDsamllcase: String = '';
  buildUPIUrl: String = '';
  aadharNo: any;
  applicationType : any;
  registrationNumber:any;
  originalData: any;
  postWeblandData: any = {};
  public isActive: Boolean = false;
  public isDisabled: Boolean = false;
  public isProd: Boolean = false;
  isLoginPresent: Boolean = false;
  isCashModeEnable: Boolean = true;
  routedData: any;
/* -------------------paytm------- */
isCheckoutVisible = true;
openInPopup = true;
config: any = {};  
/* -------------------paytm------- */

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private modalService: NgbModal,
    private readonly checkoutService: CheckoutService
  ) {
    let userData = localStorage.getItem('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      console.log(this.userInfo.id, '===user id');
      let userId = this.userInfo.userPrincipalName?.split('-');
      if (userId?.length > 0) {
        let loginIds: any = ['12345678'];
        let secretariatCode = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
        this.isLoginPresent = loginIds.includes(secretariatCode);
        //console.log('this.secretariatCode', this.secretariatCode)
      }
      if(this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') {
        this.payment_mode = 'UPI'
      }
    }

    let secreteriateCode = localStorage.getItem('locationCode');
    this.commonService.getRequest(this.commonConstants.getDistCodeBySecCode + secreteriateCode).subscribe({
      next: (responseData:any) => { 
        this.isCashModeEnable = (responseData.result?.neW_LGD_DIST_CODE == 503 ? false : true); 
        if(!this.isCashModeEnable) { 
          this.payment_mode = 'PaytmUPI'
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });

    this.paymentMode = this.formBuilder.group({
      mode: ['UPI', [Validators.required]],
    });

    //this.initializeCheckout();

  }

  ngOnInit(): void {
    //console.log(window && window.location && window.location.hostname,"---->URL")
    if (
      /^vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      //for prod gateway
      this.paymentRedirectionURL =
        'https://app-vsws-payment-response-ci-prod.azurewebsites.net/RequestPage.aspx';
        this.merchantID = "APGVWVVSWS";
        this.merchantIDsamllcase = "apgvwvvsws";
        this.buildUPIUrl = "https://pgi.billdesk.com/pgidsk/PGIMerchantPayment?msg=";

        this.isProd = true;
    } 
    else if (
      /^uat.vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      //for UAT gateway
      this.paymentRedirectionURL =
        'https://app-gsws-paymentresponse-ci-uat.azurewebsites.net/RequestPage.aspx';
      this.merchantID = "BDSKUATY";
      this.merchantIDsamllcase = "bdskuaty";
      this.buildUPIUrl = "https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg="
      this.isProd = false;
    } else {
      //this.paymentRedirectionURL ='https://gsws-paymentresponse.azurewebsites.net/RequestPage.aspx';
      this.paymentRedirectionURL ='https://app-vsws-dev-paymentresponse-ci-01.azurewebsites.net/RequestPage.aspx';
        this.merchantID = "BDSKUATY";
        this.merchantIDsamllcase = "bdskuaty";
        this.buildUPIUrl = "https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg="
        this.isProd = false;
    }

    this.currentRoute.queryParams.subscribe((params: any) => {
      this.routedData = params
      if (params.id) {
        console.log(params, 'parms');
        this.reqsuisionId = params.id;
        this.transactionID = 'T' + params.id;
        this.requisitionType = params.type;
        this.departmentID = params.department;
        this.aadharNo = params.department == 'CS' ? params.aadharNumber : 'NA'
        this.applicationType = params.department == 'CS' ? params.applicationType : 'NA'

        if (this.departmentID === 'MAUD') {
       
          this.getRequisitionMAUDData(this.reqsuisionId, this.departmentID);
       
        } else {
       
          this.getRequisitionData(this.reqsuisionId, this.departmentID);
        }

        this.getServiceListData(this.requisitionType);

        this.getAmount(this.reqsuisionId);

      } else {
        console.log('Requisition data not available.');
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = 'Requisition data not available !!!';
        modalRef.result.then((result: any) => {
            if (result) {
                this.router.navigate(['/home']);
            }
          });
      }
      //console.log(this.departmentID, '>>>URL this.departmentID');
    });
  }
  isCitizenforgetAmount: boolean = false;
 getAmount(id: any) {
    let isCitizen = false;
    this.isCitizenforgetAmount = false;
    if(this.userInfo.jobTitle === 'Citizen') {
      isCitizen = true;
      this.isCitizenforgetAmount = true;
    }
    let inputData ='?RequisitionId=' +id +'&DepartmentCode=' +this.departmentID +'&RequisitionType=' +this.requisitionType +'&IsCitizen='+ isCitizen;
     this.saveRequisitionDetails(id,this.departmentID,this.requisitionType,inputData);
 }

 
  saveRequisitionDetails(reqId:any,deptId:any,reqType:any,inputData: any){
      this.postData = {
        Userid: this.userInfo.userPrincipalName,
        RequisitionId: reqId,
        DepartmentCode: deptId,
        RequisitionType: reqType,
      };
  
      this.commonService
        .postRequest(this.commonConstants.saveRequisitionDetails, this.postData)
        .subscribe({
          next: (rsdata: any) => {
           
            console.log(rsdata,"responseData")
            if(rsdata.result == "Success"){
            
              let postData = {
                requisitionId: reqId,
	              departmentCode: this.departmentID,
	              requisitionType: this.requisitionType,
	              isCitizen: this.isCitizenforgetAmount
              }
              this.commonService.postRequest(this.commonConstants.getAmount, postData, true)
              .subscribe({
                next: (responseData: any) => {
                 
                  
                  if(responseData.result ==="Amount Data Not Available" || responseData.result === "Requisition Not Found" ){
                    this.isDisabled = false;
                    console.log( this.isDisabled,' this.isDisabled')
                    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    modalRef.componentInstance.title = 'Alert';
                    modalRef.componentInstance.message = 'Data not found !!!';
                    modalRef.result.then((result: any) => {
                      if (result) {
                            this.router.navigate(['/home']);
                      }
                    });

                  }else{
                    
                    this.isDisabled = true;
                    console.log( this.isDisabled,'2 this.isDisabled')
                    this.amountData = responseData.result;
                    this.originalData = [];
                    this.originalData.push(responseData.result);
                     this.originalData =  this.originalData.map((item: any) => {
                    item.originalTotalAmount = item.totalAmount;
                   return item;
                  });
                    if(this.requisitionType ==='WaterTaxService' && this.payment_mode=='CASH' || (this.payment_mode === 'UPI' || this.payment_mode == 'PaytmUPI')){
                      this.amountData.totalAmount =  Math.round(this.amountData.totalAmount);
                    }
                    console.log( this.amountData?.totalAmount,"AMOUNT");
                    console.log(this.requisitionType,"REQUISITIONTYPE");
                      if ((this.amountData?.totalAmount == 0  || this.amountData?.totalAmount == 0.0) &&
                      (this.requisitionType ==='RegistrationofTokens' || this.requisitionType ==='PatientBankDetails' || this.requisitionType ==='AssignmentofLandstoEx-ServicepersonServingSoldiers' || this.requisitionType ==='Pensions' || this.requisitionType ==='HouseholdSplit'  || this.requisitionType === 'HouseholdSplit@1' || this.requisitionType === 'HouseholdSplit@2' || this.requisitionType ==='HousingSiteApplication' ||  this.requisitionType ==='HousingSiteApplication-URBAN' ||  this.requisitionType ==='JobSeekerRegistration' || this.requisitionType ==='RectificationOfResurveyedRecords' ) ) 
                      {
                        this.saveCashModePayment();
                      }else if (
                        (this.amountData?.totalAmount == 0  || this.amountData?.totalAmount == 0.0) && 
                        (this.requisitionType !=='RegistrationofTokens' || this.requisitionType !=='PatientBankDetails' || this.requisitionType ==='AssignmentofLandstoEx-ServicepersonServingSoldiers' || this.requisitionType !=='Pensions'  || this.requisitionType !=='HouseholdSplit' || this.requisitionType !== 'HouseholdSplit@1' || this.requisitionType !== 'HouseholdSplit@2' || this.requisitionType !=='HousingSiteApplication' ||  this.requisitionType !=='HousingSiteApplication-URBAN' || this.requisitionType !=='RectificationOfResurveyedRecords')
                      ) {
                      
                        let InputStr = {
                          Userid: this.userInfo.userPrincipalName,
                          RequisitionId : this.reqsuisionId,
                          Department: this.departmentID,
                          RequisitionType: this.requisitionType,
                          TransactionId: null ,
                          TransactionAmount : this.amountData?.totalAmount,
                          TransactionStatus : 'aborted'
                        }
                        
                        this.updateTransactionStatusInRequisitionDetails(InputStr);
                      }
                      else{
                        
                      }
                  }  
        
                },
                error: (error) => {
                  console.log('client side', error);
                },
              });
           
            }else{
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Error';
              modalRef.componentInstance.message = rsdata.result;
            }
            
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
  }
  
  selectPaymentMode(mode: any) {
    this.payment_mode = mode;
    if(this.requisitionType === 'WaterTaxService' && (this.payment_mode === 'UPI' || this.payment_mode == 'PaytmUPI') ){
      //this.getAmount(this.reqsuisionId);
      this.amountData.totalAmount = this.originalData[0]?.originalTotalAmount;
    } else if(this.requisitionType === 'WaterTaxService' && this.payment_mode === 'CASH' || (this.payment_mode === 'UPI' || this.payment_mode == 'PaytmUPI')){
      //this.getAmount(this.reqsuisionId);
      this.amountData.totalAmount = Math.round(this.originalData[0]?.originalTotalAmount);
    }
  }

  confirmPay() {
if(this.departmentID !='LD' || (this.departmentID =='LD' && this.isContinuePayment === true)){
  if (this.payment_mode == 'CASH') {
    this.saveCashModePayment();
  } 

  if (this.payment_mode == 'UPI') { 
    this.getCheckSomeData();
  }

  if (this.payment_mode == 'PaytmUPI') { 
    this.getPaytmCheckSomeData();
  }
}else if((this.departmentID =='LD' && this.isContinuePayment === false)){
  //this.commonService.commonErrorPopup("Alert",'Registration Number is not available,Please try again' , "md");
  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    modalRef.componentInstance.title = 'Alert';
                    modalRef.componentInstance.message = 'Registration Number is not available,Please try again !!!';
                    modalRef.result.then((result: any) => {
                      if (result) {
                            this.router.navigate(['/labour-registration/registration']);
                      }
                    });
}
   
  }

  getCheckSomeData() {

    // this.getRequisitionData(this.reqsuisionId,this.departmentID);
 
    let pipeLineStr =   this.merchantID+'|' +this.transactionID +'|NA|' +this.amountData?.totalAmount + '|NA|NA|NA|INR|NA|R|'+this.merchantIDsamllcase+'|NA|NA|F|UPI|' + this.amountData?.totalAmount + '|' + this.departmentID +'|'+this.requestType+'|NA|'  + this.aadharNo + '|' + this.applicationType + '|' +this.paymentRedirectionURL;
   //  let pipeLineStr =   this.merchantID+'|' +this.transactionID +'|NA|' + this.amountData?.totalAmount + '|'  + this.aadharNo + '|' + this.applicationType + '|NA|INR|NA|R|'+this.merchantIDsamllcase+'|NA|NA|F|UPI|' + this.amountData?.totalAmount + '|' + this.departmentID +'|'+this.requestType+'|NA|NA|NA|' +this.paymentRedirectionURL;
    let postData = {
      requisitionId: this.reqsuisionId,
      transactionId: this.transactionID,
      requisitionType: this.requisitionType,
      transactionAmount: this.amountData?.totalAmount,
      transactionMode: 'UPI',
      departmentId: this.departmentID,
      piplineString: pipeLineStr,
      userId: this.userInfo.userPrincipalName,
      serviceName:  this.serviceName === '' ? this.requisitionType : this.serviceName, //this.serviceName,
      customerName: this.fullName,
      challanId: '',

      userCharges: this.amountData?.userCharges,
      serviceCharges: this.amountData?.serviceCharges,
      statutaryCharges: this.amountData?.statutaryCharges,
      deliveryCharges: this.amountData?.deliveryCharges,
      noOfCopy: this.amountData?.noOfCopy,
      paymentGateway: "billdesk"
    };

    const aesKey = this.commonService.getRandomString(32);
    const aesVector = this.commonService.getRandomString(16);
    let encodedData: any = {
      key: this.commonService.RSAEncrypt(aesKey),
      vector: this.commonService.RSAEncrypt(aesVector),
      value: this.commonService.AESEncrypt(JSON.stringify(postData), aesKey, aesVector)
    }
    this.commonService
      .postRequest(this.commonConstants.getCheckSum, encodedData)
      .subscribe({
        next: (responseData: any) => {
        if(responseData?.result?.isSuccess == true){
          this.isActive = true;
          //console.log(responseData, 'check sum respose data');
          let buildDeskURL =  this.buildUPIUrl + responseData.result.result;
          console.log(buildDeskURL, '>> buildDeskURL');
          window.location.href = buildDeskURL;          
        }else{
          this.isActive = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData.result.error;
        }
      },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  saveCashModePayment() {

    let samplePaymentResponse =
      this.transactionID +
      '|' +
      this.reqsuisionId +
      '|' +
      this.amountData?.totalAmount +
      '|cash|' +
      this.amountData?.totalAmount +
      '|success|' +
      this.departmentID;

    //this.getRequisitionData(this.reqsuisionId,this.departmentID);
   
    /* let InputStr = {
      Userid: this.userInfo.userPrincipalName,
      RequisitionId : this.reqsuisionId,
      Department: this.departmentID,
      RequisitionType: this.requisitionType,
      TransactionId: this.transactionID ,
      TransactionAmount : this.amountData?.totalAmount,
      TransactionStatus : "0300"
    } 
    this.updateTransactionStatusInRequisitionDetails(InputStr); 
    */
    
    /* save buildesk response */
    let secreteriateCode = localStorage.getItem('locationCode');
    let isCitizen = (this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') ? true : false;
    this.postData = {
      requisitionId:this.reqsuisionId,
      departmentId: this.departmentID,
      transactionMode: 'cash',
      transactionId: this.transactionID,
      transactionAmount: this.amountData?.totalAmount,
      transactionStatus: '0300',
      piplineString: samplePaymentResponse,
      requisitionType: this.requisitionType,
      userId: this.userInfo.userPrincipalName,
      secretariat_Code: (isCitizen ===  false ?   secreteriateCode : ''),
      serviceName:
        this.serviceName === '' ? this.requisitionType : this.serviceName,
      customerName: this.fullName,
     
      userCharges: this.amountData?.userCharges,
      serviceCharges: this.amountData?.serviceCharges,
      statutaryCharges: this.amountData?.statutaryCharges,
      deliveryCharges: this.amountData?.deliveryCharges,
      noOfCopy: this.amountData?.noOfCopy,
      //paymentGateway: "icici"
    };

    console.log(this.postData, 'cash mode data');
    this.commonService
      .postRequest(this.commonConstants.saveBillDeskResponse, this.postData)
      .subscribe({
        next: (responseData: any) => {

          if(responseData.result == "RequisitionID is Null"){

            this.isActive = false;
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Requistion id data is null !!!';
          }else{
              //console.log(responseData, 'cash mode saveBillDeskResponse respose data');
              this.showPaymentResponse(this.reqsuisionId);
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  updateTransactionStatusInRequisitionDetails(inputParam:any){
    this.commonService
    .postRequest( this.commonConstants.updateTransactionStatusInRequisitionDetails , inputParam )
    .subscribe({
      next: (responseData: any) => {
         console.log(responseData, 'responseData');
         const queryParams: Params = {
          msg: 'zero-payment',
          applicationId:inputParam.RequisitionId
        };
        this.router.navigate(['/payment-response'], {
          relativeTo: this.currentRoute,
          queryParams: queryParams,
          queryParamsHandling: '',
        });
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  showPaymentResponse(req_id: any) {
    let queryParams: Params = {};

    if (this.departmentID === 'MAUD') {
      queryParams = {
        id: req_id,
        department: this.departmentID,
        type: this.requestType,
      };
    } else {
      queryParams = { id: req_id, department: this.departmentID, issearchflag: (this.routedData) ? (this.routedData.issearchflag) : false };
    }
    if(this.requestType == "RegistrationofTokens" && this.userInfo.jobTitle != 'Digital Assistant' && this.userInfo.jobTitle != 'Ward Education Secretary' ) {
      this.router.navigate(['/acknowledgement-receipt'], {
        relativeTo: this.currentRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    } else {
      this.router.navigate(['/payment-receipt'], {
        relativeTo: this.currentRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    }
    
  }

  getRequisitionData(reqId: any, departmentID: any) {
    this.commonService
      .getRequest(
        this.commonConstants.getCertificateDetails +
          departmentID +
          '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId=' +
          reqId
      )
      .subscribe({
        next: (responseData: any) => {
          this.basicInfo = responseData.result?.requisitionHeader;
          
          if(this.departmentID === 'LD'){
            this.registrationNumber = responseData.result?.requisitionData?.registrationNumber;
          
            if(this.registrationNumber !== null && this.registrationNumber?.trim() !== '' && this.registrationNumber !== undefined ){
              this.isContinuePayment = true;
            }else{
              this.isContinuePayment = false;
            }
          }
          

          this.requestType = this.basicInfo?.requisitionType;
          this.fullName =
            this.basicInfo?.applicantInfo?.personNames[0]?.firstName;

          console.log(this.fullName, 'get fullName');
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  getRequisitionMAUDData(reqId: any, reqType: any) {
    this.commonService
      .getRequest(
        this.commonConstants.getMaudRequisitionById +
          'RequisitionId=' +
          reqId +
          '&RequisitionType=' +
          reqType,
        'MAUD'
      )
      .subscribe({
        next: (responseData: any) => {
          this.basicInfo = responseData.result?.requisition?.requisitionHeader;

          this.requestType = this.basicInfo?.requisitionType;

          //console.log(this.requestType, ' responseData');

          this.fullName =
            this.basicInfo?.applicantInfo?.personNames[0]?.firstName;

          console.log(this.fullName, 'get fullName');
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  getServiceListData(serviceName: string) {
    this.serviceList = [];
    this.commonService
      .getRequest(this.commonConstants.getServiceList)
      .subscribe({
        next: (responseData: any) => {
          this.serviceList = responseData.result;
          this.serviceList.filter((data: any) => {
            if (data.serviceCode === serviceName) {
              this.serviceName = data.serviceName;
            }
          });
          console.log(this.serviceName, 'get service name');
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }


  /* ------------------paytm pay-------------------- */

  appendHandler(config:any) {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler
    }

    return newConfig;
  }

  notifyMerchantHandler = (eventType:any, data:any): void => {
    console.log('MERCHANT NOTIFY LOG', eventType, data);
    if(eventType == "APP_CLOSED"){
      let InputStr = {
        Userid: this.userInfo.userPrincipalName,
        RequisitionId : this.reqsuisionId,
        Department: this.departmentID,
        RequisitionType: this.requisitionType,
        TransactionId: null ,
        TransactionAmount : this.amountData?.totalAmount,
        TransactionStatus : 'aborted'
      }      
      this.updateTransactionStatusInRequisitionDetails(InputStr);
    }
  }

  updateConfig(config:any): void {
    try {
      this.config = JSON.parse(config.target.value);
    } catch (ex) {
      this.config = {};
    }
  }

  toggleCheckout(): void {
    this.isCheckoutVisible = !this.isCheckoutVisible;
  }

  initializeCheckout(): void {
    console.log("this.openInPopup",this.openInPopup)
    console.log("this.isCheckoutVisible",this.isCheckoutVisible)
    const config = this.appendHandler(this.config);
    this.checkoutService.init(config, { env: this.isProd ? 'PROD' :'STAGE', openInPopup: this.openInPopup});
    
  }


  getPaytmCheckSomeData() {
    
    this.config = {};
    this.postData = {
      userId: this.userInfo.userPrincipalName,
      customerName: this.fullName,
      requisitionId:this.reqsuisionId,
      requisitionType: this.requisitionType,
      serviceName: this.serviceName === '' ? this.requisitionType : this.serviceName,
      departmentId: this.departmentID,
      transactionId: this.transactionID,
     
      transactionAmount: this.amountData?.totalAmount,
      transactionMode:"UPI",
      challanId:"",
      
      userCharges: this.amountData?.userCharges,
      serviceCharges: this.amountData?.serviceCharges,
      statutaryCharges: this.amountData?.statutaryCharges,
      deliveryCharges: this.amountData?.deliveryCharges,
      noOfCopy: this.amountData?.noOfCopy,

      paymentGateway:"paytm"
      };
    
      console.log(this.postData, '===> getPaytmCheckSum checkum post data');

    this.commonService
      .postRequest(this.commonConstants.getPaytmCheckSum, this.postData)
      .subscribe({
        next: (responseData: any) => {
          
          this.isActive = true;
          console.log(responseData, 'check sum respose data');
         
        
          if( responseData.result.isSuccess === true){
              this.config = {
                style: {
                    bodyBackgroundColor: "#fafafb",
                    bodyColor: "",
                    themeBackgroundColor: "#dfa231",
                    themeColor: "#ffffff",
                    headerBackgroundColor: "#284055",
                    headerColor: "#ffffff",
                    errorColor: "",
                    successColor: "",
                    card: {
                        padding: "",
                        backgroundColor: ""
                    }
                },
                jsFile: "",
                data: {
                    orderId: responseData.result.transactionId,
                    amount: this.amountData?.totalAmount,
                    //token: "e334366c509b4294a285a3b42a5659ea1584106015734",
                    token: responseData.result.token,
                    tokenType: "TXN_TOKEN",
                    userDetail: {
                        mobileNumber: this.basicInfo?.applicantInfo?.mobileNumber,
                        name: this.fullName
                    }
                },
                merchant: {
                  // mid: "wHNUTH68224456779429",
                    mid: this.isProd ? 'Andhra66557540944393' :"APSTAT88271885509383",
                    name: "Andhra Pradesh GSWS",
                    logo: "",
                    redirect: true
                },
                mapClientMessage: {},
                labels: {},
                payMode: {
                    labels: {},
                    filter: {
                        exclude: []
                    },
                    order: [
                        "NB",
                        "CARD",
                        "LOGIN"
                    ]
                },
                flow: "DEFAULT"
              };

              console.log(this.config, 'this.config');
              //call paytm methods
              this.initializeCheckout()
        }
         
      },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  

  /* ------------------paytm pay-------------------- */
}
