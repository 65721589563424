import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  NgbNavConfig,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbNavChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CommonService,
  AadharValidator,
  WhitespaceValidator,
} from 'src/shared/common.service';
import { DatePipe } from '@angular/common';
import { CommonConstants } from 'src/constants/common-constants.component';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  fileExtensionValidator,
  fileSizeValidator,fileSizeValidatorForAarogyaSri
} from 'src/shared/validators/file-validator';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { AlertPromise } from 'selenium-webdriver';
import { isNgTemplate } from '@angular/compiler';
import { filter } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js'
import { ThrottlingUtils } from '@azure/msal-common';
import { FileService } from 'src/shared/file.service';

@Component({
  selector: 'app-update-aarogya-sri',
  templateUrl: './update-aarogya-sri.component.html',
  styleUrls: ['./update-aarogya-sri.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class UpdateAarogyaSriComponent implements OnInit {
  //fileList: { fileName: string; DocumentType: string; BlobRef: string }[] = [];
  // fileListforImage:{
  //   FileName: string; DocumentType: string; BlobRef: string 
  // }[]=[];
  fileList:any[]=[];
  fileListOriginal:any[]=[];
  isNotAlive:any[]=[];
  public submitted: Boolean = false;
  public familySubmitted :Boolean= false;
  public childSubmitted :Boolean=false;
  public getDetailsFlag: Boolean = false;
  public commonFormSubmitted: Boolean = false;
  public isNotInHouseHoldData:Boolean=false;
  commonConstants: any = CommonConstants;
  aarogyaSri: FormGroup;
  Member:FormGroup;
  Child:FormGroup;
  genders: any[] = [];
  relations: any[] = [];
  memberRelations:any[]=[];
  memberFemaleRelations:any[]=[];
  memberMaleRelations:any[]=[];
  reasons:any[]=[];
  maxDate: any;
  minDate: any;
  currentDate = new Date();
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[] = [];
  duplicatesRecords: Boolean = false;
  duplicatesRecordsinMember: any;
  duplicateRecordsinChild:any;
  tempData: any[] = [];
  membertempData:any[]=[];
  FourWheeler : any[]= [];
  decrypted:any=[];
  public showDetails: Boolean = false;
  sumAnnualIncome: Number = 0;
  sumDryLand: Number = 0;
  sumWetLand: Number = 0;
  sumPropertyTax: Number = 0;
  sumFourWheeler: Number = 0;
  isClicked: number = 0;
  validExt = '';
  alertCount = 0;
  relationValidation : Boolean = true;
  selfAgeValidation : Boolean = true;
  incomeValidation: Boolean = true;
  wetlandValidation: Boolean = true;
  drylandValidation: Boolean = true;
  propertyValidation: Boolean = true;
  govtEmpOrPensionerValidation =0;
  selfValidation=0;
  fourWheelerValidation: Boolean = true;
  releationSelf : Boolean = false;
  isinHousehold :boolean =false;
  isChildAgeaboveFive:boolean=false;
  isinCard:boolean =true;
  isAlreadyInCard:any[] =[];
  isExistMember :any[]=[];
  memberincome:number = 0;
 // showDeleteBtn:Boolean=false;
  showAge: number = 0;
  showAgeforlegacy:number =0;
  basicDetailsAadharNo: any = null;
  volunteerName :any;
  loggedInSecretariat: any;
  postData: any = {};
  days:any;
  public reqHeader: any = {};
  public familyDetailsTableData: any[] = [];
  sixStepResponseData:any[] = [];
  sixStepResponse:any[]=[];
  healthCardFamilyData :any[] =[];
  tableData:any[]=[];
  sixStepResponseTocheck:any []=[];
  mobileNo: any;
  imgSrc: any;
  showImg:Boolean=false;
  typeofService:any;  
  departmentCode : string ="";
  certificateName: string="";
  certificateTypeName:string="";
  uhid: any;
  dataFlag:any;
  lastModified:any;
  TotalLand:any=0;
  TotalWetLand:any=0;
  TotalDryLand:any= 0;
  sumProperty:any=0;
  totalIncome:any=0;
  isDeathPerson :Boolean =  false;
  gridData: {
    memberName: string;
    gender: string;
    teluguName : string;
    dateOfBirth: any;
    familyAadharNo: string;
    relationwithHead: string;
    age: any;
    mobileNumber:any;
    isGovernment: string;
    isPayingIncomeTax: string;
    annualIncome: string;
    landStatus: string;
    wetLand: any;
    dryLand: any;
    isPayingPropertyTax: string;
    totalNoOfSqft: any;
    isFourWheeler: string;
    noOfFourWheelers: string;    
    fourWheelerNumbers:any;
    MigrationReason:string;
    IsActive:boolean;
    residentId:any;
    iS_LIVING_WITHFAMILY:any;
    salary:any;
   empCategory:any;
  }[] = [];
  sixstepforLegacy:any[]=[];
  LegacyDataresult:any[]=[];
  aadharvalues:any[]=[];
  aadharvaluesForChild:any[]=[];
  femaleRelations:any[]=[];
  maleRelations:any[]=[];
  isPhoto:Boolean=false;
gridDataforAdding:{
  memberName: string;
  gender: string;
  teluguName : string;
  dateOfBirth: any;
  familyAadharNo: string;
  relationwithHead: string;
  age: any;
  mobileNumber:any;
  isGovernment: string;
  isPayingIncomeTax: string;
  annualIncome: string;
  landStatus: string;
  wetLand: any;
  dryLand: any;
  isPayingPropertyTax: string;
  totalNoOfSqft: any;
  isFourWheeler: string;
  noOfFourWheelers: string;  
  fourWheelerNumbers:any
  MigrationReason:string;
  IsActive:boolean;
  residentId:any;
  iS_LIVING_WITHFAMILY:any;
  salary:any;
  empCategory:any;
}[]=[];
  @ViewChild('familyPhotoLabel')
  familyPhotoLabel!: ElementRef;
  @ViewChild('aadharCardLabel')
  aadharCardLabel!: ElementRef;
  @ViewChild('nav')
  nav: any;
  reqId: string = '';
  //date1 = ("1973-01-01T12:00:00Z").split('T')[0];
  vehicleData :any;
  constructor(
    private config: NgbNavConfig,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private searchFormBuilder: FormBuilder,
    private datePipe: DatePipe,
    private fileService:FileService
  ) {
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };

    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      let userId = userData.userPrincipalName?.split('-');
      if (userId?.length > 0) {
        this.loggedInSecretariat =
          userId[0]?.charAt(0)?.toUpperCase() === 'U'
            ? userId[0]?.split('U')[1]
            : userId[0];
      }
    }

    this.aarogyaSri = this.formBuilder.group({

     commonForm: [],
     // appDate: ['', [Validators.required]],
     aarogya_sri_number: ["", [Validators.required,Validators.minLength(11)]],
      district: [null, [Validators.required]],
      mandal: [null, [Validators.required]],
      villageWard: [null, [Validators.required]],
      doorNo: [
        '',
        [
          Validators.required
        ],
      ],
      locality: [''],
      pinCode: [
        '',
        [
          Validators.required,
          Validators.pattern(this.commonConstants.pincodePattern),
        ],
      ],
      totalIncome: [''],
      totalWetLand: [''],
      totalDryLand: [''],
      totalPropertyTax: [''],
      familyForm: ['', [Validators.required]],
      familyPhoto: ['',[Validators.required]],
      aadharCard: [''],
      familyDetailsTableData: this.formBuilder.array([])
      
    });
    this.Member = this.formBuilder.group({      
      AddingMembersData:this.formBuilder.array([ this.AddingMembersData()])
    });
    this.Child = this.formBuilder.group({
      AddingMembersDataBelowFive:this.formBuilder.array([this.AddingMembersDataBelowFive()])
    })
    this.getGender();
    this.getRelation();
    this.getDistricts();
    this.getReasons()
    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {}


  getAarogysriData():any{

    let familyLength: number = Number(this.familyDetailsTableDatas.length);
    //alert(familyLength)
    this.isClicked++;
    //alert(this.isClicked)
    if(this.isClicked >1){
      for (let i = 0; i < familyLength; i++){
        this.familyDetailsTableDatas.removeAt(i-familyLength);
      }
    }
    this.getDetailsFlag = true;
    if (this.f['aarogya_sri_number'].value == "") { 
      return false;
    } else {

      //if (this.f['aarogya_sri_number'].value && )

      this.showDetails = true;
    //  this.getById(this.f['aarogya_sri_number'].value);
    this.getById(this.f['aarogya_sri_number'].value);
    }

  }

  // getDataBasedOnUHID(UHID:any){
    // let postData= {
    //   UHID: UHID
    // }
  //   this.commonService.postRequest(this.commonConstants.getDataBasedOnUHID, postData, true).subscribe({
  //     next: (responseData: any) => { 
        
  //       //console.log(responseData.result,"Total response Result for update aarogyasri") 
  //     //  console.log(responseData?.result?.requisitionHeader?.applicantInfo?.mobileNumber,"Update aarogyasri applicant phone number") 
  //       let reqData = responseData?.result?.requisitionData
  //       console.log(reqData)
  //     },
  //   });



  // }
  getById(id: any) {
    let postData= {
      UHID: id
    }
    this.commonService.postRequest(this.commonConstants.getDataBasedOnUHID, postData, true).subscribe({ next: (responseData: any) => {       
      
        if(responseData?.result?.isSuccess===true){
          let flag=responseData?.result?.dataFlag;
          let familyData:any=[];
          let legacyData:any=[];
          let legacyaadhar:any=[];
          let aadhar:any=[];
          this.Member.reset();
          this.Member.clearValidators();
          this.Member.updateValueAndValidity();
          this.Child.reset();
          this.Child.clearValidators();
          this.Child.updateValueAndValidity();
          if(flag=== 'R'){
             familyData=responseData?.result?.requisition?.requisitionData.familyDetails;
             aadhar = familyData.filter((item:any) => (this.commonService.RSADecrypt(item.familyAadharNo) == this.f['commonForm'].value['aadharNo']))
          }else{
             legacyData=responseData?.result?.legacyData;
              legacyaadhar=legacyData.filter((item:any) => (this.commonService.RSADecrypt(item.aadhar_No) == this.f['commonForm'].value['aadharNo']))
                    }        
          
      if(aadhar.length>0 || legacyaadhar.length >0){
      
      this.showDetails = true;
      // applications raised from GSWS
      if(flag=== 'R'){
        let reqData = responseData?.result?.requisition?.requisitionData;      
       
        this.reqHeader = responseData?.result?.requisition?.requisitionHeader;
       

        this.typeofService=this.reqHeader.requisitionType;
        this.reqHeader.requisitionType='UpdateAarogyaSriCard';
        //console.log(reqData?.familyDetails,"district") 
        let req = responseData?.result?.requisition;        
       this.sixStepResponseData = [] ;
        this.healthCardFamilyData = [];
        this.tableData=[];
       // this.getVehicleDetails();
       // this.sixStepResponseData = reqData?.familyDetails;
       let reqFamilyData  : any=[];   
        reqFamilyData = reqData?.familyDetails;
        this.healthCardFamilyData= reqFamilyData.filter((item:any) =>  (item.migrationReason != 'Permanent Migration') && (item.migrationReason != 'Not a member of the Family')   && (item.migrationReason != 'Migration due to Marriage') );
        reqFamilyData.filter((item:any) => {
          if(item.migrationReason?.toUpperCase() == 'DEATH'){
           this.isDeathPerson = true;
          }
         })
        this.uhid = reqData?.uhid;
        let reqId =req?.id;
        let prefix =reqId?.substring(0,3);
        let modifiedTime:any;
        if(prefix === 'UHC'){
         modifiedTime =req.lastModified;   
        }  
    this.lastModified = this.commonService.dateFormat(modifiedTime);
    let date = new Date(modifiedTime)
    let currentDate = new Date();
    this.days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
       // this.tableData=reqData?.familyDetails;
        this.mobileNo = this.reqHeader?.applicantInfo?.mobileNumber;  
        // this.f.totalIncome.patchValue(reqData?.totalFamilyIncome)
        // this.f.totalDryLand.patchValue(reqData?.totalFamilyDryland)
        // this.f.totalWetLand.patchValue(reqData?.totalFamilyWetland)
        // this.f.totalPropertyTax.patchValue(reqData?.totalPropertyTax)        
      let fileList=[];
      this.fileList=[];
      this.fileListOriginal=[];
       fileList = responseData?.result?.requisition?.fileList
        console.log(JSON.stringify(fileList) + 'fileList')
        let photo;
        let filteredFile:any;
        if(fileList.length > 0 && fileList != null){
           photo =fileList.filter((item:any) => (item.documentType === "Family Photo"))
           filteredFile =fileList.filter((item : any) => 
          item.documentType === "Aadhaar Card");
  
        }
        
          if(fileList.length >0 && fileList != null){
           for(let i = 0 ; i< fileList.length; i++){
            this.fileList.push({"fileName":fileList[i].fileName,
                               "documentType":fileList[i].documentType,
                               "blobRef":fileList[i].blobRef});
            this.fileListOriginal.push({"fileName":fileList[i].fileName,
            "documentType":fileList[i].documentType,
            "blobRef":fileList[i].blobRef});
           }
           // this.fileList=responseData?.result?.requisition?.fileList;
            if(photo.length > 0){
           //   console.log(this.f.familyPhoto?.value + 'family photo')
             this.f['aadharCard'].patchValue('');
             this.f['familyPhoto'].patchValue('');
          this.aarogyaSri.controls['aadharCard'].clearValidators();
           this.aarogyaSri.controls['aadharCard'].updateValueAndValidity();
           this.aarogyaSri.controls['familyPhoto'].clearValidators();
           this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
              this.viewFile(photo[0]?.blobRef ,photo[0]?.fileName)
             let FileName =photo[0]?.fileName
              this.familyPhotoLabel.nativeElement.innerText = FileName  ? FileName  : 'No File Choosen';              
                this.isPhoto  = FileName ? true :false;
            }else{
              this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
            }
            if(filteredFile.length>0){
              this.aadharCardLabel.nativeElement.innerText =filteredFile[0]?.fileName ? filteredFile[0]?.fileName :'No File Choosen';
            }else{
              this.aadharCardLabel.nativeElement.innerText ='No File Choosen';
            }
          
       
        }
        if(this.healthCardFamilyData.length > 0)            
      {
        this.getCitizenInfo();
        if(this.sixStepResponse.length>0){
   for(let i=0; i < this.healthCardFamilyData.length ;i++){
    let houseHoldData=[];
    let self 
    houseHoldData = this.sixStepResponse.filter((c:any) => c.CITIZEN_ID === this.healthCardFamilyData[i].familyAadharNo)
   
   
  
    if(houseHoldData.length === 0) {
      this.sixStepResponseData.push({
         age:this.healthCardFamilyData[i].age ? this.healthCardFamilyData[i].age :'',
         annualIncome : this.healthCardFamilyData[i].annualIncome ? this.healthCardFamilyData[i].annualIncome: '',
         dateOfBirth: this.healthCardFamilyData[i].dateOfBirth ? this.healthCardFamilyData[i].dateOfBirth : null,     
         dryLand:this.healthCardFamilyData[i].dryLand ? this.healthCardFamilyData[i].dryLand  : "0",
         familyAadharNo:this.healthCardFamilyData[i].familyAadharNo ? this.healthCardFamilyData[i].familyAadharNo :'', // if aadhar is present in six step return otherwise return from old data
         fourWheelerNumbers:this.healthCardFamilyData[i].fourWheelerNumbers ? this.healthCardFamilyData[i].fourWheelerNumbers :'0' ,
         gender:this.healthCardFamilyData[i].gender ? this.healthCardFamilyData[i].gender : '',
         isActive:false,
         isFourWheeler:this.healthCardFamilyData[i].isFourWheeler ? this.healthCardFamilyData[i].isFourWheeler : 'NO',
         isGovernment:this.healthCardFamilyData[i].isGovernment ? this.healthCardFamilyData[i].isGovernment : 'NO' ,
         isPayingIncomeTax:this.healthCardFamilyData[i].isPayingIncomeTax ? this.healthCardFamilyData[i].isPayingIncomeTax : 'NO',
         isPayingPropertyTax:this.healthCardFamilyData[i].isPayingPropertyTax ? this.healthCardFamilyData[i].isPayingPropertyTax: 'NO',
         landStatus: this.healthCardFamilyData[i].landStatus ? this.healthCardFamilyData[i].landStatus : 'NO',
         memberName:this.healthCardFamilyData[i].memberName ?  this.healthCardFamilyData[i].memberName : '',
         migrationReason:'',
         noOfFourWheelers:this.healthCardFamilyData[i].noOfFourWheelers ? this.healthCardFamilyData[i].noOfFourWheelers : 'NO',
         relationwithHead:'',
         requestsFor:'',
         residentId:this.healthCardFamilyData[i].residentId ?  this.healthCardFamilyData[i].residentId : '',
         iS_LIVING_WITHFAMILY:this.healthCardFamilyData[i].iS_LIVING_WITHFAMILY ? this.healthCardFamilyData[i].iS_LIVING_WITHFAMILY : '',
         teluguName: this.healthCardFamilyData[i].teluguName ? this.healthCardFamilyData[i].teluguName :'',
         totalNoOfSqft : this.healthCardFamilyData[i].totalNoOfSqft ? this.healthCardFamilyData[i].totalNoOfSqft : "0",
         wetLand:this.healthCardFamilyData[i].wetLand ? this.healthCardFamilyData[i].wetLand : "0",
         mobileNumber:this.healthCardFamilyData[i].mobileNumber ? this.healthCardFamilyData[i].mobileNumber : '' ,
         salary:this.healthCardFamilyData[i].salary ?  this.healthCardFamilyData[i].salary : '',
         empCategory:this.healthCardFamilyData[i].empCategory ?  this.healthCardFamilyData[i].empCategory :''
      })
  } // get data requisitionData
   else{
    this.sixStepResponseData.push({
      age:houseHoldData[0].DOB_DT !== null && houseHoldData[0].DOB_DT !== ''&&  houseHoldData[0].DOB_DT != "Invalid date" ? this.ageCalculatorforLegacy(houseHoldData[0].DOB_DT) :'',
      annualIncome : '',
      dateOfBirth:houseHoldData[0].DOB_DT !== null && houseHoldData[0].DOB_DT !== ''&&  houseHoldData[0].DOB_DT != "Invalid date" ?  houseHoldData[0].DOB_DT : null,     
      dryLand:houseHoldData[0].DRY_LAND ? houseHoldData[0].DRY_LAND : "0",
      familyAadharNo:houseHoldData[0].CITIZEN_ID, // if aashar is present in six step return otherwise return from old data
      fourWheelerNumbers:'',
      gender:houseHoldData[0].GENDER,
      isActive:true, // need to send true or false
      isFourWheeler:houseHoldData[0].FOUR_WHEELER,
      isGovernment:houseHoldData[0].EMP_STATUS,
      isPayingIncomeTax:houseHoldData[0].INCOME_TAX,
      isPayingPropertyTax:houseHoldData[0].CDMA !== '0' ? "YES" : 'NO',
      landStatus: (Number(houseHoldData[0].DRY_LAND) + Number(houseHoldData[0].WET_LAND)) !== 0 ? 'YES' : 'NO',
      memberName:houseHoldData[0].CITIZEN_NAME,
      migrationReason:'',
      noOfFourWheelers:houseHoldData[0].FourWheelerNumber,
      relationwithHead:this.f['commonForm'].value['aadharNo'] == houseHoldData[0].CITIZEN_ID ? 'SELF' : '', // do we need to get relations or make it as empty like legacy data
      requestsFor:'',
      residentId:houseHoldData[0].RESIDENT_ID,
      iS_LIVING_WITHFAMILY:houseHoldData[0].IS_LIVING_WITHFAMILY,
      teluguName:this.convertToTelugu(houseHoldData[0].CITIZEN_NAME,i),
      totalNoOfSqft : houseHoldData[0].CDMA ? houseHoldData[0].CDMA : "0",
      wetLand:houseHoldData[0].WET_LAND ? houseHoldData[0].WET_LAND : "0",
      mobileNumber:houseHoldData[0].MOBILE_NUMBER,
      salary:houseHoldData[0].Salary,
      empCategory:houseHoldData[0].EMP_CATEGORY
      
    });
   }
   


  
}
          

        }
        console.log('Requisition Details from house hold' + JSON.stringify(this.sixStepResponseData))
        if(this.sixStepResponseTocheck.length >0){
          this.getDetails();  
        }
          
      }
      if(this.sixStepResponseData.length > 0){
        this.tableData = this.sixStepResponseData;
      }
 
      } 
      // legacy data , By using aadhar number from legacy data getting family details from sixstep API and using that data to bind in UI end .
      else if(flag === 'L'){
        
        this.dataFlag = flag;
        this.LegacyDataresult=[];
        this.sixStepResponseData = [] ;
        this.healthCardFamilyData = [];
        this.tableData=[];
        this.familyDetailsTableDatas.reset();
        this.familyDetailsTableDatas.clear();  
        this.fileList=[];
        this.imgSrc='';        
       this.f['aadharCard'].patchValue('');
       this.f['familyPhoto'].patchValue('');  
       this.f['familyForm'].reset();
       this.familyDetailsTableDatas.clear();  
       this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
       this.aadharCardLabel.nativeElement.innerText ='No File Choosen';
       this.LegacyDataresult=responseData?.result?.legacyData;
      //   let selfData=this.LegacyDataresult.filter((item:any) => (item.relationOriginal.toUpperCase() === 'SELF'));
      //  let aadhar = selfData[0].aadhar_No;
       this.uhid=this.f.aarogya_sri_number.value;
       
       let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
      
       
       
        // this.f.district.patchValue(this.f['commonForm'].value['preDistrict'].entityCode)
        // this.f.mandal.patchValue(this.f['commonForm'].value['preMandal'].entityCode)
        // this.f.villageWard.patchValue(this.f['commonForm'].value['preVillage'].entityCode)
        // this.f.doorNo.patchValue(this.f['commonForm'].value['preDoorNo'])
        // this.f.locality.patchValue( this.f['commonForm'].value['preStreet'])
        // this.f.pinCode.patchValue(this.f['commonForm'].value['prePinCode'])

       var sendPostData = {
        CITIZEN_ID: this.commonService.RSAEncrypt(this.f['commonForm'].value['aadharNo'], true),
      };
        this.commonService
        .postRequestForWebLand(
          this.commonConstants.newSixStepDetails,
          sendPostData,
          'citizenInfo'
        )        
        .subscribe({
          next: (responseDataforsixstep: any) => {
            console.log(responseDataforsixstep, 'CITIZEN_ID info for six step');
           // this.healthCardFamilyData = [];
          this.sixStepResponseData=[];
          let sixstepforlegacydata:any=[];
         this.sixstepforLegacy=[];
         this.sixStepResponseTocheck =responseDataforsixstep.result;
          sixstepforlegacydata=responseDataforsixstep.result;
        
            
      
        console.log(this.LegacyDataresult )
        sixstepforlegacydata.forEach((fo:any) => {
          this.LegacyDataresult.forEach(eo => {
            if(fo.CITIZEN_ID === eo.aadhar_No){
           
          
               this.sixstepforLegacy.push(fo)
            }      
        
        
        })
        })

        sixstepforlegacydata.forEach((fo:any) => {
          this.LegacyDataresult.forEach(eo => {
            if(fo.CITIZEN_ID !== eo.aadhar_No){
              // this.isNotInHouseHoldData=true;
              if(this.sixstepforLegacy.findIndex(item => item.CITIZEN_ID === eo.aadhar_No)  === -1){
                if(this.sixStepResponseTocheck.length < this.LegacyDataresult.length){
                  this.isNotInHouseHoldData=true;
                } else{
                  this.isNotInHouseHoldData=false; 
                }            
              this.sixstepforLegacy.push({
                CITIZEN_ID:eo.aadhar_No,
                RESIDENT_ID:eo.resident_Id,
                GENDER:eo.gender?.toUpperCase(),
                AGE:eo.age,
                CDMA: '0',
                CITIZEN_NAME: '',
              DISTRICT_CODE: '',
              DISTRICT_NAME: '',
              DOB_DT: '',
              DRY_LAND: '0',
              ELE_LAST_6MONTHS_UNITS:'',
              EMP_STATUS: '',
              FOUR_WHEELER: '',
              FourWheelerNumber:'',
              NoOfFourWheelers:'',
              GSWS_CODE:'',
              MANDAL_CODE: '',
              MANDAL_NAME: '',
              MOBILE_NUMBER: eo.mobile,              
              SECRETARIAT_NAME: '',
              WET_LAND: '0',
              IsActive:'',
              isSelf: '',
              iS_LIVING_WITHFAMILY:'',
              EMP_CATEGORY:'',
              Salary:'',
              })
            }
               
            }})
          })
  
       if(this.isNotInHouseHoldData === true){
        this.commonService.commonErrorPopup("Alert",'Some Family members are not there in house hold details,So please update the household then apply for Update-aarogya-sri' , "md")
       }
          console.log(JSON.stringify(this.sixstepforLegacy) + 'this.sixstepforLegac')

          this.sixstepforLegacy.forEach(
            (element: { 
              CDMA: any
              CITIZEN_ID: any
              CITIZEN_NAME: any
              DISTRICT_CODE: any
              DISTRICT_NAME: any
              AGE:any
              DOB_DT: any
              DRY_LAND: any
              ELE_LAST_6MONTHS_UNITS: any
              EMP_STATUS: any
              FOUR_WHEELER: any
              FourWheelerNumber:any
              NoOfFourWheelers:string
              GENDER: any
              GSWS_CODE: any
              HOUSEHOLD_ID: any
              INCOME_TAX: any
              MANDAL_CODE: any
              MANDAL_NAME: any
              MOBILE_NUMBER: any
              RESIDENT_ID: any
              SECRETARIAT_NAME: any
              WET_LAND: any
              IsActive:any;
              isSelf: any;
              iS_LIVING_WITHFAMILY:any;
              EMP_CATEGORY:any;
              Salary:any;
            } , index: any) => {
            
              let formGroup = this.formBuilder.group({
                tbName: element.CITIZEN_NAME,
                tbTeluguName : element.CITIZEN_NAME ?this.convertToTelugu(element.CITIZEN_NAME , index):'',
                tbGender :  element.GENDER,
                tbAadharNo : element.CITIZEN_ID !== null ? element.CITIZEN_ID : null,
                tbRelation :'',
              //  tbRelationarray:element.GENDER.toUpperCase() === 'MALE' ? [{"name" :"SON"}, {"name" : "GRANDFATHER(FATHERS SIDE)"}] : this.femaleRelations,
                tbDob : element.DOB_DT !==null && element.DOB_DT !== '' && element.DOB_DT !="Invalid date" ? element.DOB_DT:'',
                tbAge:  element.DOB_DT !== null && element.DOB_DT !== '' && element.DOB_DT !="Invalid date"? this.ageCalculatorforLegacy(element.DOB_DT) : '',
                tbMobile: element.MOBILE_NUMBER,
                tbMaskAadharNo: this.numberMasking(element.CITIZEN_ID),
                tbPayingIncome : element.INCOME_TAX,
                tbAnnualIncome : "",
                tbLandStatus :   (Number(element.DRY_LAND) + Number(element.WET_LAND)) != 0 ? 'YES' : 'NO',
                tbWetLandStatus : Number(element.WET_LAND) != 0 ? 'YES' : 'NO',
                tbDryLandStatus : Number(element.DRY_LAND) != 0 ? 'YES' : 'NO',
                tbWetLand : element.WET_LAND ? element.WET_LAND : "0",
                tbDryLand : element.DRY_LAND ? element.DRY_LAND : "0",
                tbPayingPropertyTax :element.CDMA != '0' || element.CDMA !== ''? "YES" : 'NO',
                tbPropertyTax : element.CDMA ? element.CDMA : "0",
                tbFourWheeler : element.FOUR_WHEELER,
                tbFourWheelerNo : element.NoOfFourWheelers ?  element.NoOfFourWheelers:'0',
                tbFourWheelerNumbers:element.FourWheelerNumber? element.FourWheelerNumber : 'NO',
                tbGovernmentPensioner : element.EMP_STATUS,
                tbSelf : element.isSelf,
                tbisActive:element.IsActive,
                residentId:element.RESIDENT_ID,
                iS_LIVING_WITHFAMILY:element.iS_LIVING_WITHFAMILY,
                tbGovernmentCategory:element.EMP_CATEGORY,
                tbSalary:element.Salary
              });
              this.familyDetailsTableDatas.push(formGroup)
            }
          ) 
      
          this.sumPropertyTax = 0;
          this.sumAnnualIncome =0;
          this.sumWetLand=0;
          this.sumDryLand=0;
          this.f['totalPropertyTax'].patchValue(0);
          this.f['totalIncome'].patchValue(0);
          this.f['totalWetLand'].patchValue(0);
          this.f['totalDryLand'].patchValue(0);

          for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
          {
            if(this.f['commonForm'].value['aadharNo'] === 
            this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.value){
              this.familyDetailsTableDatas.controls[i].get('tbRelation')?.patchValue('SELF');
              this.familyDetailsTableDatas.controls[i].get('tbSelf')?.patchValue(true);
              this.sixstepforLegacy[i].isSelf=true;
              //this.familyDetailsTableDatas.controls[i].get('tbRelation')?.disable();
              //this.releationSelf = true;
            }
      
            
            if(this.familyDetailsTableDatas.controls[i].get('tbPayingPropertyTax')?.value !='0'){
              this.sumPropertyTax = Number(this.sumPropertyTax) +  Number(this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.value);
              this.f['totalPropertyTax'].patchValue(this.sumPropertyTax)
              
            }
                 
      
            if(this.familyDetailsTableDatas.controls[i].get('tbPayingIncome')?.value != '0'){
              this.sumAnnualIncome = Number(this.sumAnnualIncome) +  Number(this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.value);
              this.f['totalIncome'].patchValue(this.sumAnnualIncome);
            }
      
            if(this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.value != '0'){
              this.sumDryLand = Number(this.sumDryLand) +  Number(this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.value);
              this.f['totalDryLand'].patchValue(this.sumDryLand);
            }
      
            if(this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.value != '0'){
              this.sumWetLand = Number(this.sumWetLand) +  Number(this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.value);
              this.f['totalWetLand'].patchValue(this.sumWetLand);
            }
      
            /*if(this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value !== 'NO'){
              this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.disable();
            }*/
      
           
          }
      
          for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
          {
            if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
               this.familyDetailsTableDatas.controls[i].get('tbAge')?.value <= '18'){
                this.selfAgeValidation = false;
                //console.log('I amm innn the inavlid Zone...')
              }
              else if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
              this.familyDetailsTableDatas.controls[i].get('tbAge')?.value > '18') {
                this.selfAgeValidation = true
              }
          }  
          
  //         this.f.totalIncome.patchValue(reqData?.totalFamilyIncome)
  //         this.f.totalDryLand.patchValue(reqData?.totalFamilyDryland)
  //         this.f.totalWetLand.patchValue(reqData?.totalFamilyWetland)
  //         this.f.totalPropertyTax.patchValue(reqData?.totalPropertyTax)
          
  
  if(this.sixstepforLegacy.length > 0)            
  {
  //  this.sixStepResponseData=[];
  for(let i = 0 ; i<this.sixstepforLegacy.length ; i++){
    this.sixStepResponseData.push({
      age: this.sixstepforLegacy[i].DOB_DT !== null && this.sixstepforLegacy[i].DOB_DT !== ''&&  this.sixstepforLegacy[i].DOB_DT != "Invalid date" ? this.ageCalculatorforLegacy(this.sixstepforLegacy[i].DOB_DT) :'' ,
      annualIncome : '',
      dateOfBirth:this.sixstepforLegacy[i].DOB_DT !== null && this.sixstepforLegacy[i].DOB_DT !== ''&&  this.sixstepforLegacy[i].DOB_DT != "Invalid date" ?  this.sixstepforLegacy[i].DOB_DT : null,     
      dryLand:this.sixstepforLegacy[i].DRY_LAND ? this.sixstepforLegacy[i].DRY_LAND : "0",
      familyAadharNo:this.sixstepforLegacy[i].CITIZEN_ID,
      fourWheelerNumbers:'',
      gender:this.sixstepforLegacy[i].GENDER,
      isActive:this.sixstepforLegacy[i].isActive,
      isFourWheeler:this.sixstepforLegacy[i].FOUR_WHEELER,
      isGovernment:this.sixstepforLegacy[i].EMP_STATUS,
      isPayingIncomeTax:this.sixstepforLegacy[i].INCOME_TAX,
      isPayingPropertyTax:this.sixstepforLegacy[i].CDMA !== '0' ? "YES" : 'NO',
      landStatus: (Number(this.sixstepforLegacy[i].DRY_LAND) + Number(this.sixstepforLegacy[i].WET_LAND)) !== 0 ? 'YES' : 'NO',
      memberName:this.sixstepforLegacy[i].CITIZEN_NAME,
      migrationReason:'',
      noOfFourWheelers:this.sixstepforLegacy[i].FourWheelerNumber,
      relationwithHead:this.sixstepforLegacy[i].isSelf == true ? 'SELF' : '',
      requestsFor:'',
      residentId:this.sixstepforLegacy[i].RESIDENT_ID,
      iS_LIVING_WITHFAMILY:this.sixstepforLegacy[i].IS_LIVING_WITHFAMILY,
      teluguName:this.convertToTelugu(this.sixstepforLegacy[i].CITIZEN_NAME,i),
      totalNoOfSqft : this.sixstepforLegacy[i].CDMA ? this.sixstepforLegacy[i].CDMA : "0",
      wetLand:this.sixstepforLegacy[i].WET_LAND ? this.sixstepforLegacy[i].WET_LAND : "0",
      mobileNumber:this.sixstepforLegacy[i].MOBILE_NUMBER,
      salary:this.sixstepforLegacy[i].Salary,
      empCategory:this.sixstepforLegacy[i].EMP_CATEGORY
    });
  }
  this.tableData= this.familyDetailsTableDatas.value;
//  this.sixStepResponseData=
//   this.sixStepResponseTocheck=this.sixStepResponseData;
//this.sixStepResponseData=
//console.log(JSON.stringify(this.sixStepResponseData) + 'this.sixStepResponseData')
  
      this.getDetails()  
      
  }
          }})

      }

    }else{
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'The aadhar number which you entered in Basic details does not match with this UHID'
      this.showDetails=false;
      return;
    }
    }else{
        this.fileList=[];
        this.showDetails=false;
      alert('Please enter valid UHID')
        this.AddingChild.clear();  
      this.AddingMembers.clear();
      //this.AddingMembers.reset();
      this.f.totalIncome.patchValue('');
      this.f.totalDryLand.patchValue('');
      this.f.totalWetLand.patchValue('');
      this.f.totalPropertyTax.patchValue('');
      return
      
    }
     },
      
  });


  }


  getCitizenInfo() {
    let postData: any = {};
    postData.aadhaarNumber =  this.commonService.RSAEncrypt(this.f['commonForm'].value['aadharNo'], true);
    //postData.aadhaarNumber = 314987395689;    
    this.commonService
      .postRequestForWebLand(
        this.commonConstants.getCitizenInfo,
        postData,
        'citizenInfo'
      )
      .subscribe({
        next: (responseData: any) => {
          if(responseData?.result &&responseData?.result.length !==0) {
            responseData.result.forEach((item: any) => {
              item.aadhaarNumber =item.aadhaarNumber;
            });
          }
          this.basicDetailsAadharNo = responseData?.result[0].aadhaarNumber;
      
          this.volunteerName = responseData?.result[0].volunteername;
          this.sixStepResponse = [];
          this.sixStepResponseTocheck=[];
          var sendPostData = {
            CITIZEN_ID:this.commonService.RSAEncrypt(this.f['commonForm'].value['aadharNo'], true),
          };
          this.commonService
            .postRequestForWebLand(
              this.commonConstants.newSixStepDetails,
              sendPostData,
              'citizenInfo'
            )
            .subscribe({
              next: (responseData: any) => {
               // console.log(responseData, 'CITIZEN_ID info for six step');
                this.sixStepResponse = responseData.result;
                this.sixStepResponseTocheck = responseData.result;

                let dob =this.f['commonForm'].value['dob'] 
                const convertAge = new Date(this.commonService.formatDate(dob));
                 const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                 let age  = Math.floor(timeDiff / (1000 * 3600 * 24) / 365); 
                if(this.sixStepResponse.length>0){ 
                  let reqData = responseData?.result[0];
                  console.log(reqData)                 
                    this.isNotAlive = this.sixStepResponse.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() == "DEATH"));
                    let isAlive = this.isNotAlive.filter(item => (item.CITIZEN_ID == this.f['commonForm'].value['aadharNo']))
                    if(isAlive.length === 0){
                      if(age > 18){
                        this.nav.select(2); 
                      }else{
                        
                        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                        mdRef.componentInstance.message = 'The Head of the family age must and should above 18 years!'
                     return
                      }
                  }else{
                    this.commonService.commonErrorPopup("Alert", 'This Applicant status is death in house hold details.Please apply with other member aadhar in the family', "md") 
                  }
                } 
                if(this.dataFlag === 'L'){
                  
                  

                }
                   this.getVehicleDetails(); 

          
              },
            });
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  get familyDetailsTableDatas() {
    return this.aarogyaSri.get('familyDetailsTableData') as FormArray;
  }

  get AddingMembers(){
    return this.Member.get('AddingMembersData') as FormArray;
  }
  
  get AddingChild(){
    return this.Child.get('AddingMembersDataBelowFive') as FormArray;
  }
  async onFamilyPhotoSelected(fileEvent: any) {
   
    
    const familyPhotoFile: File | null = fileEvent.target.files[0];
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    console.log(fileExtensionType);
    this.imgSrc = fileExtensionType=== 'pdf' ? this.imgSrc="" : this.imgSrc;
    if (fileExtensionType.toLowerCase() === 'jpg') {
      this.validExt = 'jpg';
    } else if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } else {
      this.validExt = 'png';
    }

   // code for resolution check
    const resolutionDimesion:any=  await this.fileService.readFile(fileEvent.target.files[0]);
    if(resolutionDimesion?.width>640){
     this.imgSrc=""
     this.f['familyPhoto'].patchValue('');
     this.isPhoto  = false;
     this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
     this.aarogyaSri.controls['familyPhoto'].setValidators([
       Validators.required
     ]);
     this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
     const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
     mdRef.componentInstance.message = 'Please upload image with 640 x 480 resolution or lower.'
     return;
    }


   
    this.aarogyaSri.controls['familyPhoto'].setValidators([
      fileExtensionValidator(this.validExt),
      fileSizeValidatorForAarogyaSri(fileEvent),
    ]);
    this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
    if (this.f.familyPhoto.valid){
      this.familyPhotoLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
      this.onFileChange(fileEvent);
      
      this.fileUpload(
        familyPhotoFile,
        this.f['familyPhoto'].value,
        fileEvent.target.files[0].name,
        'Family Photo',
        'familyPhoto', 
        'familyPhotoLabel'
      );
    }else if(!['jpg','jpeg','png'].includes(fileExtensionType)){
      this.imgSrc=""
      this.f['familyPhoto'].patchValue('');
      this.isPhoto  = false;
      this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSri.controls['familyPhoto'].setValidators([
        Validators.required
      ]);
      this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'Please upload image in PNG/JPG/JPEG Format'
     return;
    } else{
      this.imgSrc=""
      this.f['familyPhoto'].patchValue('');
      this.isPhoto  = false;
      this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSri.controls['familyPhoto'].setValidators([
        Validators.required
      ]);
      this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'File size exceeding 200KB.Please compress the image and reupload!'
      return;
    }  
      

      
  }

  onFileChange(event: any) {
    this.imgSrc='';
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imgSrc = e.target.result;
        this.showImg = true;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  viewFile(blobRef: any, fileName:any) {
    console.log('file Extension', this.checkFileExtension(fileName));
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        if (this.checkFileExtension(fileName) !== 'pdf') {
         
          this.imgSrc= 'data:image/png;base64,' +fileInfo;
          this.showImg = true;

        }
        
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
    
  }
  checkFileExtension(name: any) {
    let fileNameArr = name.split('.');
    return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  }


  onAadharCardSelected(fileEvent: any) {
    const aadharCardFile: File | null = fileEvent.target.files[0];
    this.aadharCardLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
    this.aarogyaSri.controls['aadharCard'].setValidators([
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
    this.aarogyaSri.controls['aadharCard'].updateValueAndValidity();
    if (this.f.aadharCard.valid){
      this.fileUpload(
        aadharCardFile,
        this.f['aadharCard'].value,
        fileEvent.target.files[0].name,
        'Aadhaar Card',
        'aadharCard',
        'aadharCardLabel'
      );
    }
     
  }

  fileUpload(
    file: any,
    filePath: string,
    fileName: string,
    documentType: string,
    name:string,
    label:string
  ) {
    // this.docUploaded = true;
    // console.log("doc uploaded" + this.docUploaded)
    //this.fileList=[];
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{}");
    formData.append('CertType', 'DHC')  
    console.log(formData, 'formData');
    this.commonService
      .postUploadedFileRequest(this.commonConstants.fileUpload, formData)
      .subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string'){
          let index = this.fileList
            .map((item) => item.documentType).indexOf(documentType);
            console.log(this.fileList + 'filelist')
            console.log(index)
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
          this.fileList.push({
            fileName: fileName,
            documentType: documentType,
            blobRef: responseData['result'].blobRef,
          });
        }else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
          console.log(this.fileList, 'FileList');
        },
        error: (error) => {
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          console.log('client side', error);
        },
      });
  }

  clearFileUpload(name: string, label: string) {
    this.f[name].reset();
    if (label === 'familyPhotoLabel') {
      this.showImg=false;
      this.submitted=false;
      this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSri.controls[name].setValidators([
        Validators.required 
      ]);
      this.aarogyaSri.controls[name].updateValueAndValidity();
    } if (label === 'aadharCardLabel') {
      this.aadharCardLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSri.controls[name].clearValidators();
      this.aarogyaSri.controls[name].updateValueAndValidity();
    }
  }

  /*checkSecretariat() {
    let householdSecretariat: any;
    let postData: any = {};
    postData.aadhaarNumber = this.f.commonForm.value['aadharNo'];
    this.commonService
      .postRequestForWebLand(
        this.commonConstants.getCitizenInfo,
        postData,
        'citizenInfo'
      )
      .subscribe({
        next: (responseData: any) => {
          if (responseData?.result && responseData?.result.length !== 0) {
            householdSecretariat = responseData?.result[0]?.seccode;
            console.log('householdSecretariat', householdSecretariat);
            console.log('this.loggedInSecretariat', this.loggedInSecretariat);
            console.log( 'present address',this.f.commonForm.value['preVillage'].entityCode            
         )
            //  this.nav.select(2);
            //       this.getEkycDetails();
            
            // if (
            //   this.loggedInSecretariat?.trim() ===
            //     householdSecretariat?.trim() &&
            //   householdSecretariat?.trim() ===
            //     this.f.commonForm.value['preVillage'].entityCode
            // ) {
            //   this.nav.select(2);
            //   if (this.basicDetailsAadharNo == null) {
            //     this.getCitizenInfo();
            //   }
            // } else {
            //   const modalRef = this.modalService.open(CommonMsgModalComponent, {
            //     size: 'md',
            //   });
            //   modalRef.componentInstance.title = 'Alert';
            //   modalRef.componentInstance.message =
            //     'The applicant present address/household secretariat is not belongs to this Secretariat.';
            // }
            this.nav.select(2);
            this.getCitizenInfo();
        
        
        
          } 
          
          
          else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, {
              size: 'md',
            });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message =
              'The applicant details are not available in Household data.';
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      }); 
  }*/

  checkSecretariat() {
    let householdSecretariat: any;
    let householdDob:any;
    let householdGender:any;
    let householdCaste:any;
    let householdName:any;
    
    let postData: any = {};
   
    
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.commonForm.value['aadharNo'], true);

    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => { 

      //  console.log(responseData?.result,'my checkSecretariat response')
        if(responseData?.result && responseData?.result.length !==0)
         { 
          responseData.result.forEach((item: any) => {
            item.aadhaarNumber = this.commonService.RSADecrypt(item.aadhaarNumber);
          });
          householdSecretariat = responseData?.result[0]?.seccode;
          householdDob=responseData?.result[0]?.birthDate;
          householdGender=responseData?.result[0]?.gender;
          householdName=responseData?.result[0]?.firstName;
          householdCaste=responseData?.result[0]?.caste;
          let reqData=responseData?.result[0];
          
          this.f.mandal.patchValue(reqData?.mandalMunicipality);
          this.f.district.patchValue(reqData?.district);
          this.f.villageWard.patchValue(reqData?.villageWard);
          this.f.doorNo.patchValue(reqData?.doorNo);
          this.f.locality.patchValue(reqData?.streetName);
          this.f.pinCode.patchValue(reqData?.pinCode);
          //houseHoldId = responseData?.result[0]?.id;
          //volunteername =responseData?.result[0]?.volunteername;
          //volunteermobile =responseData?.result[0]?.volunteermobile;
          let dateFormat =  this.commonService.dobFormatDate(this.f['commonForm'].value['dob']);

          //console.log('householdSecretariat', householdSecretariat);
          //console.log('this.loggedInSecretariat', this.loggedInSecretariat);
          //console.log('present address', this.f.commonForm.value['preVillage'].entityCode);
      //  this.nav.select(2);
      //       this.getEkycDetails();
      //this.nav.select(2);
    
      if(dateFormat === householdDob && this.f['commonForm'].value['gender'].toUpperCase() ===householdGender 
            && this.f['commonForm'].value['firstName']+""+this.f['commonForm'].value['middleName'] ===householdName 
            ){
             // this.nav.select(2);
           
              this.getCitizenInfo();
        
              //this.loadData();
            }

            else{
              const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = 'As per the scheme guidelines, you are not authorized to change the Name,Date of Birth and Gender, please intimate citizen to update the house hold details through eKYC';

            }
         
          /*if (this.loggedInSecretariat?.trim() === householdSecretariat?.trim() && householdSecretariat?.trim() === this.f.commonForm.value['preVillage'].entityCode) 
          {
            
            
          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'The applicant present address/household secretariat is not belongs to this Secretariat.';
          }*/
         }
        else
          {
            const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'The applicant details are not available in Household data.';
          }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  ageCalc(event:any){
  
  }
 
  ageCalculatorforLegacy(date: any) {
    this.showAgeforlegacy=0;
    const convertAge = new Date(this.commonService.formatDate(date));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    this.showAgeforlegacy = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    return this.showAgeforlegacy;
  }
  ageCalculatorforSixstep(date: any,i:any) {
   
    const convertAge = new Date(this.commonService.formatDate(date));
  //  console.log(convertAge + 'convertage')
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    this.sixStepResponseData[i].age=this.showAge
    // alert(this.showAge)
    // return this.showAge;
  }
  ageCalculator() {
    
    let tmpAge: any[] = [];
    
    for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      if(this.familyDetailsTableDatas.controls[i].get('tbDob')?.value !== null){
         tmpAge[i] = Number(new Date().getFullYear()) - Number(new Date(this.commonService.formatDate(
          this.familyDetailsTableDatas.controls[i].get('tbDob')?.value)).getFullYear());

        //alert(tmpAge[i]);
        this.familyDetailsTableDatas.controls[i].get('tbAge')?.patchValue(tmpAge[i]);
      }
      else {
       // alert("No");
      }
    }
    //return this.showAge;
  }


  dateChange(event : any , i : any)
  {
  //  console.log(event.target.value , "dateChange date change")
    const convertAge = new Date(this.commonService.formatDate(event.target.value));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  //  console.log("show age" , this.showAge)
    this.familyDetailsTableDatas.controls[i].get('tbAge')?.patchValue(this.showAge);

    if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
    this.showAge <= 18){
        this.selfAgeValidation = false;
        //console.log('I amm innn dateChangeToggle inavlid Zone...')
      }
      else if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
      this.showAge > 18) {
        this.selfAgeValidation = true
        //console.log('I amm innn dateChangeToggle valid Zone...')
      }

  }

  dateChangeToggle(event : any , i : any)
  {
   // console.log(event.year , "dateChangeToggle date change")
    this.showAge = Number(this.currentDate.getFullYear()) - Number(event.year)
   // console.log("show age" , this.showAge)
    this.familyDetailsTableDatas.controls[i].get('tbAge')?.patchValue(this.showAge);

    if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
    this.showAge <= 18){
        this.selfAgeValidation = false;
        //console.log('I amm innn dateChangeToggle inavlid Zone...')
      }
      else if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
      this.showAge > 18) {
        this.selfAgeValidation = true
        //console.log('I amm innn dateChangeToggle valid Zone...')
      }

  }
  FamilydateChange(event : any , i : any)
  {
    let showAge:any;
  //  console.log(event.target.value , "dateChange date change")
    const convertAge = new Date(this.commonService.formatDate(event.target.value));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  //  console.log("show age" , showAge)
    this.AddingMembers.controls[i].get('tbAge')?.patchValue(showAge);
    if(showAge <= 5){
      this.AddingMembers.controls[i].get('tbAge')?.patchValue('');
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'Age should more than 5 years';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
     return 
    }

  }

  FamilydateChangeToggle(event : any , i : any)
  {
    let showAge:any;
  //  console.log(event.year , "dateChangeToggle date change")
    showAge = Number(this.currentDate.getFullYear()) - Number(event.year)
  //  console.log("show age" , showAge)
    this.AddingMembers.controls[i].get('tbAge')?.patchValue(showAge);
    if(showAge <= 5){
      this.AddingMembers.controls[i].get('tbAge')?.patchValue('');
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'Age should more than 5 years';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
     return 
    }
  }
  ChilddateChange(event : any , i : any)
  {
    let showAge:any;
   // console.log(event.target.value , "dateChange date change")
    const convertAge = new Date(this.commonService.formatDate(event.target.value));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  //  console.log("show age" , showAge)
    this.AddingChild.controls[i].get('tbAge')?.patchValue(showAge);
    if(showAge > 5){
      this.AddingChild.controls[i].get('tbAge')?.patchValue('');
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'Age should be less than or equal to 5 years';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
     return 
    }
  }

  ChilddateChangeToggle(event : any , i : any)
  {
    let showAge:any;
  //  console.log(event.year , "dateChangeToggle date change")
    showAge = Number(this.currentDate.getFullYear()) - Number(event.year)
   // console.log("show age" , showAge)
    this.AddingChild.controls[i].get('tbAge')?.patchValue(showAge);
    if(showAge > 5){
      this.AddingChild.controls[i].get('tbAge')?.patchValue('');
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'Age should be less than or equal to 5 years';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
     return 
    }
  }

  relationValidator(i : any){

    var cnt : number = 0;
   
      
        if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF'){
          this.relationValidation = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = "Other members cannot be self!";
          modalRef.result.then((result: any) => {
           if (result) {
              console.log(result);
           }
      });
        }
        else{
          for(var j = 0; j<this.familyDetailsTableDatas.length ; j++){
            if (this.familyDetailsTableDatas.controls[j].get('tbRelation')?.value !== 'SELF'){
                cnt++;
                if (cnt === this.familyDetailsTableDatas.length-1){
                  this.relationValidation = true;
                  //console.log(cnt,'cnt');
                  //console.log(this.familyDetailsTableDatas.length,'cnttttt');
                }
            }
          }
         
        }


      
    
  }

  numberMasking(aadhaar:any) {
    var mask = "";
    if(aadhaar){
      for(let j=1; j<=aadhaar.length-4; j++){
        mask += "x";
      }
      return mask + aadhaar.slice(8,12);
    }
    else return null;
  }

  getDetails() {
    //this.familyDetailsTableDatas.reset();
    this.submitted = false;
    this.familyDetailsTableDatas.clear();  
    console.log(JSON.stringify(this.FourWheeler) + 'four wheeler in get byid') 
   for (let i = 0; i < this.sixStepResponseData.length; i++) {
    let fourwheelerCounter =0; 
    let vehicleNumb :any= '';
      for(let j = 0; j < this.FourWheeler.length; j++)
    if(this.sixStepResponseData[i].isFourWheeler === 'YES'){      
      if(this.sixStepResponseData[i].familyAadharNo === this.FourWheeler[j].UID_NUM){
        vehicleNumb= vehicleNumb !== '' ? (vehicleNumb + ',' + this.FourWheeler[j].VEHICLE_NUMBER) :  this.FourWheeler[j].VEHICLE_NUMBER
         fourwheelerCounter++;      
        console.log(fourwheelerCounter)
  
      }
      this.sixStepResponseData[i].fourWheelerNumbers =vehicleNumb;
      this.sixStepResponseData[i].noOfFourWheelers=fourwheelerCounter.toString();
    }
   
  }
  // this.sixStepResponseData.forEach((fo:any,index1) => {
  //   if(this.sixStepResponseTocheck.findIndex((el) =>el.CITIZEN_ID === fo.familyAadharNo) > -1){
   
  //     this.sixStepResponseData[index1].landstatus =( Number(this.sixStepResponseTocheck[index1].DRY_LAND)+Number(this.sixStepResponseTocheck[index1].WET_LAND)) !== 0 ? 'YES' : 'NO';
  //   }else{
  //     this.sixStepResponseData[index1].landstatus ='NO';
  //   }
  // })
   console.log(JSON.stringify(this.sixStepResponseData )+ 'in get')
    this.sixStepResponseData.forEach(
      (
        element: {
        age: any;
        annualIncome: any;
        dateOfBirth: any;
        dryLand: any;
        familyAadharNo: any;
        familyMobileNo: any;
        gender: any;
        isFourWheeler: any;
        isGovernment: any;
        isPayingIncomeTax:any;
        isPayingPropertyTax: any;
        landStatus: any;
        memberName:any;
        mobileNumber:any;
        teluguName:any;
        noOfFourWheelers: string;
        fourWheelerNumbers:any
        relationwithHead: any;
        totalNoOfSqft: any;
        wetLand: any;
        migrationReason:any;
        IsActive:any;
        isSelf: any;
        residentId:any;
        iS_LIVING_WITHFAMILY:any;
        salary:any;
        empCategory:any;
        },
        index
      ) => {
    
        let formGroup = this.formBuilder.group({
          tbName: element.memberName,
          tbTeluguName : element.teluguName,
          tbGender: element.gender,
          tbRelation: element.relationwithHead,
        //  tbRelationarray:element.gender.toUpperCase() === 'MALE' ? [{"name" :"SON"}, {"name" : "GRANDFATHER(FATHERS SIDE)"}] : this.femaleRelations,
         // tbDob: element.dateOfBirth,
          tbDob: element?.dateOfBirth?.includes('T') ? this.datePipe.transform((element.dateOfBirth)?.split('T')[0],'dd-MM-yyyy') : element?.dateOfBirth,
          tbAge: (element.age == "" || element.age == null) ? 0 : element.age,
          tbMobile: element.mobileNumber ? element.mobileNumber :this.mobileNo,
          tbAadharNo:
            element.familyAadharNo !== null ? element.familyAadharNo : null,

          tbMaskAadharNo: this.numberMasking(element.familyAadharNo),

          tbPayingIncome: element.isPayingIncomeTax ? element.isPayingIncomeTax :'NO',
          tbAnnualIncome: element.annualIncome,
          //tbLandStatus: (element.dryLand || element.wetLand) === true ? 'Y' : 'N',
          tbLandStatus: ((element.landStatus !== null &&  element.landStatus !== '') && element.landStatus != 'NO' ) ? 'YES' :'NO',
          //tbWetLand: element.dryLand ? element.dryLand : '',
          //tbDryLand: element.wetLand ? element.wetLand : '',
          tbWetLand: element.wetLand ? element.wetLand : '0',
          tbDryLand: element.dryLand ? element.dryLand : '0',
          tbWetLandStatus : Number(element.wetLand) !== 0 ? 'YES' : 'NO',
          tbDryLandStatus : Number(element.dryLand) !== 0 ? 'YES' : 'NO',
          tbPayingPropertyTax: element.isPayingPropertyTax ? element.isPayingPropertyTax: 'NO',
          //tbPropertyTax: element.isPayingPropertyTax,
          tbPropertyTax: element.totalNoOfSqft,
          //tbFourWheeler: element.isFourWheeler !== null ? 'Y' : 'N',
          tbFourWheeler: element.isFourWheeler? element.isFourWheeler : 'NO',
          tbFourWheelerNo: element.noOfFourWheelers ? element.noOfFourWheelers : '0',
          tbFourWheelerNumbers:element.fourWheelerNumbers ? element.fourWheelerNumbers : 'NO',
          tbGovernmentPensioner: element.isGovernment ? element.isGovernment  : 'NO',
          tbReason:element.migrationReason,
          tbisActive:element.IsActive,
          tbSelf : false,
          iS_LIVING_WITHFAMILY:element.iS_LIVING_WITHFAMILY,
          residentId:element.residentId,
          tbGovernmentCategory:element.empCategory,
          tbSalary:element.salary
          
        });
        
        this.familyDetailsTableDatas.push(formGroup);
        console.log(formGroup)
      }
    );
 console.log(this.aarogyaSri)
   for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
    {
      if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF'){
         //this.familyDetailsTableDatas.controls[i].get('tbRelation')?.disable();
         this.familyDetailsTableDatas.controls[i].get('tbSelf')?.patchValue(true);
      }
      if(this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH'){ 
        this.familyDetailsTableDatas.controls[i].get('tbReason')?.patchValue('Death');
      }
    }
  
this.sixStepResponseData.forEach((fo:any,index1) => {
  if(this.sixStepResponseTocheck.filter((x) => x.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() != 'DEATH').findIndex((el) =>el.CITIZEN_ID === fo.familyAadharNo) > -1){
    this.familyDetailsTableDatas.controls[index1].get('tbisActive')?.patchValue(true);
  }else{
    this.familyDetailsTableDatas.controls[index1].get('tbisActive')?.patchValue(false);
  }
})
//   this.sixStepResponseTocheck.findIndex((eo,index) => {
//     if((fo.familyAadharNo == eo.CITIZEN_ID) > -1){
//       alert(fo.familyAadharNo +','+ eo.CITIZEN_ID)

//      // this.sixStepResponseData[index].isActive = true;
//      console.log(JSON.stringify(fo) + 'fo')
//      console.log(JSON.stringify(eo) + 'eo')
//       this.familyDetailsTableDatas.controls[index1].get('tbisActive')?.patchValue(true); 

//     }else{
//      // this.sixStepResponseData[index].isActive = false;
// this.familyDetailsTableDatas.controls[index1].get('tbisActive')?.patchValue(false);
//     }

//  })

//})


}

  translatedText : any;
  
convertToTelugu(teluguName : any , index : any)
  {
    let obj =
    {
      Txt_English : (teluguName).toLowerCase()
    }
    this.commonService.postRequestForWebLand(this.commonConstants.teluguTranslator ,obj).subscribe({
      next: (responseData: any) => {
        // console.log(responseData, "bbb");
        this.translatedText = responseData.d[0]
        console.log(this.translatedText)
        this.familyDetailsTableDatas.controls[index].get('tbTeluguName')?.patchValue(this.translatedText);
      }
    })   
  }

  checkText(event : any , index : any)
  {
    let regex = '/?/g'
      if(event?.target?.value?.includes("?"))
      {
        let result = event.target.value.replace(event.target.value, "");
        console.log(event.target.value , "event.target.value",result)
        this.familyDetailsTableDatas.controls[index].get('tbTeluguName')?.setValue(result)
      }
  } 

  

  totalFourWheeler(value:any,i :any) {
    if(value === 0 || value === null || value === ""){
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.patchValue('NO');
      console.log(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value + 'tbFourWheelerNumbers')
    }else{
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.patchValue('');
    this.sumFourWheeler = 0;
    for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      // if(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value > 0){
      //   this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.patchValue('YES');
      // }
      // else {
      //   this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.patchValue('NO');
      // }
      this.sumFourWheeler =
        Number(this.sumFourWheeler) +
        Number(
          this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value
        );
    }
    if (this.sumFourWheeler > 1) {
      this.fourWheelerValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant Total number of four wheeler beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    } else {
      this.fourWheelerValidation = true;
    }
  }
  }
  fourWheelerOnSubmit(){
    this.sumFourWheeler = 0;
    for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
    {
      this.sumFourWheeler = Number(this.sumFourWheeler) +  Number(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value)
    }
   
   
    if(this.sumFourWheeler > 1)
    {
      this.fourWheelerValidation = false;     
    }
    else
    {
      this.fourWheelerValidation = true
    }
  

  }
  totalPropertyCalculator(event: any) {
    this.sumPropertyTax = 0;
    for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      this.sumPropertyTax =
        Number(this.sumPropertyTax) +
        Number(
          this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.value
        );
    }
    this.f['totalPropertyTax'].patchValue(this.sumPropertyTax);
    if (this.sumPropertyTax >= 3000) {
      this.propertyValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant property beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    } else {
      this.propertyValidation = true;
    }
    // console.log(this.totalPropertyTax)
  }

  totalDryLandCalculator(event: any) {
    this.sumDryLand = 0;
    for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      this.sumDryLand =
        Number(this.sumDryLand) +
        Number(
          this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.value
        );
    }
    console.log(this.sumDryLand + 'sum dry land');
    this.f['totalDryLand'].patchValue(this.sumDryLand);
    if (Number(this.sumDryLand) > 35) {
      this.drylandValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant dry land beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    } else if (Number(this.sumWetLand) + Number(this.sumDryLand) > 35) {
      this.drylandValidation = false;
      this.wetlandValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant total land beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else if(Number(this.sumDryLand) <= 35 && Number(this.sumWetLand) < 12)
    {
      this.drylandValidation = true;
      this.wetlandValidation = true;
    }
    
    /*else {
      this.drylandValidation = true;
    }*/
  }

  totalWetLandCalculator(event: any) {
    this.sumWetLand = 0;
    for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      this.sumWetLand =
        Number(this.sumWetLand) +
        Number(
          this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.value
        );
    }
    console.log(this.sumWetLand + 'sumwetland');
    this.f['totalWetLand'].patchValue(this.sumWetLand);

    if (Number(this.sumWetLand) >= 12) {
      this.wetlandValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant wet land beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    } else if (Number(this.sumWetLand) + Number(this.sumDryLand) > 35) {
      this.drylandValidation = false;
      this.wetlandValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant total land beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    } 
    else if(Number(this.sumDryLand) <= 35 && Number(this.sumWetLand) < 12)
    {
      this.drylandValidation = true;
      this.wetlandValidation = true;
    }
    
    /*else {
      this.wetlandValidation = true;
    }*/
  }

  totalIncomeCalculator(event: any) {
    this.sumAnnualIncome = 0;
    for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      this.sumAnnualIncome =
        Number(this.sumAnnualIncome) +
        Number(
          this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.value
        );
     
    }
    this.f['totalIncome'].patchValue(this.sumAnnualIncome);
    if (this.sumAnnualIncome > 500000) {
      this.incomeValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
        'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the family income beyond the eligibility';
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    } else {
      this.incomeValidation = true;
    }
  }

  get f() {
    return this.aarogyaSri.controls;
  }
  TotalLandValue(e:any, value:string, i:any){  
    let TotalLand =0 ;
    let wetLand  = Number(this.AddingMembers.controls[i].get('tbWetLand')?.value);  
    let dryLand = Number (this.AddingMembers.controls[i].get("tbDryLand")?.value);
    TotalLand = TotalLand + wetLand+ dryLand;
      this.AddingMembers.controls[i].get('tbLand')?.patchValue(TotalLand)  
    // for (let i = 0; i < this.AddingMembers.length; i++) {
     
    // let wetLand  = Number(this.AddingMembers.controls[i].get('tbWetLand')?.value); 
    // let dryLand = Number (this.AddingMembers.controls[i].get("tbDryLand")?.value);   
    // this.f.totalDryLand.patchValue(dryLand);  
    // this.f.totalWetLand.patchValue(wetLand); 
    //  TotalLand = TotalLand + wetLand+ dryLand;
     
    // this.AddingMembers.controls[i].get('tbLand')?.patchValue(TotalLand)  
    // }

    
// if(wetLand > 12){
// const modalRef = this.modalService.open(CommonMsgModalComponent, {
//   size: 'md',
// });
// modalRef.componentInstance.title = 'Alert';
// modalRef.componentInstance.message =
//   'As per eligible criteria of Aarogyasri  G.O.Rt.No.628 the applicant wet land beyond the eligibility';
// modalRef.result.then((result: any) => {
//   if (result) {
//     console.log(result);
//   }
// });
// }
//  if(dryLand > 35){
//   const modalRef = this.modalService.open(CommonMsgModalComponent, {
//     size: 'md',
//   });
//   modalRef.componentInstance.title = 'Alert';
//   modalRef.componentInstance.message =
//     'Total dry land should be less than 35.00 acres';
//   modalRef.result.then((result: any) => {
//     if (result) {
//       console.log(result);
//     }
//   });
  
// }
// if(TotalLand > 35){
//   const modalRef = this.modalService.open(CommonMsgModalComponent, {
//     size: 'md',
//   });
//   modalRef.componentInstance.title = 'Alert';
//   modalRef.componentInstance.message =
//     'Total of wet land and dry land should be less than 35.00 acres';
//   modalRef.result.then((result: any) => {
//     if (result) {
//       console.log(result);
//     }
//   });
// }
//}
}
IncomeValidator(e:any){
  // if(e){
  //  if( e.target.value > 500000 ){
   // As per eligible criteria of Aarogyasri G.O.Rt.No.628 the family income beyond the eligibility
  //   alert('income')
  //  }
  // }

}

changeConsent(){
  //console.log(this.aarogyaSri.controls['consent'].value,'consent cgange')
  if(this.aarogyaSri.controls['familyForm'].value === false){
    this.aarogyaSri.controls['familyForm'].reset();
  this.aarogyaSri.controls['familyForm'].setValidators(Validators.required);
  this.aarogyaSri.controls['familyForm'].updateValueAndValidity();
  }
}

onNavChange(changeEvent: NgbNavChangeEvent) {
  this.commonFormSubmitted = true;     
  if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
    const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    mdRef.componentInstance.message = 'Please enter the mandatory fields!'       
    changeEvent.preventDefault();
  }
  else if (changeEvent.nextId === 2 && this.f.commonForm.valid) { 
    changeEvent.preventDefault();
    this.checkValidation()
  }
}
checkValidation(){ 
  this.commonFormSubmitted = false; 
  this.f['totalIncome'].patchValue('');
    this.f['totalWetLand'].patchValue('');
    this.f['totalDryLand'].patchValue('');
    this.f['totalPropertyTax'].patchValue('');
    this.f['familyForm'].reset();
    this.f['aarogya_sri_number'].patchValue('');
    this.sumPropertyTax = 0;
    this.sumAnnualIncome = 0;
    this.sumDryLand =0;
    this.sumWetLand =0;
    this.f['aadharCard'].patchValue('');
    this.f['familyPhoto'].patchValue('');
    this.showDetails=false;
    this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
    this.checkSecretariat();
}
// onNavChange(changeEvent: NgbNavChangeEvent) {
//   if(this.f.commonForm.valid){
//     this.commonFormSubmitted = false; 
//   this.f['totalIncome'].patchValue('');
//     this.f['totalWetLand'].patchValue('');
//     this.f['totalDryLand'].patchValue('');
//     this.f['totalPropertyTax'].patchValue('');
//     this.f['familyForm'].reset();
//     this.f['aarogya_sri_number'].patchValue('');
//     this.sumPropertyTax = 0;
//     this.sumAnnualIncome = 0;
//     this.sumDryLand =0;
//     this.sumWetLand =0;
//     this.f['aadharCard'].patchValue('');
//     this.f['familyPhoto'].patchValue('');
//     this.showDetails=false;
//     this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
//   this.commonFormSubmitted = true;
//   let dob =this.f['commonForm'].value['dob'] 
//     const convertAge = new Date(this.commonService.formatDate(dob));
//   const timeDiff = Math.abs(Date.now() - convertAge.getTime());
//   let age  = Math.floor(timeDiff / (1000 * 3600 * 24) / 365); 
//   var sendPostData={
//     CITIZEN_ID: btoa(this.f['commonForm'].value['aadharNo']),
//   }
//   this.commonService.postRequestForWebLand(this.commonConstants.newSixStepDetails, sendPostData , 'citizenInfo').subscribe({
//     next: (responseData:any) => { 
//       this.sixStepResponseData = responseData.result;
//       if(this.sixStepResponseData.length>0){
//         let isValid: any;
//         let teluguName:any;
//         let data:any=[];
//           for (let i = 0; i < this.sixStepResponseData.length; i++){     
//             teluguName =[];    
//             teluguName =   this.sixStepResponseData[i].CITIZEN_NAME.match(this.commonConstants.namePattern)      
//             data.push(teluguName);
//           }
          
//           isValid=this.sixStepResponseData.filter(item => (item.CITIZEN_NAME === "")|| (item.CITIZEN_NAME === null)||(item.CITIZEN_NAME === undefined) ||(item.CITIZEN_NAME === 'NA') );
//            if(isValid.length === 0 && (data.length === this.sixStepResponseData.length)){

//             if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
//               console.log(this.f.commonForm.valid)
//               const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//               mdRef.componentInstance.message = 'Please enter the mandatory fields!'
//               this.commonFormSubmitted = true;  
//               changeEvent.preventDefault();
//               return
              
//                       }
//             else if (changeEvent.nextId === 2 && this.f.commonForm.valid) {      
              
//               if(age > 18){
//                 this.checkSecretariat();
//               }else{
                
//                 const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//                 mdRef.componentInstance.message = 'The Head of the family age must and should above 18 years!'
             
//                 changeEvent.preventDefault();
//                 return
//               }
              
//             }   
//           }
//             else{              
//               const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//               mdRef.componentInstance.message = 'One of the family members does not have proper data!'
//             changeEvent.preventDefault();
//             return
//           }
//       }               
      
//     }
//   })   
//   }else{
//      this.commonFormSubmitted = true; 
//     const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//               mdRef.componentInstance.message = 'Please enter the mandatory fields!'
//               changeEvent.preventDefault();
//               return
//   }
// }
  

goToNextTab() {
  this.f['totalIncome'].patchValue('');
    this.f['totalWetLand'].patchValue('');
    this.f['totalDryLand'].patchValue('');
    this.f['totalPropertyTax'].patchValue('');
    this.f['familyForm'].reset();
    this.f['aadharCard'].patchValue('');
    this.f['familyPhoto'].patchValue('');
    this.f['aarogya_sri_number'].patchValue('');
    this.showDetails=false;
    this.sumPropertyTax = 0;
    this.sumAnnualIncome = 0;
    this.sumDryLand =0;
    this.sumWetLand =0;
    this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
  this.commonFormSubmitted = true;
  if (this.f.commonForm.valid) {
    this.checkSecretariat();
   
  }else{
    
 const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
 mdRef.componentInstance.message = 'Please enter the mandatory fields!'
  }
}
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => {
        this.districts = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  /*onDistrictChange(event: any, name: string) {
    console.log('district', this.f[name].value.id, this.f[name].value);
    this.commonService
      .getRequest(this.commonConstants.getMandals + this.f[name].value.id)
      .subscribe({
        next: (responseData: any) => {
          this.mandals = this.commonService.sortData(responseData.result);
          this.villages = [];
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  onMandalChange(event: any, name: string) {
    this.commonService
      .getRequest(this.commonConstants.getVillages + this.f[name].value.id)
      .subscribe({
        next: (responseData: any) => {
          this.villages = this.commonService.sortData(responseData.result);
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }*/

  getGender() {
    this.commonService.getRequest(this.commonConstants.getGender).subscribe({
      next: (responseData: any) => {
        this.genders = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getRelation() {
    this.commonService
      .getRequest(this.commonConstants.getRelationsAarogyasri)
      .subscribe({
        next: (responseData: any) => {
          this.relations = this.commonService.sortData(responseData.result);
          
          this.relations = this.relations.filter(item => item.name !== 'OTHERS');
         
          this.femaleRelations=[{"name": "HUSBANDS SISTER"}, {"name"  :"GRAND DAUGHTER"},
                                {"name" : "MOTHER"}, {"name" :  "MOTHER IN LAW"},{"name": "DAUGHTER"},
                                {"name": "AUNT(FATHERS SISTER)"}, {"name": "WIFE"},{"name": "SISTER"},
                                {"name": "GRAND MOTHER(FATHERS SIDE)"},
                                {"name": "WIFE OF HUSBANDS ELDER BROTHER"},{"name": "DAUGHTER IN LAW"},
                              {"name": "BROTHERS  WIFE"},{"name": "SELF"}];
                            

        this.maleRelations=[{"name" :"SON"}, {"name" : "GRANDFATHER(FATHERS SIDE)"},
                              {"name" : "UNCLE(MOTHERS BROTHER)"},{"name": "HUSBAND"},
                              {"name": "GRAND SON"},{"name": "SON IN LAW"}, {"name": "SISTERS HUSBAND"},
                              {"name": "FATHER IN LAW"},{"name": "FATHER"},
                              {"name": "BROTHER IN LAW"},{"name": "BROTHER"},{"name": "SELF"}
                             ]  
                            
              this.memberRelations= this.relations.filter(item => (item.name !== 'OTHERS' && item.name !== 'SELF'));
              this.memberFemaleRelations=[{"name": "HUSBANDS SISTER"}, {"name"  :"GRAND DAUGHTER"},
              {"name" : "MOTHER"}, {"name" :  "MOTHER IN LAW"},{"name": "DAUGHTER"},
              {"name": "AUNT(FATHERS SISTER)"}, {"name": "WIFE"},{"name": "SISTER"},
              {"name": "GRAND MOTHER(FATHERS SIDE)"},
              {"name": "WIFE OF HUSBANDS ELDER BROTHER"},{"name": "DAUGHTER IN LAW"},
            {"name": "BROTHERS  WIFE"}];
              this.memberMaleRelations=[{"name" :"SON"}, {"name" : "GRANDFATHER(FATHERS SIDE)"},
              {"name" : "UNCLE(MOTHERS BROTHER)"},{"name": "HUSBAND"},
              {"name": "GRAND SON"},{"name": "SON IN LAW"}, {"name": "SISTERS HUSBAND"},
              {"name": "FATHER IN LAW"},{"name": "FATHER"},
              {"name": "BROTHER IN LAW"},{"name": "BROTHER"}
             ] 

        },
      
        error: (error) => {
          console.log('client side', error);
        },
      
      });
      console.log(this.relations)
  }

  getReasons() {   
    this.commonService.getRequest(this.commonConstants.getReasons).subscribe({
      next: (responseData: any) => {
        this.reasons = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
    }

  validationSetter() {
    for (var i = 0; i < this.familyDetailsTableDatas.length; i++) {

     
                  
      this.familyDetailsTableDatas.controls[i]
        .get('tbGender')
        ?.setValidators([Validators.required]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbRelation')
        ?.setValidators([Validators.required]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbDob')
        ?.setValidators([Validators.required]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbAadharNo')
        ?.setValidators([
          Validators.required,
         // AadharValidator.aadharValidate,
          //WhitespaceValidator.whiteSpaceValidate,
        ]);
        this.familyDetailsTableDatas.controls[i]
        .get('tbMobile')
        ?.setValidators([
          Validators.required,
          Validators.minLength(10),
          Validators.pattern(this.commonConstants.mobilePattern)
         // AadharValidator.aadharValidate,
          //WhitespaceValidator.whiteSpaceValidate,
        ]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbAnnualIncome')
        ?.setValidators([Validators.required]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbDryLand')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.commonConstants.decimalPatternforland),
        ]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbWetLand')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.commonConstants.decimalPatternforland),
        ]);
      this.familyDetailsTableDatas.controls[i]
        .get('tbPropertyTax')
        ?.setValidators([Validators.required,Validators.pattern(this.commonConstants.decimalPatternforland)]);
       
       if(this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'NO' &&
        this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value > 0  &&  
        this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value  === ''){
          this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.setValidators([
            Validators.required,
            Validators.pattern(this.commonConstants.fourwheelerNumber)
         ]);

        }else{
          this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.updateValueAndValidity();
        }
        //if(this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value === 'Death'){ 
        if((this.familyDetailsTableDatas?.controls[i]?.get('tbReason')?.value != null 
        && this.familyDetailsTableDatas?.controls[i]?.get('tbReason')?.value != "")
        || this.familyDetailsTableDatas?.controls[i]?.get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() == 'DEATH') {  
          this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.patchValue((this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.value !== null && this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.value !== undefined)  ? 
            this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.value: '');
            this.familyDetailsTableDatas?.controls[i]?.get('tbAadharNo')?.patchValue((this.familyDetailsTableDatas?.controls[i]?.get('tbAadharNo')?.value !== null && this.familyDetailsTableDatas?.controls[i].get('tbAadharNo')?.value !== undefined) ? 
              this.familyDetailsTableDatas?.controls[i]?.get('tbAadharNo')?.value: '');

          this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbGender')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbRelation')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbDob')?.clearValidators();
          // this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.setValidators([
          //   Validators.required , AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate
          // ]);
          this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbMobile')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.clearValidators();
          this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.clearValidators();
         
        }
        this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbGender')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.updateValueAndValidity();
        this.familyDetailsTableDatas.controls[i].get('tbMobile')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbRelation')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbDob')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.updateValueAndValidity();
    }
  
  }

  addGridDataValuesinArray() {
    this.tempData = [];
    this.gridData = [];
    this.familyDetailsTableDatas.value.forEach(
      (element: {
        tbAadharNo: any;
        tbName: any;
        tbTeluguName: any;
        tbGender: any;
        tbDob: any;
        tbMobile: any;
        tbRelation: any;
      //  tbRelationarray:any;
        tbAge: any;
        tbPayingIncome: any;
        tbLandStatus: any;
        tbWetLand: any;
        tbDryLand: any;
        tbPayingPropertyTax: any;
        tbPropertyTax: any;
        tbFourWheeler: any;
        tbFourWheelerNo: any;
        tbFourWheelerNumbers:any;
        tbGovernmentPensioner: any;
        tbAnnualIncome: any;
        tbReason:any;
        tbisActive:any;
        residentId:any;
        iS_LIVING_WITHFAMILY:any;
        tbGovernmentCategory:any;
        tbSalary:any;
      }) => {
        this.tempData.push({
          aadhar: element.tbAadharNo,
        });
        let gridDate = element.tbDob !='' && element.tbDob != null? this.commonService.formatDate(element.tbDob):null;        
        let gridReason =element.tbReason?element.tbReason : 'EDIT';
        let gridActive= element.tbReason ? false:true;
        
        return this.gridData.push({
          memberName: element.tbName,
          gender: element.tbGender,
          teluguName : element.tbTeluguName,
          dateOfBirth: gridDate,
          familyAadharNo: this.commonService.RSAEncrypt(element.tbAadharNo),
          relationwithHead: element.tbRelation,
          age: element.tbAge,
          mobileNumber:element.tbMobile,
          isGovernment: element.tbGovernmentPensioner,
          isPayingIncomeTax: element.tbPayingIncome,
          annualIncome: element.tbAnnualIncome,
          landStatus: element.tbLandStatus,
          wetLand: element.tbWetLand,
          dryLand: element.tbDryLand,
          isPayingPropertyTax: element.tbPayingPropertyTax,
          totalNoOfSqft: element.tbPropertyTax,
          isFourWheeler: element.tbFourWheeler,
          noOfFourWheelers: element.tbFourWheelerNo,
          fourWheelerNumbers:element.tbFourWheelerNumbers,
          MigrationReason:gridReason,
          IsActive:gridActive,
          residentId:element.residentId,
          iS_LIVING_WITHFAMILY:element.iS_LIVING_WITHFAMILY,
          salary:element.tbSalary,
          empCategory:element.tbGovernmentCategory
        });
      }
    );

    this.duplicatesRecords = this.tempData.some((user) => {
      let counter = 0;
      for (const iterator of this.tempData) {
        if (iterator.aadhar === user.aadhar) {
          counter += 1;
        }
      }
      return counter > 1;
    });
  
  }

  updateFileUpload(id: any) {
    let finalFiles:any[]=[];
    finalFiles = this.fileList;
    let fileData = {
      FileList: finalFiles,
      RequisitionId: id,
    };
    if(this.fileList.findIndex((c:any)=> c.documentType == 'Family Photo' )> -1){
    this.commonService
      .postRequest(this.commonConstants.HealthCardFileUpload, fileData)
      .subscribe({
        next: (resData: any) => {
          console.log(resData, 'File Upload  Respose data');
          if(resData.result?.updateResult == 'Success') {
            this.straightThruGenerateCertificateData();
          }  else{
            this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
          }        
          
          //this.showPayment(id);
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }else{
      this.commonService.commonErrorPopup('Alert', 'Family Photo is missed, Please re-upload ', 'md');
    }
  }

  showPayment(requisionId: any) {
    this.router.navigate(['/payment-gateway']);
    let type:string = 'UpdateAarogyaSriCard';
    const queryParams: Params = {
      id: requisionId,
      department: 'HF',
      type: type,
    };
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  submit():any {  
    if(this.days > 7   || this.typeofService === "AarogyaSriNewCard" ||  this.dataFlag === 'L'){
    console.log('Not submitted', this.aarogyaSri);
    this.alertCount =0;
    console.log("A=========")
    
    const tempArray = this.familyDetailsTableDatas.controls.filter((item:any) => (item.get('tbReason').value == null  || item.get('tbReason').value == "") && item.get('iS_LIVING_WITHFAMILY').value?.toUpperCase()?.trim() != 'DEATH');
    
    let isValid: any;
    let teluguName:any;
    let data:any=[];
    let isTeluguName:any=[];
    let invalidNamesMsg: string[] = [];

    for (let i = 0; i < tempArray.length; i++){     
      teluguName = null;    
      teluguName = tempArray[i].get('tbName')?.value.match(this.commonConstants.namePattern);         
      if(teluguName == null) {
        invalidNamesMsg.push(tempArray[i].get('tbName')?.value);
      }
      data.push(teluguName);
    }    
    isTeluguName = data.filter((item:any) => ( item === null));
    isValid= tempArray.filter(item => (item.get('tbName')?.value == "")|| (item.get('tbName')?.value == null)||(item.get('tbName')?.value == undefined) ||(item.get('tbName')?.value == 'NA') || (!item.get('residentId')?.value) || (!item.get('tbDob')?.value));
   
    if(isValid.length > 0 ||  isTeluguName.length > 0){
      isValid.forEach((x:any) => {
        if(x.get('tbName')?.value){
          invalidNamesMsg.push(x.get('tbName')?.value);
        }
      });
      if(invalidNamesMsg.length > 0){
      this.commonService.commonErrorPopup("Alert",'Family members does not have proper data or have blank member name(citizen Name, date of birth  or resident Id missed) i.e. Member Names: ' + invalidNamesMsg.toString() , "md");
      } else {
        this.commonService.commonErrorPopup("Alert",'Family members does not have proper data or have blank member name(citizen Name, date of birth  or resident Id missed)' , "md");
      }
      return false;
    }

    let filteredsixStepResponse = this.sixStepResponseTocheck.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() != "DEATH"));
    let isAllAadharPresent: boolean = true;
    tempArray.forEach((e: any) => {
      const presentAadhar = filteredsixStepResponse.filter((item:any) => e.get('tbAadharNo').value == item.CITIZEN_ID);
      if(presentAadhar.length <=0 ){
        isAllAadharPresent = false;
      }
    });
    if (!isAllAadharPresent){
      this.commonService.commonErrorPopup("Alert",'Some Family members are not there in house hold details,So please update the household then apply for Update-aarogya-sri' , "md");
      return false;
    } 

   this.fourWheelerOnSubmit();
    console.log("B=========")
    this.gridData = [];    
    this.validationSetter();
    this.addGridDataValuesinArray();
    console.log("c=========")
    this.submitted = true;
    this.govtEmpOrPensionerValidation =0;
    this.selfValidation=0;
    
    for(let i = 0; i < this.familyDetailsTableDatas.length; i++) {
      

      // if((this.familyDetailsTableDatas.controls[i].get('tbGovernmentPensioner')?.value === 'YES')  &&
      // (
      //   (  (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value !== 'Govt Employee') || (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value !== 'Pension')  || (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value !== '')   ) &&
      //    (  (12 * Number(this.familyDetailsTableDatas.controls[i].get('tbSalary')?.value)) > 500000)) 
      //    || 
      //    ((this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value === 'Govt Employee') || (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value === 'Pension') )
      //    && this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() !== 'DEATH' )
      
      // {
        
      //   this.govtEmpOrPensionerValidation += 1
      // }


      if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === "SELF")
      {
        
        this.selfValidation += 1
      }

    }
    this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
    if ( !this.aarogyaSri.valid || this.duplicatesRecords) {
      if(!this.aarogyaSri.valid){
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'Please enter the mandatory fields !'
        return false
      }
     else if(this.duplicatesRecords){
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'Duplicate family member details are there !'
        return false
      }
      
      // console.log('Not submitted', this.aarogyaSri.value);
      // console.log(this.aarogyaSri)
      // console.log(this.incomeValidation, 'incomeValidation');
      // console.log(this.wetlandValidation, 'wetlandValidation');
      // console.log(this.drylandValidation, 'drylandValidation');
      // console.log(this.fourWheelerValidation, 'fourWheelerValidation');
      // console.log(this.propertyValidation, 'propertyValidation');
      // console.log(this.govtEmpOrPensionerValidation,'govtEmpOrPensionerValidation');
      // console.log(this.selfValidation + 'selfValidation');
    } 
    else if(!this.incomeValidation)
    {
      //console.log(this.incomeValidation , "incomeValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant income beyond the eligibility")
    return false
    }
    else if(!this.wetlandValidation)
    {
     // console.log(this.wetlandValidation , "wetlandValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant wet land or total land beyond the eligibility")
   return false
    }
    else if(!this.drylandValidation )
    {
     // console.log(this.drylandValidation , "drylandValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant dry land or total land beyond the eligibility")
   return false
    }
    else if( !this.propertyValidation)
    {
      //console.log(this.propertyValidation , "propertyValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant property beyond the eligibility")
    return false
    }
    else if( !this.fourWheelerValidation )
    {
     // console.log(this.fourWheelerValidation , "fourWheelerValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant four wheelers beyond the eligibility")
    return false
    }
    else if( !this.relationValidation )
    {
      //console.log(this.relationValidation , "relationValidation")
      this.InvalidFiveStepValidation("Other family members are declared as SELF")
   return false
    }
    else if( !this.selfAgeValidation )
    {
      //console.log(this.selfAgeValidation , "selfAgeValidation")
      this.InvalidFiveStepValidation("Self should be above 18 years of age")
    return false
    }
    else if(this.govtEmpOrPensionerValidation > 0){
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 Family having Government Employee/Pensioner,so application has been ineligible")
   return false
    }else if(this.selfValidation > 1){
      this.InvalidFiveStepValidation("Other family members are declared as SELF") 
   return false
    }
    else {
     

      if(this.Member.touched === true && this.AddingMembers.length >0) {
        if(this.Member.valid){ 
                
      let Aadhar='';
      this.familySubmitted = false;
      
      Aadhar =(<HTMLInputElement>document.getElementById("tbAadharNo")).value;      
      if(Aadhar){
 
         if(this.isinHousehold ==false){ 
           const modalRef = this.modalService.open(CommonMsgModalComponent, { 
             size: 'md', 
           }); 
           modalRef.componentInstance.title = 'Alert'; 
           modalRef.componentInstance.message =
             'Adding members should be mapped with above family in GSWS household data ';  
 
           return false;
 
          }
 
    
 
         if(this.isinCard == true){
 
           const modalRef = this.modalService.open(CommonMsgModalComponent, {
 
                  size: 'md',
 
                    });
 
                modalRef.componentInstance.title = 'Alert';
 
               modalRef.componentInstance.message =
 
               'Member already in HealthCard';
 
           return false;
 
         }
 
         this.AddFamilyMembers();
         if(this.AddingMembers.length>0){
          
          this.TotalLand=0;
            this.TotalWetLand=0;
            this.TotalDryLand= 0;
            this.sumProperty=0;
            this.totalIncome=0;
            let wetLand =0;
            let dryLand=0;
            let property=0;
            let income =0;
            
          for (let i = 0; i < this.AddingMembers.length; i++) {            
            wetLand  = Number(wetLand)+Number(this.AddingMembers.controls[i].get('tbWetLand')?.value); 
             dryLand =Number(dryLand)+ Number (this.AddingMembers.controls[i].get("tbDryLand")?.value);
             property= Number(property)+Number(this.AddingMembers.controls[i].get("tbPropertyTax")?.value);       
            income =Number(income)+Number(this.AddingMembers.controls[i].get("tbAnnualIncome")?.value);
             

           // this.AddingMembers.controls[i].get('tbLand')?.patchValue(TotalLand)  
            }
            this.sumProperty=property ? property :0;
             this.TotalLand = this.TotalLand + wetLand+ dryLand; 
            this.TotalDryLand= dryLand ? dryLand : 0;
            this.TotalWetLand=wetLand ? wetLand : 0;
            this.totalIncome=income ? income :0;
            
        
        }
        }
      
       }else{
        this.familySubmitted = true;        
        return false
       }
      }

      if(this.Child.touched === true && this.AddingChild.length>0){        
        if(this.isChildAgeaboveFive === true){
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Age Should be less than 5 years';  
          return false
        }
      let ChildAadhar ='';    

      ChildAadhar=(<HTMLInputElement>document.getElementById("tbAadhar")).value; 
      if(this.Child.valid){ 
        this.childSubmitted =false
      if(ChildAadhar)
      {
        
        if(this.isinHousehold ==false){ 
          const modalRef = this.modalService.open(CommonMsgModalComponent, { 
            size: 'md', 
          }); 
          modalRef.componentInstance.title = 'Alert'; 
          modalRef.componentInstance.message =
            'Adding members should be mapped with above family in GSWS household data ';   
          return false;
         }
  

        if(this.isinCard == true){
          const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md',});

               modalRef.componentInstance.title = 'Alert';

              modalRef.componentInstance.message =

              'Member already in HealthCard';

          return false;

        }
      this.addChildData();
      
      }
    }else{
      this.childSubmitted = true;
      return false
    }
    }
    if(this.duplicatesRecordsinMember){
      this.commonService.commonErrorPopup("Alert",'Duplicate aadhars added in Member addition' , "md");

      return false
    }
    if(this.duplicateRecordsinChild){
      this.commonService.commonErrorPopup("Alert",'Duplicate aadhars added in Member addition below five years of age' , "md");
      return false
    }
   
   
      if (this.alertCount == 0) {
       // adding family member proprties and adding member properties
            let totFamilyIncome = 0;
            let totFamilyWetland=0;
            let totFamilyDryland=0;
            let totPropertyTax=0;

            totFamilyIncome= Number(this.f['totalIncome'].value ? this.f['totalIncome'].value : 0)+Number(this.totalIncome ? this.totalIncome : 0) ;
            totFamilyWetland= Number(this.f['totalWetLand'].value?this.f['totalWetLand'].value:0)+ Number(this.TotalWetLand ? this.TotalWetLand :0 );
            totFamilyDryland= Number(this.f['totalDryLand'].value?this.f['totalDryLand'].value:0)+ Number(this.TotalDryLand ? this.TotalDryLand :0 );
            totPropertyTax= Number(this.f['totalPropertyTax'].value?this.f['totalPropertyTax'].value:0)+ Number(this.sumProperty ? this.sumProperty :0 );
            let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
        this.postData = {
          
          // Running Code
          requisitionHeader:  {
            requisitionType: 'UpdateAarogyaSriCard',
            department: 'HF',
            applicantInfo: {
              id: '',
              aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo']?.trim(), // CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
              personNames: [
                {
                  languageCode: 'en-in',
                  firstName: this.f['commonForm'].value['firstName'],
                  middleName: this.f['commonForm'].value['middleName'],
                  lastName: this.f['commonForm'].value['lastName'],
                  fatherHusbandName:
                    this.f['commonForm'].value['fatherHusName'],
                },
              ],
              gender: this.f['commonForm'].value['gender'],
              birthDate: dateFormat,
              caste: this.f['commonForm'].value['caste'].name,
              religion: this.f['commonForm'].value['religion'].name,
              qualification: this.f['commonForm'].value['qualification'].name,
              maritalStatus: this.f['commonForm'].value['maritalStatus'],
              addresses: [
                {
                  addressType: 'present',
                  doorNo: this.f['commonForm'].value['preDoorNo'],
                  streetName: this.f['commonForm'].value['preStreet'],
                  villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                  mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                  district: this.f['commonForm'].value['preDistrict'].entityCode,
                  pinCode: this.f['commonForm'].value['prePinCode'],
                  postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                  postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                  postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                  postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
                },
                {
                  addressType: 'permanent',
                  doorNo: this.f['commonForm'].value['permDoorNo'],
                  streetName: this.f['commonForm'].value['permStreet'],
                  villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                  mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                  district: this.f['commonForm'].value['permDistrict'].entityCode,
                  pinCode: this.f['commonForm'].value['permPinCode'],
                  postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                  postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                  postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                  postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
                },
              ],
              rationCardNo: '',
              deliveryType: this.f['commonForm'].value['deliveryType'],
              emailAddress: this.f['commonForm'].value['email'],
              mobileNumber: this.f['commonForm'].value['mobileNo'],
              isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
              isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
            },
            remarks: '',
          },
                   
          requisitionData: {
            familyDetails: this.gridData,
            familyAddress: {
              addressType: 'present',
              doorNo: this.f['commonForm'].value['preDoorNo'],
              streetName: this.f['commonForm'].value['preStreet'],
              villageWard: this.f['commonForm'].value['preVillage'].name,
              mandalMunicipality: this.f['commonForm'].value['preMandal'].name,
              district: this.f['commonForm'].value['preDistrict'].name,
              pinCode: this.f['commonForm'].value['prePinCode'],
            },
            isDeclared: 'true',
            uhid: this.f.aarogya_sri_number.value?.trim(),
          
            totalFamilyIncome: totFamilyIncome ? totFamilyIncome.toString() : "0" ,
            totalFamilyWetland: totFamilyWetland ? totFamilyWetland.toString():"0",
            totalFamilyDryland: totFamilyDryland ? totFamilyDryland.toString():"0",
            totalPropertyTax: totPropertyTax? totPropertyTax : "0",
            applicationDate: this.currentDate,
          volunteerName: this.volunteerName
          },
        };
        if(totFamilyIncome > 500000){
        //  this.incomeValidation = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message =
            'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the family income beyond the eligibility';
        
          modalRef.result.then((result: any) => {
            if (result) {
              console.log(result);
            }
          });
          return false
        }



       if(totFamilyWetland > 12){
          // this.wetlandValidation  = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message =
             'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant wet land beyond the eligibility';
        
          modalRef.result.then((result: any) => {
            if (result) {
              console.log(result);
            }
          });
          return false
        }

        if(totFamilyDryland > 35){
         // this.drylandValidation   = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message =
          'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant dry land beyond the eligibility';
        
          modalRef.result.then((result: any) => {
            if (result) {
              console.log(result);
            }
          });
          return false
        }
        if(totPropertyTax >= 3000){
          //this.propertyValidation   = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message =
          'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant property beyond the eligibility'
        
          modalRef.result.then((result: any) => {
            if (result) {
              console.log(result);
            }
          });
          return false
        }  
       
        if ((Number(totFamilyWetland)  + Number(totFamilyDryland ) ) > 35) {
          // this.drylandValidation = false;
          // this.wetlandValidation = false;
          console.log('total land' + (Number(totFamilyWetland)  + Number(totFamilyDryland )))
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          })
          ;
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message =
            'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant total land beyond the eligibility';
          modalRef.result.then((result: any) => {
            if (result) {
              console.log(result);
            }
          });
          return false
        }
        let sumFourWheelers = 0;
    for(let i = 0 ; i < this.AddingMembers.length ; i++)
    {
      sumFourWheelers = Number(sumFourWheelers) +  Number(this.AddingMembers.controls[i].get('tbFourWheelerNo')?.value)
    }
    
  
    if((Number(sumFourWheelers) + Number(this.sumFourWheeler ))> 1)
    {     this.commonService.commonErrorPopup("Alert",'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant four wheelers beyond the eligibility' , "md")
      return false;     
    }

    for(let i = 0; i < this.gridData.length; i++) {   
     
      if((this.gridData[i]?.isGovernment === 'YES')  &&( (  (this.gridData[i].empCategory !== 'Govt Employee') || (this.gridData[i].empCategory !== 'Pension')  || (this.gridData[i].empCategory !== '')   ) &&  (  (12 * Number(this.gridData[i].salary)) > 500000)) || 
         ((this.gridData[i].empCategory === 'Govt Employee') || (this.gridData[i].empCategory === 'Pension') )&& this.gridData[i]?.iS_LIVING_WITHFAMILY.toUpperCase()?.trim() !== 'DEATH' )
      
      {       
          this.govtEmpOrPensionerValidation += 1       
       
      }
    }
    let newlyAddingMembers :any= [];
    newlyAddingMembers = this.gridData.filter((o:any) => (o.MigrationReason == 'NEW'))
    
    let migratingPeople : any=[];
    migratingPeople = this.gridData.filter((o:any) => (o.MigrationReason == 'Permanent Migration') || (o.MigrationReason == 'Not a member of the Family')  ||(o.MigrationReason == 'Death')  ||(o.MigrationReason == 'Migration due to Marriage') )

    // Normal Govt Emp Edit with out addition and migration  - --  INVALID
         if(  this.govtEmpOrPensionerValidation > 0 && newlyAddingMembers.length == 0 && migratingPeople.length == 0 ){
            this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 Family having Government Employee/Pensioner,so application has been ineligible")
            return false;
          } 
    // Govt Emp Edit with member addition and member miration ---  INVALID
          if(  this.govtEmpOrPensionerValidation > 0 && newlyAddingMembers.length > 0 && migratingPeople.length > 0 ){
            this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 Family having Government Employee/Pensioner,so application has been ineligible for Member addition")
            return false;
          } 
    //Govt Emp Edit with member addition with out migration    ---- INVALID
          if(  this.govtEmpOrPensionerValidation > 0 && newlyAddingMembers.length > 0 && migratingPeople.length == 0 ){
            this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 Family having Government Employee/Pensioner,so application has been ineligible for Member addition")
            return false;
          } 

    // Valid case for Govt Emp is Member migration with out member addition

      let selfValidations = 0; 
      for(var j = 0; j<this.familyDetailsTableDatas.length ; j++){
        if(this.familyDetailsTableDatas.controls[j].get('tbRelation')?.value === "SELF"){    
          selfValidations += 1
        }
} 

if(selfValidations == 1){

if(this.postData?.requisitionData?.familyDetails?.length > 0  && ((this.fileList.length >0 && (this.f.familyPhoto.valid &&( this.f.familyPhoto.value != '' && this.f.familyPhoto.value != null && this.f.familyPhoto.value != undefined  )) )|| this.fileListOriginal.length >0) ){
  this.commonService
          .postRequest(
            this.commonConstants.aarogyasriUpdateHealthCard,
            this.postData
          )
          .subscribe({
            next: (responseData: any) => {
              console.log(
                responseData,
                'Aarogya sri update health card respose data'
              );
              console.log(responseData.status, 'stats');
              this.reqId = responseData['result'].id;
              this.departmentCode = responseData['result'].requisitionHeader?.department?.trim();
              this.certificateName = responseData['result'].requisitionHeader?.requisitionType;
              // if(this.isPhotoEnable === true || this.reqHeader.requisitionType==='UpdateAarogyaSriCardForImage'){
              //   this.updateFileUpload(responseData['result'].id);
              // }else{
              //   this.showPayment(this.reqId);
              // }
              this.updateFileUpload(responseData['result'].id);
            },
            error: (error) => {
              console.log('client side', error);
            },
          }); 
      }else{
        this.commonService.commonErrorPopup("Alert",'Family details or Image are missing Unable to proceed' , "md")
     return false
      }
    }else{
      this.commonService.commonErrorPopup("Alert",'Family should have one SELF' , "md")
    }
}
      
    }
  
  }
else if(this.days  <= 7 ){
  this.commonService.commonErrorPopup("Alert",'This Health card is updated recently on' + this.lastModified+' , Once card is updated then after 7 days only you can able to update ' , "md")
}


  }
 


  InvalidFiveStepValidation(data : any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = data;
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }

  InvalidMaleRelationPair() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message =
      'Invalid Relation selected for male gender';
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }

  InvalidFemaleRelationPair() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message =
      'Invalid Relation selected for female gender';
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }
 
  addNewMember(){
       if(this.Member.valid && !this.duplicatesRecordsinMember){     
        this.familySubmitted=false; 
      //  this.AddFamilyMembers()
        this.AddingMembers.push(this.AddingMembersData());   
   }else{
     this.familySubmitted=true;
   }
        
    }

    addChild(){
      if(this.Child.valid && !this.duplicateRecordsinChild){
        this.childSubmitted = false;
       // this.addChildData()
        this.AddingChild.push(this.AddingMembersDataBelowFive()); 
        
      }else{
        this.childSubmitted = true;
      }
     
    }
    		
  AddingMembersData(): FormGroup {
  
    return this.formBuilder.group({
      tbAadharNo: [
        null,
        [
          Validators.required,
          AadharValidator.aadharValidate,
          WhitespaceValidator.whiteSpaceValidate,
        ],
      ],
      
      tbDob: [null, [Validators.required]],
      tbAge: [null, [Validators.required]],
      tbRelation: ['', [Validators.required]],
      tbName: [null, [Validators.required,Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      tbTeluguName: [null, [Validators.required]],
      tbMobile: [null, [Validators.required, Validators.minLength(10),Validators.pattern(this.commonConstants.mobilePattern)]],
      tbGender: [null, [Validators.required]],
      tbAnnualIncome: [null, [Validators.required]],
      tbGovernmentPensioner: [null, [Validators.required]],
      tbGovernmentCategory:[null],
      tbPayingIncome: [null, [Validators.required]],
      tbPayingPropertyTax:[null],
      tbFourWheeler: [null, [Validators.required]],
      tbFourWheelerNo: [null, [Validators.required]],
      tbFourWheelerNumbers:[null,[Validators.required]],
      tbPropertyTax: [null, [Validators.required,Validators.pattern(this.commonConstants.decimalPatternforland)]],
      tbLand: [null, [Validators.required]],
      tbLandStatus:[null],
      tbDryLand: [null, [Validators.required,Validators.pattern(this.commonConstants.decimalPatternforland)]],
      tbWetLand: [null, [Validators.required,Validators.pattern(this.commonConstants.decimalPatternforland)]],
      residentId:[null],
      iS_LIVING_WITHFAMILY:[null]     
    });
  }
  AddingMembersDataBelowFive(): FormGroup {
  
    return this.formBuilder.group({
      tbAadhar: [
        null,
        [
          Validators.required,
          AadharValidator.aadharValidate,
          WhitespaceValidator.whiteSpaceValidate,
        ],
      ],
      
      tbDob: [null, [Validators.required]],
      tbAge: [null, [Validators.required]],
      tbRelation: [null, [Validators.required]],
      tbName: [null, [Validators.required,Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      tbTeluguName: [null, [Validators.required]],
      tbMobile: [null , [Validators.minLength(10), Validators.pattern(this.commonConstants.mobilePattern)]],
      tbGender: [null, [Validators.required]],
      tbAnnualIncome: [''],
      tbGovernmentPensioner: [''],
      tbPayingIncome: [''],
      tbFourWheelerNo: [''],
      tbFourWheelerNumbers:[''],
      tbPropertyTax: [''],
      tbLand: [''],
      residentId:[null],
      iS_LIVING_WITHFAMILY:[null],
      tbGovernmentCategory:[''],
      tbSalary:['']
    });
  }
  
  deleteMember(index: number,e:any) {
    this.AddingMembers.removeAt(index);
    let aadhar=e.target.value
    this.aadharvalues=[];
    for (let i = 0; i < this.AddingMembers.length; i++){ 
  this.aadharvalues.push({aadhar : this.AddingMembers.controls[i].get('tbAadharNo')?.value});    
  }
    this.duplicatesRecordsinMember = this.aadharvalues.some(user => {
      let counter  = 0;
      for (const iterator of this.aadharvalues) {
        if (iterator.aadhar==user.aadhar) {
          counter += 1;
        }
      }
      return counter > 1;
    });

  }
  deleteChild(index :number){
    this.AddingChild.removeAt(index);
    this.aadharvaluesForChild=[];
    for (let i = 0; i < this.AddingChild.length; i++){ 
  this.aadharvaluesForChild.push({aadhar : this.AddingChild.controls[i].get('tbAadhar')?.value});    
  }
    this.duplicateRecordsinChild = this.aadharvaluesForChild.some(user => {
      let counter  = 0;
      for (const iterator of this.aadharvaluesForChild) {
        if (iterator.aadhar==user.aadhar) {
          counter += 1;
        }
      }
      return counter > 1;
    });
  }
  AddFamilyMembers(){
    let tempData :any[]= [];
      if(this.Member.valid){
        /*this.familySubmitted=false;      
        this.checkinHouseHoldData()
        if(this.isinHousehold ==true){
         return;
        }        
        this.checkinExistingTable()
        if(this.isinCard == true){
          return;
        }*/
       
        this.familySubmitted =false;
        this.getCitizenInfo()     
       
      
  
    this.AddingMembers.value.forEach(
      
      (element: {
        tbAadharNo: any;
        tbName: any;
        tbTeluguName: any;
        tbGender: any;
        tbDob: any;
        tbMobile: any;
        tbRelation: any;
        tbAge: any;
        tbPayingIncome: any;
        tbLandStatus: any;
        tbWetLand: any;
        tbDryLand: any;
        tbPayingPropertyTax: any;
        tbPropertyTax: any;
        tbFourWheeler: any;
        tbFourWheelerNo: any;
        tbFourWheelerNumbers:any;
        tbGovernmentPensioner: any;
        tbAnnualIncome: any;
        tbReason:any;
        tbisActive:any;
        residentId:any;
        iS_LIVING_WITHFAMILY:any;
        tbGovernmentCategory:any;
        tbSalary:any;
      }) => {
        tempData.push({
          aadhar: element.tbAadharNo,
        });
        let gridDate = element.tbDob!='' ? this.commonService.formatDate(element.tbDob):null;
       // alert('grid data');
       // console.log('griddata' + this.gridData)
       // alert('grid')
        return this.gridData.push({
          memberName: element.tbName,
          gender: element.tbGender,
          teluguName : element.tbTeluguName,
          dateOfBirth: gridDate,
          familyAadharNo: this.commonService.RSAEncrypt(element.tbAadharNo),
          relationwithHead: element.tbRelation,
          age:element.tbAge,
          mobileNumber:element.tbMobile ? element.tbMobile : this.mobileNo,
          isGovernment: element.tbGovernmentPensioner,
          isPayingIncomeTax: element.tbPayingIncome,
          annualIncome: element.tbAnnualIncome,
          landStatus: element.tbLandStatus,
          wetLand: element.tbWetLand,
          dryLand: element.tbDryLand,
          isPayingPropertyTax: element.tbPayingPropertyTax,
          totalNoOfSqft: element.tbPropertyTax,
          isFourWheeler: element.tbFourWheeler,
          fourWheelerNumbers:element.tbFourWheelerNumbers,
          noOfFourWheelers: element.tbFourWheelerNo,
          MigrationReason:'NEW',
          IsActive:true,
          residentId:element.residentId,
          iS_LIVING_WITHFAMILY:element.iS_LIVING_WITHFAMILY,
          salary:element.tbSalary,
          empCategory:element.tbGovernmentCategory
        });
      }
  
    );
    
    // this.duplicatesRecordsinMember = tempData.some((user) => {
    //   let counter = 0;
    //   for (const iterator of tempData) {
    //     if (iterator.aadhar === user.aadhar) {
    //       counter += 1;
    //     }
    //   }
    //   return counter > 1;
    // });
    // if(!this.duplicatesRecordsinMember){
    //   this.duplicatesRecordsinMember = false;
    // }else{
    //   this.duplicatesRecordsinMember = true;
    //   return
    // }  

    }   
    
     else{
      this.familySubmitted =true;
     }
     
      
     
  }
  addChildData(){
    
    let tempData :any[]= [];
    if(this.Child.valid){    
        this.childSubmitted=false;          
      this.getCitizenInfo()  
  this.AddingChild.value.forEach(
    
    (element: {
      tbAadhar: any;
      tbName: any;
      tbTeluguName: any;
      tbGender: any;
      tbDob: any;
      tbMobile: any;
      tbRelation: any;
      tbAge: any;
      tbPayingIncome: any;
      tbLandStatus: any;
      tbWetLand: any;
      tbDryLand: any;
      tbPayingPropertyTax: any;
      tbPropertyTax: any;
      tbFourWheeler: any;
      tbFourWheelerNo: any;
      tbFourWheelerNumbers:any
      tbGovernmentPensioner: any;
      tbAnnualIncome: any;
      tbReason:any;
      tbisActive:any;
      residentId:any;
      iS_LIVING_WITHFAMILY:any;
      tbGovernmentCategory:any;
      tbSalary:any;
    }) => {
      tempData.push({
        aadhar: element.tbAadhar,
      });
      let gridDate = element.tbDob != '' ? this.commonService.formatDate(element.tbDob):null;
     // console.log('griddata' + this.gridData)
      return this.gridData.push({
        memberName: element.tbName,
        gender: element.tbGender,
        teluguName :  element.tbTeluguName ,
        dateOfBirth: gridDate,
        familyAadharNo: this.commonService.RSAEncrypt(element.tbAadhar),
        relationwithHead: element.tbRelation,
        mobileNumber:element.tbMobile ? element.tbMobile :this.mobileNo,
        age:element.tbAge,
        isGovernment: 'No',
        isPayingIncomeTax: 'No',
        annualIncome: '0',
        landStatus: 'No',
        wetLand: '0',
        dryLand: '0',
        isPayingPropertyTax: 'No',
        totalNoOfSqft: '0',
        isFourWheeler: 'No',
        noOfFourWheelers: '0',
        fourWheelerNumbers:'NO',
        MigrationReason:'NEW',
        IsActive:true,
        residentId:element.residentId,
        iS_LIVING_WITHFAMILY:element.iS_LIVING_WITHFAMILY,
        salary:'0',
        empCategory:''
      });
    }

  );
  
  // this.duplicatesRecordsinMember = tempData.some((user) => {
  //   let counter = 0;
  //   for (const iterator of tempData) {
  //     if (iterator.aadhar === user.aadhar) {
  //       counter += 1;
  //     }
  //   }
  //   return counter > 1;
  // });

  }
  
  
   else{
    this.childSubmitted =true;
   }
   
   

  }
  onKeyup(event:any, i:any){
    let postData: any = {};
   this.aadharvalues=[];
    if(event.target.value.length == 12){
      console.log(event.target.value + 'event')
      postData.aadhaarNumber =  this.commonService.RSAEncrypt(event.target.value);
      // this.commonService
      // .postRequestForWebLand(
      //   this.commonConstants.getCitizenInfo,
      //   postData,
      //   'citizenInfo'
      // )
      // .subscribe({
      //   next: (responseData: any) => {
      //     let getData=[];
      //     getData=responseData?.result[0];
      //     console.log('getById' + JSON.stringify(getData))
        //  for(let i = 0; i < this.AddingMembers.length; i++){
            
            let dataFromSixStep:any=[];
            let fourWheelerDetails:any=[];
            let vehicleNumber:any= '';
            dataFromSixStep = this.sixStepResponseTocheck.filter(o => (o.CITIZEN_ID == event.target.value));
     
            if(dataFromSixStep.length > 0){
            
              if(dataFromSixStep[0]?.FOUR_WHEELER?.toUpperCase() ===  'YES'){
                fourWheelerDetails = this.FourWheeler.filter(s => (s.UID_NUM === event.target.value));
                let counter = 0;
                for(let k =0; k< fourWheelerDetails.length; k++){
                vehicleNumber= vehicleNumber !== '' ? (vehicleNumber + ',' + fourWheelerDetails[k].VEHICLE_NUMBER) :  fourWheelerDetails[k].VEHICLE_NUMBER
                   counter++;      
                  console.log(counter)
                }
                  fourWheelerDetails.fourWheelerNumbers = vehicleNumber; 
                  fourWheelerDetails.noOfFourWheelers=counter.toString();
                }else{
                  fourWheelerDetails.fourWheelerNumbers = '-'; 
                  fourWheelerDetails.noOfFourWheelers='0';     
               }
              let date = dataFromSixStep[0].DOB_DT 
              let showAge:any =0;  
              const convertAge = new Date(this.commonService.formatDate(date));
              const timeDiff = Math.abs(Date.now() - convertAge.getTime());
              showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
            if(showAge > 5){            
            this.AddingMembers.controls[i].get('tbDob')?.patchValue(dataFromSixStep[0].DOB_DT );
            this.AddingMembers.controls[i].get('tbName')?.patchValue(dataFromSixStep[0].CITIZEN_NAME);
            this.AddingMembers.controls[i].get('tbMobile')?.patchValue(dataFromSixStep[0].MOBILE_NUMBER);
            this.AddingMembers.controls[i].get('tbGender')?.patchValue(dataFromSixStep[0].GENDER);
            this.AddingMembers.controls[i].get('residentId')?.patchValue(dataFromSixStep[0].RESIDENT_ID);
            this.AddingMembers.controls[i].get('tbGovernmentPensioner')?.patchValue(dataFromSixStep[0].EMP_STATUS);
            this.AddingMembers.controls[i].get('tbDryLand')?.patchValue(dataFromSixStep[0].DRY_LAND);
            this.AddingMembers.controls[i].get('tbWetLand')?.patchValue(dataFromSixStep[0].WET_LAND);
            this.AddingMembers.controls[i].get('iS_LIVING_WITHFAMILY')?.patchValue(dataFromSixStep[0].IS_LIVING_WITHFAMILY);
            let totalLand=0;
             totalLand = (dataFromSixStep[0].WET_LAND ? Number(dataFromSixStep[0].WET_LAND) : 0) + (dataFromSixStep[0].DRY_LAND ? Number(dataFromSixStep[0].DRY_LAND) : 0);
            this.AddingMembers.controls[i].get('tbLand')?.patchValue(totalLand);
            this.AddingMembers.controls[i].get('tbPropertyTax')?.patchValue(dataFromSixStep[0].CDMA);
            this.AddingMembers.controls[i].get('tbPayingIncome')?.patchValue(dataFromSixStep[0].INCOME_TAX);
            this.AddingMembers.controls[i].get('tbGovernmentCategory')?.patchValue((dataFromSixStep[0]?.EMP_CATEGORY ? dataFromSixStep[0]?.EMP_CATEGORY : '-'));
            this.AddingMembers.controls[i].get('tbSalary')?.patchValue(dataFromSixStep[0].Salary);
            this.AddingMembers.controls[i].get('tbFourWheeler')?.patchValue(dataFromSixStep[0].FOUR_WHEELER);
            this.AddingMembers.controls[i].get('tbFourWheelerNo')?.patchValue(fourWheelerDetails.noOfFourWheelers);
            this.AddingMembers.controls[i].get('tbFourWheelerNumbers')?.patchValue(fourWheelerDetails.fourWheelerNumbers);
            this.AddingMembers.controls[i].get('tbAge')?.patchValue(showAge);
            let obj =
            {
              Txt_English : (dataFromSixStep[0].CITIZEN_NAME).toLowerCase()
            }
            this.commonService.postRequestForWebLand(this.commonConstants.teluguTranslator ,obj).subscribe({
              next: (responseData: any) => {
                this.translatedText = responseData.d[0]
                this.AddingMembers.controls[i].get('tbTeluguName')?.patchValue(this.translatedText);
              }
            }) 
          }else{
            this.commonService.commonErrorPopup("Alert", 'Age should be greater than five years', "md") 
          }  
          } else{
            this.commonService.commonErrorPopup("Alert", 'Adding members should be mapped with above family in GSWS household data', "md")
            return;
          }
            
      //    }
      //     let  aadhar = responseData?.result[0].aadhaarNumber;
      //   },
      //   error: (error) => {
      //     console.log('client side', error);
      //   },
      // })

      let aadhar = event.target.value;
if(this.AddingMembers.length >=2){
this.aadharvalues=[]

  for (let i = 0; i < this.AddingMembers.length; i++){ 
this.aadharvalues.push({aadhar : this.AddingMembers.controls[i].get('tbAadharNo')?.value});
  
}
  this.duplicatesRecordsinMember = this.aadharvalues.some(user => {
    let counter  = 0;
    for (const iterator of this.aadharvalues) {
      if (iterator.aadhar==user.aadhar) {
        counter += 1;
      }
    }
    return counter > 1;
  });
}
this.checkinExistingTable(event.target.value)

if(this.isinCard == true){

  return;

}
      this.checkinHouseHoldData(event.target.value)    

      if(this.isinHousehold ==false){

        return;

       }

      //check for the new valiation for existing UHID
      let familyMembers=[];
      familyMembers.push({'citizen_UID':this.commonService.RSAEncrypt(event.target.value)}); 
      const postDataToCheck = {
        aadharNumbers:familyMembers
      }
      this.commonService.postRequest(this.commonConstants.checkCardStatus ,postDataToCheck).subscribe({
        next: (checkData:any) => {
         if(checkData.result){
          let statusofCard=checkData.result.isExisting;
          let existingApplictionId=checkData.result.existingApplictionId;
          let existingUHID=checkData.result.existingUHID;
          if(statusofCard === true  && (existingUHID != null || existingApplictionId != null)){
            if(existingUHID !== null){ 
              this.commonService.commonErrorPopup("Alert", 'One of the member of this House hold has Health Card with UHID  ' + existingUHID + ' Kindly update the details in existing card using Update Aarogyasri Card Service.', "md");
              this.AddingMembers.controls[i].get('tbAadharNo')?.patchValue('');
            }else{
              this.commonService.commonErrorPopup("Alert", 'Pending at DC with Application id ' + existingApplictionId, "md");
              this.AddingMembers.controls[i].get('tbAadharNo')?.patchValue('');
            }
          }
        }
      }});

       
    }else{
      this.AddingMembers.controls[i].get('tbDob')?.patchValue('');
      this.AddingMembers.controls[i].get('tbAge')?.patchValue('');
      this.AddingMembers.controls[i].get('tbName')?.patchValue('');
      this.AddingMembers.controls[i].get('tbMobile')?.patchValue('');
      this.AddingMembers.controls[i].get('tbGender')?.patchValue('');
      this.AddingMembers.controls[i].get('tbRelation')?.patchValue('');
      this.AddingMembers.controls[i].get('tbTeluguName')?.patchValue('');
      this.AddingMembers.controls[i].get('tbAnnualIncome')?.patchValue('');
      this.AddingMembers.controls[i].get('tbGovernmentPensioner')?.patchValue('');  
      this.AddingMembers.controls[i].get('tbPayingIncome')?.patchValue('');
      this.AddingMembers.controls[i].get('tbFourWheelerNo')?.patchValue('');  
      this.AddingMembers.controls[i].get('tbWetLand')?.patchValue('');
      this.AddingMembers.controls[i].get('tbDryLand')?.patchValue('');  
     this.AddingMembers.controls[i].get('tbLand')?.patchValue('');  
      this.AddingMembers.controls[i].get('tbPropertyTax')?.patchValue('');
      this.AddingMembers.controls[i].get('iS_LIVING_WITHFAMILY')?.patchValue('');
      this.AddingMembers.controls[i].get('tbGovernmentCategory')?.patchValue('');
      this.AddingMembers.controls[i].get('tbSalary')?.patchValue('');
      
      }
  }
  
  onKeyupChild(event:any, i:any){
    let postData: any = {}; 
    if(event.target.value.length == 12){
      //console.log(event.target.value + 'event')
      postData.aadhaarNumber =  this.commonService.RSAEncrypt(event.target.value);

      // this.commonService
      // .postRequestForWebLand(
      //   this.commonConstants.getCitizenInfo,
      //   postData,
      //   'citizenInfo'
      // )
      // .subscribe({
      //   next: (responseData: any) => {
      //     let getData=[];
      //     getData=responseData?.result[0];
          //console.log('getById' + JSON.stringify(getData))
        //  for(let i = 0; i < this.AddingChild.length; i++){
          let dataFromSixStepForChild:any=[];
          dataFromSixStepForChild = this.sixStepResponseTocheck.filter(o => (o.CITIZEN_ID == event.target.value))
          if(dataFromSixStepForChild.length === 1){

         
          let date = dataFromSixStepForChild[0].DOB_DT 
            this.AddingChild.controls[i].get('tbDob')?.patchValue( dataFromSixStepForChild[0].DOB_DT);
            this.AddingChild.controls[i].get('tbName')?.patchValue( dataFromSixStepForChild[0].CITIZEN_NAME);
            this.AddingChild.controls[i].get('tbMobile')?.patchValue(dataFromSixStepForChild[0].MOBILE_NUMBER);
            this.AddingChild.controls[i].get('tbGender')?.patchValue(dataFromSixStepForChild[0].GENDER);
            this.AddingChild.controls[i].get('residentId')?.patchValue(dataFromSixStepForChild[0].RESIDENT_ID);
            this.AddingChild.controls[i].get('iS_LIVING_WITHFAMILY')?.patchValue(dataFromSixStepForChild[0].IS_LIVING_WITHFAMILY);
            let obj =
            {
              Txt_English : (dataFromSixStepForChild[0].CITIZEN_NAME).toLowerCase()
            }
            this.commonService.postRequestForWebLand(this.commonConstants.teluguTranslator ,obj).subscribe({
              next: (responseData: any) => {
                // console.log(responseData, "bbb");
                this.translatedText = responseData.d[0]
                console.log(this.translatedText)
                this.AddingChild.controls[i].get('tbTeluguName')?.patchValue(this.translatedText);
              }
            })
            let showAge:any;  
            const convertAge = new Date(this.commonService.formatDate(date));
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
            console.log("show age" , showAge)
            this.AddingChild.controls[i].get('tbAge')?.patchValue(showAge);
            if(showAge > 5){
            this.isChildAgeaboveFive=true;
             // this.isinHousehold =true;
              const modalRef = this.modalService.open(CommonMsgModalComponent, {
                size: 'md',
              });
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = 'Age Should be less than 5 years';   
              this.AddingChild.controls[i].get('tbAge')?.patchValue('');
              this.AddingChild.controls[i].get('tbDob')?.patchValue('');
              //this.AddingChild.controls[i].get('tbAadhar')?.patchValue('');
            
            }else{
              this.isChildAgeaboveFive= false;
              
            }
          }else{
            this.commonService.commonErrorPopup("Alert", 'Adding members should be mapped with above family in GSWS household data', "md")
            return;
          }
        //  }
      //     let  aadhar = responseData?.result[0].aadhaarNumber;
      //   },
      //   error: (error) => {
      //     console.log('client side', error);
      //   },
      // })
      
      
      if(this.AddingChild.length >=2){
        this.aadharvaluesForChild=[]
          for (let i = 0; i < this.AddingChild.length; i++){ 
        this.aadharvaluesForChild.push({aadhar : this.AddingChild.controls[i].get('tbAadhar')?.value});
          
        }
       
          this.duplicateRecordsinChild = this.aadharvaluesForChild.some(user => {
            let counter  = 0;
            for (const iterator of this.aadharvaluesForChild) {
              if (iterator.aadhar==user.aadhar) {
                counter += 1;
              }
            }
            return counter > 1;
          });
        }
        console.log(this.duplicateRecordsinChild + 'duplicaterecordsin child')
      this.checkinHouseHoldData(event.target.value)   
      if(this.isinHousehold ==false){

        return;

       }

      this.checkinExistingTable(event.target.value)

      if(this.isinCard == true){

        return;

      }

      //check for the new valiation for existing UHID
      let familyMembers=[];
      familyMembers.push({'citizen_UID':this.commonService.RSAEncrypt(event.target.value)}); 
      const postDataToCheck = {
        aadharNumbers:familyMembers
      }
      this.commonService.postRequest(this.commonConstants.checkCardStatus ,postDataToCheck).subscribe({
        next: (checkData:any) => {
         if(checkData.result){
          let statusofCard=checkData.result.isExisting;
          let existingApplictionId=checkData.result.existingApplictionId;
          let existingUHID=checkData.result.existingUHID;
          if(statusofCard === true  && (existingUHID != null || existingApplictionId != null)){
            if(existingUHID !== null){ 
              this.commonService.commonErrorPopup("Alert", 'One of the member of this House hold has Health Card with UHID  ' + existingUHID + ' Kindly update the details in existing card using Update Aarogyasri Card Service.', "md");
              this.AddingChild.controls[i].get('tbAadharNo')?.patchValue('');
            }else{
              this.commonService.commonErrorPopup("Alert", 'Pending at DC with Application id ' + existingApplictionId, "md");
              this.AddingChild.controls[i].get('tbAadharNo')?.patchValue('');
            }
          }
        }
      }});
  }else{
    this.isChildAgeaboveFive=false;
    this.AddingChild.controls[i].get('tbTeluguName')?.patchValue('');
    this.AddingChild.controls[i].get('tbAge')?.patchValue('');
    this.AddingChild.controls[i].get('tbDob')?.patchValue('');    
    this.AddingChild.controls[i].get('tbName')?.patchValue('');
    this.AddingChild.controls[i].get('tbMobile')?.patchValue('');
    this.AddingChild.controls[i].get('tbGender')?.patchValue('');
    this.AddingChild.controls[i].get('tbRelation')?.patchValue('');
    
  }


}
  
  
/*onKeydown(event:any){
  if(event)
{ 
  this.AddFamilyMembers()
}
  }
  onKeydownChild(event:any){
    if(event){
      this.addChildData()
    }
  }*/
  onchangeofAnualIncome(event:any){
   
    let totalIncome= 0
    for (let i = 0; i < this.AddingMembers.length; i++) {
      totalIncome = Number(totalIncome) + Number(this.AddingMembers.controls[i].get('tbAnnualIncome')?.value
        );
  }
  this.memberincome = totalIncome;  
}


onchangeofFourwheeler(e:any){
  if(e.target.value > 0){
    let fourwheeler=e.target.value
     for (let i = 0; i < this.familyDetailsTableDatas.length; i++) {
       if(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value > 0 || fourwheeler >0){
  
  
  
       // this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.patchValue(Number(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value) + Number(fourwheeler));
  
      this.fourWheelerValidation = false;
       }        
     }
     if( this.fourWheelerValidation == false){
      const modalRef = this.modalService.open(CommonMsgModalComponent, {
        size: 'md',
      });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =
      'As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant Total number of four wheeler beyond the eligibility';   
    }else{
       this.fourWheelerValidation = true;
      
    }
      }
  else{
    this.fourWheelerValidation = true;
  }
  }

    checkinHouseHoldData(e:any){
      let aadhar=''   
     this.isinHousehold =true;
    // this.isinCard = false;
     this.isExistMember=[];
     this.isAlreadyInCard=[];
       aadhar =e;
       console.log(JSON.stringify(this.sixStepResponseTocheck)+ 'sixStepResponseTocheck in house hold data')
       this.isExistMember =this.sixStepResponseTocheck.filter(o => (o.CITIZEN_ID == aadhar))
      
      if(this.isExistMember?.length !== 0){
        this.isinHousehold =true;         
       }else{
         this.isinHousehold =false;           
          const modalRef = this.modalService.open(CommonMsgModalComponent, {
            size: 'md',
          });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message =
            'Adding members should be mapped with above family in GSWS household data ';         
       }
     
     
  }

  checkinExistingTable(e:any){
    let aadhar='';   
    this.isAlreadyInCard =[];
    this.isExistMember=[];
    this.isinCard = false;
  // this.isinHousehold =true;
     aadhar =e;
     console.log(JSON.stringify(this.tableData));
     console.log(this.tableData)
     this.isAlreadyInCard =this.tableData.filter(o => (o.familyAadharNo == aadhar) || (o.tbAadharNo == aadhar))
   //  alert(JSON.stringify(this.isAlreadyInCard))
  if(this.isAlreadyInCard?.length !== 0){
  this.isinCard =true;
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
     size: 'md',
  });
  modalRef.componentInstance.title = 'Alert';
  modalRef.componentInstance.message =
     'Member already in HealthCard';  
  
  }
  
  
  else{
    this.isinCard =false;
  }
  }
  
  


Reset(){
this.Member.reset();
this.Member.clearValidators();
this.Member.updateValueAndValidity();
}
ResetChild(){
  this.Child.reset();
  this.Child.clearValidators();
  this.Child.updateValueAndValidity();
}
getVehicleDetails(){
  var sendPostData = {
    CITIZEN_ID: this.commonService.RSAEncrypt(this.f['commonForm']?.value['aadharNo'], true),
  };
  this.commonService.postRequestForWebLand(this.commonConstants.getVehicleDetails, sendPostData,'aarogyaSri').subscribe({
    next: (vehicle:any) => { 
     this.vehicleData =  vehicle.result[0].encryptedData;
     let dataofVechicles;
     if(this.vehicleData){

      let _key = CryptoJS.enc.Utf8.parse('319985531ea3b002');
      let _iv = CryptoJS.enc.Utf8.parse('2a98754723e4445f');
  
      this.decrypted = CryptoJS.AES.decrypt(
        this.vehicleData, _key, {
          keySize: 16,
          iv: _iv
        }).toString(CryptoJS.enc.Utf8);
     dataofVechicles=JSON.parse(this.decrypted)
      this.FourWheeler = dataofVechicles.RTARES;
      console.log(JSON.stringify(this.FourWheeler) + 'fourwheeler in get vechicles')
       

     }

    },
    error: (error) => {
      console.log('client side', error);
    }
  }); 
}
straightThruGenerateCertificateData(){
  this.certificateTypeName = ""
  this.commonConstants.certificateList.forEach((e: any) => {
    if(this.certificateName == e.certName)
    {
      // console.log('e cert name ',e.certID)
      this.certificateTypeName = e.certID
    }
  });
  console.log('this.certificateName ',this.certificateTypeName)

  var sendPostData={
    RequisitionId: this.reqId,
    Department: this.departmentCode,
    CertificateType : this.certificateTypeName
  }
  console.log(sendPostData,'post data')
  this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
    next: (responseData:any) => { 
      if (responseData?.result?.isSuccess) {
          this.straightThruGenerateCertificate()
      } else {
        this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
}
straightThruGenerateCertificate() {
  this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + this.reqId + '&Department=' + this.departmentCode+'&Mode=1').subscribe({
    next: (responseData: any) => {
      if (responseData?.result?.isSuccess) {
        this.showPayment(this.reqId);
      }  else {
        this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
      }
  },
  error: (error) => {
    console.log('client side', error);
  }
});
}
matcher(event:any) {
  const allowedRegex = /[0-9a-zA-Z]/g;
  const special =/[`~,!@#$%^&*<>;':"{}()=_+-]/g;

  if (event.key.match(allowedRegex) || event.key.match(special) ) {
      event.preventDefault();
  }
 
}
cammamatch(event:any){
  const allowedRegex = /[a-zA-Z]/g;
  const special =/[`~,!@#$%^&*<>;':"{}()=_+-]/g;

  if (event.key.match(allowedRegex) || event.key.match(special) ) {
      event.preventDefault();
  }
}
getRelationfromLegacyData(id:any,i:any){
  console.log(JSON.stringify(this.LegacyDataresult) + 'LegacyDataresult')
  let data = this.LegacyDataresult.filter((item:any) => (item.aadhar_No === id)); 
 return data[0]?.relationOriginal;
  
}

getAgeFromLegacyData(id:any,i:any){
  let data = this.LegacyDataresult.filter((item:any) => (item.aadhar_No === id));
 // this.familyDetailsTableDatas.controls[i].get('tbAge')?.patchValue(data[0]?.age);
return data[0]?.age;
}

}





