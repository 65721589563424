import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonConstants } from './../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerPopupComponent } from '../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.css'],
  providers: [DatePipe]
})
export class PaymentReceiptComponent implements OnInit {

  @ViewChild('__printingFrame') __printingFrame: ElementRef | undefined;
  currentDate = new Date();
  commonConstants: any = CommonConstants;
  aadharNum = "";
  pipeLineString: any;
  dateTime: any;
  requisitionInfo: any;
  requisitionID: any;
  serviceName: any;
  transactionID: any;
  applicantName: any;
  applicantFatherName: any;
  villageName: any;
  district: any;
  mandal: any;
  parentIdCS: any;
  landConversionData: any;
  totalAmount: any;
  ApplicantName: any;
  SLA: any;
  paymentMode: any = "UPI";
  uhidDownload: any;
  sadaramId: any;
  userInfo: any = [];
  serviceList: any = [];
  waterTax: any = [];
  departmentCode: any = "RV";
  departmentName: any = "Revenue";
  showPrintCertificate: Boolean = false;
  reIssuanceData: any;
  secretariatName: any;
  amountInwords: any;
  reqType: any;
  is_requestsent: Boolean = false;
  is_categoryA: Boolean = false;
  dataNotAvailableMaud: any;
  certificateTypeName: string = "";
  additonSurvey: any = [];
  rectification: any = [];
  landConversion: any = [];
  commonFormAddedFields: any;
  village: any;
  appType: any;
  VillageId: any;
  // amountToBePaid : any;
  // pattadar_Name : any;
  getByIdResponse: any;
  addGender: any;
  closeResult: string = '';
  stationary_number: string = '';
  stationaryForm: FormGroup;
  submitted: Boolean = false;
  applicantAadhar: any;
  showSuccessTStatus: Boolean = false;
  suomotoApplication: any = '';
  suomotoStatus: any = '';
  sixstepData: {
    age: Number
    annual_income: Number,
    englishName: string,
    citizen_name_ll: string,
    dob: string,
    mobileNumber: string,
    gender: number,
    mobile_number: string,
    occupation_type: Number,
    relationShip: Number,
    aadhaarNumber: string,
  }[] = [];
  sixStepPostData: any;
  dataGrid: any[] = [];
  responseData: any = [];
  basicInfo: any;
  presentAddr: any;
  permanentAddr: any;
  certificateName: string = "";
  requisitionTypeForCertificate: string = "";
  sacretaraitName: any;
  transactionIDForCS: any = "";
  transactionStatus: any = "";
  postingofAmount: any;
  serviceCode: any;
  fileInfoHTMl: any = "";
  print_certificate_mode = 'PDF';
  htmlBtn: boolean = false;

  redirectedFrom: any = "";
  isReissuance: any;
  amountToBePaid: any;
  applicationRequisiontionData: any;
  reIssuanceSubservice: any;
  subServiceType: any;
  noOfCopies: any;
  Copies: any[] = [];
  copiesData!: FormArray;
  duplicateRecords: any;
  hhsplitType: any = null;
  isWhatsAppRequired: boolean = false;
  services: any[] = [];
  requisitionType: string = "";
  khata_Number: any;
  isProdEnv: Boolean = false;
  searchscreenflag: any;
  routedData: any;
  postDataforreceiptpagedata: any = {};
  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private commonService: CommonService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private datepipe: DatePipe
  ) {
    if (/^vswsonline.ap.gov.in/.test(window && window.location && window.location.hostname)) {
      this.isProdEnv = true;
    }
    // this.getDetails();
    this.showPrintCertificate = false;
    this.getServiceListData();

    this.stationaryForm = this.formBuilder.group({
      copiesData: this.formBuilder.array([this.createCopies()]),
      //stationary_number: ["", [Validators.required,Validators.pattern(this.commonConstants.alphanumericPattern)]],
      mode: ['PDF', '']
    });
    this.copiesData = this.stationaryForm.get('copiesData') as FormArray;
    let userData = localStorage.getItem('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      let officeLocation = this.userInfo.officeLocation?.split('-');
      if (officeLocation?.length > 0) {
        this.secretariatName = officeLocation[1];
      }
    }

    let reIssuanceDetails = localStorage.getItem('reIssuanceData');
    if (reIssuanceDetails && reIssuanceDetails !== null) {
      this.reIssuanceData = JSON.parse(reIssuanceDetails);
    }


    //get url params
    this.currentRoute.queryParams.subscribe((params) => {
      this.routedData = params;
      console.log(this.routedData, "payment-receipt");
      this.requisitionID = params?.id;
      this.transactionID = "T" + params?.id;
      this.departmentCode = params?.department;// params?.id.split('-')[0];
      this.redirectedFrom = params?.from;
      console.log(this.redirectedFrom, "this.redirectedFrom")

      //for old transaction CS
      if (params?.transactionID) {
        this.transactionIDForCS = params?.transactionID;
      }
      if (this.departmentCode === "RV") {
        this.getByReqIdDetails(this.requisitionID);
      }
      if (this.departmentCode === "CS") {
        this.departmentName = "Civil Supplies";
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "RS") {
        this.departmentName = "PR & MAUD";
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "RD") {
        //this.departmentName = "Pensions";
        this.departmentName = "Panchayat Raj and Rural Development";
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "MAUD") {
        this.departmentName = "MAUD";
        this.reqType = params.type
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "ESeva") {
        this.departmentName = "ESeva";
        this.reqType = params.type
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "DEE") {
        this.departmentName = "Human Resource";
        this.reqType = params.type
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "MW") {
        this.departmentName = "Minorities Welfare";
        this.reqType = params.type
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "HF") {
        this.departmentName = "Health and Family Welfare";
        this.reqType = params.type;
        this.getByReqIdDetails(this.requisitionID);

      }
      if (this.departmentCode === "LD") {
        this.departmentName = "Labour";
        this.getByReqIdDetails(this.requisitionID);
      }

    });

    if (this.departmentName === "MAUD") {
      //postData = "?RequisitionId="+this.requisitionID+"&DepartmentCode="+this.departmentCode+"&TransactionId="+this.transactionID+"&RequisitionType="+ this.reqType;
      this.postDataforreceiptpagedata = {
        RequisitionId: this.requisitionID,
        DepartmentCode: this.departmentCode,
        TransactionId: this.transactionID,
        RequisitionType: this.reqType
      }
    } else {

      // postData = "?RequisitionId="+this.requisitionID+"&DepartmentCode="+this.departmentCode+"&TransactionId="+this.transactionID;

      if (this.departmentCode === "CS" && this.transactionIDForCS !== '') {
        this.transactionID = this.transactionIDForCS;
        //postData = "?RequisitionId="+this.transactionIDForCS.substring(1)+"&DepartmentCode="+this.departmentCode+"&TransactionId="+this.transactionIDForCS+"&RequisitionIdForTransaction="+this.requisitionID;
        this.postDataforreceiptpagedata = {
          RequisitionId: this.transactionIDForCS.substring(1),
          DepartmentCode: this.departmentCode,
          RequisitionIdForTransaction: this.requisitionID
        }
      } else {
        this.postDataforreceiptpagedata = {
          RequisitionId: this.requisitionID,
          DepartmentCode: this.departmentCode,
          RequisitionIdForTransaction: this.requisitionID
        }
        //postData = "?RequisitionId="+this.requisitionID+"&DepartmentCode="+this.departmentCode+"&TransactionId="+this.transactionID+"&RequisitionIdForTransaction="+this.requisitionID;
      }

    }

    this.commonService
      .postRequest(this.commonConstants.receiptPageData, this.postDataforreceiptpagedata, true)
      .subscribe({
        next: (responseData: any) => {

          this.requisitionInfo = responseData?.result;
          console.log("aaa", this.requisitionInfo);

          if (this.requisitionInfo === "Transaction Details not found" || this.requisitionInfo === "Requisition Not Found") {

            const queryParams: Params = { applicationId: this.requisitionID, msg: 'zero-payment' };
            this.router.navigate(['/payment-response'], {
              relativeTo: this.currentRoute,
              queryParams: queryParams,
              queryParamsHandling: '',
            });

          } else {
            this.dateTime = this.requisitionInfo.transactionDate;
            this.serviceName = this.departmentCode === "ES" ? this.requisitionInfo.serviceName : this.requisitionInfo.serviceCode;
            this.requisitionTypeForCertificate = this.requisitionInfo.serviceCode;
            this.applicantName = this.requisitionTypeForCertificate == 'WaterTaxService' ? this.getByIdResponse?.requisitionData?.paymentRequestsKhataDetails[0]?.pattadar_Name : this.titleCaseWord(this.requisitionInfo.applicantName);
            this.applicantFatherName = this.requisitionTypeForCertificate == 'WaterTaxService' ? this.getByIdResponse?.requisitionData?.paymentRequestsKhataDetails[0]?.pattadar_Father_Name : this.titleCaseWord(this.requisitionInfo.applicantFatherHusbandName);
            this.district = this.departmentCode === "HF" ? this.requisitionInfo.district.toUpperCase() : this.requisitionInfo.district;
            this.mandal = this.requisitionInfo.mandal;
            this.village = this.requisitionInfo.secretariateName;
            this.transactionStatus = this.requisitionInfo.transactionStatus;
            this.amountToBePaid = this.requisitionTypeForCertificate !== 'WaterTaxService' ? this.requisitionInfo.transactionAmount : (this.requisitionTypeForCertificate == 'WaterTaxService') ? Math.round(this.getByIdResponse?.requisitionData?.amountToBePaid) : this.getByIdResponse?.requisitionData?.amountToBePaid;
            this.totalAmount = this.requisitionInfo.transactionAmount;
            this.sacretaraitName = this.requisitionInfo.userName;
            this.paymentMode = (this.requisitionInfo.transactionMode === 'cash') ? 'Cash' : this.requisitionInfo.transactionMode;
            // this.amountInwords = this.intToEnglishWordConverter(this.totalAmount);
            this.amountInwords = this.commonService.decimalWords(this.totalAmount);
            console.log("amount in words", this.amountInwords);
            this.getByEntitycodePayment(this.district, 'District');
            this.getByEntitycodePayment(this.mandal, 'Mandal');
            // this.getByEntitycodePayment(this.village,'Village');
            // console.log(this.village,"village");
            if (this.requisitionInfo.transactionStatus == "0300" || this.requisitionInfo.transactionStatus == "TXN_SUCCESS") {
              this.showSuccessTStatus = true;
            }
            else { this.showSuccessTStatus = false; }

            if (this.requisitionTypeForCertificate === "ROR1BCertificate" || this.requisitionTypeForCertificate === "ComputerizedAdangalCertificate") {
              this.htmlBtn = true;
            }

            const hostname = window && window.location && window.location.hostname;
            // if (/^vswsonline.ap.gov.in/.test(hostname)) {
            console.log(this.serviceName, 'webland this.serviceName')
            if (this.serviceName == 'TitleDeedCumPassbook' && this.redirectedFrom === undefined && this.transactionStatus === "0300") {
              this.updateWorkflowForTPB();
            }
            if (this.serviceName == 'IntegratedCertificate' && this.redirectedFrom === undefined && this.transactionStatus === "0300" && this.suomotoApplication && this.suomotoStatus.toUpperCase() == 'PENDING') {
              let prefix = this.suomotoApplication?.substring(0, 4);
              if (prefix === 'SCGC') {
                this.rejectSuoMotoApplication();
              }
            }
            if (this.serviceName == 'ReIssuanceCertificateNew' && this.redirectedFrom === undefined && this.transactionStatus === "0300" && this.routedData.issearchflag) {
              this.updateMeesevaIntegratedDataSearchScreen();
            }
            // if(this.serviceName == 'WaterTaxService' && this.redirectedFrom === undefined && this.transactionStatus === "0300" ){
            //   this. waterTaxPostingAmount();
            // }
            // posting data to webland for Issuance of BhuHakkuPatram

            // 11-11-2021 commented abhi
            if (this.requisitionInfo.serviceCode == 'IssuanceOfBhuHakkuPatramSyno' || this.requisitionInfo.serviceCode == 'IssuanceOfBhuHakkuPatram' || this.serviceName == 'MutationTitleDeedPPB' || this.serviceName == 'ePassbookReplacementPPB' || this.serviceName == 'ManualAdangalCertificate' || this.serviceName == 'PattadarMobileNoSeed' || this.serviceName == 'LandConversionNewCertificate' || this.serviceName == 'InsertSurveyNumInAdangal' || this.serviceName == 'RoRFormRectification') {
              //restrict for view transaction page
              if (this.redirectedFrom === undefined && this.transactionStatus === "0300") {
                console.log(this.serviceName, 'revenue webland particular serviceName')
                this.postWeblandRequestData();
              }

            }
            if (this.serviceName == 'CorrectionsInBirthCertificate' || this.serviceName == 'CorrectionsInDeathCertificate' || this.serviceName == 'NonAvailabilityBirthApplication' || this.serviceName == 'NonAvailabilityDeathApplication' || this.serviceName == 'ChildNameInclusion') {
              //restrict for view transaction page
              if (this.redirectedFrom === undefined && this.transactionStatus === "0300") {
                console.log(this.serviceName, 'maud webland particular serviceName')
                this.postMAUDWeblandRequestData();
              }
            }
            // if(this.serviceName == 'NewRiceCard' || this.serviceName == 'MemberDeletionRiceCard' || this.serviceName == 'MemberAdditionRiceCard' || this.serviceName == 'SplittingRiceCard' ||this.serviceName == 'SurrenderRiceCard' || this.serviceName == 'CorrectionofWrongAadhaar')
            // {   
            //     //restrict for view transaction page
            //     if( this.redirectedFrom === undefined && this.transactionStatus === "0300"){
            //       console.log(this.serviceName,'civilsupplies webland particular serviceName')
            //       this.postCivilSuppliesWeblandRequestData();
            //     }
            // }


               
          
            this.certificateTypeName = ""
            let count = this.commonConstants.certificateList.filter((item: any) => item.certName === this.serviceName);
            if (count.length === 1) {
              this.certificateTypeName = count[0].certID;
            } else if (count.length > 1) {
              if (this.serviceName == '22AModification' || this.serviceName == 'FLineApplication') {
                ///console.log(this.certificateTypeName,'this.certificateTypeName id')
              } else {
                count.forEach((element: any) => {
                  if (element.subServiceType === responseData.result?.requisitionData?.certificateType) {
                    this.certificateTypeName = element.certID;
                    // console.log(this.certificateTypeName,'this.certificateTypeName id')
                  }
                });
              }

            }


            //get service name
            for (let i = 0; i < this.serviceList?.length; i++) {
              if (this.serviceList[i].serviceCode === this.serviceName) {
                this.serviceName = this.serviceList[i].serviceName;
              }
            }

            //check print option
            //check catA service or not
            for (let i = 0; i < this.commonConstants.categoryAServices.length; i++) {
              console.log(this.commonConstants.categoryAServices[i].serviceCode + "==" + this.requisitionInfo.serviceCode)
              if (this.commonConstants.categoryAServices[i].serviceCode == this.requisitionInfo.serviceCode) {
                this.is_categoryA = true;
                if (this.requisitionInfo.serviceCode === 'UpdateAarogyaSriCard') {
                  this.SLA = "24 Hours";
                } else {
                  this.SLA = "15 Minutes";
                }

                //restrict for view transaction page
                // if( this.redirectedFrom === undefined){
                //   this.sendSMS(this.SLA);
                //   this.sendSMS(this.SLA, 'success');
                // }


                if (this.certificateTypeName.length > 0 || count.length > 0) {
                  this.showPrintCertificate = true;
                } else if (this.requisitionInfo.serviceCode === 'ReIssuanceCertificate') {
                  this.showPrintCertificate = true;
                }
              }
            }


            // if(this.showPrintCertificate == false && this.departmentCode !== "CS"){
            //if(this.departmentCode !== "CS"){


            let isFpShopApplications: boolean = false;

            if (this.requisitionTypeForCertificate == "FPSHOPRENEW" || this.requisitionTypeForCertificate == "FPSHOPRENEW-OTH" || this.requisitionTypeForCertificate == "FPSHOPRENEW-VSKP") {
              isFpShopApplications = true;
            } else {
              isFpShopApplications = false;
            }
            if (this.is_categoryA == false && this.departmentCode !== "CS" && this.departmentCode !== "RD") {
              //this.checkCatBCertificateIsGeneratedOrNOt();
              let isCertificateExist: any = this.commonConstants.certificateList.filter((item: any) => item.certName === this.requisitionInfo.serviceCode)?.length > 0;
              if (this.requisitionTypeForCertificate !== "HousingSiteApplication"
                && this.requisitionTypeForCertificate !== "HousingSiteApplication-URBAN" && isCertificateExist) {
                this.checkCatBCertificateIsGeneratedOrNOt_NEW();
              }
            } else if (this.is_categoryA == false && this.departmentCode == "CS" && isFpShopApplications) {
              let isCertificateExist: any = this.commonConstants.certificateList.filter((item: any) => item.certName === this.requisitionInfo.serviceCode)?.length > 0;
              if (isCertificateExist) {
                this.checkCatBCertificateIsGeneratedOrNOt_NEW();
              }
            }


            if (this.is_categoryA == false) {
              //get SLA for those which having workflows
              this.getSLAData(this.requisitionInfo.serviceCode);
            }

            if (this.departmentName == "MAUD") {
              this.getMAUDdetails()
            }
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }


  ngOnInit(): void { }

  get fc() {
    return this.stationaryForm.controls;
  }

  updateMeesevaIntegratedDataSearchScreen() {
    let postData = {
      requisitionId: this.requisitionID
    }
    this.commonService.postRequest(this.commonConstants.UpdateMeesevaIntegratedData, postData).subscribe({
      next: (responseFromMeeseva: any) => {
        console.log(responseFromMeeseva);
      }
    })
  }

  createCopies() {
    return this.formBuilder.group({
      stationary_number: ["", [Validators.required, Validators.pattern(this.commonConstants.alphanumericPattern)]]
    })
  }

  getByEntitycodePayment(entityCode: any, entityType: any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({
      next: (responseData: any) => {
        let name = responseData.result[0]?.name;
        name = this.titleCaseWord(name);
        if (entityType === 'District') {
          this.district = this.departmentCode === "HF" ? name.toUpperCase() : name;
          this.parentIdCS = responseData.result[0]?.id;
          this.getDistrictCode();
        } else if (entityType === 'Mandal') {
          this.mandal = name;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  casteSeeva: any;
  casteMeeseva(res: any) {
    if (res.requisitionHeader?.applicantInfo?.caste == 'SC') {
      this.casteSeeva = '1';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'ST') {
      this.casteSeeva = '2';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'BC-A') {
      this.casteSeeva = '3';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'BC-B') {
      this.casteSeeva = '4';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'BC-C') {
      this.casteSeeva = '5';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'BC-D') {
      this.casteSeeva = '6';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'BC-E') {
      this.casteSeeva = '16';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'OC') {
      this.casteSeeva = '15';
    }
    if (res.requisitionHeader?.applicantInfo?.caste == 'Others') {
      this.casteSeeva = '9';
    }
  }


  selectedRowValue: any

  // postCivilSuppliesWeblandRequestData(){
  //   // console.log(this.requisitionID,' this.requisitionID')
  // let postData = {
  //   RequisitionId: this.requisitionID,
  //   RequisitionType: this.serviceName,
  //   IncludeFileUploadInfo:true,
  //   IncludePaymentDetails:false
  //   }
  //   this.commonService.postRequest(this.commonConstants.getcivilsuppliesbyId, postData, true
  //    ).subscribe({
  //     next: (responseData: any) => { 
  //      console.log(responseData,'responseData')
  //      this.basicInfo = responseData.result?.requisitionHeader;
  //        //  this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => item.addressType === 'present');
  //           //  this.permanentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => item.addressType === 'permanent');
  //         let civilReqDetails = responseData.result;
  //         let presentAddr = responseData.result?.requisitionHeader?.applicantInfo?.addresses[0];
  //         if(this.basicInfo?.requisitionType === "CorrectionofWrongAadhaar")
  //         {
  //           this.selectedRowValue = responseData.result?.requisitionData?.riceCardFamilyInfoDataList[0]?.aAdharNumber;
  //           console.log(this.selectedRowValue , "selectedRowValue")
  //         }  
  //         else
  //         {this.selectedRowValue = ''}
  //         if(this.basicInfo?.applicantInfo?.aadhaarNumber.length !== 12)
  //         {
  //         this.commonService
  //         .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(this.basicInfo?.applicantInfo?.aadhaarNumber))
  //         .subscribe({
  //           next: (responseData: any) => {
  //             console.log(responseData, 'decrypt value respose data');
  //             this.applicantAadhar  = responseData.result.rawValue;
  //             console.log(this.aadharNum,'this.commonFormAddedFields.aadharNo')
  //             this.getByEntitycodeAsync(presentAddr?.district, 'District', presentAddr, civilReqDetails);

  //           },
  //           error: (error) => {
  //             console.log('client side', error);
  //           },
  //         });
  //         }
  //         else if(this.basicInfo?.applicantInfo?.aadhaarNumber.length === 12)
  //         {
  //           this.applicantAadhar = this.basicInfo?.applicantInfo?.aadhaarNumber;
  //           this.getByEntitycodeAsync(presentAddr?.district, 'District', presentAddr, civilReqDetails);
  //         }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  //   })

  // }

  getMAUDdetails() {
    console.log(">>> C")
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + this.departmentCode + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId=' + this.requisitionID).subscribe({
      next: (responseData: any) => {
        if (responseData && responseData.length !== 0) {
          let certificateName = responseData.result?.requisitionHeader?.requisitionType;
          this.certificateTypeName = ""
          let count = this.commonConstants.certificateList.filter((item: any) => item.certName === certificateName);
          // console.log('count',count,count.length)
          // console.log(responseData.result?.requisitionData?.certificateType,'?.certificateType')
          if (count.length === 1) {
            this.certificateTypeName = count[0].certID;
          } else if (count.length > 1) {
            count.forEach((element: any) => {
              if (element.subServiceType === responseData.result?.requisitionData?.certificateType) {
                this.certificateTypeName = element.certID;
                console.log(this.certificateTypeName, 'mauudd this.certificateTypeName id')
                // if(this.certificateTypeName.length > 0){
                // this.showPrintCertificate = true;
                // }else  {
                // this.showPrintCertificate = false;
                //  }
              }
            });
          }
          this.dataNotAvailableMaud = responseData.result?.requisitionData?.dataNotAvailable;
          console.log(this.dataNotAvailableMaud, "data not available");
          console.log(responseData, "responseData");
          if (responseData.result?.requisitionHeader?.requisitionType == 'MaudBirthDeathCertificate' && !this.dataNotAvailableMaud) {
            console.log("inside show print cert");
            this.showPrintCertificate = true;
            if (responseData.result?.requisitionData?.certificateType == 'birthCertificate') {
              let obj = {
                pOST_BirthCatB1a2_InputMessage: {
                  birthCatB1a2: {
                    rucode: responseData.result?.requisitionData?.registrationUnitId,
                    requestyear: this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear().toString(),
                    mtransid: responseData.result?.id,
                    reqtype: responseData.result?.requisitionHeader?.requisitionType,
                    regyear: responseData.result?.requisitionData?.registrationYear,
                    regno: responseData.result?.requisitionData?.registrationNumber,
                    pdate: responseData.result?.requisitionData?.dateOfBirth,
                    applsurname: responseData.result?.requisitionData?.childSurName,
                    applname: responseData.result?.requisitionData?.childName,
                    applrelation: "",
                    address1: responseData.result?.requisitionData?.address1,
                    address2: responseData.result?.requisitionData?.address2,
                    address3: responseData.result?.requisitionData?.address3,
                    emailid: "",
                    mobileno: "",
                    applremarks: "",
                    userid: "UCRBDS",
                    password: "UCRBDSAPITCGMCMCMPTYGP"
                  }
                }
              }
              this.commonService
                .postRequestForWebLand(this.commonConstants.BirthCatB1a2, obj)
                .subscribe({
                  next: (responseData: any) => {
                    console.log(responseData.pOST_BirthCatB1a2_OutputMessage.birthCatB1a2Response);
                    let response = responseData.pOST_BirthCatB1a2_OutputMessage.birthCatB1a2Response
                  }
                })
            }
            if (responseData.result?.requisitionData?.certificateType == 'deathCertificate') {
              let obj = {
                pOST_DeathCatB1a2_InputMessage: {
                  deathCatB1a2: {
                    rucode: responseData.result?.requisitionData?.registrationUnitId,
                    requestyear: this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear().toString(),
                    mtransid: responseData.result?.id,
                    reqtype: responseData.result?.requisitionHeader?.requisitionType,
                    regyear: responseData.result?.requisitionData?.registrationYear,
                    regno: responseData.result?.requisitionData?.registrationNumber,
                    pdate: responseData.result?.requisitionData?.dateOfDeath,
                    applsurname: responseData.result?.requisitionData?.deceasedSurName,
                    applname: responseData.result?.requisitionData?.deceasedName,
                    applrelation: "",
                    address1: responseData.result?.requisitionData?.address1,
                    address2: responseData.result?.requisitionData?.address2,
                    address3: responseData.result?.requisitionData?.address3,
                    emailid: "",
                    mobileno: "",
                    applremarks: "",
                    userid: "UCRBDS",
                    password: "UCRBDSAPITCGMCMCMPTYGP"
                  }
                }
              }
              this.commonService
                .postRequestForWebLand(this.commonConstants.BirthCatB1a2, obj)
                .subscribe({
                  next: (responseData: any) => {
                    console.log(responseData.pOST_DeathCatB1a2_OutputMessage.deathCatB1a2Response);
                    let response = responseData.pOST_DeathCatB1a2_OutputMessage.deathCatB1a2Response
                  }
                })
            }
          }
          else if (responseData.result?.requisitionHeader?.requisitionType == 'MaudBirthDeathCertificate' && this.dataNotAvailableMaud) {
            if (responseData.result?.requisitionData?.certificateType == 'birthCertificate') {
              let obj = {
                pOST_BirthCatB3_InputMessage: {
                  birthCatB3: {
                    rucode: responseData.result?.requisitionData?.registrationUnitId,
                    requestyear: this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear().toString(),
                    mtransid: responseData.result?.id,
                    pdate: "",
                    applsurname: "",
                    applname: responseData.result?.requisitionHeader?.personNames?.firstName,
                    applrelation: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    emailid: "",
                    mobileno: "",
                    applremarks: "",
                    csurname: responseData.result?.requisitionData?.childSurName,
                    cname: responseData.result?.requisitionData?.childName,
                    msurname: responseData.result?.requisitionData?.motherSurName,
                    mname: responseData.result?.requisitionData?.motherName,
                    fsurname: responseData.result?.requisitionData?.fatherSurName,
                    fname: responseData.result?.requisitionData?.fatherName,
                    dtbirth: responseData.result?.requisitionData?.dateOfBirth,
                    placeofbirth: responseData.result?.requisitionData?.birthPlace,
                    hosname: responseData.result?.requisitionData?.hospitalName,
                    rhaddress1: responseData.result?.requisitionData?.residentAddress,
                    rhaddress2: "",
                    rhaddress3: "",
                    otherplace: responseData.result?.requisitionData?.address1 + responseData.result?.requisitionData?.address2 + responseData.result?.requisitionData?.address3,
                    userid: "UCRBDS",
                    password: "UCRBDSAPITCGMCMCMPTYGP"
                  }
                }
              }
              this.commonService
                .postRequestForWebLand(this.commonConstants.BirthCatB3, obj)
                .subscribe({
                  next: (responseData: any) => {
                    console.log(responseData.pOST_BirthCatB3_OutputMessage.birthCatB3Response);
                    let response = responseData.pOST_BirthCatB3_OutputMessage.birthCatB3Response;
                  },
                });
            }
            if (responseData.result?.requisitionData?.certificateType == 'deathCertificate') {
              let obj = {
                pOST_BirthCatB3_InputMessage: {
                  birthCatB3: {
                    rucode: responseData.result?.requisitionData?.registrationUnitId,
                    requestyear: this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear().toString(),
                    mtransid: responseData.result?.id,
                    pdate: "",
                    applsurname: "",
                    applname: responseData.result?.requisitionHeader?.personNames?.firstName,
                    applrelation: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    emailid: "",
                    mobileno: "",
                    applremarks: "",
                    dsurname: responseData.result?.requisitionData?.deceasedSurName,
                    dname: responseData.result?.requisitionData?.deceasedName,
                    fatherhus: "",
                    fsurname: responseData.result?.requisitionData?.fatherSurName,
                    fname: responseData.result?.requisitionData?.fatherName,
                    msurname: responseData.result?.requisitionData?.motherSurName,
                    mname: responseData.result?.requisitionData?.motherName,
                    dtdeath: responseData.result?.requisitionData?.dateOfDeath,
                    placeofdeath: responseData.result?.requisitionData?.deathPlace,
                    hosname: responseData.result?.requisitionData?.hospitalName,
                    rhaddress1: responseData.result?.requisitionData?.residentAddress,
                    rhaddress2: "",
                    rhaddress3: "",
                    otherplace: responseData.result?.requisitionData?.address1 + responseData.result?.requisitionData?.address2 + responseData.result?.requisitionData?.address3,
                    userid: "UCRBDS",
                    password: "UCRBDSAPITCGMCMCMPTYGP"
                  }
                }
              }
              this.commonService
                .postRequestForWebLand(this.commonConstants.DeathCatB3, obj)
                .subscribe({
                  next: (responseData: any) => {
                    console.log(responseData.pOST_DeathCatB3_OutputMessage.deathCatB3Response);
                    let response = responseData.pOST_DeathCatB3_OutputMessage.deathCatB3Response;
                  },
                });
            }
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  CSDistrictCode: any;

  getDistrictCode() {
    this.commonService.getRequest(this.commonConstants.getCSDistrictCode + this.parentIdCS).subscribe({
      next: (responseData: any) => {
        this.CSDistrictCode = responseData.result[0]?.entityCode;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }


  wFlowUserInfo: any
  wFlowSLA: any
  wFlowGetSLAData(ServiceCode: any, res: any, weblandResponseStatus: any, weblandPostData: any) {
    this.commonService
      .getRequest(this.commonConstants.getSLAData + "?ServiceCode=" + ServiceCode)
      .subscribe({
        next: (responseData: any) => {
          if (responseData?.result != "") {
            if (responseData?.result === "No applicable workflow found") {
              this.wFlowSLA = "15Minutes";
            } else {
              this.wFlowSLA = responseData.result;
            }
            console.log('this.SLA', this.wFlowSLA)
            this.workflowStatusUpdate(res, weblandResponseStatus, weblandPostData)
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  workflowStatusUpdate(responseData: any, weblandResponseStatus: string, weblandPostData: any) {
    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "-" + month + "-" + year
    let createdDate = moment(dateFormat, "DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
    let reqType = responseData.requisitionHeader.requisitionType
    let dpCode = responseData.requisitionHeader.department
    let resID = responseData.id
    let weblandMsg: any = '1';
    let weblandStatus = 'Forwarded to Webland Team'

    if (this.departmentCode === "CS") {
      console.log(this.response_code, weblandResponseStatus, 'this.response_code for cs')
      if (this.response_code == 200 || this.response_code == 201) {
        weblandMsg = '1';
        weblandStatus = 'Forwarded to NIC Team'
      } else {
        weblandMsg = '-1';
        weblandStatus = 'Not Forwarded to NIC Team'
      }
    }
    else if (this.departmentCode === 'RV' && reqType === 'WaterTaxService') {
      if (this.postingofAmount == 'Success') {
        weblandMsg = '1';
        weblandStatus = 'Data Posted to Department'
      }
      else {
        weblandMsg = '-1';
        weblandStatus = 'Not Posted to Department'
      }
    }
    else {
      if (weblandResponseStatus.includes('uccess')) {
        weblandMsg = '1';
        weblandStatus = 'Forwarded to Webland Team'
      } else {
        weblandMsg = '-1';
        weblandStatus = 'Not Forwarded to Webland Team'
      }
    }
    let stringToHash = resID + '&' +
      dpCode + '&' +
      '' + '&' + '' + '&' + '' + '&' + weblandResponseStatus + '-' + this.response_code + '&' + reqType + '&' +
      this.wFlowSLA + '&' + weblandMsg + '&' + weblandStatus + '&' + '' + '&' + this.userInfo.userPrincipalName + '&' + createdDate;

    // this.wFlowSLA+ '&' + '1&Forwarded to Webland Team&' +''+'&' + this.userInfo.userPrincipalName + '&' + createdDate;
    let postData: any = {};
    postData = {
      strToHash: stringToHash
    }

    console.log('update', postData);
    // this.commonService.postRequest(this.commonConstants.postWorkFlowGetHmacHash, postData).subscribe({
    //   next: (responseData:any) => { 
    //     console.log('response from GetHmacHash', responseData)
    //     if(responseData['result'].reason == 'Success'){
    let workFlowPostData: any = {}
    workFlowPostData = {
      workFlowStatusData: {
        createdBy: this.userInfo.userPrincipalName,
        created: createdDate,
        lastModifiedBy: this.userInfo.userPrincipalName,
        lastModified: createdDate,
        id: 0,
        requisitionId: resID,
        departmentCode: dpCode,
        requisitionType: reqType,
        subRequisitionType: '',
        statusCode: weblandMsg,
        statusDesc: weblandStatus, //'Forwarded to Webland Team',
        requisitionStatus: this.requisitionTypeForCertificate == 'WaterTaxService' ? JSON.stringify(this.postingofAmount) : this.response_code + '-' + weblandResponseStatus != undefined || null ? weblandResponseStatus : '',
        slaDays: this.wFlowSLA,
        officerId: '',
        officerName: '',
        officerMobileNumber: '',
        additionalInfo1: this.requisitionTypeForCertificate == 'WaterTaxService' ? JSON.stringify(weblandPostData) : weblandPostData,
        additionalInfo2: '',
        additionalInfo3: ''
      },
      hash: '',  //responseData['result'].hashRes
    }
    console.log('req body from workFlowPostData', workFlowPostData)
    this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData).subscribe({
      next: (responseData: any) => {
        console.log('response from postWorkFlowStatusUpdate', responseData)
        if (responseData['result'].reason == 'Success') {
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
    //     }

    //   },
    //   error: (error) => {
    //     console.log('client side', error);
    //   }
    // });

  }


  getServiceListData() {
    this.serviceList = [];
    this.commonService
      .getRequest(this.commonConstants.getServiceList)
      .subscribe({
        next: (responseData: any) => {
          this.serviceList = responseData.result;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  sendSMS(SLA: any, payment?: any) {
    let templateId: any;
    let message: any;
    // if(payment === 'success') {
    //  this.sendWhatsAppMsg(SLA);
    //  if(this.is_categoryA) {
    //   this.sendWhatsAppMsg(SLA, 'approve');
    //  }
    if (payment === 'success' && this.is_categoryA && this.isWhatsAppRequired) { //uncommented on 26 July 2023
      this.sendWhatsAppMsg(SLA, 'register');
      this.sendWhatsAppMsg(SLA, 'approve');
    }
    if (payment === 'success' && this.isWhatsAppRequired && !this.is_categoryA && this.requisitionType !== 'RectificationOfResurveyedRecords') {
      //this.sendWhatsAppMsg(SLA, 'register');
      message = `Dear Applicant, Your payment made for Application No:(` + this.requisitionID + `) has been successful. Your payment transaction No is (` + this.transactionID + `). Please download receipt from following link : https://vswsonline.ap.gov.in -GOVTAP`;
      templateId = '1007423571792564443';
    } else if (payment === 'success' && this.requisitionType === 'RectificationOfResurveyedRecords') {
      templateId = '1307170495765234954';
      message = `Dear ` + this.applicantName +
        `, Your application ` + this.requisitionID + ` is applied and forwarded to ` + this.villageName + ` Village Surveyor for further enquiry.GOVTAP`;
    }
    else {
      SLA = this.is_categoryA ? SLA + ' / 0 ' : SLA;
      message = `Dear Applicant, Your request for ` + this.serviceName?.toUpperCase() + ` has been registered vide Application No:(` + this.requisitionID + `), Transaction No:(` + this.transactionID + `) and your application will be processed in (` + SLA + `) days. You can track your application status at this link : https://vswsonline.ap.gov.in -GOVTAP`;
      templateId = '1007261373508820491';
    }
    let postData: any = {};
    postData.phoneNo = [this.requisitionInfo?.mobileNumber];
    postData.templateId = templateId;
    postData.message = message;
    postData.department = this.departmentCode;
    postData.referenceId = this.requisitionID;
    postData.referenceType = this.requisitionInfo?.serviceCode;
    postData.isBulkSend = false;
    postData.userId = this.userInfo?.userPrincipalName;
    console.log('postdata for SMS', postData);
    console.log('iswhatsapp required', this.isWhatsAppRequired);
    this.commonService.postRequest(this.commonConstants.sendSMS, postData).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'response from SMS');
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  sendWhatsAppMsg(SLA: any, from?: any) {
    console.log("check");
    console.log('iswhatsapp required', this.isWhatsAppRequired);
    let postData: any = {};
    let msgVariables: any = {};
    msgVariables.applicantName = this.titleCaseWord(this.requisitionInfo?.applicantName)?.trim();
    msgVariables.referenceId = this.requisitionID;
    msgVariables.transactionId = this.transactionID;
    msgVariables.referenceType = this.serviceName;
    msgVariables.slaDays = SLA;
    msgVariables.mediaURL = 'Text';
    
    
    if(from && from === 'register'){
      postData.templateType = "ApplicationRegistration"
    }else if(from && from === 'approve') {
    if(this.serviceName == 'Duplicate of OBC/EBC/Integrated Certificates'){
      postData.templateType = 'ApplicationApprovedWithoutCert' ;
    }else{
    if ((this.showPrintCertificate && this.showSuccessTStatus) && (this.requisitionInfo?.serviceCode !== 'SadaremPrintCertificate'
      || this.requisitionInfo?.serviceCode === 'PrintCertificate') && (this.departmentCode != 'HF')) {
      postData.templateType = 'ApplicationApproved';

    } else {
      postData.templateType =  'ApplicationApprovedWithoutCert';
    }
    }
  }
    postData.messageVariables = msgVariables;
    postData.phoneNo = this.requisitionInfo?.mobileNumber;
    postData.department = this.departmentCode;
    postData.isBulkSend = false;
    postData.userId = this.userInfo.userPrincipalName;

    this.commonService.postRequest(this.commonConstants.sendWhatsAppMsg, postData).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'response from WhatsAPP');
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  convertDataURIToBinary(base64: any): any {
    // var base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
    // var base64 = dataURI.substring(base64Index);
    // console.log(base64,'base 64 ')
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let index = 0; index < rawLength; index++) {
      array[index] = raw.charCodeAt(index);

    }
    console.log('array byte ', array)
    return array;
  }
  // getDetails(){
  //   this.commonService.getRequest(this.commonConstants.getCertificateDetails  + 'CS&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ this.requisitionID).subscribe({
  //     next: (responseData: any) => { 
  //       console.log("Respose",responseData);

  //       this.basicInfo = responseData.result?.requisitionHeader;
  //       this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => item.addressType === 'present');
  //       this.permanentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => item.addressType === 'permanent');
  //       console.log(responseData.result.requisitionHeader,"reqheader");
  //       console.log(responseData.result.requisitionHeader.applicantInfo?.addresses,"address");
  //     }
  //   })
  // }




  districts: any;
  mandals: any;
  villages: any;
  getByEntitycode(entityCode: any, entityType: any, presentAddr: any, result: any) {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({
      next: (responseData: any) => {
        let name = responseData.result[0]?.name;
        if (entityType === 'District') {
          this.districts = name;
          console.log(this.districts);

          this.getByEntitycode(presentAddr?.mandalMunicipality, 'Mandal', presentAddr, result)
        } else if (entityType === 'Mandal') {
          this.mandals = name;
          console.log(this.mandals);

          this.getByEntitycode(presentAddr?.villageWard, 'Secretariat', presentAddr, result)
        }
        else if (entityType === 'Secretariat') {
          this.villages = name;
          console.log(this.villages);
          if (this.serviceName === 'Mutation TitleDeed PPB' || this.serviceName === 'MutationTitleDeedPPB') {
            //mutationandtitledeedvumpassbook
            this.weblandPostingForMutationandTitleDeed(result);

          } else if (this.serviceName === 'ePassbook Replacement PPB' || this.serviceName === 'ePassbookReplacementPPB' || this.serviceName === 'ePassbookReplacementPPB') {
            //epassbook
            this.weblandPostingForEpassbookReplacement(result);


          }
          // else if(this.serviceName === 'Manual Adangal Certificate' || this.serviceName === 'ManualAdangalCertificate'){
          //   this.weblandPostingForOldAdangal(result);
          // }

        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });

  }


  getSLAData(ServiceCode: any) {

    this.commonService
      .getRequest(this.commonConstants.getSLAData + "?ServiceCode=" + ServiceCode)
      .subscribe({
        next: (responseData: any) => {
          if (responseData?.result != "") {
            if (responseData?.result === "No applicable workflow found" || responseData?.result === "No applicable ServiceConfig found") {
              if (ServiceCode === 'UpdateAarogyaSriCard') {
                this.SLA = "24 Hours";
              } else {
                this.SLA = "15 Minutes";
              }
            } else {
              this.SLA = responseData.result;
            }

            if (this.redirectedFrom === undefined) {
              console.log(this.redirectedFrom, "=======>redirectedFrom")
              this.sendSMS(this.SLA);
              this.sendSMS(this.SLA, 'success');
            }
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  checkCatBCertificateIsGeneratedOrNOt() {
    this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.requisitionID + '&DepartmentCode=' + this.departmentCode).subscribe({
      next: (responseData: any) => {
        console.log(responseData?.result?.isSuccess, 'showing status of signed certificate respose data');

        //For cat B
        if (responseData?.result?.isSuccess && this.is_categoryA == false && responseData?.result?.certificateData?.status == 2) {
          console.log("FOr Cat B");
          this.showPrintCertificate = true;
        }

        //For cat A
        //regenerate certificate
        /*  if(this.is_categoryA == true && responseData?.result?.certificateData?.status != 1) {
        console.log("FOr Cat A>>>",responseData?.result?.certificateData?.status);
        this.straightThruGenerateCertificateData(); 
      } */

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  checkCatBCertificateIsGeneratedOrNOt_NEW() {
    this.commonService.getRequest(this.commonConstants.getApprovedWorkItem + "requisitionId=" + this.requisitionID + '&departmentCode=' + this.departmentCode).subscribe({
      next: (responseData: any) => {

        //For cat B
        /*  if(responseData?.result?.assignedTasks){
        if(responseData?.result?.assignedTasks.length == 1 && this.is_categoryA == false) {
          console.log("For Cat B enable print button");
          this.showPrintCertificate = true;
         }
        }
        */

        console.log("For Cat B enable print button status", responseData?.result?.isApproved);
        if (responseData?.result?.isApproved == true && this.is_categoryA == false && this.requisitionTypeForCertificate !== 'ManualAdangalCertificate') {
          console.log("For Cat B enable print button");
          this.showPrintCertificate = true;
        } else if (responseData?.result?.isRejected == true && this.is_categoryA == false && this.requisitionTypeForCertificate === 'ManualAdangalCertificate') {
          this.showPrintCertificate = true;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  downLoadCertificate(content: any) {
    console.log('this.requisitionID', this.serviceName, this.requisitionID);
    /* if (this.requisitionTypeForCertificate === 'ReIssuanceCertificate' && this.reIssuanceData?.serviceType === 'ReIssuanceCertificate' && !this.reIssuanceData?.isAppFromMeeSeva) {
      this.requisitionID = this.reIssuanceData?.applicationNo;
      console.log('this.requisitionID >><<<<>>>>>>', this.requisitionTypeForCertificate,this.requisitionID);
      this.downloadCertificateFromGSWS();
    } else if (this.reIssuanceData?.isAppFromMeeSeva && this.requisitionTypeForCertificate === 'ReIssuanceCertificate') {
      this.downLoadCertificateFromMeeSeva() */

    if (this.requisitionTypeForCertificate === 'ReIssuanceCertificate' && this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'ReIssuanceCertificate' && !this.applicationRequisiontionData?.requisitionData?.isAppFromMeeSeva) {
      this.requisitionID = this.applicationRequisiontionData?.requisitionData?.applicationNo;
      console.log('this.requisitionID >><<<<>>>>>>', this.requisitionTypeForCertificate, this.requisitionID);
      this.downloadCertificateFromGSWS();
    } else if (this.applicationRequisiontionData?.requisitionData?.isAppFromMeeSeva && this.requisitionTypeForCertificate === 'ReIssuanceCertificate') {
      this.downLoadCertificateFromMeeSeva()

    } else if (this.departmentCode === 'MAUD') {
      this.downLoadCertificateForMAUD()
    }
    else {

      let htmlCertificate = ['PrintingOfTitleDeedCumPassbook', 'ROR1BCertificate', 'ComputerizedAdangalCertificate',
        'ReIssuanceCertificateNew'];

      if (this.is_categoryA == true && htmlCertificate.includes(this.requisitionTypeForCertificate)) {
        //for Cat A HTMl certficate
        this.htmlDownloadCertificateFromGSWS(content);
      } else {
        //for Cat B PDF certficate
        this.downloadCertificateFromGSWS();
      }
    }
  }

  downLoadCertificateForMAUD() {
    var sendPostData = {
      requisitionId: this.requisitionID,
      department: this.departmentCode,
      certificateType: this.certificateTypeName,
      requisitionType: 'MaudBirthDeathCertificate'
    }
    //console.log(sendPostData,'post data')
    this.commonService.postRequestForWebLand(this.commonConstants.maudPostforApprovedCertificate, sendPostData).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'post respose data for showing certificate');
        // console.log(responseData.result.certificateId,'CertificateId');
        this.getCertificate(responseData.result.certificateId, this.departmentCode)
      },
      error: (error) => {
        // this.isSigning = false;

        console.log('client side', error);
      }
    });
  }

  getCertificate(certificateId: any, departmentName: string) {
    // console.log('certificateId',certificateId,departmentName)
    this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + certificateId + '&Department=' + departmentName).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'get data for showing certificate respose data');
        this.downloadCertificateFromGSWS()
        //  this.downloadCertId  = responseData.result.fileName;  //certificateId;
        //  this.isApproveExist = false;
        //   this.signDoc();
        //this.commonService.downloadPdfFile(responseData.result.fileContents, "Signed_Certificate")
      },
      error: (error) => {
        // this.isSigning = false;

        console.log('client side', error);
      }
    });
  }

  select_print_certificate_mode(mode: any) {
    this.print_certificate_mode = mode;
    console.log(this.print_certificate_mode, "mode")
  }

  downloadCertificateFromGSWS() {

    let IsSigned = false;
    if (this.is_categoryA == false) {
      IsSigned = true;
    }

    // if(this.requisitionTypeForCertificate === 'ReIssuanceCertificate'){
    //   this.requisitionID
    // }
    if (this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'PrintCertificate' || this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'PrintCertificateForAarogyaSri') {
      this.requisitionID = this.applicationRequisiontionData?.requisitionData?.applicationNo;
      this.departmentCode = this.applicationRequisiontionData?.requisitionData?.department;
    }
    this.commonService.getRequest(this.commonConstants.getSignedCertificateData + this.requisitionID + '&DepartmentCode=' + this.departmentCode + '&IsSigned=' + IsSigned).subscribe({
      next: (responseData: any) => {

        console.log('responseData.result.fileContents', responseData)

        if (responseData.result.isSuccess == true) {

          let fileInfo = responseData.result.fileContents;
          const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
          modalRef.componentInstance.sourceFile = fileInfo;
          if (this.departmentCode == 'HF') {
            modalRef.componentInstance.fileName = this.uhidDownload;
          } else {
            modalRef.componentInstance.fileName = responseData.result.fileDownloadName;
          }
          modalRef.componentInstance.showPrintDownload = true;

        } else {

          //Cat B
          if (this.is_categoryA == false) {
            /*   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = 'PDF is not generated for this Application'; */
            this.regenerateCatBCertificateWithOutSign();
          }

          //Cat A PDF
          if (this.is_categoryA == true) {
            this.straightThruGenerateCertificateData("CatA");
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });

  }

  regenerateCatBCertificateWithOutSign() {

    let IsSigned = false;
    if (this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'PrintCertificate' || this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'PrintCertificateForAarogyaSri') {
      this.requisitionID = this.applicationRequisiontionData?.requisitionData?.applicationNo;
      this.departmentCode = this.applicationRequisiontionData?.requisitionData?.department;
    }
    this.commonService.getRequest(this.commonConstants.getSignedCertificateData + this.requisitionID + '&DepartmentCode=' + this.departmentCode + '&IsSigned=' + IsSigned).subscribe({
      next: (responseData: any) => {
        console.log('responseData.result.fileContents', responseData)
        if (responseData.result.isSuccess == true) {

          let fileInfo = responseData.result.fileContents;
          const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
          modalRef.componentInstance.sourceFile = fileInfo;
          if (this.departmentCode == 'HF') {
            modalRef.componentInstance.fileName = this.uhidDownload;
          } else {
            modalRef.componentInstance.fileName = responseData.result.fileDownloadName;
          }
          modalRef.componentInstance.showPrintDownload = true;

        } else {

          this.straightThruGenerateCertificateData("CatB");
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  straightThruGenerateCertificateData(certitificateType: any) {

    if (this.departmentCode == "RV") {

      this.getCertficateTypeName(this.requisitionID, certitificateType);

    } else {

      var sendPostData = {
        RequisitionId: this.requisitionID,
        Department: this.departmentCode,
        CertificateType: this.certificateTypeName
      }
      console.log(sendPostData, 'post data')
      this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
        next: (responseData: any) => {
          //this.straightThruGenerateCertificate(certitificateType);
          if (responseData.result.isSuccess == true) {
            this.straightThruGenerateCertificate(certitificateType);
          } else {

            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Certificate data not available..!!';
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }

  }

  getCertficateTypeName(reqId: any, certType: any) {

    console.log(">>> A")
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + this.departmentCode + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId=' + reqId).subscribe({
      next: (responseData: any) => {

        console.log(responseData, 'responseData certificateTypeName')
        let count = this.commonConstants.certificateList.filter((item: any) => item.certName === this.requisitionTypeForCertificate);
        if (count.length === 1) {
          this.certificateTypeName = count[0].certID;
        } else if (count.length > 1) {
          if (this.requisitionTypeForCertificate == '22AModification' || this.requisitionTypeForCertificate == 'FLineApplication') {
          } else {
            count.forEach((element: any) => {
              if (element.subServiceType === responseData.result?.requisitionData?.certificateType) {
                this.certificateTypeName = element.certID;
                console.log(this.certificateTypeName, 'this.certificateTypeName id')
              }
            });
          }
        }

        //genreate certifacate
        var sendPostData = {
          RequisitionId: this.requisitionID,
          Department: this.departmentCode,
          CertificateType: this.certificateTypeName
        }
        console.log(sendPostData, 'post data')
        this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
          next: (responseData: any) => {
            // this.straightThruGenerateCertificate(certType);
            if (responseData.result.isSuccess == true) {
              this.straightThruGenerateCertificate(certType);
            } else {

              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = 'Certificate data not available..!!';
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
        });

      },
      error: (error) => {
        console.log('client side', error);
      }
    });


  }

  straightThruGenerateCertificate(certitificateType: any) {
    this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + this.requisitionID + '&Department=' + this.departmentCode).subscribe({
      next: (responseData: any) => {

        if (certitificateType === "CatB") {
          this.regenerateCatBCertificateWithOutSign();
        } else {
          this.downloadCertificateFromGSWS();
        }
      },
      error: (error) => {

        console.log('client side', error);
      }
    });
  }

  downLoadCertificateFromMeeSeva() {
    let body = `<?xml version="1.0" encoding="utf-8"?>
    <Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">
     <Body>
     <VSWS_GETREISSUANCEDETAILS_PDF xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://AP.org/">
           <USERID>VSWS-APTS</USERID>
           <PASSWORD>P$W$@13112019</PASSWORD>
           <TOKENID>` + this.reIssuanceData?.token + `</TOKENID>
           <SERVICEID>` + this.reIssuanceData?.serviceId + `</SERVICEID>
           <APPNO>` + this.reIssuanceData?.applicationNo + `</APPNO>
           <GSWSAPPNO>` + this.requisitionID + `</GSWSAPPNO>
      </VSWS_GETREISSUANCEDETAILS_PDF>
     </Body>
  </Envelope>`;
    this.commonService.postSoapRequest(this.commonConstants.getTokenFromMeeseva, body, 'VSWS_GETREISSUANCEDETAILS_PDF').subscribe({
      next: (resData: any) => {
        console.log(resData, 'response');
        let responseData = JSON.parse(resData.getElementsByTagName('VSWS_GETREISSUANCEDETAILS_PDFResult')[0].innerHTML);
        console.log('responseData', responseData);
        if (responseData.remarks === 'Success') {
          console.log(responseData.Json, 'file');
          const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
          modalRef.componentInstance.sourceFile = responseData.Json;
          modalRef.componentInstance.fileName = '';
          modalRef.componentInstance.showPrintDownload = true;
        } else {
          console.log('file not found');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  postWeblandRequestData() {
    console.log(">>> b")
    console.log(this.requisitionID, ' this.requisitionID')
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + 'RV&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId=' + this.requisitionID).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'responseData')
        if (responseData && responseData.length !== 0) {
          console.log('this.requisitionID', responseData.result)
          console.log('before webland this.serviceName', this.serviceName)

          // this.decryptAadharNum(responseData?.result?.requisitionHeader?.applicantInfo?.aadhaarNumber)
          if (this.serviceName === 'Mutation TitleDeed PPB' || this.serviceName === 'MutationTitleDeedPPB') {
            //mutationandtitledeedvumpassbook
            let presentAddr = responseData.result?.requisitionHeader?.applicantInfo?.addresses[1];
            // console.log(presentAddr);



            // if(responseData.result?.requisitionData?.serviceType === 'Mutation for Corrections') {
            //     this.weblandPostingForRectificationOfEntries(responseData.result);
            // } else if(responseData.result?.requisitionData?.serviceType === 'Mutation for Transactions') {
            //   this.casteMeeseva(responseData.result);
            //   this.getByEntitycode(presentAddr?.district, 'District', presentAddr, responseData.result);
            // } else if(responseData.result?.requisitionData?.serviceType === 'Mutation for Transactions with Corrections') {
            //   this.weblandPostingForRectificationOfEntries(responseData.result);
            //   this.casteMeeseva(responseData.result);
            //   this.getByEntitycode(presentAddr?.district, 'District', presentAddr, responseData.result);
            // }
          } else if (this.serviceName === 'ePassbook Replacement PPB' || this.serviceName === 'ePassbookReplacementPPB' || this.serviceName === 'ePassbookReplacementPPB') {
            //epassbook
            let presentAddr = responseData.result?.requisitionHeader?.applicantInfo?.addresses[1];
            this.getByEntitycode(presentAddr?.district, 'District', presentAddr, responseData.result);
          }
          // else if(this.serviceName === 'Manual Adangal Certificate' || this.serviceName === 'ManualAdangalCertificate'){
          //   //manualadangal
          //   let presentAddr = responseData.result?.requisitionHeader?.applicantInfo?.addresses[1];
          //   this.getByEntitycode(presentAddr?.district, 'District', presentAddr, responseData.result);

          // }
          else if (this.serviceName === 'PattadarMobileNoSeed' || this.serviceName === 'Pattadar MobileNo Seed') {
            //updateMobileinPattadar
            this.weblandPostingForPattadarMobileNoSeeding(responseData.result);
          } else if (this.serviceName === 'LandConversionNewCertificate' || this.serviceName === 'Land Conversion New Certificate') {
            //landconversion
            this.weblandPostingForLandConversion(responseData.result);
          } else if (this.serviceName === 'Addition Of Survey No In Adangal And 1B' || this.serviceName === 'InsertSurveyNumInAdangal') {
            //additonSurvey
            this.weblandPostingForAdditonSurvey(responseData.result);
          } else if (this.serviceName === 'RoRFormRectification') {
            //additonSurvey
            this.weblandPostingForRectificationOfEntries(responseData.result);
          } else if (this.requisitionInfo.serviceCode === 'IssuanceOfBhuHakkuPatram' || this.requisitionInfo.serviceCode === 'IssuanceOfBhuHakkuPatramSyno') {
            this.postDataForIssuanceOfBhuHakkuPatram(responseData.result);
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    })

  }

  postMAUDWeblandRequestData() {
    console.log(this.requisitionID, ' this.requisitionID')
    this.commonService.getRequest(this.commonConstants.getMaudRequisitionById +
      'RequisitionId=' + this.requisitionID + "&RequisitionType=MAUD", 'MAUD'
    ).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'responseData')
        if (responseData && responseData.length !== 0) {
          console.log('this.requisitionID for webland', responseData.result)
          console.log('this.serviceName for webland', this.serviceName)
          // this.decryptAadharNum(responseData?.result?.requisitionHeader?.applicantInfo?.aadhaarNumber)
          if (this.serviceName === 'NonAvailabilityBirthApplication') {
            this.postBirthNonAvailabilityMethod(responseData.result);
          } else if (this.serviceName === 'NonAvailabilityDeathApplication') {
            this.postDeathNonAvailabilityMethod(responseData.result);
          } else if (this.serviceName === 'Corrections In Birth Certificate' || this.serviceName === 'CorrectionsInBirthCertificate') {
            this.weblandPostingForCorrectionsInBirthCertificate(responseData.result.requisition);
          } else if (this.serviceName === 'Corrections In Death Certificate' || this.serviceName === 'CorrectionsInDeathCertificate') {
            this.weblandPostingForCorrectionsInDeathCertificate(responseData.result.requisition);
          } else if (this.serviceName === 'Child Name Inclusion') {
            console.log("childname");
            this.webpostBirthNameInclusion(responseData.result);
          }

        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    })

  }

  civilDistrict: any;
  civilMandal: any;
  civilVillage: any;
  getByEntitycodeAsync(entityCode: any, entityType: any, presentAddr: any, result: any) {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({
      next: (responseData: any) => {
        let name = responseData.result[0]?.name;
        if (entityType === 'District') {
          this.civilDistrict = name;
          this.getByEntitycodeAsync(presentAddr?.mandalMunicipality, 'Mandal', presentAddr, result)
        } else if (entityType === 'Mandal') {
          this.civilMandal = name;
          this.getByEntitycodeAsync(presentAddr?.villageWard, 'Secretariat', presentAddr, result)
        }
        else if (entityType === 'Secretariat') {
          this.civilVillage = name;
          this.getDetails(result);
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });

  }
  getDetail: any[] = [];
  hofName: any;
  getDetails(response: any) {
    if (response?.requisitionData?.riceCardNumber.includes("T") || response?.requisitionData?.riceCardNumber.length > 10) {
      console.log("navaskam flow")
      for (let i = 0; i < response?.requisitionData?.riceCardMembersDataList.length; i++) {
        if (response?.requisitionData?.riceCardMembersDataList[i].relationShip.includes("SELF")) {
          this.hofName = response?.requisitionData?.riceCardMembersDataList[i].englishName;
          console.log(this.hofName, "hofName1")
        }
      }
      this.weblandpostSurrender(response);
    }
    else {
      let obj =
      {
        rice_card_no: response?.requisitionData?.riceCardNumber
      }
      this.commonService.postRequest(this.commonConstants.getRiceCardMemberDetails, obj).subscribe({
        next: (responseData: any) => {
          console.log(responseData.result, "riceCardDetails");
          this.getDetail = responseData['result'].rice_card_mem_details;
          for (let i = 0; i < this.getDetail.length; i++) {
            if (this.getDetail[i].relation.includes("SELF")) {
              this.hofName = this.getDetail[i].name
              console.log(this.hofName, "hofName2")
            }
          }
          this.weblandpostSurrender(response);

        }
      })
    }
  }

  response_code: any;
  occType: any;
  relationGrid: any;
  casteNic: any; DOB: any;
  // DistrictCode:any;
  maxAge: any;
  changedname: any;
  aadharNoPost: any;
  relationCdePost: any;
  changednameelse: any;
  // getDetails:any[]=[];
  weblandpostSurrender(response: any) {

    this.aadharNum = "",
      this.commonService
        .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(response?.requisitionHeader?.applicantInfo?.aadhaarNumber))
        .subscribe({
          next: (responseData: any) => {
            console.log(responseData, 'decrypt value respose data');
            this.aadharNum = this.commonService.RSADecrypt(responseData.result.rawValue);
          }
        })



    this.dataGrid = [];
    var hofAlternate: any;
    if (this.getDetail) {
      if (response?.requisitionHeader?.requisitionType === "MemberAdditionRiceCard") {
        this.appType = "3"
      }
      if (response?.requisitionHeader?.requisitionType === "MemberDeletionRiceCard") {
        this.appType = "4"
      }
      if (response?.requisitionHeader?.requisitionType === "SurrenderRiceCard") {
        this.appType = "5"
      }
      if (response?.requisitionHeader?.requisitionType === "CorrectionofWrongAadhaar") {
        this.appType = "32"
      }
      //splitting
      if (response?.requisitionHeader?.requisitionType === "SplittingRiceCard") {

        if (response?.requisitionData?.splitSingleMemberType === "normalSplit" || response?.requisitionData?.splitSingleMemberType === "widowSplit" || response?.requisitionData?.splitSingleMemberType === "divorceeSplit") {
          this.appType = "2";
        }

        else if (response?.requisitionData?.singleMemberType === "Widower (male) and never had any children") {
          this.appType = "12"
        }
        else if (response?.requisitionData?.singleMemberType === "Widow (female) and never had any children") {
          this.appType = "11"
        }
        else if (response?.requisitionData?.singleMemberType === "Transgender") {
          this.appType = "13"
        }
        else if (response?.requisitionData?.singleMemberType === "Unmarried and above 50 years of age") {
          this.appType = "14"
        }
      }

      //New rice cardS
      if (response?.requisitionHeader?.requisitionType === "NewRiceCard") {
        if (
          response?.requisitionData?.memberType == 'multipleMember' && response?.requisitionData?.singleMemberType == '') {
          this.appType = "1";
        }
        else if (response?.requisitionData?.singleMemberType === "Widow (female) and never had any children" && response?.requisitionData?.memberType == 'singleMember') {
          this.appType = "6"
        }
        else if (response?.requisitionData?.singleMemberType === "Widower (male) and never had any children" && response?.requisitionData?.memberType == 'singleMember') {
          this.appType = "7"
        }
        else if (response?.requisitionData?.singleMemberType === "Destitue and no other family member is alive" && response?.requisitionData?.memberType == 'singleMember') {
          this.appType = "10"
        }
        else if (response?.requisitionData?.singleMemberType === "Transgender" && response?.requisitionData?.memberType == 'singleMember') {
          this.appType = "8"
        }
        else if (response?.requisitionData?.singleMemberType === "Unmarried and above 50 years of age" && response?.requisitionData?.memberType == 'singleMember') {
          this.appType = "9"
        }

        //true Ineligible, false eligible
        if (response?.requisitionData?.singleMemberType === "Widower (male) and never had any children" && response?.requisitionData?.memberType == false) {
          this.appType = "28"
        }
        else if (response?.requisitionData?.singleMemberType === "Widow (female) and never had any children" && response?.requisitionData?.memberType == false) {
          this.appType = "27"
        }
        else if (response?.requisitionData?.singleMemberType === "Destitue and no other family member is alive" && response?.requisitionData?.memberType == false) {
          this.appType = "31"
        }
        else if (response?.requisitionData?.singleMemberType === "Transgender" && response?.requisitionData?.memberType == false) {
          this.appType = "29"
        }
        else if (response?.requisitionData?.singleMemberType === "Unmarried and above 50 years of age" && response?.requisitionData?.memberType == false) {
          this.appType = "30"
        }
        else if ((response?.requisitionData?.memberType == "true" || response?.requisitionData?.memberType == "false") && response?.requisitionData?.singleMemberType == '') {
          this.appType = "25"
        }
      }

      //occupation
      if (response?.requisitionData?.occupation === "AGRICULTURE LABOUR") {
        this.occType = "1";
      }
      if (response?.requisitionData?.occupation === "AUTO/TAXI/BUS/PRIVATE DRIVER") {
        this.occType = "54";
      }
      if (response?.requisitionData?.occupation === "BARBER") {

        this.occType = "15";
      }
      if (response?.requisitionData?.occupation === "BEGGAR") {
        this.occType = "55"
      }
      if (response?.requisitionData?.occupation === "BLACKSMITH") {
        this.occType = "16"
      }
      if (response?.requisitionData?.occupation === "BUSINESS") {
        this.occType = "2"
      }
      if (response?.requisitionData?.occupation === "COBBLER") {
        this.occType = "17"
      }
      if (response?.requisitionData?.occupation === "CONSTRUCTION TECHNICIAN") {
        this.occType = "18"
      }
      if (response?.requisitionData?.occupation === "CONTRACTOR") {
        this.occType = "19"
      }
      if (response?.requisitionData?.occupation === "COOK") {
        this.occType = "20"
      }
      if (response?.requisitionData?.occupation === "DHOBI / WASHERMAN") {
        this.occType = "21"
      }
      if (response?.requisitionData?.occupation === "ENTERPRENEOUR") {
        this.occType = "22"
      }
      if (response?.requisitionData?.occupation === "EX-NRI") {
        this.occType = "23"
      }
      if (response?.requisitionData?.occupation === "FACTORY OWNER/ INDUSTRIALIST ") {
        this.occType = "24"
      }
      if (response?.requisitionData?.occupation === "FARMER/ZAMINDAR/Small/ Marginal Farmer") {
        this.occType = "3"
      }
      if (response?.requisitionData?.occupation === "FISHERMAN") {
        this.occType = "25"
      }


      if (response?.requisitionData?.occupation === "FREEDOM FIGHTER PENSIONER") {
        this.occType = "26"
      }
      if (response?.requisitionData?.occupation === "GARDENER ") {
        this.occType = "27"
      }
      if (response?.requisitionData?.occupation === "GOLDSMITH") {
        this.occType = "28"
      }
      if (response?.requisitionData?.occupation === "GOVT CASUAL LABOURER") {
        this.occType = "29"
      }
      if (response?.requisitionData?.occupation === "GOVT PENSIONER") {
        this.occType = "30"
      }
      if (response?.requisitionData?.occupation === "GOVT. SERVICE") {
        this.occType = "4"
      }
      if (response?.requisitionData?.occupation === "HANDICRAFT ARTISANS/Technician") {
        this.occType = "31"
      }

      if (response?.requisitionData?.occupation === "HOUSE MAID") {
        this.occType = "32"
      }
      if (response?.requisitionData?.occupation === "HOUSE WIFE") {
        this.occType = "5"
      }
      if (response?.requisitionData?.occupation === "HOUSE/SHOP LENDER") {
        this.occType = "33"
      }
      if (response?.requisitionData?.occupation === "JOURNALIST/ MEDIA EMPLOYEE") {
        this.occType = "34"
      }
      if (response?.requisitionData?.occupation === "LABOUR/Cooli/Porter/ Hand Card Puller") {
        this.occType = "6"
      }

      if (response?.requisitionData?.occupation === "LOCAL VENDOR") {
        this.occType = "56"
      }
      if (response?.requisitionData?.occupation === "MECHANIC") {
        this.occType = "35"
      }
      if (response?.requisitionData?.occupation === "MONEY LENDER") {
        this.occType = "57"
      }
      if (response?.requisitionData?.occupation === "NRI") {
        this.occType = "36"
      }
      if (response?.requisitionData?.occupation === "OTHER") {
        this.occType = "14"
      }

      if (response?.requisitionData?.occupation === "PHOTOGRAPHER") {
        this.occType = "37"
      }
      if (response?.requisitionData?.occupation === "PRIEST") {
        this.occType = "38"
      }
      if (response?.requisitionData?.occupation === "PRIVATE CONTRACT LABOURER") {
        this.occType = "39"
      }
      if (response?.requisitionData?.occupation === "PRIVATE SERVICE") {
        this.occType = "7"
      }
      if (response?.requisitionData?.occupation === "PROFESSIONALIST") {
        this.occType = "40"
      }

      if (response?.requisitionData?.occupation === "PSU EMPLOYEE") {
        this.occType = "41"
      }
      if (response?.requisitionData?.occupation === "REAL ESTATE") {
        this.occType = "42"
      }
      if (response?.requisitionData?.occupation === "RELIGIOUS EMPLOYEE") {
        this.occType = "43"
      }
      if (response?.requisitionData?.occupation === "RETD. PSU EMPLOYEE") {
        this.occType = "44"
      }
      if (response?.requisitionData?.occupation === "RETD. PVT. COMPANY") {
        this.occType = "45"
      }

      if (response?.requisitionData?.occupation === "RETIRED") {
        this.occType = "8"
      }
      if (response?.requisitionData?.occupation === "RICKSHAW PULLER") {
        this.occType = "46"
      }
      if (response?.requisitionData?.occupation === "SALESMAN") {
        this.occType = "47"
      }
      if (response?.requisitionData?.occupation === "SCAVENGER") {
        this.occType = "58"
      }
      if (response?.requisitionData?.occupation === "SECURITY / WATCHMAN") {
        this.occType = "48"
      }


      if (response?.requisitionData?.occupation === "SELF EMPLOYED") {
        this.occType = "9"
      }
      if (response?.requisitionData?.occupation === "SHOP KEEPER") {
        this.occType = "10"
      }
      if (response?.requisitionData?.occupation === "STUDENT") {
        this.occType = "49"
      }
      if (response?.requisitionData?.occupation === "TAILOR") {
        this.occType = "50"
      }
      if (response?.requisitionData?.occupation === "TEACHER") {
        this.occType = "11"
      }

      if (response?.requisitionData?.occupation === "TRANSPORT LABOURER") {
        this.occType = "51"
      }
      if (response?.requisitionData?.occupation === "TRANSPORT OWNER") {
        this.occType = "52"
      }
      if (response?.requisitionData?.occupation === "UNEMPLOYED") {
        this.occType = "12"
      }
      if (response?.requisitionData?.occupation === "WEAVER") {
        this.occType = "53"
      }


      //caste
      if (response.requisitionHeader.applicantInfo?.caste === "BC-A") {
        this.casteNic = "11"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "BC-B") {
        this.casteNic = "12"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "BC-C") {
        this.casteNic = "13"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "BC-D") {
        this.casteNic = "14"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "BC-E") {
        this.casteNic = "15"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "OC") {
        this.casteNic = "1"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "Others") {
        this.casteNic = "16"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "SC") {
        this.casteNic = "3"
      }
      if (response.requisitionHeader.applicantInfo?.caste === "ST") {
        this.casteNic = "4"
      }

      response?.requisitionData?.riceCardMembersDataList.forEach((element: any, englishName: any, aadhaarNumber: any, gender: any) => {
        if (element.relationShip === "DAUGHTER") {
          this.relationGrid = "9"
        }
        if (element.relationShip === "DAUGHTER IN LAW") {
          this.relationGrid = "48"
        }
        if (element.relationShip === "FATHER") {
          this.relationGrid = "5"
        }
        if (element.relationShip === "FATHER IN LAW") {
          this.relationGrid = "18"
        }
        if (element.relationShip === "GRAND DAUGHTER") {
          this.relationGrid = "33"
        }
        if (element.relationShip === "GRAND FATHER") {
          this.relationGrid = "66"
        }
        if (element.relationShip === "GRAND MOTHER") {
          this.relationGrid = "65"
        }
        if (element.relationShip === "GRAND SON") {
          this.relationGrid = "34"
        }
        if (element.relationShip === "HUSBAND") {
          this.relationGrid = "7"
        }
        if (element.relationShip === "MOTHER") {
          this.relationGrid = "4"
        }
        if (element.relationShip === "MOTHER IN LAW") {
          this.relationGrid = "17"
        }
        if (element.relationShip === "SELF/HOF" || element.relationShip === "SELF") {

          this.relationGrid = "1"
        }
        if (element.relationShip === "SON") {
          this.relationGrid = "8"
        }
        if (element.relationShip === "SON IN LAW") {
          this.relationGrid = "49"
        }
        if (element.relationShip === "NA" || element.relationShip === "WIFE") {
          this.relationGrid = "51"
        }


        if (response?.requisitionHeader?.requisitionType === "MemberAdditionRiceCard" || response?.requisitionHeader?.requisitionType === "MemberDeletionRiceCard") {
          this.changedname = this.hofName;
          this.aadharNoPost = this.getDetail[0].adhar_number
          this.relationCdePost = this.relationGrid;

        } else {


          if (element.relationShip === "DAUGHTER") {
            let curdate = this.currentDate.getDate() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getFullYear()
            let details = element.dateOfBirth
            var a = details.split(" ");

            var x: any = curdate.split("-")[2];
            var y: any = details.split("-")[0];

            let age = x - y;
            if (age >= 18) {
              this.relationCdePost = "1";
              this.changedname = element.englishName;
              this.aadharNoPost = element.aadhaarNumber;

            }
            if (age <= 18) {
              this.relationCdePost = this.relationGrid;
              this.changedname = element.englishName;
              this.aadharNoPost = element.aadhaarNumber;

            }

          }


          else if ((element.relationShip.includes("SELF") ||
            element.relationShip === "WIFE" ||
            element.relationShip.includes("DAUGHTER") ||
            element.relationShip.includes("MOTHER")) ||
            response?.requisitionData?.singleMemberType != '') {
            hofAlternate = element.englishName
            this.changedname = element.englishName;
            this.aadharNoPost = element.aadhaarNumber;
            this.relationCdePost = "1";
          }

          else {
            // this.changedname=  response?.requisitionData?.riceCardMembersDataList[0]?.englishName;
            // this.aadharNoPost= response?.requisitionData?.riceCardMembersDataList[0]?.aadhaarNumber;
            //   console.log(this.aadharNoPost);
            this.relationCdePost = this.relationGrid;
            // console.log("else",this.relationCdePost); 
          }
        }
        // console.log(this.relationCdePost);


        this.DOB = element.dateOfBirth.split('T')[0];
        if (element.gender === "1" || element.gender === 'MALE') {
          this.addGender = "1";
          // console.log("gender",this.addGender);

        }
        if (element.gender === "2" || element.gender === 'FEMALE') {
          this.addGender = "2";
          // console.log("gender",this.addGender);
        }

        this.dataGrid.push({
          age: Number(element.age) ? Number(element.age) : 0,
          annual_income: Number(response?.requisitionData?.familyIncome ? response?.requisitionData?.familyIncome : 0),

          citizen_name: element.englishName ? element.englishName : "",
          citizen_name_ll: element.teluguName ? element.teluguName : element.englishName,
          dob_dt: this.DOB ? this.DOB : "",
          dob_dtSpecified: true,
          mapping_id: 0,
          mobile_number: element.mobileNumber ? element.mobileNumber : response?.requisitionHeader?.applicantInfo?.mobileNumber,

          gender: Number(this.addGender),
          occupation_type: Number(this.occType),
          relationship_hoh: Number(this.relationCdePost),
          uid_no: element.aadhaarNumber ? element.aadhaarNumber : " ",
        });
      })

      console.log(this.changedname, "changedName 2")
      console.log(this.hofName, "hofName 2")
      console.log(hofAlternate, "hofAlternate")
      console.log("this.aadharNum", this.aadharNum)
      if (!this.hofName) {
        for (let i = 0; i < response?.requisitionData?.riceCardMembersDataList.length; i++) {
          if (response?.requisitionData?.riceCardMembersDataList[i].relationShip.includes("SELF")) {
            this.hofName = response?.requisitionData?.riceCardMembersDataList[i].englishName;
            console.log(this.hofName, "hofName1")
          }
        }
      }
      var finalUid;
      if (response?.requisitionHeader?.requisitionType === 'CorrectionofWrongAadhaar') {
        finalUid = this.selectedRowValue
      }
      else if (response?.requisitionHeader?.requisitionType === 'SplittingRiceCard') {
        finalUid = this.aadharNoPost
      }
      else {
        finalUid = this.applicantAadhar
      }
      this.sixStepPostData = {
        application_Details: {
          app_address: {
            city: this.civilDistrict,
            line1: this.civilMandal,
            line2: this.civilVillage,
            pincode: response.requisitionHeader?.applicantInfo?.addresses[0].pinCode,
            state_code: "28",
          },
          app_member_details: this.dataGrid,
          application: {


            application_type: this.appType,
            caste: Number(this.casteNic) ? Number(this.casteNic) : " ",
            cluster_id: "0",
            district_code: this.CSDistrictCode,
            hof_name: response?.requisitionHeader?.requisitionType == 'SplittingRiceCard' || (response?.requisitionHeader?.requisitionType == 'NewRiceCard' && response?.requisitionData?.memberType == "true") ? hofAlternate : this.hofName,
            household_id: "101",
            mapping_id: 0,
            no_of_units: Number(response?.requisitionData?.riceCardMembersDataList?.length ? response?.requisitionData?.riceCardMembersDataList?.length : " "),
            religion: response?.requisitionHeader?.applicantInfo?.religion ? response?.requisitionHeader?.applicantInfo?.religion : " ",
            rice_card_no: response?.requisitionData?.riceCardNumber,
            secretariat_code: response?.requisitionData?.riceCardAddress?.villageWard ? response.requisitionData?.riceCardAddress?.villageWard : " ",
            spandana_application_code: response?.id,
            uid_no: finalUid
          }
        },
        password: "11169d3abc20724bb857cc5ea59e3c42",
        hts: "12",
        aadharNumber: this.aadharNum ? this.aadharNum : response?.requisitionHeader?.applicantInfo?.aadhaarNumber,
        createdBy: this.userInfo.userPrincipalName,
        requestNumber: response?.id
      }



      console.log(this.sixStepPostData, 'sixStepPostData postNICdata');


      this.commonService.postRequest(this.commonConstants.postNICdata, this.sixStepPostData).subscribe({
        next: (resData: any) => {
          console.log(resData, 'postNICdata Respose data');
          this.responseData = resData.result.status_flag != undefined || null ? resData.result.status_flag : '';
          this.response_code = resData.result.response_code;
          // this.statusUpdateApprove();
          // if(this.response_code == 200){
          //   this.statusApprove();
          // }else{
          //   this.statusReject();
          // }
          if (resData.result.response_code == 200 || resData.result.response_code == 201) {
            console.log(this.responseData, 'post method for nic');
            this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, resData.result?.status_flag, this.sixStepPostData)

          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, {
              size: 'md',
            });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = resData.result.status_flag ? "NIC response :" + resData.result.status_flag : 'Error from NIC Team';
          }



        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }

  }

  statusApprove() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = 'Application has successfully posted to Nic';
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });
  }

  statusReject() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = this.responseData;
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });
  }


  /*  //epassbook
   this.commonService.getRequest(this.commonConstants.getCertificateDetails + 'RV-ReplacementPPB-8').subscribe({
     next: (responseData: any) => { 
       if(responseData && responseData.length !==0) {
        console.log(' epassbook responseData.result',responseData.result)
       //  this.weblandPostingForEpassbookReplacement(responseData.result);
       } 
     },
     error: (error) => {
       console.log('client side', error);
     }
   })

   //mutationandtitledeedvumpassbook
   this.commonService.getRequest(this.commonConstants.getCertificateDetails + 'RV-MutationNTitleCumPPB-56').subscribe({
     next: (responseData: any) => { 
       if(responseData && responseData.length !==0) {
        console.log(' mutationandtitledeedvumpassbook responseData.result',responseData.result)
       //  this.weblandPostingForMutationandTitleDeed(responseData.result);
       } 
     },
     error: (error) => {
       console.log('client side', error);
     }
   })

   //applyOldPahani manual adangal
   this.commonService.getRequest(this.commonConstants.getCertificateDetails + 'RV-ManualAdangalCertificate-1030').subscribe({
     next: (responseData: any) => { 
       if(responseData && responseData.length !==0) {
        console.log(' applyOldPahani responseData.result',responseData.result)
       //  this.weblandPostingForOldAdangal(responseData.result);
       } 
     },
     error: (error) => {
       console.log('client side', error);
     }
   })

   //updateMobileinPattadar
   this.commonService.getRequest(this.commonConstants.getCertificateDetails + 'RV-PattadarMobInLandRecords-1029').subscribe({
     next: (responseData: any) => { 
       if(responseData && responseData.length !==0) {
        console.log(' updateMobileinPattadar responseData.result',responseData.result)
       //  this.weblandPostingForMutationandTitleDeed(responseData.result);
       } 
     },
     error: (error) => {
       console.log('client side', error);
     }
   }) */

  decryptAadharNum(aadhar: string) {
    console.log(aadhar, 'aadhar')
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar))
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'decrypt value respose data');
          this.aadharNum = this.commonService.RSADecrypt(responseData.result.rawValue);
          //console.log(this.aadharNum,'this.commonFormAddedFields.aadharNo')
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  gridData: {
    // totalextent: Number;

  }[] = [];
  gridDataRecitification: {

  }[] = [];
  // additonSurvey= "";
  weblandPostingForAdditonSurvey(response: any) {

    this.gridData = [];
    let postData: any = {};
    let getPahaniAppeal: any = {};


    console.log(response?.requisitionData?.surveyNo);


    getPahaniAppeal.pahaniappeal = this.gridData;
    for (let i = 0; i < response?.requisitionData?.adangalSurveyInfo.length; i++) {
      console.log("==response", response?.requisitionData?.adangalSurveyInfo[i]?.totalExtent);

      let tempData: any = {};
      tempData.pAppl_No = response?.id;
      tempData.pSurvey_no = response?.requisitionData?.surveyNo;
      tempData.pSerial_No = i + 1;
      tempData.pTotal_Extent = Number(response?.requisitionData?.adangalSurveyInfo[i]?.totalExtent);
      tempData.pUncultivated_Land = response?.requisitionData?.adangalSurveyInfo[i]?.unCultivatableLand;
      tempData.pCultivatable_Land = response?.requisitionData?.adangalSurveyInfo[i]?.cultivatableLand;
      tempData.pLand_Nature = response?.requisitionData?.adangalSurveyInfo[i]?.landNature;
      tempData.pLand_Classification = response?.requisitionData?.adangalSurveyInfo[i]?.landClassification;
      tempData.pWater_Resource = response?.requisitionData?.adangalSurveyInfo[i]?.waterSource;
      tempData.pTax = response?.requisitionData?.adangalSurveyInfo[i]?.tax;
      tempData.pAyakat_Extent = response?.requisitionData?.adangalSurveyInfo[i]?.extentofWaterSource;
      tempData.pKhata_Number = Number(response?.requisitionData?.adangalSurveyInfo[i]?.khataNo);
      tempData.pPattadar_Name = response?.requisitionData?.adangalSurveyInfo[i]?.pattadarDetails?.firstName;
      tempData.pOccupant_Name = response?.requisitionData?.adangalSurveyInfo[i]?.occupantDetails?.firstName;
      tempData.pOccupant_Extent = Number(response?.requisitionData?.adangalSurveyInfo[i]?.occupantExtent);
      tempData.pEnjoyment_Nature = response?.requisitionData?.adangalSurveyInfo[i]?.enjoymentNature;
      tempData.pOccupant_Father_Name = response?.requisitionData?.adangalSurveyInfo[i]?.occupantDetails?.fatherHusbandName;
      tempData.pPattadar_Father_Name = response?.requisitionData?.adangalSurveyInfo[i]?.pattadarDetails?.fatherHusbandName;
      tempData.pVillage_Name = response?.requisitionData?.selectionCriteriaDetails?.villageWardName;
      tempData.pVillage_Code = Number(response?.requisitionData?.selectionCriteriaDetails?.villageWard);
      tempData.pBase_Survey_No = response?.requisitionData?.surveyNo;
      tempData.appl_slno = i + 1;
      tempData.correctionType = "0";
      this.gridData.push(tempData);
    }
    getPahaniAppeal.dcode = Number(response?.requisitionData?.selectionCriteriaDetails?.district);
    getPahaniAppeal.mcode = Number(response?.requisitionData?.selectionCriteriaDetails?.mandalMunicipality);
    //  getPahaniAppeal.user_id ="Land_WLU";
    // getPahaniAppeal.pwd = "UL@nD@2023";
    postData.getPahaniAppeal = getPahaniAppeal;
    console.log("==tttt", postData);


    this.commonService.postRequestForWebLand(this.commonConstants.getPahaniAppeal, postData)
      .subscribe({
        next: (responseData: any) => {
          console.log('responseData', responseData.getPahaniAppealResponse?.getPahaniAppealResult);

          this.additonSurvey =
            responseData.getPahaniAppealResponse?.getPahaniAppealResult;
          this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.getPahaniAppealResponse?.getPahaniAppealResult, postData)
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  weblandPostingForRectificationOfEntriesOld(response: any) {
    console.log("==response", response);



    this.gridDataRecitification = [];
    let postDataROR: any = {};
    let getPahaniAppealROR: any = {};
    // this.is_requestsent=false;
    getPahaniAppealROR.pahaniappealROR = this.gridDataRecitification;
    for (let i = 0; i < response?.requisitionData?.adangalCertificateDetailsList.length; i++) {
      let tempDataROR: any = {};
      tempDataROR.pAppl_No = response?.id;
      tempDataROR.pSurvey_no = response?.requisitionData?.formSurveyNo.trim();
      tempDataROR.pSerial_No = i + 1;
      tempDataROR.pTotal_Extent = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.oldTotalExtent);
      tempDataROR.pUncultivated_Land = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldUncultivatedLand;
      tempDataROR.pCultivatable_Land = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldCultivatedLand;
      tempDataROR.pLand_Nature = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldLandNature;
      tempDataROR.pLand_Classification = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldLandClassification;
      tempDataROR.pTax = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.oldTax);
      tempDataROR.pWater_Resource = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldWaterSource;
      tempDataROR.pAyakat_Extent = (response?.requisitionData?.adangalCertificateDetailsList[i]?.oldWaterSourceExtent).toString();
      tempDataROR.pKhata_Number = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.oldKhataNumber);
      tempDataROR.pPattadar_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldPattadarName;
      tempDataROR.pOccupant_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldOccupantName;
      tempDataROR.pOccupant_Extent = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.oldOccupantExtent);
      tempDataROR.pEnjoyment_Nature = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldEnjoymentNature;
      tempDataROR.pOccupant_Father_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldOccupantFatherName;
      tempDataROR.pPattadar_Father_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.oldPattadarFatherName;
      tempDataROR.pVillage_Name = response?.requisitionData?.formVillageName;
      tempDataROR.pVillage_Code = Number(response?.requisitionData?.formVillage);
      tempDataROR.appl_slno = i + 1;
      tempDataROR.correctionType = response?.requisitionData?.editableData;

      tempDataROR.pNewKhata_Number = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.newKhataNumber);
      tempDataROR.pNewSurvey_Number = response?.requisitionData?.adangalCertificateDetailsList[i]?.newSurveyNo.trim();
      tempDataROR.pNewTotal_Extent = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.newTotalExtent);
      tempDataROR.pNewUncultivated_Land = response?.requisitionData?.adangalCertificateDetailsList[i]?.newUncultivatedLand;
      tempDataROR.pNewCultivatable_Land = response?.requisitionData?.adangalCertificateDetailsList[i]?.newCultivatedLand;
      tempDataROR.pNewLand_Nature = response?.requisitionData?.adangalCertificateDetailsList[i]?.newLandNature;
      tempDataROR.pNewTax = Number(response?.requisitionData?.adangalCertificateDetailsList[i]?.newTax);
      tempDataROR.pNewLand_Classification = response?.requisitionData?.adangalCertificateDetailsList[i]?.newLandClassification;
      tempDataROR.pNewWater_Resource = response?.requisitionData?.adangalCertificateDetailsList[i]?.newWaterSource;
      tempDataROR.pNewAyakat_Extent = response?.requisitionData?.adangalCertificateDetailsList[i]?.newWaterSourceExtent;
      tempDataROR.pNewPattadar_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.newPattadarName;
      tempDataROR.pNewOccupant_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.newOccupantName;
      tempDataROR.pNewOccupant_Extent = response?.requisitionData?.adangalCertificateDetailsList[i]?.newOccupantExtent;
      tempDataROR.pNewEnjoyment_Nature = response?.requisitionData?.adangalCertificateDetailsList[i]?.newEnjoymentNature;
      tempDataROR.pNewOccupant_Father_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.newOccupantFatherName;
      tempDataROR.pNewPattadar_Father_Name = response?.requisitionData?.adangalCertificateDetailsList[i]?.newPattadarFatherName;
      tempDataROR.pMutation_reason = "";
      tempDataROR.pNewMutation_reason = ""

      tempDataROR.pBase_Survey_No = response?.requisitionData?.formSurveyNo.trim();
      this.gridDataRecitification.push(tempDataROR);
    }

    postDataROR.getPahaniAppeal = getPahaniAppealROR;
    getPahaniAppealROR.dcode = Number(response?.requisitionData?.formDistrict);
    getPahaniAppealROR.mcode = Number(response?.requisitionData?.formMandal);
    //  getPahaniAppealROR.user_id ="Land_WLU";
    // getPahaniAppealROR.pwd = "UL@nD@2023";
    postDataROR.getPahaniAppeal = getPahaniAppealROR;
    // console.log("==aaa",postData);
    console.log("==rectificatiion", postDataROR);


    this.commonService.postRequestForWebLand(this.commonConstants.getPahaniAppeal, postDataROR)
      .subscribe({
        next: (responseData: any) => {
          console.log('responseData', responseData.getPahaniAppealResponse?.getPahaniAppealResult);
          this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.getPahaniAppealResponse?.getPahaniAppealResult, postDataROR)
          // this.rectification =
          //   responseData.getPahaniAppealResponse?.getPahaniAppealResult;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  weblandPostingForRectificationOfEntries(response: any) {
    console.log("==response", response);
    let oldDetails: any;
    let newDetails: any;
    if (response?.requisitionData?.serviceType === 'Mutation for Transactions with Corrections') {

      // newDetails = JSON.parse(response?.requisitionData?.updatedSellerRORDetails);
      // newDetails.forEach((item: any) => {
      // oldDetails = JSON.parse(response?.requisitionData?.sellerRORDetails)?.filter((el: any) => el.pSurvey_no === item.oldSurvey_no);

      // })
    } else {
      oldDetails = JSON.parse(response?.requisitionData?.rorDetails)?.filter((item: any) => item.pSurvey_no === response?.requisitionData?.editSurveyNo);
      newDetails = JSON.parse(response?.requisitionData?.updatedSurveyDetails);
    }

    this.gridDataRecitification = [];
    let postDataROR: any = {};
    let getPahaniAppealROR: any = {};
    // this.is_requestsent=false;

    // for (let i = 0; i < response?.requisitionData?.adangalCertificateDetailsList.length; i++) {
    let tempDataROR: any = {};
    tempDataROR.pAppl_No = response?.id;
    tempDataROR.pSurvey_no = oldDetails[0]?.pSurvey_no?.trim();
    tempDataROR.pSerial_No = 1;
    tempDataROR.pTotal_Extent = Number(oldDetails[0]?.pTotal_Extent);
    tempDataROR.pUncultivated_Land = oldDetails[0]?.pUncultivated_Land;
    tempDataROR.pCultivatable_Land = oldDetails[0]?.pCultivatable_Land;
    tempDataROR.pLand_Nature = oldDetails[0]?.pLand_Nature;
    tempDataROR.pLand_Classification = oldDetails[0]?.pLand_Classification;
    tempDataROR.pTax = Number(oldDetails[0]?.pTax);
    tempDataROR.pWater_Resource = oldDetails[0]?.pWater_Resource;
    tempDataROR.pAyakat_Extent = (oldDetails[0]?.pAyakat_Extent)?.toString();
    tempDataROR.pKhata_Number = Number(oldDetails[0]?.pKhata_Number);
    tempDataROR.pPattadar_Name = oldDetails[0]?.pPattadar_Name;
    tempDataROR.pOccupant_Name = oldDetails[0]?.pOccupant_Name;
    tempDataROR.pOccupant_Extent = Number(oldDetails[0]?.pOccupant_Extent);
    tempDataROR.pEnjoyment_Nature = oldDetails[0]?.pEnjoyment_Nature;
    tempDataROR.pOccupant_Father_Name = oldDetails[0]?.pOccupant_Father_Name;
    tempDataROR.pPattadar_Father_Name = oldDetails[0]?.pPattadar_Father_Name;
    tempDataROR.pVillage_Name = response?.requisitionData?.documentAddressdetails?.villageWardName;
    tempDataROR.pVillage_Code = Number(response?.requisitionData?.documentAddressdetails?.villageWard);
    tempDataROR.appl_slno = 1;
    tempDataROR.correctionType = response?.requisitionData?.updatedWebLandCols;
    if (response?.requisitionData?.serviceType === 'Mutation for Transactions with Corrections') {
      tempDataROR.pNewKhata_Number = Number(newDetails?.pKhata_Number);
      tempDataROR.pNewSurvey_Number = newDetails?.pSurvey_no;
      tempDataROR.pNewTotal_Extent = Number(newDetails?.pTotal_Extent);
      tempDataROR.pNewUncultivated_Land = newDetails?.pUncultivated_Land;
      tempDataROR.pNewCultivatable_Land = newDetails?.pCultivatable_Land;
      tempDataROR.pNewLand_Nature = newDetails?.pLand_Nature;
      tempDataROR.pNewLand_Classification = newDetails?.pLand_Classification;
      tempDataROR.pNewWater_Resource = newDetails?.pWater_Resource;
      tempDataROR.pNewAyakat_Extent = newDetails?.pAyakat_Extent;
      tempDataROR.pNewPattadar_Name = newDetails?.pPattadar_Name;
      tempDataROR.pNewOccupant_Name = newDetails?.pOccupant_Name;
      tempDataROR.pNewOccupant_Extent = Number(newDetails?.pOccupant_Extent);
      tempDataROR.pNewEnjoyment_Nature = newDetails?.pEnjoyment_Nature;
      tempDataROR.pNewOccupant_Father_Name = newDetails?.pOccupant_Father_Name;
      tempDataROR.pNewPattadar_Father_Name = newDetails?.pPattadar_Father_Name;
      tempDataROR.pNewMutation_reason = newDetails?.pAcquiredReason;
    } else {
      tempDataROR.pNewKhata_Number = Number(newDetails?.rorKhataNo);
      tempDataROR.pNewSurvey_Number = newDetails?.rorGridSurveyNo;
      tempDataROR.pNewTotal_Extent = Number(newDetails?.rorTotalExtent);
      tempDataROR.pNewUncultivated_Land = newDetails?.rorUncultivable;
      tempDataROR.pNewCultivatable_Land = newDetails?.rorCultivable;
      tempDataROR.pNewLand_Nature = newDetails?.rorLandNature;
      tempDataROR.pNewLand_Classification = newDetails?.rorLandClassification;
      tempDataROR.pNewWater_Resource = newDetails?.rorWaterSource;
      tempDataROR.pNewAyakat_Extent = newDetails?.rorWaterExtent;
      tempDataROR.pNewPattadar_Name = newDetails?.rorPattadarName;
      tempDataROR.pNewOccupant_Name = newDetails?.rorOccupantName;
      tempDataROR.pNewOccupant_Extent = Number(newDetails?.rorOccupantExtent);
      tempDataROR.pNewEnjoyment_Nature = newDetails?.rorEnjoymentNature;
      tempDataROR.pNewOccupant_Father_Name = newDetails?.rorOccupantFatherName;
      tempDataROR.pNewPattadar_Father_Name = newDetails?.rorPattadarFatherName;
      tempDataROR.pNewMutation_reason = newDetails?.rorAcquisition;
    }
    tempDataROR.pNewTax = Number(oldDetails[0]?.pTax);
    tempDataROR.pMutation_reason = oldDetails[0]?.pAcquiredReason;
    tempDataROR.pBase_Survey_No = Number(oldDetails[0]?.pBase_Survey_No);
    let record: any = {}
    record.pahaniAppeal = tempDataROR;  //
    this.gridDataRecitification.push(tempDataROR);
    //}
    getPahaniAppealROR.pahaniappeal = this.gridDataRecitification;
    getPahaniAppealROR.dcode = Number(response?.requisitionData?.documentAddressdetails?.district);
    getPahaniAppealROR.mcode = Number(response?.requisitionData?.documentAddressdetails?.mandalMunicipality);
    // getPahaniAppealROR.user_id ="Land_WLU";
    //getPahaniAppealROR.pwd = "UL@nD@2023";
    postDataROR.getPahaniAppeal = getPahaniAppealROR;
    // console.log("==aaa",postData);
    console.log("==rectificatiion", postDataROR);


    this.commonService.postRequestForWebLand(this.commonConstants.getPahaniAppeal, postDataROR)
      .subscribe({
        next: (responseData: any) => {
          console.log('responseData', responseData.getPahaniAppealResponse?.getPahaniAppealResult);
          this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.getPahaniAppealResponse?.getPahaniAppealResult, postDataROR)
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  weblandPostingForLandConversion(response: any) {
    let i = 0;
    let postData: any = {};
    this.gridData = [];
    let conversionInformation: any = {};
    console.log(response?.requisitionData?.docAddressDetails?.district);
    conversionInformation.appl_no = response?.id;

    conversionInformation.appl_name = response?.requisitionHeader?.applicantInfo?.personNames[0]?.firstName + " "
      + response?.requisitionHeader?.applicantInfo?.personNames[0]?.middleName + " " +
      response?.requisitionHeader?.applicantInfo?.personNames[0]?.lastName,
      conversionInformation.Appl_addr1 = response?.requisitionHeader?.applicantInfo?.addresses[0]?.doorNo;
    conversionInformation.Appl_addr2 = response?.requisitionHeader?.applicantInfo?.addresses[0]?.district + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.mandalMunicipality;
    conversionInformation.Appl_addr3 = response?.requisitionHeader?.applicantInfo?.addresses[0]?.villageWard + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode;
    conversionInformation.Appl_date = new Date();
    conversionInformation.Appl_slno = i + 1;
    conversionInformation.Dist_code = response?.requisitionData?.docAddressDetails?.district;
    conversionInformation.Mand_code = response?.requisitionData?.docAddressDetails?.mandalMunicipality;
    conversionInformation.Vill_code = response?.requisitionData?.docAddressDetails?.villageWard;
    conversionInformation.Appl_syno = response?.requisitionData?.documentInfo[0]?.surveyNo;
    conversionInformation.Syno_ext = response?.requisitionData?.documentInfo[0]?.totalExtent;
    conversionInformation.appl_khata = response?.requisitionData?.documentInfo[0]?.khataNo;
    conversionInformation.khata_ext = response?.requisitionData?.documentInfo[0]?.kathaExtent;
    conversionInformation.conversionextent = response?.requisitionData?.documentInfo[0]?.extentforConversion;
    conversionInformation.Purpose_Code = response?.requisitionData?.purposeforConversion;
    conversionInformation.ppbno = 0;
    conversionInformation.docno = response?.requisitionData?.documentInfo[0]?.docNoOryear;
    conversionInformation.mobilenumber = response?.requisitionHeader?.applicantInfo?.mobileNumber;
    conversionInformation.aadhaar_no = response?.requisitionHeader?.applicantInfo?.aadhaarNumber;
    conversionInformation.Latitude = "";
    conversionInformation.description = "";
    postData.conversionInformation = conversionInformation;
    console.log("==land", postData);
    console.log("landConversion", postData);
    this.commonService.postRequestForWebLand(this.commonConstants.surveyNumberlandConversion, postData)
      .subscribe({
        next: (responseData: any) => {
          console.log('responseData', responseData.conversionInformationResponse?.conversionInformationResult);
          this.landConversion = responseData.conversionInformationResponse?.conversionInformationResult;
          this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.conversionInformationResponse?.conversionInformationResult, postData)
        },
        error: (error) => {
          console.log('client side', error);
        },
      });

  }
  // weblandLandConversionSurvey(response:any){
  //   let postData: any = {};
  //   let conversionInformation: any = {};
  //   console.log(response.result.requisitionData.docAddressDetails.district);
  //   conversionInformation.appl_no = response.result.requisitionData.docAddressDetails.district;
  //   conversionInformation.appl_name = "";
  //   conversionInformation.Appl_addr1 ="";
  //   conversionInformation.Appl_addr2 = "";
  //   conversionInformation.Appl_addr3 ="";
  //   conversionInformation.Appl_date ="";

  // }

  get64BitFileConvertDataforEpassbook(blobRef: any) {
    this.commonService.getRequest(this.commonConstants.fileDownload + blobRef).subscribe({
      next: (responseData: any) => {
        // this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
        console.log('file contents', responseData.result.fileContents)
        this.ePassbookPhotoField = responseData.result.fileContents

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }


  postWeblandData: any = {};
  ePassbookPhotoField = '';
  weblandPostingForEpassbookReplacement(response: any) {
    let photoField: any;
    let typeApplication = "";
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    response?.fileList.forEach((element: any) => {
      if (element.documentType === "Passport size photo") {
        // this.get64BitFileConvertDataforEpassbook(element.blobRef)
        this.commonService.getRequest(this.commonConstants.fileDownload + element.blobRef).subscribe({
          next: (responseData: any) => {
            // this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
            console.log('file contents', responseData.result.fileContents)
            this.ePassbookPhotoField = responseData.result.fileContents

            this.aadharNum = "";
            this.commonService
              .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(response?.requisitionHeader?.applicantInfo?.aadhaarNumber))
              .subscribe({
                next: (responseData: any) => {
                  console.log(responseData, 'decrypt value respose data');
                  this.aadharNum = this.commonService.RSADecrypt(responseData.result.rawValue);
                  if (response?.requisitionData?.serviceType.trim() == 'Replacement of Pattadhar Passbook') {
                    typeApplication = "2"
                  } else if (response?.requisitionData?.serviceType.trim() == 'Duplicate Pattadhar Passbook') {
                    typeApplication = "3"
                  }
                  console.log(this.aadharNum, 'this.commonFormAddedFields.aadharNo')
                  if (this.aadharNum.length > 1) {
                    let finalDate = null;
                    if (response?.requisitionData?.registrationDate == null) {
                      finalDate = dd + '/' + mm + '/' + yyyy
                    } else {
                      finalDate = this.formatDate(response?.requisitionData?.registrationDate, "YYYY-MM-DDThh:mm:ss[Z]", "DD/MM/YYYY")
                    }
                    this.postWeblandData = {
                      makePPBRequestToWebLand: {
                        objPPBRequest: {
                          dcode: response?.requisitionData?.pattadarAddress?.district,
                          mcode: response?.requisitionData?.pattadarAddress?.mandalMunicipality,
                          applicationNo: response?.id,  //response?.requisitionHeader?.requisitionType,     //"RPP012101600920",
                          typeOfAplication: typeApplication, //"2", //response?.requisitionData?.serviceType.trim(),  //duplicate 3 replacement 2
                          khataNo: response?.requisitionData?.khataNumber.trim(),
                          photo: this.ePassbookPhotoField, //photoField,
                          name: response?.requisitionData?.pattadarDetails?.firstName,
                          f_W_Name: response?.requisitionData?.pattadarDetails?.fatherHusbandName,
                          address: response?.requisitionHeader?.applicantInfo?.addresses[1]?.doorNo + " " +
                            // response?.requisitionHeader?.applicantInfo?.addresses[0]?.streetName + " " +
                            this.districts + " " +
                            this.mandals + " " +
                            this.villages + " " +
                            response?.requisitionHeader?.applicantInfo?.addresses[1]?.pinCode,
                          caste: response?.requisitionData?.casteCode, //response?.requisitionData?.casteCategory, "15" , //
                          villageCode: response?.requisitionData?.pattadarAddress?.villageWard,
                          divisionName: response?.requisitionData?.divisionEntityCode,
                          dateOfRegistration: finalDate, //this.formatDate(response?.requisitionData?.registrationDate,"YYYY-MM-DDThh:mm:ss[Z]","DD/MM/YYYY"),
                          serial_number: "1",   // response?.requisitionData?.registrationNumber, //1
                          aadhaar_no: this.aadharNum, // response?.requisitionHeader?.applicantInfo?.aadhaarNumber,
                          gender: response?.requisitionHeader?.applicantInfo?.gender,
                          mobile_no: response?.requisitionHeader?.applicantInfo?.mobileNumber
                        }
                      }
                    }
                    console.log('weblandPostingFor EpassbookReplacement', this.postWeblandData)
                    this.commonService.postRequestForWebLand(this.commonConstants.makePPBRequestToWebLand, this.postWeblandData).subscribe({
                      next: (responseData: any) => {
                        console.log('repsonse data', responseData.makePPBRequestToWebLandResponse?.makePPBRequestToWebLandResult)
                        this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.makePPBRequestToWebLandResponse?.makePPBRequestToWebLandResult, this.postWeblandData)
                      },
                      error: (error) => {
                        console.log('client side', error);
                      }
                    });
                  }
                },
                error: (error) => {
                  console.log('client side', error);
                },
              });



          },
          error: (error) => {
            console.log('client side', error);
          }
        });
      }
    });



  }

  postDeathResultObj: any;
  deathCorrectionList: { fieldcode: Number, fielddesc: string, fieldmaxlength: Number, fieldtype: string, fieldvalue: string }[] = [];
  weblandPostingForCorrectionsInDeathCertificate(response: any) {
    if (response?.requisitionData?.isDeceasedNameUpdate == true) {
      //correctDeceasedName
      this.deathCorrectionList.push({
        fieldcode: 3,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeceasedName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeceasedName,
      })
      //correctDeceasedSurName
      this.deathCorrectionList.push({
        fieldcode: 2,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeceasedSurName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeceasedSurName,
      })
    }
    if (response?.requisitionData?.isDateOfDeathUpdate == true) {
      //correctDOD
      this.deathCorrectionList.push({
        fieldcode: 1,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDOD.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDOD,
      })
    }
    if (response?.requisitionData?.isGenderUpdate == true) {
      //correctGender
      this.deathCorrectionList.push({
        fieldcode: 22,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctGender.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctGender,
      })
    }
    if (response?.requisitionData?.isRelationUpdate == true) {
      //correctRelation
      this.deathCorrectionList.push({
        fieldcode: 24,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctRelation.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctRelation,
      })
    }
    if (response?.requisitionData?.isDeceasedFatherNameUpdate == true) {
      //correctDeceasedFatherName
      this.deathCorrectionList.push({
        fieldcode: 5,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeceasedFatherName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeceasedFatherName,
      })
      //correctDeceasedFatherSurName
      this.deathCorrectionList.push({
        fieldcode: 4,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeceasedFatherSurName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeceasedFatherSurName,
      })
    }
    if (response?.requisitionData?.isDeceasedMotherNameUpdate == true) {
      //actualDeceasedMotherSurName
      this.deathCorrectionList.push({
        fieldcode: 7,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.actualDeceasedMotherSurName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.actualDeceasedMotherSurName,
      })
      //correctDeceasedMotherName
      this.deathCorrectionList.push({
        fieldcode: 8,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeceasedMotherName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeceasedMotherName,
      })
    }
    if (response?.requisitionData?.isDeathPlaceUpdate == true) {

      if (response?.requisitionData?.correctDeathPlace == 'hospital') {
        //correctDeceasedMotherName
        this.deathCorrectionList.push({
          fieldcode: 6,
          fielddesc: "",
          fieldmaxlength: 1,
          fieldtype: "",
          fieldvalue: "H",
        })
      } else if (response?.requisitionData?.correctDeathPlace == 'residence') {
        //correctDeceasedMotherName
        this.deathCorrectionList.push({
          fieldcode: 6,
          fielddesc: "",
          fieldmaxlength: 1,
          fieldtype: "",
          fieldvalue: "R",
        })
      } else if (response?.requisitionData?.correctDeathPlace == 'others') {
        //correctDeceasedMotherName
        this.deathCorrectionList.push({
          fieldcode: 6,
          fielddesc: "",
          fieldmaxlength: 1,
          fieldtype: "",
          fieldvalue: "O",
        })
      }

      //changedAddress1
      this.deathCorrectionList.push({
        fieldcode: 18,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.changedAddress1.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.changedAddress1,
      })
      //changedAddress2
      this.deathCorrectionList.push({
        fieldcode: 19,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.changedAddress2.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.changedAddress2,
      })
      //changedAddress3
      this.deathCorrectionList.push({
        fieldcode: 20,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.changedAddress3.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.changedAddress3,
      })
      //locationName
      this.deathCorrectionList.push({
        fieldcode: 23,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.locationName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.locationName,
      })
      //pincode
      this.deathCorrectionList.push({
        fieldcode: 26,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.pincode.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.pincode,
      })


    }
    if (response?.requisitionData?.isDeathAddressUpdate == true) {

      //correctDeathAddress1
      this.deathCorrectionList.push({
        fieldcode: 12,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeathAddress1.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeathAddress1,
      })
      //correctDeathAddress2
      this.deathCorrectionList.push({
        fieldcode: 13,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeathAddress2.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeathAddress2,
      })
      //correctDeathAddress3
      this.deathCorrectionList.push({
        fieldcode: 14,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDeathAddress3.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDeathAddress3,
      })


    }
    if (response?.requisitionData?.isPermanentAddressUpdate == true) {
      //correctPermanentAddress1
      this.deathCorrectionList.push({
        fieldcode: 15,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctPermanentAddress1.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctPermanentAddress1,
      })
      //correctPermanentAddress2
      this.deathCorrectionList.push({
        fieldcode: 16,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctPermanentAddress2.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctPermanentAddress2,
      })
      //correctPermanentAddress3
      this.deathCorrectionList.push({
        fieldcode: 17,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctPermanentAddress3.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctPermanentAddress3,
      })
    }

    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "/" + month + "/" + year

    var count = 0;
    response?.fileList.forEach((element: any) => {
      count = count + 1;
      this.commonService.getRequest(this.commonConstants.fileDownload + element.blobRef).subscribe({
        next: (responseData: any) => {
          // this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
          console.log('file contents', responseData.result.fileContents)
          // if(count== 1){
          this.postDeathResultObj = responseData.result.fileContents + ",";
          var raw = window.atob(responseData.result.fileContents);
          var rawLength = raw.length;
          var array = new Uint8Array(new ArrayBuffer(rawLength));

          for (let index = 0; index < rawLength; index++) {
            array[index] = raw.charCodeAt(index);

          }
          // }
          // else{
          //   this.postDeathResultObj = this.postDeathResultObj+ ","+responseData.result.fileContents
          // }
          console.log("polstweblandlength", this.postDeathResultObj.length);
          if (count === response?.fileList.length) {
            let obj = {
              pOST_DeathCorrections_InputMessage: {
                deathCorrections: {
                  rucode: response?.requisitionData?.registrationUnitId,//this.sf['searchRegUnitId'].value.oFFICEID,
                  requestyear: String(year),
                  mtransid: response?.id,//response?.requisitionData?. ,
                  pdate: dateFormat,//response?.requisitionData?. ,
                  applsurname: response?.requisitionHeader?.applicantInfo?.personNames[0]?.lastName,
                  applname: response?.requisitionHeader?.applicantInfo?.personNames[0]?.firstName + " "
                    + response?.requisitionHeader?.applicantInfo?.personNames[0]?.middleName + " " +
                    response?.requisitionHeader?.applicantInfo?.personNames[0]?.lastName,
                  applrelation: response?.requisitionData?.correctRelation,
                  address1: response?.requisitionHeader?.applicantInfo?.addresses[0]?.doorNo,
                  address2: response?.requisitionHeader?.applicantInfo?.addresses[0]?.district + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.mandalMunicipality,
                  address3: response?.requisitionHeader?.applicantInfo?.addresses[0]?.villageWard + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode,
                  emailid: "xyz@gmail.com", //response?.requisitionHeader?.applicantInfo?.emailAddress ,
                  mobileno: response?.requisitionHeader?.applicantInfo?.mobileNumber,
                  applremarks: response?.requisitionHeader?.remarks,
                  correctionsList: JSON.stringify(this.deathCorrectionList),
                  // {
                  //     fieldcode: fieldListData?.fieldcode,
                  //     fielddesc: fieldListData?.fielddesc,
                  //     fieldmaxlength: fieldListData?.fieldmaxlength,
                  //     fieldtype: fieldListData?.fieldtype,
                  //     fieldvalue: fieldListData?.fieldvalue
                  // },
                  regYear: response?.requisitionData?.registrationYear,
                  regNo: response?.requisitionData?.registrationNumber,
                  attachFile: JSON.stringify(this.postDeathResultObj),//{},
                  userid: "UCRBDS",
                  password: "UCRBDSAPITCGMCMCMPTYGP"
                }
              }
            };
            this.commonService
              .postRequestForWebLand(this.commonConstants.DeathCorrections, obj)
              .subscribe({
                next: (responseData: any) => {
                  console.log(responseData.pOST_DeathCorrections_OutputMessage.deathCorrectionsResponse);
                  // this.hospitals = responseData.pOST_BirthCorrections_OutputMessage.birthCorrectionsResponse;
                },
              });
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    });

  }

  postBirthResultObj: any
  birthCorrectionList: { fieldcode: Number, fielddesc: string, fieldmaxlength: Number, fieldtype: string, fieldvalue: string }[] = [];
  weblandPostingForCorrectionsInBirthCertificate(response: any) {
    console.log(response, 'request weblandPostingForCorrectionsInBirthCertificate')
    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "/" + month + "/" + year
    if (response?.requisitionData?.isChildNameUpdate == true) {
      //correctChildName
      this.birthCorrectionList.push({
        fieldcode: 4,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctChildName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctChildName,
      })
      //correctChildSurName
      this.birthCorrectionList.push({
        fieldcode: 3,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctChildSurName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctChildSurName,
      })
    } else if (response?.requisitionData?.isDateOfBirthUpdate == true) {
      //correctDOB
      this.birthCorrectionList.push({
        fieldcode: 1,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctDOB.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctDOB,
      })
    } else if (response?.requisitionData?.isGenderUpdate == true) {
      //correctGender
      this.birthCorrectionList.push({
        fieldcode: 2,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctGender.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctGender,
      })
    } else if (response?.requisitionData?.isFatherNameUpdate == true) {
      //correctFatherName
      this.birthCorrectionList.push({
        fieldcode: 6,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctFatherName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctFatherName,
      })
      //correctFatherSurName
      this.birthCorrectionList.push({
        fieldcode: 5,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctFatherSurName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctFatherSurName,
      })
    } else if (response?.requisitionData?.isMotherNameUpdate == true) {
      //correctMotherSurName
      this.birthCorrectionList.push({
        fieldcode: 19,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctMotherSurName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctMotherSurName,
      })
      //correctMotherName
      this.birthCorrectionList.push({
        fieldcode: 20,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctMotherName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctMotherName,
      })
    } else if (response?.requisitionData?.isBirthPlaceUpdate == true) {

      if (response?.requisitionData?.correctBirthPlace == 'hospital') {
        //correctDeceasedMotherName
        this.birthCorrectionList.push({
          fieldcode: 7,
          fielddesc: "",
          fieldmaxlength: 1,
          fieldtype: "",
          fieldvalue: "H",
        })
      } else if (response?.requisitionData?.correctBirthPlace == 'residence') {
        //correctDeceasedMotherName
        this.birthCorrectionList.push({
          fieldcode: 7,
          fielddesc: "",
          fieldmaxlength: 1,
          fieldtype: "",
          fieldvalue: "R",
        })
      } else if (response?.requisitionData?.correctBirthPlace == 'others') {
        //correctDeceasedMotherName
        this.birthCorrectionList.push({
          fieldcode: 7,
          fielddesc: "",
          fieldmaxlength: 1,
          fieldtype: "",
          fieldvalue: "O",
        })
      }

      //changedAddress1
      this.birthCorrectionList.push({
        fieldcode: 14,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.changedAddress1.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.changedAddress1,
      })
      //changedAddress2
      this.birthCorrectionList.push({
        fieldcode: 15,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.changedAddress2.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.changedAddress2,
      })
      //changedAddress3
      this.birthCorrectionList.push({
        fieldcode: 16,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.changedAddress3.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.changedAddress3,
      })
      //locationName
      this.birthCorrectionList.push({
        fieldcode: 22,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.locationName.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.locationName,
      })
      //pincode
      this.birthCorrectionList.push({
        fieldcode: 23,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.pincode.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.pincode,
      })


    } else if (response?.requisitionData?.isBirthAddressUpdate == true) {

      //correctBirthAddress1
      this.birthCorrectionList.push({
        fieldcode: 8,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctBirthAddress1.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctBirthAddress1,
      })
      //correctBirthAddress2
      this.birthCorrectionList.push({
        fieldcode: 9,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctBirthAddress2.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctBirthAddress2,
      })
      //correctBirthAddress3
      this.birthCorrectionList.push({
        fieldcode: 10,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctBirthAddress3.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctBirthAddress3,
      })


    } else if (response?.requisitionData?.isPermanentAddressUpdate == true) {
      //correctPermanentAddress1
      this.birthCorrectionList.push({
        fieldcode: 11,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctPermanentAddress1.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctPermanentAddress1,
      })
      //correctPermanentAddress2
      this.birthCorrectionList.push({
        fieldcode: 12,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctPermanentAddress2.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctPermanentAddress2,
      })
      //correctPermanentAddress3
      this.birthCorrectionList.push({
        fieldcode: 13,
        fielddesc: "",
        fieldmaxlength: Number(response?.requisitionData?.correctPermanentAddress3.length),
        fieldtype: "",
        fieldvalue: response?.requisitionData?.correctPermanentAddress3,
      })
    }
    var count = 0;
    console.log(response?.fileList, 'response?.fileList')
    response?.fileList.forEach((element: any) => {
      count = count + 1;
      this.commonService.getRequest(this.commonConstants.fileDownload + element.blobRef).subscribe({
        next: (responseData: any) => {
          // this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
          console.log('file contents', responseData.result.fileContents)
          if (count == 1) {
            this.postBirthResultObj = responseData.result.fileContents + ",";

          }
          else {
            this.postBirthResultObj = this.postBirthResultObj + "," + responseData.result.fileContents
          }
          console.log("polstweblandlength", this.postBirthResultObj.length);
          if (count === response?.fileList.length) {
            let obj = {
              pOST_BirthCorrections_InputMessage: {
                birthCorrections: {
                  rucode: response?.requisitionData?.registrationUnitId,//this.sf['searchRegUnitId'].value.oFFICEID,
                  requestyear: String(year),
                  mtransid: response?.id,//response?.requisitionData?. ,
                  pdate: dateFormat,//response?.requisitionData?. ,
                  applsurname: response?.requisitionHeader?.applicantInfo?.personNames[0]?.lastName,
                  applname: response?.requisitionHeader?.applicantInfo?.personNames[0]?.firstName + " "
                    + response?.requisitionHeader?.applicantInfo?.personNames[0]?.middleName + " " +
                    response?.requisitionHeader?.applicantInfo?.personNames[0]?.lastName,
                  applrelation: "",//response?.requisitionData?. ,
                  address1: response?.requisitionHeader?.applicantInfo?.addresses[0]?.doorNo,
                  address2: response?.requisitionHeader?.applicantInfo?.addresses[0]?.district + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.mandalMunicipality,
                  address3: response?.requisitionHeader?.applicantInfo?.addresses[0]?.villageWard + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode,
                  emailid: "xyz@gmail.com",  //response?.requisitionHeader?.applicantInfo?.emailAddress ,
                  mobileno: response?.requisitionHeader?.applicantInfo?.mobileNumber,
                  applremarks: response?.requisitionHeader?.remarks,
                  correctionsList: JSON.stringify(this.birthCorrectionList),
                  // {
                  //     fieldcode: fieldListData?.fieldcode,
                  //     fielddesc: fieldListData?.fielddesc,
                  //     fieldmaxlength: fieldListData?.fieldmaxlength,
                  //     fieldtype: fieldListData?.fieldtype,
                  //     fieldvalue: fieldListData?.fieldvalue
                  // },
                  regYear: response?.requisitionData?.registrationYear,
                  regNo: response?.requisitionData?.registrationNumber,
                  attachFile: JSON.stringify(this.postBirthResultObj),  //{},
                  userid: "UCRBDS",
                  password: "UCRBDSAPITCGMCMCMPTYGP"
                }
              }
            };
            this.commonService
              .postRequestForWebLand(this.commonConstants.BirthCorrections, obj)
              .subscribe({
                next: (responseData: any) => {
                  console.log(responseData.pOST_BirthCorrections_OutputMessage.birthCorrectionsResponse);
                  // this.hospitals = responseData.pOST_BirthCorrections_OutputMessage.birthCorrectionsResponse;
                },
              });
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    });

  }



  fileObj: any = {};
  postBirthNonAvailabilityObj: any;
  posData: any
  outputFile: any;
  byte: any = [];
  bytearray: any = [];
  postBirthNonAvailabilityMethod(response: any) {
    // console.log("Header",response?.requisition.requisitionHeader);
    // console.log("data",responseData?.requisition.requisitionData);
    // console.log( "name",responseData?.requisition.requisitionData.childName);


    // console.log("test",response?.requisition.requisitionData);


    response?.requisition?.fileList.forEach((element: any) => {
      this.commonService.getRequest(this.commonConstants.fileDownload + element.blobRef).subscribe({
        next: (responseData: any) => {

          console.log('file contents', responseData.result.fileContents)
          this.fileObj = responseData.result.fileContents;


          // console.log(responseData.result.fileContents, "responsefile")
          // console.log(this.fileObj, "fileobj")
          // // console.log("aa",response?.requisition.requisitionData.registrationUnitId);
          // console.log( "name",response?.requisition.requisitionData.childName);
          // const base64 = this.fileObj;
          // const imageName = 'name.png';
          // const imageBlob = this.dataURItoBlob(base64);

          // const imageFile = new File([imageBlob], imageName, { type: 'image/png' });      
          // console.log("this.fileObj",imageFile);



          let obj = {
            pOST_NonAvailabilityBirth_InputMessage: {
              nonAvailabilityBirth: {

                rucode: "50030",
                requestyear: new Date().getFullYear().toString(),
                mtransid: response.requisition?.id,
                pdate: this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear().toString(),
                applsurname: (response.requisition.requisitionHeader.applicantInfo.personNames[0].lastName) ? (response.requisition.requisitionHeader.applicantInfo.personNames[0].firstName) : '-',
                applname: (response.requisition.requisitionHeader.applicantInfo.personNames[0].firstName) ? (response.requisition.requisitionHeader.applicantInfo.personNames[0].firstName) : '-',
                applrelation: response?.requisition.requisitionHeader?.applicantInfo.personNames[0].fatherHusbandName,
                applparentssurname: response?.requisition.requisitionHeader?.applicantInfo.personNames[0].firstName,
                applparentsname: response.requisition.requisitionHeader.applicantInfo.personNames[0].firstName,
                address1: response?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.doorNo, // + "," + response?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.streetName,
                address2: response?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.district + " " + response?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.mandalMunicipality,
                address3: response?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.villageWard + " " + response?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode,
                emailid: response.requisition?.requisitionHeader.applicantInfo.emailAddress,
                mobileno: response.requisition?.requisitionHeader.applicantInfo.mobileNumber,
                applremarks: response.requisition?.requisitionHeader.remarks,
                cname: response?.requisition.requisitionData.childName,
                childrelation: response.requisition?.requisitionData.relationWithChild,
                fsurname: response.requisition?.requisitionData?.fatherSurName,
                fname: response.requisition?.requisitionData.fatherName,
                msurname: response.requisition?.requisitionData.motherSurName,
                mname: response.requisition?.requisitionData.motherName,
                dtbirth: response.requisition?.requisitionData.dateOfBirth,
                placeofbirth: response.requisition?.requisitionData.placeOfBirth,
                hosname: response.requisition?.requisitionData.locationName,
                rhaddress1: response.requisition?.requisitionData.address1,
                rhaddress2: response.requisition?.requisitionData.address2,
                rhaddress3: response.requisition?.requisitionData.address3,
                otherplace: response.requisition?.requisitionData.locationName,
                attachFile: this.fileObj,
                userid: "UCRBDS",
                password: "UCRBDSAPITCGMCMCMPTYGP"
              }
            }
          }
          console.log(obj);
          this.commonService
            .postRequestForWebLand(this.commonConstants.postNonAvailabilityOfBirth, obj)
            .subscribe({
              next: (responseData: any) => {

                console.log("cc", responseData.pOST_NonAvailabilityBirth_OutputMessage.nonAvailabilityBirthResponse);

                // console.log(this.location);
              },
              error: (error) => {
                console.log('client side', error);
              },
            });

        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    });
  }

  postDeathNonAvailabilityObj: any;
  postDeathNonAvailabilityMethod(responseData: any) {
    responseData?.requisition?.fileList.forEach((element: any) => {
      this.commonService.getRequest(this.commonConstants.fileDownload + element.blobRef).subscribe({
        next: (response: any) => {

          console.log('file contents', response.result.fileContents)
          this.fileObj = response.result.fileContents;
          console.log(response.result.fileContents, "responsefile")
          console.log(this.fileObj, "fileobj")


          var posData = {
            pOST_NonAvailabilityDeath_InputMessage: {
              nonAvailabilityDeath: {
                rucode: responseData.requisition.requisitionData.registrationUnitId,
                requestyear: new Date().getFullYear().toString(),
                mtransid: responseData.requisition.id,
                pdate: this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear().toString(),
                applsurname: responseData.requisition.requisitionHeader.applicantInfo.personNames[0].lastName,
                applname: responseData.requisition.requisitionHeader.applicantInfo.personNames[0].firstName,
                applrelation: responseData.requisition.requisitionData.relationWithParent,
                applparentssurname: responseData.requisition.requisitionHeader.applicantInfo.personNames[0].firstName,
                applparentsname: responseData.requisition.requisitionHeader.applicantInfo.personNames[0].firstName,
                address1: responseData?.requisition.requisitionHeader?.applicantInfo?.addresses[0]?.doorNo, //+ "," + responseData?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.streetName,
                address2: responseData?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.district + "," + responseData?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.mandalMunicipality,
                address3: responseData?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.villageWard + "," + responseData?.requisition?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode,
                emailid: responseData.requisition?.requisitionHeader.applicantInfo.emailAddress,
                mobileno: responseData.requisition?.requisitionHeader.applicantInfo.mobileNumber,
                applremarks: responseData.requisition?.requisitionHeader.remarks,
                dsurname: responseData.requisition?.requisitionData.deceasedSurName,
                dname: responseData.requisition?.requisitionData.deceasedName,
                deceasedrelation: responseData.requisition.requisitionData.relationWithParent,
                fsurname: responseData.requisition?.requisitionData.fatherSurName,
                fname: responseData.requisition?.requisitionData.fatherName,
                msurname: responseData.requisition?.requisitionData.motherSurName,
                mname: responseData.requisition?.requisitionData.motherName,
                dtdeath: responseData.requisition?.requisitionData.dateOfDeath,
                placeofdeath: responseData.requisition?.requisitionData.placeOfDeath,
                hosname: responseData.requisition?.requisitionData.locationName,
                rhaddress1: responseData.requisition?.requisitionData.address1,
                rhaddress2: responseData.requisition?.requisitionData.address2,
                rhaddress3: responseData.requisition?.requisitionData.address3,
                otherplace: responseData.requisition?.requisitionData.locationName,
                attachFile: this.fileObj,
                userid: "UCRBDS",
                password: "UCRBDSAPITCGMCMCMPTYGP"
              }
            }
          }
          console.log(posData);
          this.commonService
            .postRequestForWebLand(this.commonConstants.postNonAvailabilityOfDeath, posData)
            .subscribe({
              next: (responseData: any) => {

                console.log("cc", responseData.pOST_NonAvailabilityDeath_OutputMessage.nonAvailabilityDeathResponse);

                // console.log(this.location);
              },
              error: (error) => {
                console.log('client side', error);
              },
            });

        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    });
  }


  //abhi working 
  weblandPostingForMutationandTitleDeed(response: any) {
    console.log(response);

    let postArrayData: any[] = []
    let successRes: any[] = []
    let failureRes: any[] = []
    let sixtyFourBit = "";
    let sign1 = "";
    let photoField: any;
    let signatureField: any;
    console.log('MutationTitleDeedPPB')
    console.log('response?.requisitionData?.passportDataByteArray', response?.requisitionData?.passportDataByteArray)
    console.log('response?.requisitionData?.signatureByteArray', response?.requisitionData?.signatureByteArray)
    photoField = response?.requisitionData?.passportDataByteArray //this.convertDataURIToBinary(response?.requisitionData?.passportDataByteArray)
    signatureField = response?.requisitionData?.signatureByteArray //this.convertDataURIToBinary(response?.requisitionData?.signatureByteArray)

    // var finalOBj= "";
    // response?.fileList.forEach((element: any) => {
    //   var bitObject = this.get64BitFileConversion(element.blobRef)
    //   finalOBj = finalOBj + "," + bitObject ;
    // });

    // if(element.documentType=== "Passport size photo"){
    //   photoField= element
    // }
    // if(element.documentType=== "Signature"){
    //   signatureField= element
    // }

    let mutReason = ""; //code
    let reqType = ""  //subCode
    let reglink: any = '';
    if (response?.requisitionData?.registrationOutput !== '') {
      let regData = JSON.parse(response?.requisitionData?.registrationOutput);
      reglink = regData[0]?.DOCLINK;
    }
    if (response?.requisitionData?.mutationCategory?.trim() == "Succession") {
      let successionSubType: any = response?.requisitionData?.successionSubCatg?.trim();
      mutReason = "1006";
      if (successionSubType === 'Registered Will') {
        reqType = "1";
      } else if (successionSubType === 'Partition within Family') {
        reqType = "2";
      } else if (successionSubType === 'FMC (Single Legal Heir)') {
        reqType = "3";
      }
    } else {
      reqType = '0';
      let category = response?.requisitionData?.mutationCategory.trim();
      if (category == "Sale") {
        mutReason = "1001";
      } else if (category == "Gift") {
        mutReason = "1002";
      } else if (category == "Exchange") {
        mutReason = "1004";
      } else if (category == "Release") {
        mutReason = "1005";
      } else if (category == "Partition Deed Outside Family") {
        mutReason = "1003";
      }
    }

    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "/" + month + "/" + year
    // console.log('todat date ',this.formatDate(response?.requisitionData?.mutationCategoryInfo[0]?.documentDate,"YYYY-MM-DDThh:mm:ss[Z]","DD/MM/YYYY"))
    this.aadharNum = "";
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(response?.requisitionHeader?.applicantInfo?.aadhaarNumber))
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'decrypt value respose data');
          this.aadharNum = this.commonService.RSADecrypt(responseData.result.rawValue);
          console.log(this.aadharNum, this.aadharNum.length, 'this.commonFormAddedFields.aadharNo')
          if (this.aadharNum.length > 1) {
            console.log(this.aadharNum, 'this if .aadharNo')

            for (let i = 0; i < response?.requisitionData?.mutationCategoryInfo.length; i++) {
              console.log("==response", response?.requisitionData?.mutationCategoryInfo[i]);
              this.postWeblandData = {
                mutationForTranscation: {
                  uid: "Land_WLU",
                  pwd: "UL@nD@2023",
                  appl_no: response?.id,
                  appl_name: response?.requisitionHeader?.applicantInfo?.personNames[0]?.firstName.trim(),
                  appl_foh_name: response?.requisitionHeader?.applicantInfo?.personNames[0]?.fatherHusbandName.trim(),
                  appl_addr1: response?.requisitionHeader?.applicantInfo?.addresses[1]?.doorNo,//+ "," + response?.requisitionHeader?.applicantInfo?.addresses[0]?.streetName,
                  appl_addr2: this.districts + "," + this.mandals,
                  appl_addr3: this.villages + "," + response?.requisitionHeader?.applicantInfo?.addresses[1]?.streetName + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode,
                  appl_date: dateFormat,
                  appl_slno: i + 1,//response?.requisitionData?.nriIdNo,    //needed in loop as per survy no recors ingrid 
                  dist_code: Number(response?.requisitionData?.documentAddressdetails?.district),
                  mand_code: Number(response?.requisitionData?.documentAddressdetails?.mandalMunicipality),
                  vill_code: Number(response?.requisitionData?.documentAddressdetails?.villageWard),
                  appl_syno: response?.requisitionData?.mutationCategoryInfo[i]?.surveyNo.trim(),
                  syno_ext: response?.requisitionData?.mutationCategoryInfo[i]?.extentArea, // 3,//
                  req_type: reqType, //response?.requisitionData?.mutationCategory.trim(),  //02
                  mut_reason: mutReason,//response?.requisitionData?.mutationSubCategory.trim(),
                  doc_no: reqType === '3' ? null : response?.requisitionData?.mutationCategoryInfo[i]?.documentNo,
                  doc_date: reqType === '3' ? null : this.formatDate(response?.requisitionData?.mutationCategoryInfo[i]?.documentDate, "YYYY-MM-DDThh:mm:ss[Z]", "DD/MM/YYYY"), //response?.requisitionData?.mutationCategoryInfo[0]?.documentDate,
                  doc_sro_name: reqType === '3' ? null : response?.requisitionData?.registrationInput?.regSRO?.split('-')[1],
                  owner_name: response?.requisitionData?.mutationCategoryInfo[i]?.successorName,
                  status_type: "P",
                  khataNumber: response?.requisitionData?.mutationCategoryInfo[i]?.khataNo,
                  caste: this.casteSeeva,
                  photo: photoField,  //BASE 64 string format Data
                  divisionName: response?.requisitionData?.division.split(',')[0],
                  signature: signatureField,
                  doc_year: reqType === '3' ? null : this.formatDate(response?.requisitionData?.mutationCategoryInfo[i]?.documentDate, "YYYY-MM-DDThh:mm:ss[Z]", "YYYY"), //docYear,  ///current year from june to july
                  aadhaarno: this.commonService.RSAEncrypt(this.aadharNum, true), //response?.requisitionHeader?.applicantInfo?.aadhaarNumber,
                  gender: response?.requisitionHeader?.applicantInfo?.gender,
                  mobile_no: response?.requisitionHeader?.applicantInfo?.mobileNumber,
                  fMCN: reqType === '3' ? response?.requisitionData?.fmbAppNo : '',
                  typeOfTranscation: response?.requisitionData?.mutationCategory,
                  owner_Foh_Name: response?.requisitionData?.mutationCategoryInfo[i]?.fatherName,
                  passbooktype: response?.requisitionData?.passbookType === 'ePassbook' ? '1' : '2',
                  nOD: reqType === '3' ? response?.requisitionData?.mutationCategoryInfo[i].deceasedName : '',
                  nORD: reqType === '3' ? response?.requisitionData?.mutationCategoryInfo[i].relationwithDeceased : '',
                  sellerAadhaarNo: response?.requisitionData?.mutationCategoryInfo[i]?.sellerAadhar,
                  buyetKhata: response?.requisitionData?.buyerKhataNo,
                  registrationLink: reglink
                }
              }

              postArrayData.push({
                mutationForTranscation: {
                  uid: "Land_WLU",
                  pwd: "UL@nD@2023",
                  appl_no: response?.id,
                  appl_name: response?.requisitionHeader?.applicantInfo?.personNames[0]?.firstName.trim(),
                  appl_foh_name: response?.requisitionHeader?.applicantInfo?.personNames[0]?.fatherHusbandName.trim(),
                  appl_addr1: response?.requisitionHeader?.applicantInfo?.addresses[1]?.doorNo,//+ "," + response?.requisitionHeader?.applicantInfo?.addresses[0]?.streetName,
                  appl_addr2: this.districts + "," + this.mandals,
                  appl_addr3: this.villages + "," + response?.requisitionHeader?.applicantInfo?.addresses[1]?.streetName + " " + response?.requisitionHeader?.applicantInfo?.addresses[0]?.pinCode,
                  appl_date: dateFormat,
                  appl_slno: i + 1,//response?.requisitionData?.nriIdNo,    //needed in loop as per survy no recors ingrid 
                  dist_code: Number(response?.requisitionData?.documentAddressdetails?.district),
                  mand_code: Number(response?.requisitionData?.documentAddressdetails?.mandalMunicipality),
                  vill_code: Number(response?.requisitionData?.documentAddressdetails?.villageWard),
                  appl_syno: response?.requisitionData?.mutationCategoryInfo[i]?.surveyNo.trim(),
                  syno_ext: response?.requisitionData?.mutationCategoryInfo[i]?.extentArea, // 3,//
                  req_type: reqType, //response?.requisitionData?.mutationCategory.trim(),  //02
                  mut_reason: mutReason,//response?.requisitionData?.mutationSubCategory.trim(),
                  doc_no: reqType === '3' ? null : response?.requisitionData?.mutationCategoryInfo[i]?.documentNo,
                  doc_date: reqType === '3' ? null : this.formatDate(response?.requisitionData?.mutationCategoryInfo[i]?.documentDate, "YYYY-MM-DDThh:mm:ss[Z]", "DD/MM/YYYY"), //response?.requisitionData?.mutationCategoryInfo[0]?.documentDate,
                  doc_sro_name: reqType === '3' ? null : response?.requisitionData?.registrationInput?.regSRO?.split('-')[1],
                  owner_name: response?.requisitionData?.mutationCategoryInfo[i]?.successorName,
                  status_type: "P",
                  khataNumber: response?.requisitionData?.mutationCategoryInfo[i]?.khataNo,
                  caste: this.casteSeeva,
                  photo: photoField,  //BASE 64 string format Data
                  divisionName: response?.requisitionData?.division.split(',')[0],
                  signature: signatureField,
                  doc_year: reqType === '3' ? null : this.formatDate(response?.requisitionData?.mutationCategoryInfo[i]?.documentDate, "YYYY-MM-DDThh:mm:ss[Z]", "YYYY"), //docYear,  ///current year from june to july
                  aadhaarno: this.aadharNum, //response?.requisitionHeader?.applicantInfo?.aadhaarNumber,
                  gender: response?.requisitionHeader?.applicantInfo?.gender,
                  mobile_no: response?.requisitionHeader?.applicantInfo?.mobileNumber,
                  fMCN: reqType === '3' ? response?.requisitionData?.fmbAppNo : '',
                  typeOfTranscation: response?.requisitionData?.mutationCategory,
                  owner_Foh_Name: response?.requisitionData?.mutationCategoryInfo[i]?.fatherName,
                  passbooktype: response?.requisitionData?.passbookType === 'ePassbook' ? '1' : '2',
                  nOD: reqType === '3' ? response?.requisitionData?.mutationCategoryInfo[i].deceasedName : '',
                  nORD: reqType === '3' ? response?.requisitionData?.mutationCategoryInfo[i].relationwithDeceased : '',
                  sellerAadhaarNo: response?.requisitionData?.mutationCategoryInfo[i]?.sellerAadhar,
                  buyetKhata: response?.requisitionData?.buyerKhataNo,
                  registrationLink: reglink
                }
              })

              // console.log('weblandPostingFor MutationandTitleDeed',this.postWeblandData)
              this.commonService.postRequestForWebLand(this.commonConstants.postMutationTransactions, this.postWeblandData).subscribe({
                next: (responseData: any) => {
                  // console.log('repsonse data',responseData.applyMutationAndPPBResponse?.applyMutationAndPPBResult)
                  // postArrayData.push(this.postWeblandData)
                  if (responseData.mutationForTranscationResponse?.mutationForTranscationResult.includes('uccess')) {
                    successRes.push(responseData.mutationForTranscationResponse?.mutationForTranscationResult)
                  } else {
                    failureRes.push(responseData.mutationForTranscationResponse?.mutationForTranscationResult)
                  }
                  console.log('i +1', i + 1, response?.requisitionData?.mutationCategoryInfo.length);

                  if (i + 1 == response?.requisitionData?.mutationCategoryInfo.length) {
                    // console.log('i +1', i +1 , response?.requisitionData?.mutationCategoryInfo.length);
                    // console.log('successRes side', successRes);
                    // console.log('failureRes side', failureRes);
                    // console.log('postArrayData',postArrayData)
                    if (successRes.length > 0) {
                      this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, successRes[0], postArrayData)
                    }
                    if (failureRes.length > 0) {
                      this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, failureRes[0], postArrayData)
                    }
                  }
                  // this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType,response,responseData.applyMutationAndPPBResponse?.applyMutationAndPPBResult,this.postWeblandData)
                },
                error: (error) => {
                  console.log('client side', error);
                }
              });
            }

          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });


  }

  //abhi working 
  weblandPostingForPattadarMobileNoSeeding(response: any) {
    this.aadharNum = "";
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(response?.requisitionHeader?.applicantInfo?.aadhaarNumber))
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'decrypt value respose data');
          this.aadharNum = this.commonService.RSADecrypt(responseData.result.rawValue);
          //console.log(this.aadharNum,'this.commonFormAddedFields.aadharNo')
          if (this.aadharNum.length > 1) {

            this.postWeblandData = {
              updateMObileinPattadar: {
                dcode: response?.requisitionData?.district,
                mcode: response?.requisitionData?.mandalMunicipality,
                vCode: response?.requisitionData?.villageWard,
                khata: response?.requisitionData?.khataNo,
                aid: this.aadharNum,//response?.requisitionHeader?.applicantInfo?.aadhaarNumber,  //aadhar no  
                mobile: response?.requisitionData?.mobileNo,
                uid: "Land_WLU",
                pwd: "UL@nD@2023"
              }
            }
            console.log('weblandPostingFor PattadarMobileNoSeeding', this.postWeblandData)
            this.commonService.postRequestForWebLand(this.commonConstants.updateMobileinPattadar, this.postWeblandData).subscribe({
              next: (responseData: any) => {
                console.log('repsonse data', responseData.updateMObileinPattadarResponse?.updateMObileinPattadarResult)
                this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.updateMObileinPattadarResponse?.updateMObileinPattadarResult, this.postWeblandData)
              },
              error: (error) => {
                console.log('client side', error);
              }
            });

          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });


  }

  //abhi running fine 
  weblandPostingForOldAdangal(response: any) {
    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "/" + month + "/" + year
    let yearSplit = response?.requisitionData?.fasliYear?.split('(');
    console.log(yearSplit[0], 'yearSPlit')
    this.postWeblandData = {
      applyOldPahani: {
        userId: "Land_WLU",
        password: "UL@nD@2023",
        appl_no: response?.id,
        appl_name: response?.requisitionHeader?.applicantInfo?.personNames[0]?.firstName + " "
          + response?.requisitionHeader?.applicantInfo?.personNames[0]?.middleName + " " +
          response?.requisitionHeader?.applicantInfo?.personNames[0]?.lastName,
        appl_foh_name: response?.requisitionHeader?.applicantInfo?.personNames[0]?.fatherHusbandName,
        appl_addr1: response?.requisitionHeader?.applicantInfo?.addresses[1]?.doorNo + " " +
          // response?.requisitionHeader?.applicantInfo?.addresses[0]?.streetName + " " +
          this.districts + " " +
          this.mandals + " " +
          this.villages + " " +
          response?.requisitionHeader?.applicantInfo?.addresses[1]?.pinCode,
        appl_addr2: response?.requisitionHeader?.applicantInfo?.mobileNumber,

        appl_addr3: "",
        appl_date: dateFormat,
        appl_slno: 1, //1.0,
        khataNo: response?.requisitionData?.khataNo,
        pattdarName: response?.requisitionData?.pattadharName?.firstName,
        dist_code: response?.requisitionData?.selectionCriteriaDetails?.district,
        mand_code: response?.requisitionData?.selectionCriteriaDetails?.mandalMunicipality,
        vill_code: response?.requisitionData?.selectionCriteriaDetails?.villageWard,
        appl_syno: response?.requisitionData?.surveyNo,
        req_type: "O",  //"O"
        year: yearSplit[0] //response?.requisitionData?.fasliYear
      }
    }
    console.log('weblandPostingFor OldAdangal', this.postWeblandData)
    this.commonService.postRequestForWebLand(this.commonConstants.applyOldPahani, this.postWeblandData).subscribe({
      next: (responseData: any) => {
        console.log('repsonse data', responseData.applyOldPahaniResponse?.applyOldPahaniResult)
        this.wFlowGetSLAData(response?.requisitionHeader?.requisitionType, response, responseData.applyOldPahaniResponse?.applyOldPahaniResult, this.postWeblandData)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  documentYear(value: any) {
    console.log('original date value', value);
    // console.log('Moment date value',  moment(value,"DD-MM-YYYY").format("yyyy"));
    let date = value.split('-');
    let day = parseInt(date[0], 10);
    let month = parseInt(date[1], 10);
    let year = parseInt(date[2], 10);
    console.log('iso', parseInt(date[0], 10));
    return parseInt(date[0], 10);
  }

  formatDate(value: any, inputFormat: any, outputFormat: any) {
    console.log('original date value', value);
    console.log('Moment date value', moment(value, inputFormat).format(outputFormat));
    let date = value.split('-');
    let day = parseInt(date[0], 10);
    let month = parseInt(date[1], 10);
    let year = parseInt(date[2], 10);
    let dateVal = new Date(month + '-' + day + '-' + year);
    //console.log('iso', dateVal.toISOString());
    //console.log('utc', dateVal.toISOString());
    //console.log('moment',moment(dateVal).format('YYYY-MM-DDThh:mm:ss-hh'));
    //return moment(value,"dd-MM-yyyy") 
    return moment(value, inputFormat).format(outputFormat);
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    word = word.toLowerCase();
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }


  get64BitFileConversion(blobRef: any) {
    this.commonService.getRequest(this.commonConstants.fileDownload + blobRef).subscribe({
      next: (responseData: any) => {
        // this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
        console.log('file contents', responseData.result.fileContents)
        return responseData.result.fileContents

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }


  public printReceipt() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }

  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('__printingFrame');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>Receipt</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      "<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }


  printReceipt_with_page_reload() {

    const printContent: HTMLElement | null =
      document.getElementById('__printingFrame');

    if (printContent != null) {
      window.document.write(
        '<html><head><title>PaymentReceipt</title><link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"></head><body><br>'
      );
      window.document.write(printContent.innerHTML);
      window.document.write('</body></html>');
      window.document.close();
      setTimeout(() => {
        window.focus();
        window.print();
        window.location.reload();
      }, 1000);
    }
  }

  public intToEnglishWordConverter(number: number): any {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Forty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglishWordConverter(t) +
              ' ' +
              n.str +
              ' ' +
              this.intToEnglishWordConverter(d)
            );
          } else {
            return this.intToEnglishWordConverter(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }
  // childnameinclusion postwebland starts
  // fileObj: any = {};
  webpostBirthNameInclusion(response: any) {
    console.log(response, "response");
    console.log(this.currentDate.getFullYear());
    let year = this.currentDate.getFullYear();
    let date = this.currentDate.getDate() + '/' + this.currentDate.getMonth() + '/' + this.currentDate.getFullYear();
    console.log(response.requisition.fileList, "response");
    response?.requisition.fileList.forEach((element: any) => {
      console.log(element, "element");
      this.commonService.getRequest(this.commonConstants.fileDownload + element.blobRef).subscribe({
        next: (responseData: any) => {
          console.log('file contents', responseData.result.fileContents)
          this.fileObj = responseData.result.fileContents;
          console.log(responseData.result.fileContents, "responsefile")
          console.log(this.fileObj, "fileobj")
          let obj = {
            pOST_BirthNameInclusion_InputMessage: {
              birthNameInclusion: {
                rucode: response?.requisition.requisitionData.registrationUnitId,
                requestyear: year.toString(),
                mtransid: response.requisition.id,
                pdate: date,
                applsurname: response?.requisition.requisitionHeader.applicantInfo.personNames[0].lastName,
                applname: response?.requisition.requisitionHeader.applicantInfo.personNames[0].firstName,
                applrelation: response?.requisition.requisitionHeader.applicantInfo.personNames[0].fatherHusbandName,
                address1: response?.requisition.requisitionData.birthAddress ? response?.requisition.requisitionData.birthAddress : "",
                address2: response?.requisition.requisitionData.permanentAddress ? response?.requisition.requisitionData.permanentAddress : "",
                address3: response?.requisition.requisitionData.residenceAddress ? response?.requisition.requisitionData.residenceAddress : "",
                emailid: response?.requisition.requisitionHeader.applicantInfo.emailAddress,
                mobileno: response?.requisition.requisitionHeader.applicantInfo.mobileNumber,
                applremarks: response?.requisition.requisitionHeader.remarks ? response?.requisition.requisitionHeader.remarks : "",
                csurname: response?.requisition.requisitionData.changedChildSurName ? response?.requisition.requisitionData.changedChildSurName : "",
                cname: response?.requisition.requisitionData.changedChildName ? response?.requisition.requisitionData.changedChildName : "",
                regYear: response?.requisition.requisitionData.registrationYear ? response?.requisition.requisitionData.registrationYear : "",
                regNo: response?.requisition.requisitionData.registrationNumber,
                // attachFile: Object.assign(this.fileObj),
                // attachFile: {
                //   obj: this.fileObj
                // },
                attachFile: this.fileObj,
                userid: "UCRBDS",
                password: "UCRBDSAPITCGMCMCMPTYGP"
              }
            }
          }
          console.log(obj, "postdepa");
          this.commonService
            .postRequestForWebLand(this.commonConstants.postBirthNameInclusion, obj)
            .subscribe({
              next: (responseData: any) => {
                console.log(responseData);
              },
              error: (error) => {
                console.log('client side', error);
              },
            });

        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    });

  }
  // childnameinclusion postwebland ends

  getPrintedCertificate_old() {

    /* if (
      /^vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {   
      this.downLoadCertificate();

    }else{ */

    let inputData = "?UserId=" + this.userInfo.userPrincipalName + "&RequisitionId=" + this.requisitionID + "&TransactionId=" + this.transactionID;
    this.commonService.getRequest(this.commonConstants.getPrintedCertificate + inputData).subscribe({
      next: (responseData: any) => {

        console.log('getPrintedCertificate', responseData)
        let printedDate = "";
        let todaysDate = new Date().toJSON().split("T")[0]; //new Date().toISOString().slice(0, 10);

        let copies = 0;
        if (responseData.result.length == 0) {
          copies = 0;
        } else {
          copies = responseData.result[0].noOfCopy;
          printedDate = responseData.result[0].printedDate.split("T")[0];
        }
        // console.log(responseData.result.length+">>"+printedDate,"printedDate")
        console.log(todaysDate + ">>" + printedDate, "coppies")
        if ((responseData.result.length == 0) || (todaysDate == printedDate)) {
          this.savePrintedCertificate_old(copies);
          this.downLoadCertificate('');
        } else {
          this.alertPopupForNoRecords(printedDate);
        }

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
    //}
  }

  savePrintedCertificate_old(noOfCopy: any) {

    let postData = {
      userId: this.userInfo.userPrincipalName,
      serviceName: this.serviceName,
      serviceCode: this.requisitionInfo.serviceCode,
      requisitionId: this.requisitionID,
      department: this.departmentCode,
      transactionId: this.transactionID,
      noOfCopy: (noOfCopy + 1),
    }

    this.commonService.postRequest(this.commonConstants.savePrintedCertificate, postData).subscribe({
      next: (responseData: any) => {
        console.log('savePrintedCertificate', responseData)

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  submit(content: any): any {
    this.submitted = true;
    if (!this.stationaryForm.valid) {
      return false;
    } else {

      //this.getPrintedCertificate(this.fc['stationary_number'].value,content);
      let invites: any = [];
      for (var i = 0; i < this.copiesData.controls.length; i++) {
        console.log(this.copiesData.controls[i].get('stationary_number')?.value)
        invites.push({ stationary_number: this.copiesData.controls[i].get('stationary_number')?.value })
      }
      console.log(invites + 'invites')
      // const findDuplicateVal = (invites:any=[]) => invites.filter((item:any, index: any) => invites.indexOf(item) != index);

      // console.log(findDuplicateVal(invites).join(','));
      this.duplicateRecords = invites.some((user: any) => {
        let counter = 0;
        for (const iterator of invites) {
          if (iterator.stationary_number === user.stationary_number) {
            counter += 1;
          }
        }
        return counter > 1;
      }); // returns true if duplicate values are there in array

      if (this.duplicateRecords === true) {

        //       const findDuplicateVal = (invites:any=[]) => invites.filter((item:any, index: any) => invites.indexOf(item) != index);

        // console.log(findDuplicateVal(invites).join(','));
        // 
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = 'Duplicate stationary serial numbers entered..!!';
        return;

      } else {
        const origins = [...new Set(invites.map((invite: any) => invite.stationary_number))];
        console.log('origins', JSON.stringify(origins.join(',')));
        let stationayNumbers = origins.join(',')
        console.log(stationayNumbers)
        this.getPrintedCertificate(stationayNumbers, content);
      }


    }

  }
  responsedate: any;
  getPrintedCertificate(id: any, content: any) {

    let closePopupBtn: HTMLElement = document.getElementById(
      'closePopup'
    ) as HTMLElement;

    let inputData = "?UserId=" + this.userInfo.userPrincipalName + "&StationaryNumber=" + id + "&TransactionId=" + this.transactionID;
    this.commonService.getRequest(this.commonConstants.getPrintedCertificate + inputData).subscribe({
      next: (responseData: any) => {

        /*  let printedDate = "";
        let todaysDate =   new Date().toJSON().split("T")[0]; //new Date().toISOString().slice(0, 10);
        let copies = 0;
        if(responseData.result.length == 0){
          copies = 0;
        }else{
          copies = responseData.result[0].noOfCopy;
          printedDate = responseData.result[0].printedDate.split("T")[0];
        }
        // if((responseData.result.length == 0) || (todaysDate == printedDate)){
          //  if((responseData.result.length == 0) ){ */

        console.log('getPrintedCertificate', responseData)

        /* if(responseData?.result?.insertFlag === 'Y' ){
             closePopupBtn?.click();
             this.savePrintedCertificate(id); */

        if ((responseData?.result?.isPrinted === false) && (responseData?.result?.stationaryExistList.length === 0)) {
          closePopupBtn?.click();
          this.savePrintedCertificate(id);
          /* if(this.print_certificate_mode ==='HTML' && this.is_categoryA == true){
             this.downloadCertificateFromGSWSHTML(content);
          }else{ */
          if (this.requisitionInfo?.serviceCode === 'SadaremPrintCertificate') {
            this.getSadaramCertificate('certificate');
          } else {
            this.downLoadCertificate(content);

          }

          //}

        } else {
          //this.alertPopupForNoRecords(responseData.result);
          if ((responseData?.result?.isPrinted === true)) {
            if (responseData?.result?.printedDate != null) {
              this.responsedate = `on  ${this.datepipe.transform((responseData?.result?.printedDate), 'dd/MM/yyyy')}.`
            }
            else {
              this.responsedate = ".";
            }
            this.commonService.commonErrorPopup('Alert', `You have already taken the print for this certificate ${this.responsedate}`, "md");
          }
          if (responseData?.result?.stationaryExistList.length > 0) {
            this.alertPopupForNoRecords(responseData?.result?.stationaryExistList.join(',') + ' stationary number already exists');
            // alert('stationary number already exsits + stationary numbers ')
          }
        }

      },
      error: (error) => {
        console.log('client side', error);
      }
    });

  }

  savePrintedCertificate(stationaryNumber: any) {
    let postData: any;
    if (this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'PrintCertificate' || this.applicationRequisiontionData?.requisitionHeader?.requisitionType === 'PrintCertificateForAarogyaSri') {
      postData = {
        userId: this.userInfo.userPrincipalName,
        serviceName: this.serviceName,
        serviceCode: this.requisitionInfo?.serviceCode,
        requisitionId: this.requisitionID,
        department: this.departmentCode,
        transactionId: this.transactionID,
        noOfCopy: this.applicationRequisiontionData?.requisitionData?.noOfCopies,
        StationaryNumber: stationaryNumber
      }
    } else {
      postData = {
        userId: this.userInfo.userPrincipalName,
        serviceName: this.serviceName,
        serviceCode: this.requisitionInfo?.serviceCode,
        requisitionId: this.requisitionID,
        department: this.departmentCode,
        transactionId: this.transactionID,
        noOfCopy: 1,
        StationaryNumber: stationaryNumber
      }
    }


    this.commonService.postRequest(this.commonConstants.savePrintedCertificate, postData).subscribe({
      next: (responseData: any) => {
        console.log('savePrintedCertificate', responseData)

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  alertPopupForNoRecords(printedDate: any) {

    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    //modalRef.componentInstance.message = 'You have already taken a print of certificate for this Application with this stationary number.';
    // modalRef.componentInstance.message = 'This stationary number is already used for print certicificate.Try with another stationary number.';
    modalRef.componentInstance.message = printedDate;
    modalRef.result.then((result: any) => { });
  }

  stationaryPopup(content: any, transactionContent: any) {
    if (this.userInfo.jobTitle === 'Citizen' || (this.departmentCode === 'HF' && this.requisitionInfo.serviceCode !== 'SadaremPrintCertificate') || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment'
      || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment@1' || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment@2' || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment@3') {
      this.downLoadCertificate(transactionContent);
    } else {
      this.stationaryForm.reset();

      this.modalService
        .open(content, {
          size: 'lg',
          ariaLabelledBy: 'modal-basic-title',
          windowClass: 'app-modal-window',
        })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  /* HTML certificate start for CAT A*/

  htmlDownloadCertificateFromGSWS(content: any) {

    let IsSigned = false;
    /* if(this.is_categoryA == false){
      IsSigned = true;
    } */

    this.commonService.getRequest(this.commonConstants.getSignedCertificateData + this.requisitionID + '&DepartmentCode=' + this.departmentCode + '&IsSigned=' + IsSigned + '&isHtml=true').subscribe({
      next: (responseData: any) => {

        console.log('responseData.result.fileContents', responseData)
        if (responseData.result.isSuccess == true) {

          let fileInfo = responseData.result.fileContents;
          let dataHTMl = this.b64DecodeUnicode(fileInfo);

          this.fileInfoHTMl = this.sanitizer.bypassSecurityTrustHtml(dataHTMl);

          console.log(this.fileInfoHTMl, 'fileInfoHTMl')
          this.transactionPopupHTML(content)
        } else {
          //regenreate Cat A HTML certificate
          this.htmlStraightThruGenerateCertificateData(content);
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }


  htmlStraightThruGenerateCertificateData(content: any) {

    if (this.departmentCode == "RV") {
      this.htmlGetCertficateTypeName(this.requisitionID, content);
    } else {

      var sendPostData = {
        RequisitionId: this.requisitionID,
        Department: this.departmentCode,
        CertificateType: this.certificateTypeName
      }
      console.log(sendPostData, 'post data')
      this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
        next: (responseData: any) => {
          // this.htmlGenerateCertificate(content);
          if (responseData.result.isSuccess == true) {
            this.htmlGenerateCertificate(content);
          } else {

            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Certificate data not available..!!';
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }

  htmlGetCertficateTypeName(reqId: any, content: any) {

    let count = this.commonConstants.certificateList.filter((item: any) => item.certName === this.requisitionTypeForCertificate);
    if (count.length === 1) {
      this.certificateTypeName = count[0].certID;
    } else if (count.length > 1) {
      if (this.requisitionTypeForCertificate == '22AModification' || this.requisitionTypeForCertificate == 'FLineApplication') {
      } else {
        count.forEach((element: any) => {
          if (element.subServiceType === this.subServiceType) {
            this.certificateTypeName = element.certID;
            console.log(this.certificateTypeName, 'this.certificateTypeName id')
          }
        });
      }
    }

    //genreate certifacate
    var sendPostData = {
      RequisitionId: this.requisitionID,
      Department: this.departmentCode,
      CertificateType: this.certificateTypeName
    }
    console.log(sendPostData, 'post data')
    this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
      next: (responseData: any) => {
        //this.htmlGenerateCertificate(content);
        if (responseData.result.isSuccess == true) {
          this.htmlGenerateCertificate(content);
        } else {

          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Certificate data not available..!!';
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });


  }

  getByReqIdDetails(reqId: any) {
    console.log(">>> c")
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + this.departmentCode + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId=' + reqId).subscribe({
      next: (responseData: any) => {
        let reqType: any = responseData.result?.requisitionHeader?.requisitionType;
        this.getByIdResponse = responseData.result;
        this.serviceCode = responseData.result?.requisitionHeader?.requisitionType;
        this.isWhatsAppRequired = responseData.result?.requisitionHeader?.applicantInfo?.isWhatsAppAvailable === 'Yes' ? true : false;
        this.villageName = responseData.result?.requisitionData?.khathaDetails?.villagewardName;
        //Identification ReIssuanceCertificate
        this.applicationRequisiontionData = responseData.result;
        this.sadaramId = responseData?.result?.requisitionData?.sadaremId;
        this.suomotoApplication = responseData?.result?.requisitionData?.suomotoApplicationNo,
          this.suomotoStatus = responseData?.result?.requisitionData?.suomotoApplicationStatus
        if (this.departmentCode === "HF") {
          this.uhidDownload = responseData?.result?.requisitionData?.uhid;
        }
        if (this.departmentCode === "RV" && (reqType === 'AddressCertificateForAadharEnrollment' || reqType === 'AddressCertificateForAadharEnrollment@1' || reqType === 'AddressCertificateForAadharEnrollment@2' || reqType === 'AddressCertificateForAadharEnrollment@3')) {
          this.departmentName = 'GSWS';
        }
        if (this.departmentCode === "RV" && (reqType === 'HouseholdSplit' || reqType === 'HouseholdSplit@1' || reqType === 'HouseholdSplit@2')) {
          this.departmentName = 'GSWS';
          this.hhsplitType = responseData?.result?.requisitionData?.serviceType;
        }
        if (this.departmentCode === "RV" && (reqType === 'RegistrationofTokens')) {
          this.departmentName = 'GSWS';
        }
        if (this.departmentCode === "RV" && (reqType === 'WaterTaxService')) {
          this.departmentName = 'GSWS';
          this.waterTax = responseData.result?.requisitionData?.paymentRequestsKhataDetails;
          this.amountToBePaid = this.getByIdResponse?.requisitionData?.amountToBePaid;
          // console.log(this.getByIdResponse?.requisitionData?.amountToBePaid,'amount to be paid');
          if (this.serviceName == 'WaterTaxService' && this.redirectedFrom === undefined && this.transactionStatus === "0300") {
            this.waterTaxPostingAmount();
          }
        }
        if (responseData.result?.requisitionData?.noOfCopies && (responseData.result?.requisitionHeader?.requisitionType === 'PrintCertificate' || responseData.result?.requisitionHeader?.requisitionType === 'PrintCertificateForAarogyaSri')) {
          this.noOfCopies = responseData.result?.requisitionData?.noOfCopies;
          //  this.copiesData = this.stationaryForm.get('copiesData') as FormArray;
          for (let i = 0; i < this.noOfCopies - 1; i++) {
            this.copiesData.push(this.createCopies())
          }
        }
        this.subServiceType = responseData.result?.requisitionData?.certificateType;
        this.isReissuance = responseData.result?.requisitionHeader?.requisitionType === 'ReIssuanceCertificateNew' ? true : false;
        if (this.isReissuance) {
          if (this.subServiceType === 'Income')
            this.reIssuanceSubservice = 'Re-Issuance of Income Certificate';
          else if (this.subServiceType === 'Integrated')
            this.reIssuanceSubservice = 'Re-Issuance of Integrated Certificate';
          else if (this.subServiceType === 'FMB')
            this.reIssuanceSubservice = 'Re-Issuance of Family Member Certificate';
          else if (this.subServiceType === 'LRB' || this.subServiceType === 'LRD')
            this.reIssuanceSubservice = 'Re-Issuance of Late Registration of Birth/Death Certificate';
        }

        this.requisitionType = responseData.result?.requisitionHeader?.requisitionType;
        if (responseData.result?.requisitionHeader?.requisitionType == 'RegistrationofTokens') {
          this.getTokenServiceList(reqId)
        }
        this.commonService
          .postRequest(this.commonConstants.receiptPageData, this.postDataforreceiptpagedata, true)
          .subscribe({
            next: (responseData: any) => {

              this.requisitionInfo = responseData?.result;
              if (this.redirectedFrom === undefined) {
                console.log("sendSMS");
                this.sendSMS(this.SLA);
                this.sendSMS(this.SLA, 'success');
              }
            }
          })

      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  getTokenServiceList(reqId: any) {
    this.commonService.getRequest(this.commonConstants.getJSTokenDetailsInViewTransactions + reqId)
      .subscribe({
        next: (responseData: any) => {
          if (responseData?.result?.isSuccess) {
            this.services = responseData?.result?.services.filter((x: any) => x.isAllDocuments == "Yes" && !x.isDeleted);
          } else {
            this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
  }
  htmlGenerateCertificate(content: any) {
    this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + this.requisitionID + '&Department=' + this.departmentCode + '&Mode=1').subscribe({
      next: (responseData: any) => {
        this.htmlDownloadCertificateFromGSWS(content);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  b64DecodeUnicode(str: any) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  transactionPopupHTML(content: any) {

    this.modalService
      .open(content, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'transactionCls',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

  }

  public PrintCertificateHTML() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormatHTML());
    pwa?.document.close();
  }

  preparePrintFormatHTML() {

    let htmlData: any = '';
    const printContent: HTMLElement | null =
      document.getElementById('htmlDataCertificate');
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>&nbsp;&nbsp;</title>';
    htmlData +=
      "<style type='text/css' media='print'> @media print{ @page{margin:1.5cm !important;  margin-left: 1.5cm !important;margin-right: 1.5cm !important; }@page :footer {display: none;}@page :header {display: none;} @page:first{size: auto;margin-top: 6.5cm !important; margin-left: 1.5cm !important;margin-bottom: 5cm !important; margin-right: 1.5cm !important;}} </style><script> function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";

    htmlData += printdata;
    htmlData += '</body></html>';

    /* htmlData = "";
    htmlData = this.fileInfoHTMl;
    htmlData += "<script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";
   */
    return htmlData;
  }

  /* HTML certificate end */

  updateWorkflowForTPB() {
    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "-" + month + "-" + year
    let createdDate = moment(dateFormat, "DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
    let postData: any = {};
    postData = {
      workFlowStatusData: {
        createdBy: this.userInfo.userPrincipalName,
        created: createdDate,
        lastModifiedBy: this.userInfo.userPrincipalName,
        lastModified: createdDate,
        id: 0,
        requisitionId: this.requisitionID,
        departmentCode: this.departmentCode,
        requisitionType: 'TitleDeedCumPassbook',
        statusCode: '3',
        statusDesc: 'Approved',
        isMovedToPattadar: false,
      }
    }
    this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, postData).subscribe({
      next: (workflowRes: any) => {
        console.log('response from postWorkFlowStatusUpdate', workflowRes)
        if (workflowRes['result'] == 'Saved Successfully') {
        } else {
          this.commonService.commonErrorPopup('Alert', 'Error occured while saving data in workflow status update table.', 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  waterTaxPostingAmount() {
    console.log("to check");
    console.log(Math.round(this.getByIdResponse?.requisitionData?.amountToBePaid), 'amount check');
    let postData: any = {};
    postData = {
      postingOfAmount: {
        distcode: this.waterTax[0]?.distCode,
        mandalcode: this.waterTax[0].mandalCode,
        villagecode: this.waterTax[0].villageCode,
        khatano: this.waterTax[0].khata_Number,
        txtamount: (this.requisitionInfo.transactionMode === 'cash') ? Math.round(this.getByIdResponse?.requisitionData?.amountToBePaid) : this.getByIdResponse?.requisitionData?.amountToBePaid,
        transcationid: this.requisitionInfo.transactionID,
        uid: 'Land_WLU',
        pwd: 'UL@nD@2023',
        dept: this.requisitionInfo.departmentID,
        mobileno: this.requisitionInfo.mobileNumber,
      }
    }
    this.commonService.postRequestForWebLand(this.commonConstants.getWaterTaxPaymentDetails, postData).subscribe({
      next: (responseData: any) => {
        console.log('response from getWaterTaxPaymentDetails', responseData);
        this.postingofAmount = responseData?.postingOfAmountResponse?.postingOfAmountResult;
        console.log('postingofamount', this.postingofAmount);
        if (responseData?.postingOfAmountResponse?.postingOfAmountResult == 'Success') {
          this.wFlowGetSLAData(this.reqType, this.getByIdResponse, responseData, postData)
          console.log(this.serviceCode, this.getByIdResponse, responseData, postData, 'test')
        }
        else {
          this.wFlowGetSLAData(this.serviceCode, this.getByIdResponse, responseData, postData)
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  postDataForIssuanceOfBhuHakkuPatram(responseData: any) {
    let applicantName: any;
    if (responseData.requisitionHeader.requisitionType === 'IssuanceOfBhuHakkuPatram') {
      applicantName = responseData.requisitionData.pattadarDetailsList[0]?.pattadarTeluguName;
    } else {
      applicantName = responseData.requisitionData.pattadarDetailsListForSurveyNo[0]?.pattadarName;
    }
    this.commonService.postRequestForWebLand(this.commonConstants.tokenGenerationBHP, {}).subscribe({
      next: (responseToken: any) => {
        if (responseToken.Code == 100) {
          let token = 'Bearer ' + responseToken.Data;
          let postData: any = {};
          postData.Ptype = 'I';
          postData.ApplicationNumber = this.requisitionID;
          postData.DistrictCode = responseData.requisitionData.landDetails.district;
          postData.MandalCode = responseData.requisitionData.landDetails.mandalMunicipality;
          postData.VillageCode = responseData.requisitionData.landDetails.villageWard;
          postData.KhataNo = responseData.requisitionData.khataNo;
          postData.ApplicantName = applicantName;
          postData.PassbookType = responseData.requisitionData.passbookType;
          postData.ApplicationType = responseData.requisitionHeader.requisitionType === 'IssuanceOfBhuHakkuPatram' ? 'B' : 'P';

          this.commonService.postRequestForWebLand(this.commonConstants.postDetailsBHP, postData, 'resurvey', token).subscribe({
            next: (result: any) => {
              if (result.Code == 100) {
                this.workflowUpdateForBHP(responseData, result, postData, '1');
              } else if (result.Code == 101) {
                this.workflowUpdateForBHP(responseData, result, postData, '-1');
              } else {
                this.workflowUpdateForBHP(responseData, result, postData, '-1');
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
          });
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  workflowUpdateForBHP(responseData: any, postAPIRes?: any, postAPIReq?: any, statusCode?: any) {
    let weblandMsg: any;
    let weblandStatus: any;
    let year = this.currentDate.getFullYear()
    let month = this.currentDate.getMonth() + 1
    let day = this.currentDate.getDate()
    let dateFormat = day + "-" + month + "-" + year
    let createdDate = moment(dateFormat, "DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
    let reqType = responseData.requisitionHeader.requisitionType
    let dpCode = responseData.requisitionHeader.department
    let resID = responseData.id
    if (statusCode === '1') {
      weblandMsg = '1';
      weblandStatus = 'Forwarded to Webland Team'
    } else {
      weblandMsg = '-1';
      weblandStatus = 'Not Forwarded to Webland Team'
    }
    let stringToHash = resID + '&' +
      dpCode + '&' +
      '' + '&' + '' + '&' + '' + '&' + weblandStatus + '&' + reqType + '&' +
      this.SLA + '&' + weblandMsg + '&' + weblandStatus + '&' + '' + '&' + this.userInfo.userPrincipalName + '&' + createdDate;
    let postData: any = {};
    postData = {
      strToHash: stringToHash
    }

    let workFlowPostData: any = {}
    workFlowPostData = {
      workFlowStatusData: {
        createdBy: this.userInfo.userPrincipalName,
        created: createdDate,
        lastModifiedBy: this.userInfo.userPrincipalName,
        lastModified: createdDate,
        id: 0,
        requisitionId: resID,
        departmentCode: dpCode,
        requisitionType: reqType,
        subRequisitionType: '',
        statusCode: weblandMsg,
        statusDesc: weblandStatus,
        requisitionStatus: (postAPIRes != undefined || postAPIRes != null) ? JSON.stringify(postAPIRes) : '',
        slaDays: this.SLA,
        officerId: '',
        officerName: '',
        officerMobileNumber: '',
        additionalInfo1: JSON.stringify(postAPIReq),
        additionalInfo2: '',
        additionalInfo3: (postAPIRes?.Code == 100) ? postAPIRes?.Data?.PassbookNumber : ''
      },
      hash: '',  //responseData['result'].hashRes
    }
    this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData).subscribe({
      next: (workflowRes: any) => {
        if (workflowRes['result'] == 'Saved Successfully') {

        }
        // else {
        //   this.commonService.commonErrorPopup('Alert', 'Error occured while saving data in workflow status update table.', 'md');
        // }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  goToHome() {
    this.router.navigate(['/home']);
  }
  getSadaramCertificate(item: any) {
    if (this.showSuccessTStatus == true) {
      let certPostData: any = {};
      certPostData.sadaremID = this.sadaramId;
      this.commonService.postRequestForWebLand(this.commonConstants.getSadaramCertificateData, certPostData).subscribe({
        next: (certData: any) => {
          if (certData.RESPONSE.Certificate) {
            if (item == 'certificate') {
              let fileInfo = certData.RESPONSE.Certificate;
              const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
              modalRef.componentInstance.sourceFile = fileInfo;
              modalRef.componentInstance.fileName = this.sadaramId;
              modalRef.componentInstance.showPrintDownload = true;
            } else {
              let fileInfo = certData.RESPONSE.IDCard;
              const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
              modalRef.componentInstance.sourceFile = fileInfo;
              modalRef.componentInstance.fileName = this.sadaramId;
              modalRef.componentInstance.showPrintDownload = true;
            }

          }
          else {
            this.commonService.commonErrorPopup('Alert', certData.RESPONSE.Status, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    else {
      this.commonService.commonErrorPopup('Alert', 'Payment not completed', 'md');
    }
  }
  rejectSuoMotoApplication() {
    let postData = {
      "SuoMotoApplicationNumber": this.suomotoApplication,
      "ApplicationStatus": this.suomotoStatus,
      "NewApplicationNumber": this.requisitionID

    };
    this.commonService.postRequest(
      this.commonConstants.RejectSuoMotoApplication, postData
    ).subscribe({
      next: (response: any) => {
        if (response) {

        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    })
  }

}



